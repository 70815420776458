'use client'

import { useTranslations } from 'next-intl'

const FormattedMessage = ({
  id,
  values = {},
}: {
  id: string
  values?: any
}) => {
  const t = useTranslations()

  return t(id, values)
}

export { FormattedMessage }
