import { cva } from 'class-variance-authority'

export const DialogStyles = {
  content: cva(
    [
      'relative',
      'flex w-fit flex-col h-fit overflow-hidden rounded-md z-50 items-center justify-center',
      'animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-18 ',
      'focus-visible:outline-none',
    ],
    {
      variants: {
        intent: {
          black: [
            'bg-secondary-black-900 text-neutral-white',
            'pl-4 pr-12 py-4',
          ],
          white: [
            'bg-neutral-white border border-neutral-gray-400',
            'p-6 m-auto',
          ],
        },
      },
      defaultVariants: {
        intent: 'white',
      },
    }
  ),
  overlay: cva(
    [
      'grid z-50 fixed inset-0 ',
      'transition-all duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in',
    ],
    {
      variants: {
        intent: {
          black: [' flex items-center my-auto justify-center'],
          white: ['flex items-center '],
        },
      },
      defaultVariants: {
        intent: 'white',
      },
    }
  ),
}
