export const ESTABLISHMENT_FIELDS = [
  /*'title',
  'term_destination',
  'term_category',
  'term_type',
  'text_phone',
  'geofield',
  'int_zipcode',
  'list',
  'text_long_schedule',
  'link',
  'body',
  'body_a11y',
  'text_flong_additional_info',
  'text_social',
  'image',
  'term_misc',
  'term_services',*/
  'status',
  'computed_image',
  'computed_image.image',
  'phone',
]

export const ESTABLISHMENT_CARD_FIELDS = [
  'type',
  'title',
  'link',
  'status',
  'term_category',
  'term_accommodation_type',
  'term_cuisine_type',
  'term_hotel_chain',
  'term_municipality',
  'term_programs',
  'term_services',
  'geofield_address',
  'computed_image',
  'gmaps_url',
  'body',
  'phone',
]

export const ESTABLISHMENT_ROUTE_FIELDS = [
  'type',
  'status',
  'title',
  'body',
  'text_address',
  'geofield_address',
  'computed_image',
  'gmaps_url',
  'phone',
]

export const ESTABLISHMENT_INCLUDES = [
  'term_category',
  'term_accommodation_type',
  'term_cuisine_type',
  'term_hotel_chain',
  'term_municipality',
  'term_programs',
  'term_services',
  'computed_image',
]
