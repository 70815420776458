'use client'

import { cn } from 'utils-tailwindcss'

import { FooterItemStyles } from './FooterItem.styles'
import { FooterItemProps } from './types'

const FooterItem = ({ children, className }: FooterItemProps) => (
  <div className={cn(FooterItemStyles.root(), className)}>{children}</div>
)

FooterItem.displayName = 'NavigationFooterItem'

export { FooterItem }
