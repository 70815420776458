import Link from 'next/link'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { FileText } from 'lucide-react'

import { DrupalMediaDocumentsProps } from '../../types'

const Default = ({ media }: DrupalMediaDocumentsProps) => {
  const conditionalTranslationKeys = {
    externalLink: 'global.externalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  if (!media.file) {
    return null
  }

  return (
    <div className="flex flex-wrap gap-3">
      <Link
        href={media.file.uri.url ?? ''}
        target="_blank"
        className="flex gap-3 font-semibold no-underline text-primary-red-900"
        aria-label={media.name}
        aria-describedby={translations.externalLink}
      >
        <FileText
          className="text-secondary-black-900 min-w-[24px]"
          role="presentation"
          aria-hidden="true"
        />
        {media.name}
      </Link>
      <p className="sr-only">{translations.newWindow}</p>
    </div>
  )
}

export { Default }
