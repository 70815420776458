import { VIEW_MODE_DEFAULT } from '@/content/constants'

import { DrupalMediaRemoteVideoProps } from './types'
import { Default } from './viewmodes/Default'

const RemoteVideo = ({
  media,
  viewMode = VIEW_MODE_DEFAULT,
}: DrupalMediaRemoteVideoProps) => (
  <div className={`${media.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        default:
          // @ts-ignore
          return <Default media={media} />
      }
    })()}
  </div>
)

export { RemoteVideo }
