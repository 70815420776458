import { ImageStyleBreakPoints } from './types'

export const MOCK_BREAKPOINTS: ImageStyleBreakPoints = {
  // when window width is >= 768 px
  '768': {
    id: 'scale_webp_600x400',
    media: '(max-width: 768px)',
  },
  // when window width is >= 320 px
  '320': {
    id: 'scale_webp_600x400',
    media: '(max-width: 320px)',
  },
  default: {
    id: 'scale_webp_600x400',
  },
}

export const MOCK_FILE_DERIVATIVE = {
  type: 'media--file',
  id: 'b2bd9ad6-9cc6-49d7-90b5-b3027382a4443',
  drupal_internal__fid: '',
  langcode: 'es',
  filename: 'lorem.png',
  uri: {
    value: 'public://0000-00/lorem.png',
    url: '/sites/default/files/0000-00/lorem.png',
  },
  filemime: 'image/png',
  filesize: 0,
  status: true,
  created: '2023-06-20T08:49:34+00:00',
  changed: '2023-06-20T08:49:34+00:00',
  links: {
    thumbnail: {
      href: 'https://picsum.photos/id/883/20/20',
      title: '',
      type: 'image/png',
      meta: {
        width: 20,
        height: 20,
      },
    },
    scale_webp_640x320: {
      href: 'https://picsum.photos/id/883/640/320',
      title: 'Image Style: [Scale][Webp] 640x320',
      type: 'image/png',
      meta: {
        width: 640,
        height: 320,
      },
    },
    scale_webp_320x240: {
      href: 'https://picsum.photos/id/883/390/260',
      title: '[Scale][Webp] 320x240',
      type: 'image/png',
      meta: {
        width: 390,
        height: 260,
      },
    },
    scale_webp_600x400: {
      href: 'https://picsum.photos/id/883/600/400',
      title: '[Scale][Webp] 600x400',
      type: 'image/png',
      meta: {
        width: 600,
        height: 400,
      },
    },
    scale_webp_110x85: {
      href: 'https://picsum.photos/id/883/110/85',
      title: '[Scale][Webp] 110x85',
      type: 'image/png',
      meta: {
        width: 110,
        height: 85,
      },
    },
    scale_webp_150x100: {
      href: 'https://picsum.photos/id/883/150/100',
      title: '[Scale][Webp] 150x100',
      type: 'image/png',
      meta: {
        width: 150,
        height: 100,
      },
    },
    scale_webp_390x520: {
      href: 'https://picsum.photos/id/883/390/520',
      title: '[Scale][Webp] 390x520',
      type: 'image/png',
      meta: {
        width: 390,
        height: 520,
      },
    },
    scale_webp_390x260: {
      href: 'https://picsum.photos/id/883/390/260',

      title: '[Scale][Webp] 390x260',
      type: 'image/png',
      meta: {
        width: 390,
        height: 260,
      },
    },
    scale_webp_286x191: {
      href: 'https://picsum.photos/id/883/286/191',

      title: '[Scale][Webp] 286x191',
      type: 'image/png',
      meta: {
        width: 286,
        height: 191,
      },
    },
    scale_webp_600x150: {
      href: 'https://picsum.photos/id/883/600/150',
      title: '[Scale][Webp] 600x150',
      type: 'image/png',
      meta: {
        width: 600,
        height: 150,
      },
    },
    scale_webp_1280x720: {
      href: 'https://picsum.photos/id/883/1280/720',
      title: '[Scale][Webp] 1280x720',
      type: 'image/png',
      meta: {
        width: 1280,
        height: 720,
      },
    },
    scale_webp_640x400: {
      href: 'https://picsum.photos/id/883/640/400',
      title: '[Scale][Webp] 640x400',
      type: 'image/png',
      meta: {
        width: 640,
        height: 400,
      },
    },
    scale_webp_1920x400: {
      href: 'https://picsum.photos/id/883/1920/400',
      title: '[Scale][Webp] 1920x400',
      type: 'image/png',
      meta: {
        width: 1920,
        height: 400,
      },
    },
    scale_webp_596x806: {
      href: 'https://picsum.photos/id/883/596/806',
      title: 'Image Style: [Scale][Webp] 596x806',
      type: 'image/png',
      meta: {
        width: 596,
        height: 806,
      },
    },
    scale_webp_768x1024: {
      href: 'https://picsum.photos/id/883/768/1024',
      title: 'Image Style: [Scale][Webp] 768x1024',
      type: 'image/png',
      meta: {
        width: 596,
        height: 806,
      },
    },
    scale_webp_474x316: {
      href: 'https://picsum.photos/474/316',
      title: 'Image Style: [Scale][Webp] 474x316',
      type: 'image/png',
      meta: {
        width: 474,
        height: 316,
      },
    },
    scale_webp_474x520: {
      href: 'https://picsum.photos/474/520',
      title: 'Image Style: [Scale][Webp] 474x520',
      type: 'image/png',
      meta: {
        width: 474,
        height: 520,
      },
    },
  },
  resourceIdObjMeta: {
    alt: 'Lorem ipsum dolor sit amet',
    title: 'Lorem ipsum',
    width: 0,
    height: 0,
    drupal_internal__target_id: 0,
    imageDerivatives: {
      links: {
        thumbnail: {
          href: 'https://picsum.photos/id/883/20/20',
          title: '',
          type: 'image/png',
          meta: {
            width: 20,
            height: 20,
          },
        },
        scale_webp_640x320: {
          href: 'https://picsum.photos/id/883/640/320',
          title: 'Image Style: [Scale][Webp] 640x320',
          type: 'image/png',
          meta: {
            width: 640,
            height: 320,
          },
        },
        scale_webp_320x240: {
          href: 'https://picsum.photos/id/883/390/260',
          title: '[Scale][Webp] 320x240',
          type: 'image/png',
          meta: {
            width: 390,
            height: 260,
          },
        },
        scale_webp_600x400: {
          href: 'https://picsum.photos/id/883/600/400',
          title: '[Scale][Webp] 600x400',
          type: 'image/png',
          meta: {
            width: 600,
            height: 400,
          },
        },
        scale_webp_110x85: {
          href: 'https://picsum.photos/id/883/110/85',
          title: '[Scale][Webp] 110x85',
          type: 'image/png',
          meta: {
            width: 110,
            height: 85,
          },
        },
        scale_webp_150x100: {
          href: 'https://picsum.photos/id/883/150/100',
          title: '[Scale][Webp] 150x100',
          type: 'image/png',
          meta: {
            width: 150,
            height: 100,
          },
        },
        scale_webp_390x520: {
          href: 'https://picsum.photos/id/883/390/520',
          title: '[Scale][Webp] 390x520',
          type: 'image/png',
          meta: {
            width: 390,
            height: 520,
          },
        },
        scale_webp_600x150: {
          href: 'https://picsum.photos/id/883/600/150',
          title: '[Scale][Webp] 600x150',
          type: 'image/png',
          meta: {
            width: 600,
            height: 150,
          },
        },
        scale_webp_1280x720: {
          href: 'https://picsum.photos/id/883/1280/720',
          title: '[Scale][Webp] 1280x720',
          type: 'image/png',
          meta: {
            width: 1280,
            height: 720,
          },
        },
        scale_webp_640x400: {
          href: 'https://picsum.photos/id/883/640/400',
          title: '[Scale][Webp] 640x400',
          type: 'image/png',
          meta: {
            width: 640,
            height: 400,
          },
        },
        scale_webp_1920x400: {
          href: 'https://picsum.photos/id/883/1920/400',
          title: '[Scale][Webp] 1920x400',
          type: 'image/png',
          meta: {
            width: 1920,
            height: 400,
          },
        },
        scale_webp_596x806: {
          href: 'https://picsum.photos/id/883/596/806',
          title: 'Image Style: [Scale][Webp] 596x806',
          type: 'image/png',
          meta: {
            width: 596,
            height: 806,
          },
        },
        scale_webp_768x1024: {
          href: 'https://picsum.photos/id/883/768/1024',
          title: 'Image Style: [Scale][Webp] 768x1024',
          type: 'image/png',
          meta: {
            width: 596,
            height: 806,
          },
        },
        scale_webp_474x316: {
          href: 'https://picsum.photos/474/316',
          title: 'Image Style: [Scale][Webp] 474x316',
          type: 'image/png',
          meta: {
            width: 474,
            height: 316,
          },
        },
        scale_webp_474x520: {
          href: 'https://picsum.photos/474/520',
          title: 'Image Style: [Scale][Webp] 474x520',
          type: 'image/png',
          meta: {
            width: 474,
            height: 520,
          },
        },
        scale_webp_120x80: {
          href: 'https://picsum.photos/120/80',
          title: 'Image Style: [Scale][Webp] 120x80',
          type: 'image/png',
          meta: {
            width: 120,
            height: 80,
          },
        },
      },
    },
  },
}
