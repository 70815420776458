import { forwardRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { ImageProps } from './types'

const Image = forwardRef<HTMLDivElement, ImageProps>(
  ({ className, children, ...rest }, ref) => (
    <div ref={ref} className={cn('relative w-full', className)} {...rest}>
      {children}
    </div>
  )
)

Image.displayName = 'Image'

export { Image }
