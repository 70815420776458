import { ElementRef, forwardRef } from 'react'
import Link from 'next/link'
import { CKEditor } from '@/components/CKEditor'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Media } from '@/components/Media'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import {
  CheckCircle2,
  CopyIcon,
  ExternalLinkIcon,
  MapPin,
  MinusCircleIcon,
  PlusCircleIcon,
} from 'lucide-react'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardImage,
  CardSubtitle,
  CardTitle,
  CloseButton,
  Separator,
  Tag,
  Text,
} from 'ui'
import { cn } from 'utils-tailwindcss'

import { BREAKPOINTS } from '../../constants'
import { LocationCardProps } from '../../types'
import { useCopyPath } from '../../useCopyPath'

const Itinerary = forwardRef<ElementRef<typeof Card>, LocationCardProps>(
  (
    {
      className,
      location,
      image,
      tags,
      title,
      link,
      description,
      onCloseClick,
      onRemoveClick,
      onAddedClick,
      isAdded,
      geofield_address,
      gmapsUrl,
    },
    ref
  ) => {
    const {
      state: { showMessage },
      actions: { copyPath },
    } = useCopyPath(gmapsUrl || '')
    const handleClick = () => {
      copyPath()
    }

    const conditionalTranslationKeys = {
      copyToClipboard: 'searcher.routes.copyToClipboard',
      copied: 'searcher.routes.copied',
      knowMore: 'global.knowMore',
      addToMyItinerary: 'itinerary.addToMyItinerary',
      removeFromMyItinerary: 'itinerary.removeFromMyItinerary',
      externalLink: 'global.externalLink',
      newWindow: 'global.newWindow',
    }

    const translations: { [key: string]: string } = useCustomTranslations(
      conditionalTranslationKeys
    )

    return (
      <Card ref={ref} className={cn('border max-w-[22rem]', className)}>
        <CardContent className="pb-1">
          <CardTitle className="w-full pt-0 no-underline ">
            {title && (
              <div className="flex items-start justify-between mb-2">
                <Text className="text-lg font-bold max-w-[17.1875rem]">
                  {title}
                </Text>
                <CloseButton onClick={onCloseClick} />
              </div>
            )}
          </CardTitle>
          <CardImage className="self-center rounded h-fit w-fit">
            <div className="rounded w-[18.75rem]">
              {!!image && (
                <Media
                  //@ts-ignore
                  breakpoints={BREAKPOINTS}
                  media={image}
                />
              )}
            </div>
            {location && (
              <div className="flex items-center gap-2 py-3 text-text-medium">
                <MapPin
                  size={16}
                  className="text-primary-red-900"
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
                <Text>{location}</Text>
              </div>
            )}
          </CardImage>

          <Link
            href={
              gmapsUrl ??
              `https://www.google.com/maps?q=${geofield_address?.latlon}`
            }
            target="_blank"
            className="flex flex-row gap-3 font-semibold"
            aria-label={FormattedMessage({ id: 'searcher.routes.openInGMaps' })}
            aria-describedby={translations.externalLink}
          >
            <ExternalLinkIcon
              aria-roledescription="presentation"
              aria-hidden="true"
            />
            <FormattedMessage id="searcher.routes.openInGMaps" />
          </Link>
          <p className="sr-only">{translations.newWindow}</p>

          {gmapsUrl && (
            <div className="relative inline-block">
              <Button intent="tertiary" onClick={handleClick} className="p-0">
                <CopyIcon
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
                {translations.copyToClipboard}
              </Button>
              {showMessage && (
                <Alert>
                  <CheckCircle2
                    height="1rem"
                    aria-roledescription="presentation"
                    aria-hidden="true"
                  />
                  {translations.copied}
                </Alert>
              )}
            </div>
          )}

          <CardSubtitle className="flex flex-col py-3 text-text-medium">
            {!!tags?.length && (
              <div className="flex flex-wrap gap-4 mb-3">
                {tags?.map((item) => (
                  <Tag className="px-3 py-1 truncate" key={item.id}>
                    {item.name}
                  </Tag>
                ))}
              </div>
            )}

            {description && (
              <CKEditor
                text={description}
                className="mb-3 overflow-y-auto line-clamp-4"
              />
            )}
            {link && (
              <>
                <Link
                  href={link || ''}
                  target="_blank"
                  className="flex flex-row gap-3 font-semibold text-secondary-black-900"
                  aria-label={translations.knowMore}
                  aria-describedby={translations.externalLink}
                >
                  <ExternalLinkIcon
                    aria-roledescription="presentation"
                    aria-hidden="true"
                  />
                  {translations.knowMore}
                </Link>
                <p className="sr-only">{translations.newWindow}</p>
              </>
            )}
          </CardSubtitle>
          <Separator color="gray" size={'sm'} className="my-0 lg:my-0" />
          {!isAdded ? (
            <Button
              onClick={onAddedClick}
              intent={'tertiary'}
              className="justify-start pl-0"
            >
              <PlusCircleIcon
                size={22}
                aria-roledescription="presentation"
                aria-hidden="true"
              />
              {translations.addToMyItinerary}
            </Button>
          ) : (
            <Button
              onClick={onRemoveClick}
              fullWidth={true}
              intent="tertiary"
              className="justify-start pl-0"
            >
              <MinusCircleIcon
                size={22}
                aria-roledescription="presentation"
                aria-hidden="true"
              />
              {translations.removeFromMyItinerary}
            </Button>
          )}
        </CardContent>
      </Card>
    )
  }
)

Itinerary.displayName = 'Itinerary'

export { Itinerary }
