import { SCALE_WEBP_1280x720 } from '@/content/constants'
import {
  ActivityIcon,
  HistoryIcon,
  PersonStandingIcon,
  RulerIcon,
  SchoolIcon,
  SparklesIcon,
  TreePineIcon,
  UtensilsIcon,
} from 'lucide-react'

import { ImageStyleBreakPoints } from '../ImageStyle'
import { CICLA_MADRID_INDEX, GRAN_TOUR_INDEX } from '../List/constants'
import { Facets } from '../List/types'
import { LIST_TYPE_ITINERARY } from '../Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'

export const SET_CURRENT_ROUTE = 'set_current_route'
export const SET_SHOW_FILTERS = 'set_show_filters'
export const SET_SHOW_SORT_FILTERS = 'set_show_sort_filters'
export const SET_PAGE = 'set_page'
export const SET_SELECTED = 'set_selected'
export const SET_ID = 'set_id'
export const TOOGLE_FILTERS = 'toogle_filters'
export const TOOGLE_SHOW_ITINERARIES = 'toogle_list'
export const TOOGLE_SHOW_ITINERARIES_DIALOG = 'toogle_dialog'

export const TYPE_ROUTE = 'route'
export const TYPE_CATEGORY = 'category'

export const DIFICULTIES_ROUTES_FILTER = 'routes_level'
export const ROUTES_TYPE = 'routes_type'
export const ACTIVITIES_ROUTES_FILTER = 'activities'
export const DURATION_ROUTES_FILTER = 'routes_duration'
export const DISTANCE_ROUTES_FILTER = 'routes_distance'
export const CLOSEST_ROUTES_FILTER = 'routes_closest'
export const CATEGORY_ROUTES_FILTER = 'routes_category'
export const ITINERARIES_FILTER_SERVICES = 'itineraries_services'
export const ITINERARIES_FILTER = 'itineraries_category'
export const CATEGORY_FILTER = 'term_categories'
// LAS REFERENCIAS A ESTOS FILTROS ESTAN HECHAS POR ID DE TAXONOMÍA, SI LA TAXONOMÍA CAMBIA EN EL BACK EL FILTRO NO SERÁ VISIBLE
export const ITINERARIES_GASTRONOMY_FILTER =
  '095a1865-2634-4d97-82ec-8ffe3e1407d7'
export const ITINERARIES_FREETIME_FILTER =
  '73f1952f-ba1b-4f3b-894f-803187dea990'
export const ITINERARIES_NATURE_FILTER = '96d3b417-2788-4cbd-b412-f9911c63b775'
export const ITINERARIES_CULTURAL_FILTER =
  '7217c11f-8ff1-4fb7-88f8-4ad7aa9c88bd'

export const CULTURE_ROUTES_FILTER = '29328cf4-dac2-4b07-a26f-fc74c7cf0800'
export const GASTRONOMY_ROUTES_FILTER = '3b1c1e40-d5fd-4ed5-add4-50b4ecf454c9'
export const NATURAL_ROUTES_FILTER = '993c5883-740b-4180-a723-5e63dc93a738'

export const SORT_FILTER = 'sort'

export const BREAKPOINTS: ImageStyleBreakPoints = {
  default: {
    id: SCALE_WEBP_1280x720,
  },
}

export const FACETS_TYPE = {
  [TYPE_ROUTE]: [
    CULTURE_ROUTES_FILTER,
    GASTRONOMY_ROUTES_FILTER,
    NATURAL_ROUTES_FILTER,
  ],
  [TYPE_CATEGORY]: [
    DIFICULTIES_ROUTES_FILTER,
    ACTIVITIES_ROUTES_FILTER,
    DURATION_ROUTES_FILTER,
    DISTANCE_ROUTES_FILTER,
    CATEGORY_ROUTES_FILTER,
  ],
  [CICLA_MADRID_INDEX]: [
    DIFICULTIES_ROUTES_FILTER,
    DURATION_ROUTES_FILTER,
    DISTANCE_ROUTES_FILTER,
  ],
  [GRAN_TOUR_INDEX]: [
    DIFICULTIES_ROUTES_FILTER,
    DURATION_ROUTES_FILTER,
    DISTANCE_ROUTES_FILTER,
  ],
  [LIST_TYPE_ITINERARY]: [],
}

export const FACETS_ICONS = {
  [DIFICULTIES_ROUTES_FILTER]: () => <ActivityIcon />,
  [ACTIVITIES_ROUTES_FILTER]: () => <PersonStandingIcon />,
  [DURATION_ROUTES_FILTER]: () => <HistoryIcon />,
  [DISTANCE_ROUTES_FILTER]: () => <RulerIcon />,
  [CULTURE_ROUTES_FILTER]: () => <SchoolIcon />,
  [GASTRONOMY_ROUTES_FILTER]: () => <UtensilsIcon />,
  [NATURAL_ROUTES_FILTER]: () => <TreePineIcon />,
  [CATEGORY_ROUTES_FILTER]: () => <PersonStandingIcon />,
  [ITINERARIES_GASTRONOMY_FILTER]: () => <UtensilsIcon />,
  [ITINERARIES_CULTURAL_FILTER]: () => <SchoolIcon />,
  [ITINERARIES_NATURE_FILTER]: () => <TreePineIcon />,
  [ITINERARIES_FREETIME_FILTER]: () => <SparklesIcon />,
}

export const SORT_FILTER_FACET: Facets = {
  id: SORT_FILTER,
  label: '',
  path: '',
  terms: [
    {
      values: {
        value: `${DIFICULTIES_ROUTES_FILTER}-ASC`,
        label: 'Dificultad: de menor a mayor',
        count: 2,
      },
      type: SORT_FILTER,
    },
    {
      values: {
        value: `${DIFICULTIES_ROUTES_FILTER}-DESC`,
        label: 'Dificultad: de mayor a menor',
        count: 2,
      },
      type: SORT_FILTER,
    },
    {
      values: {
        value: `${CLOSEST_ROUTES_FILTER}-CLOSE`,
        label: 'Más cercana',
        count: 2,
      },
      type: SORT_FILTER,
    },
    {
      values: {
        value: `${DURATION_ROUTES_FILTER}-ASC`,
        label: 'Duración: de menor a mayor',
        count: 2,
      },
      type: SORT_FILTER,
    },
    {
      values: {
        value: `${DURATION_ROUTES_FILTER}-DESC`,
        label: 'Duración: de mayor a menor',
        count: 2,
      },
      type: SORT_FILTER,
    },
    {
      values: {
        value: `${DISTANCE_ROUTES_FILTER}-ASC`,
        label: 'Longitud: de menor a mayor',
        count: 2,
      },
      type: SORT_FILTER,
    },
    {
      values: {
        value: `${DISTANCE_ROUTES_FILTER}-DESC`,
        label: 'Longitud: de mayor a menor',
        count: 2,
      },
      type: SORT_FILTER,
    },
  ],
}

export const ROUTES_CATEGORIES = [
  CULTURE_ROUTES_FILTER,
  GASTRONOMY_ROUTES_FILTER,
  NATURAL_ROUTES_FILTER,
]
