import { FormattedMessage } from '@/components/FormattedMessage'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { Heart } from 'lucide-react'
import { InfoTag, InfoTagBody, InfoTagHeader } from 'ui'

const PointsOfInterest = ({ points }) => (
  <InfoTag>
    <InfoTagHeader>
      <Heart />
      <FormattedMessage id="global.pointsOfInterest" />
    </InfoTagHeader>
    <InfoTagBody>
      {points.slice(0, 4).map((content: any, index: number, array: any[]) => (
        <div key={index} className="inline">
          {content.title}
          {index !== array.length - 1 && <span> • </span>}
        </div>
      ))}
      {points.length > 6 && (
        <p className="text-sm">
          <FormattedRichMessage
            id="node.route.andPointsMore"
            values={{ points: points.length - 5 }}
          />
        </p>
      )}
    </InfoTagBody>
  </InfoTag>
)

export { PointsOfInterest }
