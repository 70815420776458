import { Carousel } from '@/components/Carousel'
import { Grid } from '@/components/Grid'
import { Link } from '@/components/Link'
import { Node } from '@/components/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { VIEW_MODE_CARD_BASIC } from '@/content/constants'
import { ArrowRight } from 'lucide-react'

import { DrupalParagraphResource } from '../../types'

const Default = ({ paragraph }: { paragraph: DrupalParagraphResource }) => {
  if (paragraph.contents.length > 3) {
    return (
      <div className="custom-container">
        <Carousel
          title={paragraph.title}
          body={paragraph?.body}
          redLine={true}
          cols={3}
        >
          {!!paragraph.contents.length &&
            paragraph.contents
              .filter(
                (publishedContent) =>
                  publishedContent.id !== 'missing' && publishedContent?.status
              )
              .map((content: BundleDrupalNode) => (
                <Node
                  node={{
                    ...content,
                    popup: true,
                    viewMode: VIEW_MODE_CARD_BASIC,
                  }}
                  key={content.id}
                />
              ))}
        </Carousel>
        {paragraph.link && (
          <div className="container mb-12 text-right md:mb-18">
            <Link
              href={paragraph.link.uri}
              intent="link"
              className="font-semibold underline"
            >
              {paragraph.link.title}{' '}
              <ArrowRight
                aria-roledescription="presentation"
                aria-hidden="true"
              />
            </Link>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <Grid
        title={paragraph.title}
        body={paragraph?.body}
        redLine={true}
        cols={paragraph.contents.length}
      >
        {!!paragraph.contents.length &&
          paragraph.contents
            .filter(
              (publishedContent) =>
                publishedContent.id !== 'missing' && publishedContent?.status
            )
            .map((content: BundleDrupalNode) => (
              <Node
                node={{
                  ...content,
                  popup: true,
                  viewMode: VIEW_MODE_CARD_BASIC,
                }}
                key={content.id}
              />
            ))}
      </Grid>
      {paragraph.link && (
        <div className="container mb-12 text-right md:mb-18">
          <Link
            href={paragraph.link.uri}
            intent="link"
            className="font-semibold underline"
          >
            {paragraph.link.title}{' '}
            <ArrowRight
              aria-roledescription="presentation"
              aria-hidden="true"
            />
          </Link>
        </div>
      )}
    </>
  )
}

export { Default }
