import { FacebookIcon, LucideIcon, MailIcon } from 'lucide-react'

import { ExternalLink } from './components/ExternalLink'
import { MailTo } from './components/MailTo'
import { TwitterIcon } from './components/TwitterIcon'

export const SOCIAL_NETWORKS = [
  {
    id: 'facebook',
    language: 'Facebook',
    icon: FacebookIcon,
    Component: ExternalLink,
  },
  {
    id: 'twitter',
    language: 'X',
    icon: TwitterIcon as unknown as LucideIcon,
    Component: ExternalLink,
  },
  {
    id: 'email',
    language: 'Email',
    icon: MailIcon,
    Component: MailTo,
  },
]
