'use client'

import { cn } from 'utils-tailwindcss'

import { CloseButton } from '../CloseButton'
import { FilterTagStyles } from './FilterTag.styles'
import { FilterTagProps } from './types'

const FilterTag = ({
  className,
  children,
  onCloseClick,
  ...props
}: FilterTagProps) => (
  <div className={cn(FilterTagStyles.root(), className)} {...props}>
    {children}
    <CloseButton
      onClick={onCloseClick}
      className={cn(FilterTagStyles.close())}
    />
  </div>
)

export { FilterTag }
