import { ComponentPropsWithRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { TagStyles } from './Tag.styles'

const Tag = ({
  className,
  children,
  ...props
}: ComponentPropsWithRef<'div'>) => (
  <div className={cn(TagStyles(), className)} {...props}>
    {children}
  </div>
)

export { Tag }
