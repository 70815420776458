import { DrupalNodePlace } from '@/components/Node/components/Place/types'
import { Accordion, AccordionItem, AccordionTrigger, Card } from 'ui'
import { cn } from 'utils-tailwindcss'

const Navigation = ({
  villages,
  currentVillage,
  onItemClick,
}: {
  villages: DrupalNodePlace[]
  currentVillage: DrupalNodePlace
  onItemClick: (_zone: DrupalNodePlace) => void
}) => (
  <Card className="min-w-[21.125rem] px-6 border shadow-none overflow-y-scroll custom-scroll">
    <Accordion type="single" defaultValue="item-0">
      {villages.map((item) => {
        return (
          <AccordionItem
            key={item.id}
            value={`item-${item.id}`}
            onClick={() => onItemClick(item)}
          >
            <AccordionTrigger
              icon="right"
              className={cn('py-4 font-normal', {
                'text-primary-red-900 font-bold':
                  item.id === currentVillage?.id,
              })}
            >
              {item.title}
            </AccordionTrigger>
          </AccordionItem>
        )
      })}
    </Accordion>
  </Card>
)
export { Navigation }
