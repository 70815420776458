import { LocationCard } from '@/components/LocationCard'

import { useVillagesMapProvider } from '../../VillagesMapProvider'
import { Map } from '../Map'
import { Navigation } from './components'

export const Desktop = () => {
  const {
    actions: { setCurrentVillage },
    state: { currentVillage, villages },
  } = useVillagesMapProvider()

  return (
    <div className="container flex flex-col md:flex-row h-[47.8125rem]">
      <Navigation
        currentVillage={currentVillage}
        onItemClick={setCurrentVillage}
        villages={villages}
      />
      <div className="relative w-full h-full">
        <Map />
        {currentVillage && (
          <LocationCard
            className="absolute bottom-2 left-2"
            onCloseClick={() => {
              setCurrentVillage(null)
            }}
            title={currentVillage.title}
            description={currentVillage.body}
            location={currentVillage.text_address}
            image={currentVillage.computed_image}
            link={currentVillage.path?.alias}
          />
        )}
      </div>
    </div>
  )
}
