import { Drawer } from 'vaul'

export { Content as MobileDrawerContent } from './Content'

const MobileDrawerTrigger = Drawer.Trigger
export { MobileDrawerTrigger }
const MobileDrawerPortal = Drawer.Portal
export { MobileDrawerPortal }
const MobileDrawerOverlay = Drawer.Overlay
export { MobileDrawerOverlay }
const MobileDrawerTitle = Drawer.Title
export { MobileDrawerTitle }
const MobileDrawerClose = Drawer.Close
export { MobileDrawerClose }
const MobileDrawerDescription = Drawer.Description
export { MobileDrawerDescription }
