import { useCallback, useEffect, useMemo, useState } from 'react'
import { fetchIndexDrupal } from '@/components/List/services/api'
import { Facets } from '@/components/List/types'
import { NODE_ROUTE_BASE } from '@/components/Node/constants'
import { BundleDrupalNode } from '@/components/Node/types'
import { LIST_TYPE_ITINERARY } from '@/components/Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'
import {
  FACETS_TYPE,
  SORT_FILTER,
  TYPE_CATEGORY,
  TYPE_ROUTE,
} from '@/components/RoutesList/constants'
import { debounce } from '@/components/RoutesList/helpers/debounce'
import { convertFilters } from '@/components/RoutesList/helpers/utils'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { HookOutput } from 'utils'

import { useItineraryProvider } from '../../../../ItineraryProvider'
import { useMapListProvider } from '../../../../MapListProvider'

type useFilterResult = HookOutput<
  {
    showFilters: boolean
    form: UseFormReturn<FieldValues, any, undefined>
    results?: BundleDrupalNode[]
    facets?: Facets[]
    topFilters?: Facets[]
    bottomFilters?: Facets[]
    isPending: boolean
    data?: number
  },
  {
    onResetForm: () => void
    onSubmitFilter: (
      _data: Record<string, string | string[] | number[]>
    ) => void
    fetch: () => void
  }
>

export const useFilters = (): useFilterResult => {
  const {
    state: { showFilters, form: formMap },
    listState: { facets, query, total_items, activeFilters },
    type,
    onSubmitFilter,
    removeFilterFromList,
    setShowFilters,
    onResetForm: onResetMap,
  } = useMapListProvider()

  const {
    state: { form: formItinerary },
  } = useItineraryProvider()

  const [topFilters, setTopFilters] = useState<Facets[] | undefined>([])
  const [bottomFilters, setBottomFilters] = useState<Facets[] | undefined>([])
  const total = useMemo(() => total_items ?? 0, [total_items])
  const [data, setData] = useState<number | undefined>(total)
  const [isPending, setIsPending] = useState(false)
  const form = useMemo(
    () => (type === LIST_TYPE_ITINERARY ? formItinerary : formMap),
    [type, formItinerary, formMap]
  )
  useEffect(() => {
    total !== data && setData(total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total])

  useEffect(() => {
    if (type === NODE_ROUTE_BASE) {
      setTopFilters(
        facets?.filter((filter) =>
          FACETS_TYPE[TYPE_CATEGORY].includes(filter.id)
        )
      )
      setBottomFilters(
        facets?.filter((filter) => FACETS_TYPE[TYPE_ROUTE].includes(filter.id))
      )
    } else if (type === LIST_TYPE_ITINERARY) {
      setTopFilters(facets)
      setBottomFilters([])
    } else {
      setTopFilters(
        facets?.filter((filter) => FACETS_TYPE[type].includes(filter.id))
      )
      setBottomFilters([])
    }
  }, [facets, type])

  const filterFetch = useCallback(
    async (data): Promise<number | undefined> => {
      try {
        const filters = convertFilters(data, facets)

        const response = await fetchIndexDrupal(
          { activeFilters: filters, query: query, per_page: 1, page: 1 },
          type,
          true
        )
        return response.total_items
      } catch (error) {
        console.error('Error en convertFilters: ', error)
        return 0
      }
    },
    [facets, query, type]
  )
  const fetch = useCallback(async () => {
    setIsPending(true)
    debounce(
      () =>
        filterFetch(form.getValues()).then((response) => {
          setData(response ?? 0)
          setIsPending(false)
        }),
      700
    )
  }, [form, filterFetch])

  const onResetItinerary = useCallback(() => {
    const active =
      activeFilters?.filter((fil) => fil.type === SORT_FILTER) ?? []
    removeFilterFromList(active)
    formItinerary.reset()
    setShowFilters(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeFilterFromList, formItinerary])

  const onResetForm = useMemo(
    () => (type === LIST_TYPE_ITINERARY ? onResetItinerary : onResetMap),
    [type, onResetItinerary, onResetMap]
  )

  return {
    state: { showFilters, topFilters, bottomFilters, isPending, data, form },
    actions: { onSubmitFilter, onResetForm, fetch },
  }
}
