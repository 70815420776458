import { DrupalClient } from 'next-drupal'

export const drupal = new DrupalClient(
  process.env.NEXT_PUBLIC_DRUPAL_BASE_URL || '',
  {
    previewSecret: process.env.DRUPAL_PREVIEW_SECRET,
    frontPage: '/home',
    auth: {
      username: process.env.DRUPAL_CLIENT_USERNAME! ?? ' ',
      password: process.env.DRUPAL_CLIENT_PASS! ?? ' ',
    },
    withAuth: true,
    debug: false,
  }
)

// Redeploy
