import 'swiper/css'
import 'swiper/css/grid'

import { useState } from 'react'
import { Link } from '@/components/Link'
import { ShowMoreCkEditor } from '@/components/ShowMoreCkEditor'
import { Title } from '@/components/Title'
import { internalLink } from '@/lib/utils/internalLink'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ArrowRight, ExternalLink } from 'lucide-react'
import { Carousel as CarouselUI } from 'ui'
import { DrupalFieldTextFormat } from 'utils-drupal'

import { CARD_BIG, EXTERNAL_CONTENT, INTERNAL_CONTENT } from '../constants'
import { Layout } from './components/Layout'
import { DrupalParagraphGalleryCarouselProps } from './types'

const Carousel = ({ paragraph }: DrupalParagraphGalleryCarouselProps) => {
  /*  paragraph.paragraph_contents = paragraph.paragraph_contents.filter(
    (p) => p?.content?.id !== 'missing' && p.content?.status
  ) */

  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [activeSlide, setActiveSlide] = useState(
    paragraph.paragraph_contents[0] || null
  )

  const onSlideClick = (index: number) => {
    activeSlideIndex !== index &&
      setActiveSlide(paragraph.paragraph_contents[index])
    setActiveSlideIndex(index)
  }

  const INTERNAL_LINK = paragraph.link && internalLink(paragraph.link.uri)

  const conditionalTranslationKeys = {
    showMore: 'paragraph.gallery.showMore',
    externalLink: 'global.externalLink',
    internalLink: 'global.internalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div className="flex flex-col gap-8 bg-neutral-white my-18">
      <div className="container">
        <Title
          title={paragraph.title}
          subtitle={
            typeof paragraph.subtitle !== 'string'
              ? paragraph.subtitle
              : ({
                  value: paragraph.subtitle,
                } as unknown as DrupalFieldTextFormat)
          }
        />
        <ShowMoreCkEditor
          description={paragraph.body}
          className="text-text-medium"
        />
        {activeSlide &&
          (() => {
            switch (activeSlide.type) {
              case INTERNAL_CONTENT:
                return (
                  activeSlide.content?.id !== 'missing' &&
                  activeSlide.content?.status && (
                    <Layout
                      paragraph={activeSlide.content}
                      type={CARD_BIG}
                      key={activeSlide.id}
                      url={activeSlide.content.path?.alias}
                      showMore={translations.showMore}
                    />
                  )
                )

              case EXTERNAL_CONTENT:
                return (
                  <Layout
                    paragraph={activeSlide}
                    type={CARD_BIG}
                    key={activeSlide.id}
                    url={activeSlide.link?.uri}
                    showMore={activeSlide.link?.title || translations.showMore}
                  />
                )
            }
          })()}
      </div>
      <div className="container">
        <CarouselUI cols="auto" onSlideClick={onSlideClick}>
          {paragraph.paragraph_contents.map((paragraphType, index) => {
            switch (paragraphType.type) {
              case INTERNAL_CONTENT:
                return (
                  paragraphType.content?.id !== 'missing' &&
                  paragraphType.content?.status && (
                    <Layout
                      paragraph={paragraphType.content}
                      key={paragraphType.id}
                      index={index}
                      activeSlideIndex={activeSlideIndex}
                      url={activeSlide.content?.path?.alias}
                      showMore={translations.showMore}
                    />
                  )
                )

              case EXTERNAL_CONTENT:
                return (
                  <Layout
                    paragraph={paragraphType}
                    key={paragraphType.id}
                    index={index}
                    activeSlideIndex={activeSlideIndex}
                    url={activeSlide.link?.uri}
                    target="_blank"
                    showMore={activeSlide.link?.title || translations.showMore}
                  />
                )
            }
          })}
        </CarouselUI>
        {paragraph.link && (
          <div className="container mt-12 text-right ">
            <Link
              target={INTERNAL_LINK ? '_self' : '_blank'}
              href={paragraph.link.uri}
              intent="link"
              className="font-semibold underline"
              aria-label={paragraph.link.title}
              aria-describedby={
                INTERNAL_LINK
                  ? translations.internalLink
                  : translations.externalLink
              }
            >
              {paragraph.link.title}
              {INTERNAL_LINK ? (
                <ArrowRight
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
              ) : (
                <ExternalLink
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
              )}
            </Link>
            {INTERNAL_LINK ? (
              <p className="sr-only">{translations.newWindow}</p>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}

export { Carousel }
