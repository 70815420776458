import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { LIST_TYPE_ITINERARY } from '@/components/Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'
import { SORT_FILTER } from '@/components/RoutesList/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { CheckCircle2Icon, ChevronLeft, MapIcon, Settings2 } from 'lucide-react'
import {
  Button,
  Card,
  CardTitle,
  Dialog,
  DialogBody,
  DialogHeader,
  Separator,
} from 'ui'
import { cn } from 'utils-tailwindcss'

import { Itineraries } from '../../../Itineraries'
import { SortFilter } from '../SortFilter'
import { Items, Loader, SkeletonList } from './components'
import { useNavigation } from './useNavigation'

export const Navigation = () => {
  const {
    state: {
      showFilters,
      isLoading,
      activeFilters,
      type,
      total_items,
      showItineraries,
      selected,
      showItinerariesDialog,
      showNavigation,
    },
    actions: {
      toogleFilters,
      toogleShowItineraries,
      toogleShowItinerariesDialog,
      setShowNavigation,
    },
  } = useNavigation()

  const conditionalTranslationKeys = {
    addedToYourItinerary: 'itinerary.addedToYourItinerary',
    filters: 'global.filters',
    myItinerary: 'itinerary.myItinerary',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return !showItineraries ? (
    <div className="relative flex shadow-sm">
      <button
        type="button"
        onClick={() => setShowNavigation(!showNavigation)}
        className="absolute top-0 z-10 p-0 rounded-l-none h-18 rounded-r-2xl -right-4 bg-neutral-white"
      >
        <ChevronLeft
          className={cn({
            'transform rotate-180': !showNavigation,
          })}
        />
      </button>
      {showItinerariesDialog && (
        <Dialog
          open={showItinerariesDialog}
          onOpenChange={() => toogleShowItinerariesDialog()}
          intent={'black'}
        >
          <DialogHeader className="items-center text-xs">
            <CheckCircle2Icon />
            {translations.addedToYourItinerary}
          </DialogHeader>
          <DialogBody>
            <Button
              intent={'link'}
              className="text-sm w-fit text-neutral-white"
              onClick={() => toogleShowItineraries()}
            >
              <FormattedRichMessage
                id="itinerary.seeMyItinerary"
                values={{ total: selected.length }}
              />
            </Button>
          </DialogBody>
        </Dialog>
      )}
      {showNavigation && (
        <Card
          className={cn(
            'rounded-r-none duration-500 transition-all ease-in-out max-h-[807px]',
            {
              'w-[480px] py-6 pt-0 border-neutral-gray-200 border-[0.5px]':
                showNavigation,
              'w-0': !showNavigation,
            }
          )}
        >
          {/* FILTROS */}
          <CardTitle className="sticky top-0 flex items-center justify-between px-6 py-4 mb-0 no-underline bg-neutral-gray-200">
            <Loader
              isLoading={isLoading}
              total={total_items || 0}
              type={type}
            />
            <div className="flex gap-4">
              <Button
                intent="link"
                className="flex gap-3 p-0 no-underline"
                onClick={() => toogleFilters(!showFilters, false)}
              >
                <Settings2 />
                {translations.filters}
                {!!activeFilters?.filter((fil) => fil.type !== SORT_FILTER)
                  ?.length && (
                  <span className="flex items-center justify-center w-8 h-8 font-semibold rounded-full bg-primary-red-900 text-neutral-white">
                    {
                      activeFilters.filter((fil) => fil.type !== SORT_FILTER)
                        ?.length
                    }
                  </span>
                )}
              </Button>
              {type !== LIST_TYPE_ITINERARY ? (
                <SortFilter />
              ) : (
                <Button
                  intent={'tertiary'}
                  isDisabled={selected?.length == 0}
                  className="justify-start gap-2 pl-0 pr-0"
                  onClick={() => toogleShowItineraries()}
                >
                  <MapIcon /> {translations.myItinerary}
                </Button>
              )}
            </div>
          </CardTitle>
          <Separator color="gray" size="empty" />

          {isLoading ? <SkeletonList /> : <Items />}
        </Card>
      )}
    </div>
  ) : (
    <div className="relative flex shadow-sm">
      <button
        type="button"
        onClick={() => setShowNavigation(!showNavigation)}
        className="absolute top-0 z-10 p-0 rounded-l-none h-18 rounded-r-2xl -right-4 bg-neutral-white"
      >
        <ChevronLeft
          className={cn({
            'transform rotate-180': !showNavigation,
          })}
        />
      </button>
      <Card
        className={cn(
          'rounded-r-none duration-500 transition-all ease-in-out max-h-[807px]',
          {
            'w-[480px] py-6 pt-0 border-neutral-gray-200 border-[0.5px]':
              showNavigation,
            'w-0': !showNavigation,
          }
        )}
      >
        {/* FILTROS */}
        <CardTitle className="sticky top-0 flex items-center justify-between px-6 py-4 mb-0 text-sm font-normal no-underline bg-neutral-gray-200">
          <FormattedRichMessage
            id="itinerary.totalPointsAdded"
            values={{ total: selected.length }}
          />
        </CardTitle>
        <Separator color="gray" size="empty" />
        <Itineraries />
      </Card>
    </div>
  )
}
