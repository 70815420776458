import { ChevronDown, ChevronUp } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { ItemStyles } from './Item.styles'
import { ItemsProps } from './types'

const Item = ({
  children,
  className,
  menuValue,
  value,
  onValueChange = () => {},
  ariaHasPopup,
}: ItemsProps) => (
  <li
    className={cn(ItemStyles(), className)}
    onClick={() => {
      menuValue && onValueChange(menuValue)
    }}
  >
    <button
      aria-haspopup={ariaHasPopup}
      className="flex flex-row items-center text-left"
    >
      {children}{' '}
      {value === menuValue ? (
        <ChevronUp role="presentation" aria-hidden="true" />
      ) : (
        <ChevronDown role="presentation" aria-hidden="true" />
      )}
    </button>
  </li>
)
export { Item }
