export const MEGAMENU_FIELDS = [
  'id',
  'title',
  'promoted',
  'map',
  'url',
  'parent',
  'content',
  'image',
]
export const MEGAMENU_CLIENT_FIELDS = ['id', 'content', 'weight']
export const SITEMAP_MEGAMENU_FIELDS = ['id', 'title', 'url', 'parent']

export const MEGAMENU_INCLUDES = ['image']
export const MEGAMENU_CLIENT_INCLUDES = ['content', 'content.computed_image']

export const SITEMAP_MEGAMENU_INCLUDES = []
