import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
} from 'react'
import { DrupalNodePlace } from '@/components/Node/components/Place/types'

import { useVillagesMap } from './useVillagesMap'

type ContextProps = {
  state: {
    villages: DrupalNodePlace[]
    currentVillage: DrupalNodePlace
  }
  actions: {
    setCurrentVillage: Dispatch<SetStateAction<DrupalNodePlace | null>>
  }
}

const VillageMapContext = createContext<ContextProps | undefined>(undefined)

type ContextProviderProps = {
  children: ReactNode
  villages: DrupalNodePlace[]
}

const VillagesMapProvider = ({ children, villages }: ContextProviderProps) => {
  const { state, actions } = useVillagesMap({ villages })

  const value = useMemo(() => {
    return { villages, state, actions }
  }, [villages, state, actions])

  return (
    <>
      {/**@ts-ignore */}
      <VillageMapContext.Provider value={value}>
        {children}
      </VillageMapContext.Provider>
    </>
  )
}

const useVillagesMapProvider = () => {
  const ctxValue = useContext(VillageMapContext)
  if (!ctxValue)
    throw new Error(`Please wrap in an ${VillageMapContext.displayName}`)
  return ctxValue
}

export { VillagesMapProvider, useVillagesMapProvider }
