import {
  Children,
  ComponentPropsWithRef,
  ElementType,
  isValidElement,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
} from 'react'

type AnyComponent = string | JSXElementConstructor<any>

type ReactElementOfType<T extends AnyComponent> = ReactElement<
  T extends ElementType ? ComponentPropsWithRef<T> : unknown,
  T
>

type GroupedChildren<T extends Record<string, AnyComponent>> = {
  [K in keyof T]?: ReactElementOfType<T[K]>[]
} & { rest?: ReactNode }

/**
 * This creates a function that inspects your children and groups them
 * based on which component they are.
 *
 * You pass it an object that communicates the shape of the resulting
 * object.
 *
 * ```js
 * const groupChildren = createGroupChildren({
 *   icons: MyIconComponent,
 *   labels: MyLabelComponent,
 *   buttons: MyButtonComponent
 * })
 *
 * const MyParentComponent = ({ children }) => {
 *   const groupedChildren = groupChildren(children)
 *
 *   // ...
 * }
 * ```
 *
 * In this example, `groupedChildren` will be an object like the following:
 *
 * ```js
 * ({
 *   icons: [ ... ] // `ReactNode`s of type `MyIconComponent`
 *   labels: [ ... ] // `ReactNode`s of type `MyLabelComponent`
 *   buttons: [ ... ] // `ReactNode`s of type `MyButtonComponent`
 *   rest: [ ... ] // `ReactNode`s that didn't fit any of the given components
 * })
 * ```
 *
 * > Please take into account that this function has not been benchmarked or
 * > optimized. Act accordingly. It might be a good idea to wrap the component
 * > in a `memo` if it becomes a bottleneck.
 */
export const createGroupChildren = <T extends Record<string, AnyComponent>>(
  groups: T
) => {
  const keyMap = new Map<AnyComponent, keyof T>()
  for (const [groupName, component] of Object.entries(groups)) {
    keyMap.set(component, groupName)
  }

  return (node: ReactNode): GroupedChildren<T> => {
    const obj: GroupedChildren<T> = {}

    for (const child of Children.toArray(node)) {
      const type = isValidElement(child) && child.type
      const key = (type && keyMap.get(type)) || 'rest'
      // @ts-expect-error
      ;(obj[key] ??= []).push(child)
    }

    return obj
  }
}
