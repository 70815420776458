import { Media } from '@/components/Media'
import { useItineraryProvider } from '@/components/RoutesList/components/List/ItineraryProvider'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { MinusCircleIcon, PlusCircleIcon } from 'lucide-react'
import { Button, Heading } from 'ui'

import { BREAKPOINTS } from './constants'
import { DrupalNodeItineraryTeaserProps } from './types'

const SearchTeaser = ({ node }: DrupalNodeItineraryTeaserProps) => {
  const { isSelected, addItinerary, removeItinerary } = useItineraryProvider()

  const conditionalTranslationKeys = {
    addToMyItinerary: 'itinerary.addToMyItinerary',
    removeFromMyItinerary: 'itinerary.removeFromMyItinerary',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div className="flex flex-row items-center justify-between w-full">
      <div className="flex flex-col w-full gap-2">
        <Heading as="h3" className="max-w-[258px] text-base">
          {node.title}
        </Heading>
        {!isSelected(node.id) ? (
          <Button
            onClick={(e) => {
              addItinerary(node)
              e.stopPropagation()
            }}
            intent={'tertiary'}
            className="justify-start pl-0 w-fit"
          >
            <PlusCircleIcon size={22} />
            {translations.addToMyItinerary}
          </Button>
        ) : (
          <Button
            onClick={(e) => {
              removeItinerary(node.id)
              e.stopPropagation()
            }}
            fullWidth={true}
            intent="tertiary"
            className="justify-start pl-0 w-fit"
          >
            <MinusCircleIcon size={22} />
            {translations.removeFromMyItinerary}
          </Button>
        )}
      </div>
      {node.computed_image && (
        <div className="w-[10.5rem]">
          <Media
            media={node.computed_image}
            //@ts-ignore
            breakpoints={BREAKPOINTS}
          />
        </div>
      )}
    </div>
  )
}
export { SearchTeaser }
