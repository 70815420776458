import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { Label } from 'ui'

import { DrupalTaxonomyTermTodotogoProps } from './types'

const Todotogo = ({
  term,
  viewMode = VIEW_MODE_DEFAULT,
}: DrupalTaxonomyTermTodotogoProps) => (
  <div className={`${term.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        default:
          return <Label>{term.name}</Label>
      }
    })()}
  </div>
)

export { Todotogo }
