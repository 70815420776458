import { DrupalParagraphItineraryMapProps } from '../ItineraryMap/types'
import { Default } from './viewmodes/Default'

const CreateItineraryMap = ({
  paragraph,
  viewMode = 'default',
  ...rest
}: DrupalParagraphItineraryMapProps) => (
  <div className={`${paragraph.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        default:
          return <Default paragraph={paragraph} {...rest} />
      }
    })()}
  </div>
)

export { CreateItineraryMap }
