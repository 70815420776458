import { Establishment } from './components/Establishment'
import { Event } from './components/Event'
import { Experience } from './components/Experience'
import { Itinerary } from './components/Itinerary'
import { Landing } from './components/Landing'
import { NextPage } from './components/NextPage'
import { Place } from './components/Place'
import { Route } from './components/Route'
import { TouristPlan } from './components/TouristPlan'
import { TouristPoint } from './components/TouristPoint'

export const NODE = 'node'
export const NODE_EVENT = 'node--event'
export const NODE_EVENT_BASE = 'node--event--base'
export const NODE_EVENT_CARD = 'node--event--card'

export const NODE_TOURIST_POINT = 'node--tourist_point'
export const NODE_TOURIST_POINT_CARD = 'node--tourist_point--card'

export const NODE_ITINERARY = 'node--itinerary'

export const NODE_TOURIST_PLAN = 'node--tourist_plan'
export const NODE_TOURIST_PLAN_CARD = 'node--tourist_plan--card'

export const NODE_ESTABLISHMENT = 'node--establishment'
export const NODE_ESTABLISHMENT_CARD = 'node--establishment--card'

export const NODE_ROUTE = 'node--route'
export const NODE_ROUTE_BASE = 'node--route--base'
export const NODE_ROUTE_CARD = 'node--route--card'

export const NODE_EXPERIENCE = 'node--experience'

export const NODE_LANDING = 'node--landing'
export const NODE_LANDING_CARD = 'node--landing--card'

export const NODE_PLACE = 'node--place'
export const NODE_PLACE_BASE = 'node--place--base'
export const NODE_PLACE_CARD = 'node--place--card'

export const NODE_MEET = 'node--meet'
export const NODE_GUIDED_ROUTE = 'node--guided_route'
export const NODE_AREA = 'node--area'
export const NODE_PROPOSAL = 'node--proposal'
export const NODE_USER_ITINERARY = 'node--user_itinerary'
export const NODE_PAGES = 'node--next_pages'

export const MENU_LINK_MEGAMENU = 'menu_link_content--megamenu'

export const NODES = {
  [NODE_EVENT]: Event,
  [NODE_TOURIST_POINT]: TouristPoint,
  [NODE_TOURIST_PLAN]: TouristPlan,
  [NODE_ESTABLISHMENT]: Establishment,
  [NODE_ROUTE]: Route,
  [NODE_EXPERIENCE]: Experience,
  [NODE_LANDING]: Landing,
  [NODE_PAGES]: NextPage,
  [NODE_PLACE]: Place,
  [NODE_ITINERARY]: Itinerary,
  [NODE_MEET]: null,
  [NODE_GUIDED_ROUTE]: null,
  [NODE_AREA]: null,
  [NODE_PROPOSAL]: null,
}

export const NODES_FULL = [
  NODE_PAGES,
  NODE_TOURIST_PLAN,
  NODE_EVENT,
  NODE_LANDING,
  NODE_PLACE,
  NODE_ROUTE,
]

export const NODES_DES = [NODE_LANDING]
