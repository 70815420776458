import { createContext, ReactNode, useContext, useMemo } from 'react'

export type ContextProps = {
  width?: number | string
}

const SelectContext = createContext<ContextProps | undefined>(undefined)

type ContextProviderProps = {
  width?: number | string
  children: ReactNode
}

const SelectProvider = ({ children, width }: ContextProviderProps) => {
  const value = useMemo(() => {
    return { width }
  }, [width])

  return (
    <SelectContext.Provider value={value}>{children}</SelectContext.Provider>
  )
}

const useSelectProvider = () => {
  const ctxValue = useContext(SelectContext)
  if (!ctxValue) throw new Error(`Please wrap in an SelectProvider`)
  return ctxValue
}

export { SelectProvider, useSelectProvider }
