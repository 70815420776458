import { cva } from 'class-variance-authority'

import { focusable } from '../../../../../utils/helpers'

export const TriggerStyles = {
  root: cva(
    [
      'border border-neutral-gray-400 rounded-md py-3 pl-6 pr-3 justify-between inline-flex items-center w-full bg-neutral-white text-base text-secondary-black-900 shadow-sm',
      '[&[data-state=open]>span>svg]:rotate-180 [&[data-state=open]>span]:border-primary-red-900 [&[data-state=open]>span>svg]:stroke-primary-red-900',
      focusable(),
    ],
    {
      variants: {
        isDisabled: {
          true: 'opacity-50 cursor-not-allowed',
        },
      },
      defaultVariants: {
        isDisabled: false,
      },
    }
  ),
  icon: cva([
    'p-1 border rounded-md border-neutral-gray-500 flex items-center justify-center text-neuborder-neutral-gray-500',
    '[&>svg]:transition-transform [&>svg]:duration-200',
    'hover:bg-neutral-gray-200',
  ]),
}
