import { FormattedMessage } from '@/components/FormattedMessage'
import { Node } from '@/components/Node'
import { DrupalNodeItinerary } from '@/components/Node/components/Itinerary/types'
import { VIEW_MODE_BASIC_TEASER } from '@/content/constants'
import { AlertCircleIcon } from 'lucide-react'
import { Button, Separator } from 'ui'
import { cn } from 'utils-tailwindcss'

import { useItineraries } from './useItineraries'

export const Itineraries = () => {
  const {
    state: { currentRoute, selected, isLoading },
    actions: { setCurrentRoute, toogleShowItineraries, handleClick },
  } = useItineraries()

  return (
    <div className="h-fit md:h-[752px] overflow-auto gap-1 flex flex-col bg-neutral-gray-200">
      {selected?.map((itinerary: DrupalNodeItinerary) => (
        <div
          key={itinerary.id}
          className={cn(
            'flex justify-between gap-4 p-4 hover:cursor-pointer no-underline bg-neutral-white',
            currentRoute?.id === itinerary.id && 'bg-neutral-gray-300'
          )}
          onClick={() => setCurrentRoute(itinerary)}
          accessKey="button"
          tabIndex={0}
        >
          <Node
            node={{
              ...itinerary,
              viewMode: VIEW_MODE_BASIC_TEASER,
            }}
          />
        </div>
      ))}
      <Separator color="gray" size="empty" />
      <div className="w-full px-4 py-10 bg-neutral-white">
        <Button
          intent={'link'}
          onClick={() => {
            toogleShowItineraries()
          }}
        >
          <FormattedMessage id="itinerary.addMorePoints" />
        </Button>
      </div>
      <Separator color="gray" size="empty" />
      <div className="flex flex-col w-full gap-4 p-6 bg-neutral-white">
        <div className="flex flex-row max-w-xs gap-2 p-3 font-semibold rounded-lg text-primary-red-900 bg-primary-red-50">
          <AlertCircleIcon size={24} />
          <p className="text-xs">
            <FormattedMessage id="itinerary.warning" />
          </p>
        </div>

        <Button
          onClick={handleClick}
          isDisabled={!!!selected.length}
          className="w-fit"
          isLoading={isLoading}
        >
          <FormattedMessage id="myItinerary.saveItinerary" />
        </Button>
      </div>
    </div>
  )
}
