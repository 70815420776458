import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from '@/components/FormattedMessage'
import { MobileModal } from '@/components/MobileModal'
import {
  DISTANCE_ROUTES_FILTER,
  DURATION_ROUTES_FILTER,
  SORT_FILTER,
} from '@/components/RoutesList/constants'
import { Settings2 } from 'lucide-react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Form,
} from 'ui'

import { getAllDistinctFilterTypes } from '../../utils/helpers'
import { Content, MobileActiveFilters } from './components'
import { MobileFilterListProps } from './types'

export const MobileFilterList = ({
  filters,
  onDeleteActiveFilter,
  form,
  activeFilters,
  onFormChage = (_data) => {},
  onSubmit,
  isLoading,
  onCancel = () => {},
  onOpenChange = () => {},
  onSubmitText,
  showModal,
}: MobileFilterListProps) => {
  const [activeAccordionValues, setAccordionActiveValues] = useState<string[]>(
    getAllDistinctFilterTypes({
      activeFilters,
    }) as string[]
  )
  const activeFilterCount = useMemo(() => {
    return (
      activeFilters?.filter((filter) => filter.type !== SORT_FILTER)?.length ||
      null
    )
  }, [activeFilters])

  useEffect(() => {
    if (showModal) {
      const types = getAllDistinctFilterTypes({
        activeFilters,
      })

      types?.length
        ? types?.forEach((type) => {
            type !== SORT_FILTER &&
              form.setValue(
                type!,
                type === DISTANCE_ROUTES_FILTER ||
                  type === DURATION_ROUTES_FILTER
                  ? activeFilters?.filter((fil) => fil.type === type)[0].range
                  : activeFilters
                      ?.filter((item) => item.type === type)
                      .map((item) => item.values.value)
              )
          })
        : form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  return (
    <MobileModal
      title={FormattedMessage({ id: 'global.filters' })}
      triggerIcon={Settings2}
      activeFilterCount={activeFilterCount}
      isLoading={isLoading}
      onCancel={onCancel}
      onOpenChange={onOpenChange}
      showModal={showModal}
      onSubmitText={onSubmitText}
      onSubmit={onSubmit}
    >
      <Form {...form}>
        <form onChange={() => onFormChage(form.getValues())}>
          <Accordion
            type="multiple"
            className="overflow-auto divide-y-8 divide-neutral-gray-200"
            onValueChange={setAccordionActiveValues}
            value={activeAccordionValues}
          >
            {filters
              ?.filter((item) => item.id !== 'sort')
              .map((filter) => {
                return (
                  <AccordionItem
                    key={filter.id}
                    value={filter.id}
                    className="z-0 px-6 py-1 bg-neutral-white"
                  >
                    <AccordionTrigger icon="plus">
                      {filter.label}
                    </AccordionTrigger>
                    <AccordionContent className="h-fit">
                      <Content filter={filter} />
                    </AccordionContent>
                    {!!activeAccordionValues.find(
                      (value) => value === filter.id
                    ) && (
                      <MobileActiveFilters
                        id={filter.id}
                        activeFilters={activeFilters}
                        onDeleteFilter={onDeleteActiveFilter}
                      />
                    )}
                  </AccordionItem>
                )
              })}
          </Accordion>
        </form>
      </Form>
    </MobileModal>
  )
}
