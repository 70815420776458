import { ElementRef, forwardRef } from 'react'
import { Media } from '@/components/Media'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { MinusCircleIcon, PlusCircleIcon } from 'lucide-react'
import { Button, Card, CardContent, CardImage, CardTitle, Text } from 'ui'
import { cn } from 'utils-tailwindcss'

import { BREAKPOINTS } from '../../constants'
import { LocationCardProps } from '../../types'

const ItineraryBasic = forwardRef<ElementRef<typeof Card>, LocationCardProps>(
  ({ className, image, title, onRemoveClick, onAddedClick, isAdded }, ref) => {
    const conditionalTranslationKeys = {
      addToMyItinerary: 'itinerary.addToMyItinerary',
      removeFromMyItinerary: 'itinerary.removeFromMyItinerary',
    }

    const translations: { [key: string]: string } = useCustomTranslations(
      conditionalTranslationKeys
    )
    return (
      <Card
        ref={ref}
        className={cn(' p-0 max-w-[18.5rem] h-[23.65rem]', className)}
      >
        <CardContent className="h-full gap-0 px-0 pt-0 pb-0">
          <CardImage className="self-center h-fit w-fit">
            <div className=" w-[18.55rem]">
              {!!image && (
                <Media
                  //@ts-ignore
                  breakpoints={BREAKPOINTS}
                  media={image}
                />
              )}
            </div>
          </CardImage>
          <div className="flex flex-col justify-between border-t-0 w-full h-full border-[0.5px] border-neutral-gray-400 p-4">
            <CardTitle className="w-full pt-0 ">
              {title && (
                <div className="flex items-start justify-between mb-2">
                  <Text className="text-lg font-bold max-w-[18.5rem]">
                    {title}
                  </Text>
                </div>
              )}
            </CardTitle>
            {!isAdded ? (
              <Button
                onClick={onAddedClick}
                intent={'tertiary'}
                className="justify-start pl-0"
              >
                <PlusCircleIcon size={22} />
                {translations.addToMyItinerary}
              </Button>
            ) : (
              <Button
                onClick={onRemoveClick}
                fullWidth={true}
                intent="tertiary"
                className="justify-start w-full p-0"
              >
                <MinusCircleIcon size={22} />
                {translations.removeFromMyItinerary}
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    )
  }
)

ItineraryBasic.displayName = 'Itinerary'

export { ItineraryBasic }
