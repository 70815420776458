import Link from 'next/link'
import { FormattedMessage } from '@/components/FormattedMessage'
import { NoResults } from '@/components/List'
import { Node } from '@/components/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { VIEW_MODE_BASIC_CAROUSEL_3COL } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Heading, Searcher, Separator } from 'ui'

import { ResultCards } from './components/ResultCards'
import { useExplore } from './useExplore'

const Explore = ({
  highlights,
  url,
  ariaLabelledby,
}: {
  highlights?: BundleDrupalNode[]
  url?: { alias: string }
  ariaLabelledby?: string
}) => {
  const {
    state: { query, data, isPending, haveData },
    actions: { setQuery, search, clear },
  } = useExplore()

  const conditionalTranslationKeys = {
    seeMore: 'searcher.global.seeMore',
    highlights: 'global.highlights',
    noResults: 'searcher.noResults',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div className="z-50 pb-8" id={ariaLabelledby}>
      <Heading className="mb-8 md:hidden">
        <FormattedMessage id="global.searcher" />
      </Heading>
      <Searcher
        className="my-8 text-secondary-black-900 "
        placeholder={FormattedMessage({
          id: 'megamenu.searcher.inputPlaceholder',
        })}
        value={query}
        onValueChange={setQuery}
        text={FormattedMessage({
          id: 'global.search',
        })}
        onClear={clear}
        //@ts-ignore
        onKeyDown={search}
        onSearchClick={search}
        ariaLabel={FormattedMessage({
          id: 'megamenu.searcher.clearSearch',
        })}
        aria-labelledby={ariaLabelledby}
      />
      <Separator size="empty" className="my-8 bg-neutral-gray-300" />
      <div
        className="z-20 overflow-y-auto"
        style={{ height: 'calc(100vh - 300px)' }}
      >
        <ResultCards isPending={isPending} highlights={data} />
        {!!data.length && (
          <div className="flex justify-end w-full">
            <Link
              className="flex items-center justify-between py-4 text-base font-semibold underline cursor-pointer text-neutral-600 hover:text-primary-red-900"
              href={`${url?.alias ?? ''}${
                !!query ? `?query[query]=${query}` : ''
              }`}
            >
              {translations.seeMore}
            </Link>
          </div>
        )}
        {haveData && <NoResults className="md:h-fit bg-opacity-0" />}

        {!!data.length && (
          <Separator size="empty" className="my-8 bg-neutral-gray-300" />
        )}
        {highlights && (
          <>
            <Heading as="h3" className="my-8 font-semibold">
              {translations.highlights}
            </Heading>
            <div className="grid grid-cols-2 gap-6 md:grid-cols-4 md:gap-8">
              {highlights.map((node: BundleDrupalNode, key: number) => (
                <Node
                  key={key}
                  node={{
                    ...node,
                    viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL,
                    className: 'bg-opacity-0',
                  }}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export { Explore }
