import {
  VIEW_MODE_CAROUSEL_3COL,
  VIEW_MODE_CAROUSEL_4COL,
  VIEW_MODE_DEFAULT,
  VIEW_MODE_GRID_2COL,
  VIEW_MODE_GRID_3COL,
  VIEW_MODE_GRID_4COL,
  VIEW_MODE_HIGHLIGHTED,
} from '@/content/constants'

import { DrupalParagraphCardsProps } from './types'
import { Carousel } from './viewmodes/Carousel'
import { Grid } from './viewmodes/Grid'
import { Highlight } from './viewmodes/Highlight'

const Cards = ({
  paragraph,
  viewMode = VIEW_MODE_DEFAULT,
  ...rest
}: DrupalParagraphCardsProps) => (
  <div className={`${paragraph.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        case VIEW_MODE_HIGHLIGHTED:
          return <Highlight paragraph={paragraph} {...rest} />
        case VIEW_MODE_CAROUSEL_3COL:
          return <Carousel paragraph={paragraph} cols={3} {...rest} />
        case VIEW_MODE_CAROUSEL_4COL:
          return <Carousel paragraph={paragraph} cols={4} {...rest} />
        case VIEW_MODE_GRID_2COL:
          return <Grid paragraph={paragraph} cols={2} {...rest} />
        case VIEW_MODE_GRID_3COL:
          return <Grid paragraph={paragraph} cols={3} {...rest} />
        case VIEW_MODE_GRID_4COL:
          return <Grid paragraph={paragraph} cols={4} {...rest} />
        default:
          return <Grid paragraph={paragraph} {...rest} />
      }
    })()}
  </div>
)

export { Cards }
