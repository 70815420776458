'use client'

import { forwardRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { Spinner } from '../Spinner'
import { ButtonStyles } from './Button.styles'
import { ButtonProps } from './types'

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      intent,
      size,
      fullWidth,
      isDisabled,
      isLoading,
      children,
      className,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      disabled={!!isDisabled}
      className={cn(
        ButtonStyles({
          intent,
          size,
          fullWidth,
          isDisabled,
          isLoading,
        }),
        className
      )}
      data-loading={isLoading}
      {...props}
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner className="absolute" size="md" />
          <span className="opacity-0">{children}</span>
          <span className="sr-only">Loading</span>
        </div>
      ) : (
        children
      )}
    </button>
  )
)

Button.displayName = 'Button'

export { Button }
