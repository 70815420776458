import Link from 'next/link'
import { Card as CardUI } from 'ui'

import { Content } from './components'
import { CardTeaserProps } from './types'

const CardTeaser = ({
  title,
  image,
  url,
  description,
  size,
  type,
  popup = false,
  phone,
}: CardTeaserProps) => (
  <CardUI className="flex flex-col h-full">
    {!url || type === 'node--establishment' || popup ? (
      <Content
        title={title}
        image={image}
        description={description}
        size={size}
        popup={popup}
        phone={phone}
      />
    ) : (
      <Link
        href={url}
        className="flex flex-col h-full no-underline hover:underline focus:border-2 focus:border-secondary-black-900 rounded-md focus:p-[2px]"
      >
        <Content
          title={title}
          image={image}
          description={description}
          size={size}
          phone={phone}
        />
      </Link>
    )}
  </CardUI>
)

export { CardTeaser }
