import { Title } from '@/components/Title'

import { DrupalParagraphVillageMap } from '../../types'
import { ResponsiveView } from './ResponsiveView'
import { VillagesMapProvider } from './VillagesMapProvider'

const Default = ({ paragraph }: { paragraph: DrupalParagraphVillageMap }) => {
  const multipointContent =
    paragraph.bool_multipoint &&
    paragraph.paragraphs
      ?.filter((p) => p?.type === 'paragraph--multipoint' && !!p)
      .map((paragraph) => {
        return {
          id: paragraph.id,
          isMultipoint: true,
          title: paragraph.content?.title,
          path: paragraph.content?.path,
          computed_image: paragraph.content?.computed_image,
          contents: paragraph.contents?.filter(
            (content) => content.id !== 'missing' && content?.status
          ),
          body: paragraph.content?.body,
          text_address: paragraph.content.text_address,
          status: true,
        }
      })

  const villages = !paragraph.bool_multipoint
    ? paragraph.contents
    : multipointContent

  return (
    <div className="py-10">
      <div className="container">
        <Title title={paragraph.title} subtitle={paragraph.subtitle} />
      </div>
      <VillagesMapProvider
        villages={villages.filter((vil) => vil.id !== 'missing' && vil?.status)}
      >
        <ResponsiveView />
      </VillagesMapProvider>
    </div>
  )
}
export { Default }
