import { Desktop, Mobile } from './components'
import { ItineraryProvider } from './ItineraryProvider'
import { MapListProvider } from './MapListProvider'

const List = ({ categories }: { categories?: Record<string, string[]> }) => {
  return (
    <MapListProvider categories={categories}>
      <ItineraryProvider>
        <Mobile />
        <Desktop />
      </ItineraryProvider>
    </MapListProvider>
  )
}
export { List }
