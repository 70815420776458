import { useRef, useState } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { useOnClickOutside } from 'usehooks-ts'
import { cn } from 'utils-tailwindcss'

import { DialogStyles } from './Dialog.styles'
import { DialogProps } from './types'

const Dialog = ({
  children,
  className,
  open,
  onOpenChange,
  intent,
  ...rest
}: DialogProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = useState(open)
  const handleOpenChange = () => {
    onOpenChange && onOpenChange(!isOpen)
    setOpen((open) => !open)
  }
  useOnClickOutside(ref, handleOpenChange)

  return (
    <DialogPrimitive.Root
      open={isOpen}
      onOpenChange={handleOpenChange}
      {...rest}
    >
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay
          className={cn(DialogStyles.overlay({ intent }), className)}
        >
          <DialogPrimitive.Content className={DialogStyles.content({ intent })}>
            <div ref={ref}>{children}</div>
          </DialogPrimitive.Content>
        </DialogPrimitive.Overlay>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

Dialog.displayName = 'Dialog'

export { Dialog }
