import { useState } from 'react'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Button, Checkbox, FilterButton } from 'ui'
import { FilterButtonLabel } from 'ui/src/components/molecules/FilterButton'

const CheckBoxList = ({ label, computed_villages, setVillages, villages }) => {
  const [selectedVillages, setSelectedVillages] = useState(
    computed_villages.map(() => false)
  )

  const handleCheckboxChange = (position: number) => {
    const updatedSelection = selectedVillages.map((item, index) =>
      index === position ? !item : item
    )
    setSelectedVillages(updatedSelection)
  }

  const applyFilter = () => {
    const selectedNames = selectedVillages
      .map((isSelected, index) =>
        isSelected ? computed_villages[index].id : false
      )
      .filter((name) => name !== false)

    setVillages(selectedNames)
  }

  const removeFilter = () => {
    setSelectedVillages(computed_villages.map(() => false))
    setVillages([])
  }

  return (
    <FilterButton>
      <FilterButtonLabel>{label}</FilterButtonLabel>
      {computed_villages?.map((village, index) => (
        <div className="flex items-center space-x-4 space-y-2" key={village.id}>
          <Checkbox
            id={village.id}
            checked={selectedVillages[index]}
            onCheckedChange={() => handleCheckboxChange(index)}
          />
          <label>{village.name?.value}</label>
        </div>
      ))}
      <div>
        <div className="w-full pt-6">
          <Button
            intent="secondary"
            onClick={removeFilter}
            isDisabled={villages.length <= 0}
            className="mr-6"
          >
            <FormattedMessage id="global.delete" />
          </Button>
          <Button intent="primary" onClick={applyFilter}>
            <FormattedMessage id="global.apply" />
          </Button>
        </div>
      </div>
    </FilterButton>
  )
}

export { CheckBoxList }
