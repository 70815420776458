import { FormattedMessage } from '@/components/FormattedMessage'
import { MapPinIcon } from 'lucide-react'
import { InfoTag, InfoTagBody, InfoTagHeader } from 'ui'

const Origin = ({ node }) => (
  <InfoTag>
    <InfoTagHeader>
      <MapPinIcon />
      <FormattedMessage id="global.origin" />
    </InfoTagHeader>
    <InfoTagBody>{node.text_address} </InfoTagBody>
  </InfoTag>
)

export { Origin }
