import { createElement } from 'react'
import { VIEW_MODE_DEFAULT } from '@/content/constants'

import { PARAGRAPHS } from './constants'
import { BundleDrupalParagraph, ParagraphType } from './types'

const Paragraph = ({
  paragraph,
  title,
  ...rest
}: {
  paragraph: BundleDrupalParagraph
  title?: string
}) => {
  const bundle = PARAGRAPHS[paragraph.type as ParagraphType]
  if (paragraph?.type !== 'unknown' && paragraph && bundle) {
    return createElement(bundle, {
      paragraph: paragraph,
      nodeTitle: title,
      viewMode: paragraph.paragraph_view_mode || VIEW_MODE_DEFAULT,
      ...rest,
    })
  } else {
    console.error(`Error in paragraph, ${paragraph.type}, it doesn't exist`)
  }
}
export { Paragraph }
