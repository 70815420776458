import { useEffect, useState } from 'react'
import Link from 'next/link'
import { Language } from '@/components/PublicLayout/usePublicLayout'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ChevronDown } from 'lucide-react'
import { Heading, Separator, Text } from 'ui'
import { DrupalMenuLinkContents } from 'utils-drupal'

import { Dropdown } from '../../../LanguageMenu/components/Dropdown'
import { WhatToDo } from '../../../Megamenu/components/WhatToDo'
import { WhereToGo } from '../../../Megamenu/components/WhereToGo'
import { MainMenuMobile } from './components'
import { SubMenuItems } from './types'
import { useDropDownMobileMenu } from './useDropDownMobileMenu'

const DropDownMobileMenu = ({
  megamenu,
  languages,
}: {
  megamenu: DrupalMenuLinkContents[]
  languages: Language[]
}) => {
  const {
    state: { selected, locale },
    actions: { setSelected },
  } = useDropDownMobileMenu()

  const [currentLvl, setCurrentLvl] = useState<string>('DEFAULT')
  const [isWhatLvl, setIsWhatLvl] = useState<string>('')

  const subMenus = {
    [SubMenuItems.DEFAULT]: (
      <MainMenuMobile megamenu={megamenu} setSelected={setSelected} />
    ),
    [SubMenuItems.WHAT]: (
      <WhatToDo
        categories={
          megamenu.find(
            (menu) =>
              menu.id ===
              'menu_link_content:671cc771-9165-48eb-acc0-0a1216311c8a'
          ) as DrupalMenuLinkContents
        }
        isMobile={true}
      />
    ),
  }

  megamenu.forEach((menu) => {
    subMenus[menu.id] = menu.map ? (
      <div>
        <Heading className="mt-6 mb-8">{menu.title}</Heading>
        <WhereToGo categories={menu} />
      </div>
    ) : (
      <div className="h-full" onClick={() => setIsWhatLvl('lvl')}>
        <WhatToDo categories={menu} isMobile={true} />
      </div>
    )
  })

  useEffect(() => {
    if (
      selected === 'menu_link_content:671cc771-9165-48eb-acc0-0a1216311c8a' &&
      isWhatLvl === 'lvl'
    ) {
      setCurrentLvl('WHAT')
    } else {
      setCurrentLvl('DEFAULT')
    }
  }, [selected, isWhatLvl])

  const conditionalTranslationKeys = {
    menu: 'global.menu',
    createYourItinerary: 'global.createYourItinerary',
    professionals: 'global.professionals',
    what: 'global.what',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div
      className="flex flex-col overflow-y-scroll bg-neutral-gray-100"
      style={{ height: 'calc(100vh - 56px)' }}
    >
      {selected !== SubMenuItems[currentLvl] ? (
        <div
          onClick={() => {
            setSelected(SubMenuItems[currentLvl])
          }}
          className="flex items-center gap-2 px-6 py-2 bg-neutral-white"
        >
          <ChevronDown
            className="w-4 h-4 rotate-90 text-neutral-gray-500"
            aria-roledescription="presentation"
            aria-hidden="true"
          />
          <Text className="underline">
            {currentLvl === 'DEFAULT' ? translations.menu : translations.what}
          </Text>
        </div>
      ) : null}
      <div className="container">
        <div className="h-full pb-6 md:pb-0">{subMenus[selected]}</div>
      </div>
      {selected === SubMenuItems.DEFAULT ? (
        <div className="container mt-2 text-sm ">
          {}
          <Link
            href="/crea-tu-itinerario"
            className="text-sm no-underline "
            locale={locale}
          >
            {translations.createYourItinerary}
          </Link>
          <Separator className="my-4 bg-neutral-gray-300" />
          {}
          <Link
            href="/profesionales"
            className="text-sm no-underline "
            locale={locale}
          >
            {translations.professionals}
          </Link>
          <Separator className="my-4 bg-neutral-gray-300" />
          <Dropdown isMobile={true} languages={languages} />
        </div>
      ) : null}
    </div>
  )
}

export { DropDownMobileMenu }
