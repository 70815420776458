import { ElementRef, forwardRef } from 'react'
import Link from 'next/link'
import { CKEditor } from '@/components/CKEditor'
import { FormattedMessage } from '@/components/FormattedMessage'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { Media } from '@/components/Media'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { CheckCircle2, CopyIcon, ExternalLinkIcon, MapPin } from 'lucide-react'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardImage,
  CardSubtitle,
  CardTitle,
  CloseButton,
  Separator,
  Tag,
  Text,
} from 'ui'
import { cn } from 'utils-tailwindcss'

import { BREAKPOINTS } from '../../constants'
import { LocationCardProps } from '../../types'
import { useCopyPath } from '../../useCopyPath'

const Default = forwardRef<ElementRef<typeof Card>, LocationCardProps>(
  (
    {
      className,
      location,
      durationHours,
      durationMinutes,
      distance,
      image,
      tags,
      title,
      link,
      gmapsUrl,
      description,
      onCloseClick,
    },
    ref
  ) => {
    const {
      state: { showMessage },
      actions: { copyPath },
    } = useCopyPath(gmapsUrl || '')
    const handleClick = () => {
      copyPath()
    }

    const addressWithoutSpain = location?.replace(', España', '')

    const conditionalTranslationKeys = {
      openInGMaps: 'searcher.routes.openInGMaps',
      copyToClipboard: 'searcher.routes.copyToClipboard',
      copied: 'searcher.routes.copied',
      externalLink: 'global.externalLink',
      newWindow: 'global.newWindow',
    }

    const translations: { [key: string]: string } = useCustomTranslations(
      conditionalTranslationKeys
    )

    return (
      <Card
        ref={ref}
        className={cn(
          'border max-w-[22rem] border-neutral-gray-400',
          className
        )}
      >
        <CardContent>
          <CardTitle className="w-full pt-0 no-underline ">
            {title && (
              <div className="flex items-start justify-between mb-2">
                <Text className="text-lg font-bold max-w-[17.1875rem]">
                  {title}
                </Text>
                <CloseButton onClick={onCloseClick} />
              </div>
            )}
          </CardTitle>
          <CardImage className="self-center rounded h-fit w-fit">
            <div className="rounded w-[18.75rem]">
              {!!image && (
                <Media
                  //@ts-ignore
                  breakpoints={BREAKPOINTS}
                  media={image}
                />
              )}
            </div>
            {location && (
              <>
                <div className="flex items-center gap-2 py-3 text-secondary-black-900 ">
                  <MapPin
                    size={16}
                    className="text-primary-red-900 min-w-[16px]"
                    aria-roledescription="presentation"
                    aria-hidden="true"
                  />
                  <Text>{addressWithoutSpain}</Text>
                </div>
                <Separator className=" my-0 lg:my-1 bg-neutral-gray-300 h-[1px]" />
              </>
            )}
          </CardImage>

          {gmapsUrl && (
            <>
              <Link
                href={gmapsUrl}
                target="_blank"
                className="flex flex-row gap-3 font-semibold"
                aria-label={translations.openInGMaps}
                aria-describedby={translations.externalLink}
              >
                <ExternalLinkIcon
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
                {translations.openInGMaps}
              </Link>
              <p className="sr-only">{translations.newWindow}</p>
            </>
          )}

          {gmapsUrl && (
            <div className="relative inline-block">
              <Button intent="tertiary" onClick={handleClick} className="p-0">
                <CopyIcon
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
                {translations.copyToClipboard}
              </Button>
              {showMessage && (
                <Alert>
                  <CheckCircle2
                    height="1rem"
                    aria-roledescription="presentation"
                    aria-hidden="true"
                  />
                  {translations.copied}
                </Alert>
              )}
            </div>
          )}

          <CardSubtitle className="flex flex-col py-3 text-text-medium">
            {tags && (
              <div className="flex flex-wrap gap-4 mb-3">
                {tags?.map((item) => (
                  <Tag className="px-3 py-1 truncate" key={item}>
                    {item}
                  </Tag>
                ))}
              </div>
            )}

            {(distance || durationHours || durationMinutes) && (
              <div className="flex flex-row items-center gap-1 mb-3">
                {distance && (
                  <FormattedRichMessage
                    id="searcher.routes.distance"
                    values={{
                      b: (chunks) => <b className="font-semibold">{chunks}</b>,
                      distance: `${distance}`,
                    }}
                  />
                )}
                {durationHours && durationMinutes && (
                  <>
                    <span className="w-1 h-1 rounded-full bg-text-dark" />
                    <FormattedRichMessage
                      id="searcher.routes.duration"
                      values={{
                        b: (chunks) => (
                          <b className="font-semibold">{chunks}</b>
                        ),
                        duration: `${durationHours}:${durationMinutes}`,
                        unit: '´',
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {description && (
              <CKEditor text={description} className="mb-3 line-clamp-2" />
            )}
            <Link className="no-underline" href={link || ''}>
              <Button intent="secondary" className="w-full md:w-auto">
                <FormattedMessage id="global.knowMore" />
              </Button>
            </Link>
          </CardSubtitle>
        </CardContent>
      </Card>
    )
  }
)

Default.displayName = 'Default'

export { Default }
