export const TOURIST_PLAN_FIELDS = [
  'path',
  'title',
  'body',
  'paragraphs',
  'term_municipality',
  'term_type',
  'image_background',
  'link',
  'geofield_address',
  'text_address',
  'int_zipcode',
  'date_range',
  'metatags',
  'moderation_state',
  'status',
  'computed_image',
  'gmaps_url',
]
export const TOURIST_PLAN_CARD_FIELDS = [
  'type',
  'path',
  'title',
  'status',
  'body',
  'term_municipality',
  'term_type',
  'computed_image',
]

export const TOURIST_PLAN_CARD_BASIC_FIELDS = [
  'status',
  'type',
  'path',
  'title',
  'body',
  'geofield_address',
  'date_range',
  'term_municipality',
  'term_programs',
  'term_type',
  'computed_image',
  'gmaps_url',
]

export const TOURIST_PLAN_INCLUDES = [
  'paragraphs',
  'term_municipality',
  'term_type',
  'term_programs',
  'image_background',
  'computed_image',
  /*  */
  'paragraphs.contents',
  'paragraphs.contents.computed_image',
]

export const TOURIST_PLAN_CARD_INCLUDES = [
  'term_municipality',
  'term_type',
  'term_programs',
  'computed_image',
]
