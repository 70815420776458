import { CardVerticalBasic } from '@/components/CardVerticalBasic'

import { DrupalNodePlaceVerticalBasictProps } from './types'

const VerticalBasic = ({ node }: DrupalNodePlaceVerticalBasictProps) => (
  <CardVerticalBasic
    title={node.title}
    image={node.computed_image}
    url={node.path?.alias}
    type={node.type}
  />
)

export { VerticalBasic }
