const FrenchFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    width="28px"
    height="19px"
    viewBox="0 0 28 19"
    version="1.1"
    role="presentation"
    aria-hidden="true"
  >
    <g id="surface1">
      <rect
        x="0"
        y="0"
        width="28"
        height="19"
        fill="rgb(92.941176%,16.078431%,22.352941%)"
        fillOpacity="1"
        stroke="none"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 0 0 L 18.667969 0 L 18.667969 19 L 0 19 Z M 0 0 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,13.72549%,58.431373%)"
        fillOpacity="1"
        d="M 0 0 L 9.332031 0 L 9.332031 19 L 0 19 Z M 0 0 "
      />
    </g>
  </svg>
)

export { FrenchFlag }
