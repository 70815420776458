import { useEffect, useRef, useState } from 'react'
import { VIEW_MODE_CARD_ACTIVITY } from '@/content/constants'

import { NoResults } from '../List'
import { useListProvider } from '../List/ListProvider'
import { Node } from '../Node'
import { HighlightCardSkeleton } from '../PublicLayout/components/Header/components/Megamenu/components/HighlightCard'

const ActivitiesList = () => {
  const {
    state: { results = [], isLoading },
  } = useListProvider()
  /// @TODO cambiar con la ocurrencia de los eventos

  const firstNewResultRef = useRef<HTMLDivElement>(null)
  const [prevResultsCount, setPrevResultsCount] = useState(0)

  useEffect(() => {
    if (results.length > prevResultsCount) {
      if (firstNewResultRef.current) {
        firstNewResultRef.current.focus()
      }
    }
    setPrevResultsCount(results.length)
  }, [results])

  return (
    <div className="grid w-full grid-cols-1 gap-2 mt-0 md:mt-12 lg:gap-12 bg-neutral-gray-200 md:py-6 lg:bg-neutral-white lg:grid-cols-2 xl:grid-cols-3 justify-items-center md:container">
      {results?.length !== 0 &&
        results?.map((r, key) => {
          const isFirstNew = key === prevResultsCount
          return (
            <div
              key={key}
              ref={isFirstNew ? firstNewResultRef : null}
              tabIndex={-1}
              className="w-full"
            >
              <Node
                node={{
                  ...r,
                  viewMode: VIEW_MODE_CARD_ACTIVITY,
                  className:
                    'flex items-center md:flex-col border-none lg:border-solid',
                }}
              />
            </div>
          )
        })}
      {isLoading &&
        Array(6)
          .fill(0)
          .map((_, index) => <HighlightCardSkeleton key={index} />)}
      {!isLoading && !!!results?.length && <NoResults />}
    </div>
  )
}

export { ActivitiesList }
