import { List, ListContent, ListFooter, ListHeader } from '../List'
import { VIEW_MODE_SEARCHER } from '../List/components/Header/constants'
import { LandingList } from './components'
import { SearcherListProps } from './types'

const SearcherList = ({ initialState, ...rest }: SearcherListProps) => (
  <List initialState={initialState} {...rest}>
    <ListHeader viewMode={VIEW_MODE_SEARCHER} />
    <ListContent>
      <LandingList />
    </ListContent>
    <ListFooter />
  </List>
)
export { SearcherList }
