import { Title } from '@/components/Title'
import { useWidth } from 'utils'
import { DrupalFieldTextFormat } from 'utils-drupal'
import { cn } from 'utils-tailwindcss'

import { GridProps } from './types'

const Grid = ({
  title,
  body,
  subtitle,
  cols = 2,
  children,
  className,
  redLine,
}: GridProps) => {
  const { isMobile, width } = useWidth()

  return (
    <div className={cn('container', className)}>
      <Title
        title={title}
        subtitle={
          typeof subtitle !== 'string'
            ? subtitle
            : ({ value: subtitle } as DrupalFieldTextFormat)
        }
        description={
          typeof body !== 'string'
            ? body
            : ({ value: body } as DrupalFieldTextFormat)
        }
        redLine={redLine}
      />
      <ul
        style={{
          gridTemplateColumns: `repeat(${
            !isMobile() ? (cols >= 3 && width < 1024 ? 2 : cols) : 1
          }, minmax(0, 1fr))`,
          placeContent: 'center',
        }}
        className="grid gap-6"
      >
        {children}
      </ul>
    </div>
  )
}

export { Grid }
