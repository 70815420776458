import * as SelectPrimitive from '@radix-ui/react-select'
import { cn } from 'utils-tailwindcss'

import { useSelectProvider } from '../../SelectProvider'
import { ContentStyles } from './Content.styles'
import { ContentProps } from './types'

export const Content = ({
  children,
  position = 'popper',
  ariaLabel,
  className,
  ...rest
}: ContentProps) => {
  const { width } = useSelectProvider()
  return (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        position={position}
        className={cn(ContentStyles.content(), className)}
        asChild
        sideOffset={-18}
        style={{
          width,
        }}
        {...rest}
      >
        <SelectPrimitive.Viewport
          className={ContentStyles.viewport()}
          aria-label={ariaLabel}
        >
          {children}
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  )
}
