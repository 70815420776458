import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from 'utils-tailwindcss'

import { TabsContentsStyles } from './TabsContent.styles'

const TabsContent = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(TabsContentsStyles(), className)}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { TabsContent }
