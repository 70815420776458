import {
  VIEW_MODE_CAROUSEL_4COL,
  VIEW_MODE_DEFAULT,
  VIEW_MODE_GRID,
} from '@/content/constants'

import { DrupalParagraphGalleryProps } from './types'
import { Carousel } from './viewmodes/Carousel'
import { Default } from './viewmodes/Default'
import { Grid } from './viewmodes/Grid'

const Gallery = ({
  paragraph,
  viewMode = VIEW_MODE_DEFAULT,
  ...rest
}: DrupalParagraphGalleryProps) => (
  <div className={`${paragraph.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        case VIEW_MODE_CAROUSEL_4COL:
          return <Carousel paragraph={paragraph} {...rest} />
        case VIEW_MODE_GRID:
          return <Grid paragraph={paragraph} {...rest} />
        default:
          return <Default paragraph={paragraph} {...rest} />
      }
    })()}
  </div>
)

export { Gallery }
