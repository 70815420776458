import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { closeHandler } from 'ui'
import { HookOutput } from 'utils'

import { useListProvider } from '../../ListProvider'
import { Filter } from '../../types'

type useCheckBoxListResult = HookOutput<
  {
    closePopoverButtonRef: RefObject<closeHandler>
    isDisabled: boolean
    selected: Filter[]
    showResults: boolean | undefined
  },
  {
    // eslint-disable-next-line unused-imports/no-unused-vars
    toogleSelected: (item: Filter) => void
    resetSelected: () => void
    // eslint-disable-next-line unused-imports/no-unused-vars
    isSelected: (id: string) => boolean
    close: () => void
    setIsOpen: Dispatch<SetStateAction<boolean>>
  }
>

export const useCheckBoxList = (type: string): useCheckBoxListResult => {
  const [selected, setSelected] = useState<Filter[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const closePopoverButtonRef = useRef<closeHandler>(null)
  const [isDisabled, setIsDisabled] = useState(false)

  const {
    state: { activeFilters, showResults },
    addFilters,
    removeFilter,
  } = useListProvider()

  const toogleSelected = (item: Filter) => {
    const newSelected: Filter[] = selected?.slice()
    isSelected(item.values.value)
      ? setSelected(
          newSelected?.filter(
            (selected) => selected.values.value != item.values.value
          )
        )
      : newSelected?.push(item) && setSelected(newSelected)
  }

  const isSelected = (id: string) => {
    return (
      selected?.find((filter: Filter) => filter.values.value === id) !==
      undefined
    )
  }

  const resetSelected = () => {
    setSelected([])
  }

  const close = () => {
    //@TODO COMPROBAR EL TYPE CON LAS TAXONOMIAS Y PREGUNTAR SI LAS LLAMADAS A LAS API DE LOS OTROS FILTROS AFECTAN AL COUNT
    if (selected.length > 0) {
      addFilters(
        selected.map((item) => {
          return { ...item, type }
        }),
        type
      )
    } else {
      removeFilter(type, true)
    }
    closePopoverButtonRef.current?.close()
  }

  useEffect(() => {
    setIsDisabled(selected.length === 0)
  }, [selected])

  useEffect(() => {
    const filtersSelected =
      activeFilters?.filter((filter) => filter.type === type) ?? []

    setSelected(filtersSelected)
  }, [activeFilters, type, isOpen])

  return {
    state: { closePopoverButtonRef, isDisabled, selected, showResults },
    actions: { toogleSelected, resetSelected, isSelected, close, setIsOpen },
  }
}
