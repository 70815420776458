import { FormattedMessage } from '@/components/FormattedMessage'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { useListProvider } from '@/components/List/ListProvider'
import { Title } from '@/components/Title'
import { Text } from 'ui'
import { DrupalFieldTextFormat } from 'utils-drupal'

import { ActiveFilterList } from '../../../ActiveFilterList'
import { SearchForm } from '../../../SearchForm'

const ItineraryDefault = () => {
  const {
    state: { activeFilters, query },
    removeFilterFromList,
  } = useListProvider()

  return (
    <div className="flex flex-col gap-8 px-6 sm:px-0">
      <div className="flex flex-col items-center justify-between w-full lg:flex-row">
        <Title
          className="lg:pb-0"
          title={FormattedMessage({
            id: 'itinerary.addPoints',
          })}
          subtitle={
            {
              value: FormattedMessage({
                id: 'itinerary.choosePoints',
              }),
            } as DrupalFieldTextFormat
          }
        />
        <SearchForm
          placeholder={FormattedMessage({
            id: 'megamenu.searcher.inputPlaceholder',
          })}
        />
      </div>
      {!!query && (
        <Text className="hidden text-lg font-semibold md:flex">
          <FormattedRichMessage
            id="searcher.activeQueryText"
            values={{ query }}
          />
        </Text>
      )}
      {!!activeFilters?.length && (
        <div className="hidden w-full md:flex">
          <ActiveFilterList
            filters={activeFilters}
            onDeleteFilter={removeFilterFromList}
          />
        </div>
      )}
    </div>
  )
}

export { ItineraryDefault }
