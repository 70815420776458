'use client'

import { ComponentProps } from 'react'
import { es } from 'date-fns/locale'
import { DateRange, DayPicker } from 'react-day-picker'

import { CalendarStyles } from './Calendar.styles'

export type CalendarProps = ComponentProps<typeof DayPicker> & {
  border?: boolean
  rounded?: boolean
}

export type CalendarDateRange = DateRange

const Calendar = ({
  showOutsideDays = false,
  border = true,
  rounded = true,
  locale,
  ...props
}: CalendarProps) => (
  <DayPicker
    showOutsideDays={showOutsideDays}
    classNames={{
      caption: CalendarStyles.header.arrows(),
      day_outside: CalendarStyles.day.outside(),
      day_selected: CalendarStyles.day.selected(),
      month: CalendarStyles.card({ border, rounded }),
      head_row: CalendarStyles.table.head.row(),
      head_cell: CalendarStyles.table.head.daysText(),
      row: CalendarStyles.table.body.daysNumbers(),
      cell: CalendarStyles.cell(),
      day: CalendarStyles.day.general(),
      tbody: CalendarStyles.tBody(),
      day_today: CalendarStyles.day.today(),
      day_range_middle: CalendarStyles.day.rangeMiddle(),
      months: CalendarStyles.months(),
      caption_label: CalendarStyles.header.currentMonthAndYearText(),
      nav_button_next: CalendarStyles.header.next(),
      nav_button_previous: CalendarStyles.header.previous(),
      day_disabled: CalendarStyles.day.disabled(),
      table: CalendarStyles.table.root(),
      day_range_end: CalendarStyles.day.day_range_end(),
      day_range_start: CalendarStyles.day.day_range_start(),
    }}
    locale={locale ?? es}
    {...props}
  />
)

Calendar.displayName = 'Calendar'

export { Calendar }
