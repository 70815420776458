import Link from 'next/link'
import { AttachedDocument } from '@/components/AttachedDocument'
import { CKEditor } from '@/components/CKEditor'
import { Media } from '@/components/Media'
import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { internalLink } from '@/lib/utils/internalLink'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ExternalLink, LinkIcon } from 'lucide-react'
import { Heading } from 'ui'

import { DrupalParagraphImgCustomTextProps } from '../../types'
import { BREAKPOINTS } from './constants'

const ImageFloatRigh = ({
  paragraph,
}: {
  paragraph: DrupalParagraphImgCustomTextProps
}) => {
  const INTERNAL_LINK = paragraph.link && internalLink(paragraph.link.uri)

  const conditionalTranslationKeys = {
    showMore: 'paragraph.customText.showMore',
    externalLink: 'global.externalLink',
    internalLink: 'global.internalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )
  return (
    <div className="my-16">
      <div className="container">
        <Heading className="mb-4">{paragraph.title}</Heading>
        <div className="flex flex-col-reverse md:block">
          <div className="w-[309px] md:w-[409px] md:float-right rounded-2xl overflow-hidden mb-8 lg:ml-12  self-center md:self-auto">
            <Media
              media={{
                ...(paragraph.computed_image || paragraph.image),
                viewMode: VIEW_MODE_DEFAULT,
              }}
              // @ts-ignore
              breakpoints={BREAKPOINTS}
            />
          </div>
          <div className=" md:pr-12 mb-8 md:mb-0 md:min-h-[230px]">
            <CKEditor text={paragraph.body} />
          </div>
        </div>
        {paragraph.link && (
          <>
            <Link
              target={INTERNAL_LINK ? '_self' : '_blank'}
              href={paragraph.link.uri}
              className="flex gap-3 font-bold no-underline"
              aria-label={paragraph.link.title}
              aria-describedby={
                INTERNAL_LINK
                  ? translations.internalLink
                  : translations.externalLink
              }
            >
              {INTERNAL_LINK ? (
                <LinkIcon
                  className="w-4 md:w-6"
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
              ) : (
                <ExternalLink
                  className="w-4 md:w-6"
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
              )}
              {paragraph.link.title || translations.showMore}
            </Link>
            {!INTERNAL_LINK ? (
              <p className="sr-only">{translations.newWindow}</p>
            ) : null}
          </>
        )}
        {!!paragraph.documents?.length && (
          <AttachedDocument documents={paragraph.documents} />
        )}
      </div>
    </div>
  )
}

export { ImageFloatRigh }
