import { cva } from 'class-variance-authority'

export const LayoutStyles = cva('flex w-full', {
  variants: {
    hasSidebar: {
      true: 'flex-row',
      false: 'flex-col',
    },
  },
  defaultVariants: {
    hasSidebar: false,
  },
})
