import { CardTeaser } from '@/components/CardTeaser'

import { DrupalNodeEstablishmentTeaserProps } from './types'

const Teaser = ({ node, size }: DrupalNodeEstablishmentTeaserProps) => (
  <CardTeaser
    title={node.title}
    image={node.computed_image}
    url={node.path?.alias}
    description={node.body}
    size={size}
    type={node.type}
    phone={node.phone}
    popup={!!node.popup}
  />
)

export { Teaser }
