'use client'

import { cn } from 'utils-tailwindcss'

import { ButtonTagStyles } from './ButtonTag.styles'
import { ButtonTagProps } from './types'

const ButtonTag = ({
  isActive = true,
  className,
  children,
  ...props
}: ButtonTagProps) => (
  <button className={cn(ButtonTagStyles({ isActive }), className)} {...props}>
    {children}
  </button>
)

export { ButtonTag }
