import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { GRAN_TOUR_INDEX } from '@/components/List/constants'
import { LIST_TYPE_ITINERARY } from '@/components/Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'
import { Spinner, Text } from 'ui'

export const Loader = ({
  isLoading,
  total,
  type,
}: {
  isLoading?: boolean
  total: number
  type?: string
}) => {
  return isLoading ? (
    <Spinner />
  ) : (
    <Text className="text-sm font-normal">
      <FormattedRichMessage
        id={
          type === LIST_TYPE_ITINERARY
            ? 'itinerary.totalPoints'
            : type === GRAN_TOUR_INDEX
            ? 'ciclaMadrid.granTourSearcher.totalStage'
            : 'searcher.routes.totalRoutes'
        }
        values={{ total }}
      />
    </Text>
  )
}
