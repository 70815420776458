import { useMemo, useRef } from 'react'
import dynamic from 'next/dynamic'
import { DrupalNodeRoute } from '@/components/Node/components/Route/types'
import { NODE_ROUTE_BASE } from '@/components/Node/constants'
import { getIcon } from '@/components/Paragraph/components/ItineraryMap/viewmodes/Default/utils/helpers'
import { LAT_LON_MADRID } from '@/content/constants'
import { useWidth } from 'utils'

import { getRoutesIcon } from '../../../List/helpers'
import { useMapListProvider } from '../../../List/MapListProvider'
import { getPosition } from '../../helper'

const DynamicMap = dynamic(() => import('ui/src/components/maps/Map/Map'), {
  ssr: false,
})

const DynamicMapRouting = dynamic(
  () => import('ui/src/components/maps/Map/components/Routing'),
  {
    ssr: false,
  }
)
const DynamicMapMarker = dynamic(
  () => import('ui/src/components/maps/Map/components/Marker'),
  {
    ssr: false,
  }
)

export const Default = () => {
  const { isMobile } = useWidth()
  const {
    state: { currentRoute, categories },
    listState: { results },
    setCurrentRoute,
    type,
  } = useMapListProvider()

  const position = useRef<[number, number]>()
  const currentPosition = useMemo(() => {
    if (!!currentRoute) {
      position.current = getPosition(currentRoute?.text_initial_point)
    }
    return position.current
  }, [currentRoute])

  return (
    <div className="relative w-full h-full">
      <DynamicMap
        scrollWheelZoom={true}
        height={isMobile() ? '100vh' : '807px'}
        width="auto"
        zoom={14}
        doubleClickZoom={false}
        className="z-0 rounded-l-none shadow-sm"
        calculateCenter
        center={
          currentPosition
            ? isMobile()
              ? [currentPosition[0] - 0.001, currentPosition[1]]
              : [currentPosition[0], currentPosition[1] - 0.003]
            : LAT_LON_MADRID
        }
      >
        {results?.map((route: DrupalNodeRoute) => {
          return (
            <DynamicMapMarker
              key={route.id}
              position={getPosition(route.text_initial_point) || LAT_LON_MADRID}
              Icon={
                type === NODE_ROUTE_BASE
                  ? getRoutesIcon(
                      route.term_categories?.map((cat) => cat.name) ?? [],
                      categories
                    )
                  : getIcon(type)
              }
              isActive={currentRoute?.id === route.id}
              eventHandlers={{
                click: () => {
                  setCurrentRoute(route)
                },
              }}
            />
          )
        })}
        {currentRoute?.gpx.resourceIdObjMeta.trk?.map((trk) => (
          <DynamicMapRouting
            key={trk.name}
            points={trk.trkseg[0].points?.map((point) => [
              Number(point.lat),
              Number(point.lon),
            ])}
          />
        ))}
      </DynamicMap>
    </div>
  )
}
