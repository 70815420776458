import { ElementRef, forwardRef } from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cn } from 'utils-tailwindcss'

import { ContentStyles } from './Content.styles'
import { ContentProps } from './types'

const Content = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  ContentProps
>(({ className, align, sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(ContentStyles.content(), className)}
      {...props}
    />
  </PopoverPrimitive.Portal>
))

Content.displayName = PopoverPrimitive.Content.displayName

export { Content }
