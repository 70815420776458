import { Dispatch, SetStateAction, useState } from 'react'
import { useRouter } from 'next-translate-routes'
import { HookOutput } from 'utils'

type UseDropdownMobileMenuResult = HookOutput<
  { selected: string; locale: string | undefined },
  {
    setSelected: Dispatch<SetStateAction<string>>
  }
>

export const useDropDownMobileMenu = (): UseDropdownMobileMenuResult => {
  const [selected, setSelected] = useState<string>('default')
  const { locale } = useRouter()
  return {
    state: { selected, locale },
    actions: { setSelected },
  }
}
