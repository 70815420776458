import {
  FormControl,
  FormField,
  FormItem,
  RadioGroup,
  RadioGroupItem,
  RadioGroupItemLabel,
} from 'ui'

import { RadioGroupFieldProps } from './types'

export const RadioGroupField = ({
  id,
  terms,
  defaultValue,
}: RadioGroupFieldProps) => {
  return (
    <FormField
      name={id}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <div className="w-full">
            <FormItem>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  {...field}
                  className="flex flex-col gap-4"
                >
                  {terms.map((radio) => (
                    <RadioGroupItem
                      id={radio.values.value}
                      key={radio.values.value}
                      value={radio.values.value}
                    >
                      <RadioGroupItemLabel
                        htmlFor={radio.values.value}
                        className="text-text-medium"
                      >
                        {radio.values.label}
                      </RadioGroupItemLabel>
                    </RadioGroupItem>
                  ))}
                </RadioGroup>
              </FormControl>
            </FormItem>
          </div>
        )
      }}
    />
  )
}
