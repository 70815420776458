import { useWidth } from 'utils'

import { Desktop } from './components/Desktop'
import { Mobile } from './components/Mobile'

const Searcher = () => {
  const { isMobile } = useWidth()

  return isMobile() ? <Mobile /> : <Desktop />
}

export { Searcher }
