import { forwardRef } from 'react'
import { XIcon } from 'lucide-react'

import { CloseButtonProps } from './types'

const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  ({ className, onClick, ...props }, ref) => (
    <button
      ref={ref}
      type="button"
      aria-label="Close"
      data-testid="closeButton"
      className={className}
      onClick={onClick}
    >
      <XIcon role="presentation" aria-hidden="true" {...props} />
    </button>
  )
)

CloseButton.displayName = 'CloseButton'

export { CloseButton }
