import dynamic from 'next/dynamic'
import { Link } from '@/components/Link'
import { LocationCard } from '@/components/LocationCard'
import { VIEW_MODE_DYNAMIC } from '@/components/LocationCard/constants'
import { DrupalNodeItinerary } from '@/components/Node/components/Itinerary/types'
import { DrupalNodePlace } from '@/components/Node/components/Place/types'
import { Title } from '@/components/Title'
import { LAT_MADRID, LON_MADRID } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { useRouter } from 'next-translate-routes'
import { DrupalFieldTextFormat } from 'utils-drupal'
import { cn } from 'utils-tailwindcss'

import { DrupalParagraphItineraryMapDefaultProps } from './types'
import { getIcon } from './utils/helpers'

const DynamicMap = dynamic(() => import('ui/src/components/maps/Map/Map'), {
  ssr: false,
})

const DynamicMarker = dynamic(
  () => import('ui/src/components/maps/Map/components/Marker'),
  {
    ssr: false,
  }
)

const Default = ({
  paragraph,
  nodeTitle,
}: DrupalParagraphItineraryMapDefaultProps) => {
  const conditionalTranslationKeys = {
    createYourItinerary: 'global.createYourItinerary',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  const { locale } = useRouter()

  return (
    <section
      className={cn(
        !!paragraph.background ? 'my-6 md:bg-neutral-gray-100' : ''
      )}
    >
      <div className="container">
        <div
          className={cn(
            `grid items-center mx-auto md:grid-cols-2 md:gap-18 ${
              !!paragraph.background ? 'md:py-16' : 'md:py-3'
            }`
          )}
        >
          {!!paragraph.contents.filter(
            (node) => node.id !== 'missing' && node?.status
          ).length && (
            <DynamicMap
              height="19.5rem"
              width="auto"
              className="z-0 order-2 rounded md:order-1"
              zoomControl={false}
              scrollWheelZoom={false}
              calculateCenter
            >
              {paragraph.contents
                .filter(
                  (node) => node?.id !== 'missing' && !!node && node?.status
                )
                ?.map((node: DrupalNodePlace | DrupalNodeItinerary) => (
                  <DynamicMarker
                    key={node.id}
                    position={[
                      node.geofield_address?.lat || LAT_MADRID,
                      node.geofield_address?.lon || LON_MADRID,
                    ]}
                    isActive={false}
                    Icon={getIcon(node.type)}
                    extrernalIcon={
                      !!node.term_category?.resourceIdObjMeta
                        ? node.term_category?.resourceIdObjMeta.icon_url
                        : undefined
                    }
                    ariaLabel={`${node.term_category?.name ?? nodeTitle}, ${
                      node.title
                    }`}
                  >
                    <LocationCard
                      viewMode={VIEW_MODE_DYNAMIC}
                      title={node.term_category?.name ?? nodeTitle}
                      location={node.term_municipality?.name?.value}
                      description={
                        { value: node.title } as DrupalFieldTextFormat
                      }
                      image={node.computed_image ?? node.image}
                    />
                  </DynamicMarker>
                ))}
            </DynamicMap>
          )}
          <div className="flex flex-col items-start order-1 md:order-2">
            <Title
              title={paragraph.title}
              subtitle={paragraph.subtitle}
              description={paragraph.body}
            />
            {paragraph.content && !paragraph.bool && (
              <Link
                locale={locale}
                className="flex"
                size="sm"
                href={paragraph.content.path.alias}
              >
                {paragraph.content.title || translations.createYourItinerary}
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
export { Default }
