import { cva } from 'class-variance-authority'

export const ItemStyles = {
  wrapper: cva(['flex', 'items-center', 'gap-2'], {
    variants: {
      disabled: {
        true: ['opacity-50 pointer-events-none'],
      },
    },
  }),
  item: cva([
    'w-6 h-6',
    'ring-1 ring-neutral-gray-400 rounded-full',
    'data-[state=checked]:bg-neutral-white',
    'data-[state=checked]:ring-2 data-[state=checked]:ring-primary-red-900',
    'disabled:pointer-events-none',
    'hover:ring-2',
    'data-[state=unchecked]:ring-neutral-gray-400',
    'hover:bg-neutral-gray-200',
  ]),
  indicator: cva([
    'flex',
    'w-full h-full',
    'relative',
    'after:content-[""]',
    'after:w-3',
    'after:h-3',
    'after:rounded-full',
    'after:bg-primary-red-900',
    'after:m-auto',
  ]),
}
