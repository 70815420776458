const JapaneseFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    width="28px"
    height="19px"
    viewBox="0 0 28 19"
    version="1.1"
    role="presentation"
    aria-hidden="true"
  >
    <g id="surface1">
      <rect
        x="0"
        y="0"
        width="28"
        height="19"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        stroke="none"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(73.72549%,0%,17.647059%)"
        fillOpacity="1"
        d="M 19.601562 9.5 C 19.601562 12.648438 17.09375 15.199219 14 15.199219 C 10.90625 15.199219 8.398438 12.648438 8.398438 9.5 C 8.398438 6.351562 10.90625 3.800781 14 3.800781 C 17.09375 3.800781 19.601562 6.351562 19.601562 9.5 Z M 19.601562 9.5 "
      />
    </g>
  </svg>
)

export { JapaneseFlag }
