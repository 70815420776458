import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Language } from '@/components/PublicLayout/usePublicLayout'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { Menu, Search, X } from 'lucide-react'
import { Link } from 'next-translate-routes'
import { Text } from 'ui'

import { Explore } from '../Megamenu/components/Explore'
import { SEARCH } from '../Megamenu/constants'
import { MegamenuType } from '../Megamenu/types'
import { DropDownMobileMenu } from './components'

const HeaderMobile = ({
  megamenu,
  languages,
}: {
  megamenu: MegamenuType
  languages: Language[]
}) => {
  const [scroll, setScroll] = useState<any>()
  const [value, setValue] = useState('')
  const { asPath } = useRouter()

  useEffect(() => {
    setScroll(document)
    setValue('')
  }, [asPath])
  scroll &&
    (!value
      ? (scroll.body.style.overflow = 'scroll')
      : (scroll.body.style.overflow = 'hidden'))
  return (
    <NavigationMenu.Root
      className="relative w-screen z-21"
      onValueChange={setValue}
      value={value}
      aria-label={FormattedMessage({
        id: 'global.mainMenu',
      })}
    >
      <NavigationMenu.List className="flex justify-between px-6 py-3 align-middle bg-primary-red-900">
        <NavigationMenu.Item className="flex justify-center">
          <NavigationMenu.Trigger
            className="block group text-neutral-white"
            aria-label="menu"
          >
            <Menu
              className="group-data-[state=open]:hidden block w-8 h-8"
              aria-roledescription="presentation"
              aria-hidden="true"
            />
            <span>
              <X
                className=" group-data-[state=open]:block hidden w-8 h-8"
                aria-roledescription="presentation"
                aria-hidden="true"
              />
              <span className="sr-only">
                <FormattedMessage id="global.close" />
              </span>
            </span>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="min-h-screen bg-secondary-black-900 ">
            <DropDownMobileMenu
              megamenu={megamenu.menu}
              languages={languages}
            />
          </NavigationMenu.Content>
        </NavigationMenu.Item>
        <Link href="/home" className="no-underline">
          <Text className="text-lg text-neutral-white">
            Visit
            <span className="font-bold">Madrid</span>
          </Text>
        </Link>
        <NavigationMenu.Item className="flex justify-center text-neutral-white">
          <NavigationMenu.Trigger className="block group">
            <Search
              className="group-data-[state=open]:hidden block w-8 h-8"
              aria-roledescription="presentation"
              aria-hidden="true"
            />
            <span>
              <X
                className=" group-data-[state=open]:block hidden w-8 h-8"
                aria-roledescription="presentation"
                aria-hidden="true"
              />
              <span className="sr-only">
                <FormattedMessage id="global.close" />
              </span>
            </span>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="px-2 pt-6 bg-neutral-gray-100">
            <Explore
              highlights={megamenu?.[SEARCH]?.items || []}
              url={megamenu?.[SEARCH]?.path}
            />
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      </NavigationMenu.List>
      <NavigationMenu.Viewport />
    </NavigationMenu.Root>
  )
}

export { HeaderMobile }
