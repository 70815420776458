import { LanguageMenu } from '../../../LanguageMenu'
import { Megamenu } from '../../../Megamenu'
import { HeaderBaseType } from './types'

export const HeaderBase = ({
  megamenu,
  languages,
  children,
}: HeaderBaseType) => (
  <div className="relative flex flex-col w-full z-18">
    <div className="w-full">
      <div className="container z-50">
        <LanguageMenu languages={languages} />
        <Megamenu megamenu={megamenu} />
      </div>
    </div>
    {children}
  </div>
)
