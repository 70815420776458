import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from 'utils-tailwindcss'

import { TabsTriggerStyles } from './TabsTrigger.styles'

const TabsTrigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...rest }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    tabIndex={0}
    className={cn(TabsTriggerStyles(), className)}
    {...rest}
  />
))

TabsTrigger.displayName = TabsPrimitive.Trigger.displayName
export { TabsTrigger }
