import { ComponentPropsWithRef } from 'react'
import { cn } from 'utils-tailwindcss'

const RadixTag = ({
  target = '_blank',
  rel = 'noreferrer',
  title = 'Radix UI',
  className,
  children,
  ...props
}: ComponentPropsWithRef<'a'>) => (
  <div className="inline-flex rounded-md bg-neutral-gray-200">
    <a
      target={target}
      rel={rel}
      className={cn('flex items-center px-2 py-1', className)}
      {...props}
    >
      <svg viewBox="0 0 25 25" className="w-3 h-3 mr-1">
        <path
          d="M12 25C7.58173 25 4 21.4183 4 17C4 12.5817 7.58173 9 12 9V25Z"
          fill="#000"
        ></path>
        <path d="M12 0H4V8H12V0Z" fill="#000"></path>
        <path
          d="M17 8C19.2091 8 21 6.20914 21 4C21 1.79086 19.2091 0 17 0C14.7909 0 13 1.79086 13 4C13 6.20914 14.7909 8 17 8Z"
          fill="#000"
        ></path>
      </svg>
      <span className="text-sm text-text-dark">{title}</span>
    </a>
  </div>
)

export { RadixTag }
