import { ComponentPropsWithRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { AlertStyles } from './Alert.styles'

const Alert = ({
  children,
  className,
  ...props
}: ComponentPropsWithRef<'span'>) => (
  <span className={cn(AlertStyles.container(), className)} {...props}>
    <div className={cn(AlertStyles.content())}>{children}</div>
  </span>
)

export { Alert }
