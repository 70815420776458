import { TaxonomyTerm } from '@/components/TaxonomyTerm'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { PersonStandingIcon } from 'lucide-react'
import { DrupalTaxonomyTerm } from 'next-drupal'
import { InfoTag, InfoTagBody, InfoTagHeader } from 'ui'

const Activity = ({ term }: { term: DrupalTaxonomyTerm }) => {
  const conditionalTranslationKeys = {
    activity: 'global.activity',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return term.id === 'missing' ? (
    <></>
  ) : (
    <InfoTag>
      <InfoTagHeader>
        <PersonStandingIcon />
        {translations.activity}
      </InfoTagHeader>
      <InfoTagBody>
        <TaxonomyTerm term={term} />
      </InfoTagBody>
    </InfoTag>
  )
}

export { Activity }
