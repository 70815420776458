const PortugueseFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    width="28px"
    height="19px"
    viewBox="0 0 28 19"
    version="1.1"
    role="presentation"
    aria-hidden="true"
  >
    <g id="surface1">
      <rect
        x="0"
        y="0"
        width="28"
        height="19"
        fill="rgb(100%,0%,0%)"
        fillOpacity={1}
        stroke="none"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,40%,0%)"
        fillOpacity="1"
        d="M 0 0 L 11.199219 0 L 11.199219 19 L 0 19 Z M 0 0 "
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 318.247768 262.006579 C 288.030134 261.101974 149.497768 174.671053 148.577009 160.855263 L 156.194196 148.108553 C 169.921875 168.092105 311.551339 252.220395 325.446429 249.177632 L 318.247768 262.006579 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 154.603795 146.381579 C 151.841518 153.700658 190.764509 177.713816 237.472098 206.167763 C 284.263393 234.621711 324.52567 252.138158 327.539062 249.671053 C 327.706473 249.342105 329.045759 247.121711 328.878348 247.121711 C 328.376116 247.944079 326.953125 248.190789 324.860491 247.615132 C 312.220982 243.996711 279.241071 228.865132 238.476562 204.111842 C 197.712054 179.358553 162.220982 156.578947 156.696429 146.875 C 156.277902 146.217105 156.026786 144.983553 156.026786 143.996711 L 155.94308 143.996711 L 154.771205 146.052632 Z M 318.917411 262.417763 C 318.415179 263.404605 317.49442 263.404605 315.652902 263.240132 C 304.352679 261.924342 270.117188 245.3125 229.520089 220.970395 C 182.310268 192.763158 143.303571 166.940789 147.572545 160.279605 L 148.660714 158.223684 L 148.91183 158.305921 C 145.145089 169.654605 225.837054 215.871711 230.608259 218.832237 C 277.315848 247.779605 316.741071 264.638158 320.256696 260.279605 Z M 318.917411 262.417763 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 240.15067 169.243421 C 270.368304 168.996711 307.700893 165.131579 329.21317 156.578947 L 324.52567 149.013158 C 311.886161 156.085526 274.386161 160.690789 239.899554 161.348684 C 199.21875 161.019737 170.424107 157.154605 156.026786 147.532895 L 151.674107 155.509868 C 178.125 166.694079 205.245536 169.078947 240.15067 169.243421 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 330.46875 156.743421 C 329.715402 157.894737 315.736607 162.746711 295.061384 166.282895 C 281.082589 168.421053 262.834821 170.230263 240.066964 170.230263 C 218.470982 170.3125 200.725446 168.75 187.416295 166.940789 C 165.736607 163.569079 154.520089 158.799342 150.418527 157.154605 C 150.837054 156.332237 151.08817 155.838816 151.422991 155.098684 C 163.309152 159.786184 174.609375 162.664474 187.751116 164.720395 C 200.976562 166.447368 218.470982 168.009868 239.983259 168.009868 C 262.667411 168.009868 280.747768 166.036184 294.642857 163.980263 C 315.736607 160.526316 327.287946 156.167763 328.962054 154.111842 Z M 326.367188 149.095395 C 324.107143 150.904605 312.639509 155.016447 292.550223 158.223684 C 279.157366 160.115132 262.081473 161.842105 240.31808 161.842105 C 219.559152 161.842105 202.65067 160.444079 189.84375 158.634868 C 169.419643 155.921053 158.537946 151.151316 154.603795 149.753289 C 155.022321 149.095395 155.440848 148.355263 155.77567 147.697368 C 158.872768 149.259868 169.335938 153.536184 190.011161 156.414474 C 202.734375 158.223684 219.726562 159.621711 240.31808 159.539474 C 261.997768 159.539474 278.822545 157.8125 292.215402 155.838816 C 312.388393 153.125 323.270089 147.944079 324.944196 146.628289 Z M 326.367188 149.095395 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 140.876116 205.674342 C 159.458705 215.625 200.809152 220.723684 239.899554 221.052632 C 275.47433 221.134868 321.847098 215.542763 339.174107 206.332237 L 338.671875 196.381579 C 333.231027 204.851974 283.59375 212.993421 239.481027 212.664474 C 195.368304 212.253289 154.436384 205.509868 140.792411 196.628289 L 140.876116 205.674342 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 340.094866 204.194079 L 340.094866 206.578947 C 337.5 209.703947 321.177455 214.473684 300.669643 217.763158 C 285.100446 220.148026 264.760045 221.957237 239.397321 221.957237 C 215.373884 221.957237 196.121652 220.230263 181.305804 217.927632 C 157.784598 214.555921 142.717634 208.552632 139.704241 206.743421 L 139.704241 203.947368 C 148.74442 209.950658 173.353795 214.391447 181.640625 215.707237 C 196.372768 218.009868 215.457589 219.736842 239.397321 219.736842 C 264.676339 219.736842 284.84933 217.927632 300.334821 215.542763 C 315.066964 213.404605 335.993304 207.894737 340.094866 204.194079 Z M 340.094866 195.723684 L 340.094866 198.108553 C 337.5 201.233553 321.177455 205.921053 300.669643 209.292763 C 285.100446 211.677632 264.760045 213.486842 239.397321 213.486842 C 215.373884 213.486842 196.205357 211.759868 181.305804 209.457237 C 157.784598 206.003289 142.717634 200 139.704241 198.273026 L 139.704241 195.476974 C 148.74442 201.480263 173.353795 205.921053 181.640625 207.236842 C 196.372768 209.539474 215.457589 211.184211 239.397321 211.184211 C 264.676339 211.184211 284.84933 209.457237 300.334821 207.072368 C 315.066964 204.934211 335.993304 199.424342 340.094866 195.723684 Z M 340.094866 195.723684 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 239.815848 260.279605 C 197.042411 260.032895 160.379464 248.684211 152.594866 246.792763 L 158.28683 255.592105 C 171.930804 261.348684 207.672991 269.901316 240.234375 268.996711 C 272.712054 268.009868 301.08817 265.542763 321.09375 255.756579 L 326.953125 246.628289 C 313.309152 253.042763 266.852679 260.279605 239.815848 260.279605 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.55"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 323.270089 253.700658 C 322.433036 255.016447 321.595982 256.332237 320.675223 257.565789 C 311.300223 260.855263 296.40067 264.391447 290.122768 265.378289 C 277.315848 268.009868 257.477679 269.983553 239.815848 269.983553 C 201.981027 269.407895 170.926339 262.006579 156.361607 255.674342 L 155.189732 253.700658 L 155.357143 253.371711 L 157.366071 254.111842 C 183.314732 263.404605 212.444196 267.105263 240.066964 267.763158 C 257.561384 267.845395 275.139509 265.789474 289.36942 263.240132 C 311.132812 258.881579 319.921875 255.592105 322.600446 254.111842 Z M 328.292411 245.476974 C 328.376116 245.476974 328.376116 245.476974 328.376116 245.559211 C 327.706473 246.628289 327.120536 247.697368 326.450893 248.766447 C 321.344866 250.575658 307.700893 254.605263 287.779018 257.401316 C 274.637277 259.128289 266.434152 260.9375 240.31808 261.430921 C 191.266741 260.197368 159.542411 250.575658 152.008929 248.190789 L 150.837054 246.052632 C 179.296875 253.453947 208.258929 258.634868 240.31808 259.210526 C 264.174107 258.717105 274.386161 256.907895 287.444196 255.180921 C 310.714286 251.5625 322.433036 247.697368 325.948661 246.546053 C 325.864955 246.546053 325.864955 246.463816 325.78125 246.381579 Z M 328.292411 245.476974 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 328.794643 197.779605 C 328.962054 225.904605 314.564732 251.151316 303.013393 262.253289 C 286.523438 278.042763 264.84375 288.157895 239.397321 288.651316 C 211.021205 289.144737 184.319196 270.641447 177.120536 262.582237 C 163.058036 246.628289 151.674107 226.480263 151.25558 199.342105 C 153.013393 168.585526 165.066964 147.203947 182.561384 132.565789 C 200.055804 117.845395 223.325893 110.690789 242.66183 111.266447 C 265.011161 111.842105 291.127232 122.779605 309.207589 144.572368 C 321.010045 158.799342 326.116071 174.342105 328.794643 197.779605 Z M 239.648437 101.398026 C 294.140625 101.398026 338.922991 145.723684 338.922991 200.082237 C 338.922991 254.523026 294.140625 298.848684 239.648437 298.848684 C 185.15625 298.848684 140.708705 254.523026 140.708705 200.082237 C 140.708705 145.723684 185.15625 101.398026 239.648437 101.398026 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 239.899554 101.069079 C 294.475446 101.069079 338.922991 145.559211 338.922991 200.082237 C 338.922991 254.605263 294.475446 299.095395 239.899554 299.095395 C 185.407366 299.095395 140.876116 254.605263 140.876116 200.082237 C 140.876116 145.559211 185.407366 101.069079 239.899554 101.069079 Z M 143.052455 200.082237 C 143.052455 253.371711 186.830357 296.957237 239.899554 296.957237 C 292.96875 296.957237 336.746652 253.371711 336.746652 200.082237 C 336.746652 146.875 292.96875 103.289474 239.899554 103.289474 C 186.830357 103.289474 143.052455 146.875 143.052455 200.082237 Z M 143.052455 200.082237 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 239.983259 109.292763 C 289.704241 109.292763 330.719866 150.164474 330.719866 200 C 330.719866 249.917763 289.704241 290.707237 239.983259 290.707237 C 190.262277 290.707237 149.330357 249.917763 149.330357 200 C 149.330357 150.164474 190.262277 109.292763 239.983259 109.292763 Z M 151.506696 200 C 151.506696 248.684211 191.434152 288.569079 239.983259 288.569079 C 288.532366 288.569079 328.543527 248.684211 328.543527 200 C 328.543527 151.315789 288.532366 111.513158 239.983259 111.513158 C 191.434152 111.513158 151.506696 151.315789 151.506696 200 Z M 151.506696 200 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 244.001116 100.657895 L 235.46317 100.657895 L 235.46317 299.671053 L 244.001116 299.671053 Z M 244.001116 100.657895 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 243.164062 99.506579 L 245.256696 99.506579 L 245.340402 300.822368 L 243.164062 300.822368 Z M 234.709821 99.588816 L 236.886161 99.588816 L 236.886161 300.822368 L 234.709821 300.822368 Z M 234.709821 99.588816 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 339.006696 203.947368 L 339.006696 196.546053 L 332.979911 191.036184 L 298.995536 181.990132 L 250.027902 176.973684 L 191.015625 180.016447 L 148.995536 189.967105 L 140.541295 196.299342 L 140.541295 203.618421 L 161.969866 193.996711 L 213.030134 186.019737 L 261.997768 186.019737 L 297.991071 189.967105 L 323.018973 195.970395 Z M 339.006696 203.947368 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 239.983259 184.786184 C 263.337054 184.703947 286.021205 187.006579 304.017857 190.460526 C 322.600446 194.161184 335.658482 198.848684 340.094866 204.029605 L 340.094866 206.661184 C 334.737723 200.164474 317.159598 195.394737 303.59933 192.680921 C 285.770089 189.226974 263.169643 186.924342 239.983259 187.006579 C 215.373884 187.006579 192.522321 189.391447 174.944196 192.845395 C 160.881696 195.641447 142.047991 201.151316 139.704241 206.661184 L 139.704241 203.947368 C 140.959821 200.164474 154.938616 194.490132 174.609375 190.542763 C 192.354911 187.088816 215.290179 184.786184 239.983259 184.786184 Z M 239.983259 176.315789 C 263.337054 176.233553 286.021205 178.453947 304.017857 181.990132 C 322.600446 185.690789 335.658482 190.378289 340.094866 195.559211 L 340.094866 198.190789 C 334.737723 191.694079 317.159598 186.924342 303.59933 184.210526 C 285.770089 180.674342 263.253348 178.453947 239.983259 178.536184 C 215.457589 178.536184 192.689732 180.921053 175.111607 184.292763 C 161.467634 186.924342 141.88058 192.680921 139.704241 198.190789 L 139.704241 195.476974 C 140.959821 191.694079 155.273438 185.855263 174.69308 182.072368 C 192.354911 178.618421 215.290179 176.315789 239.983259 176.315789 Z M 239.983259 176.315789 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 239.481027 132.976974 C 276.311384 132.8125 308.454241 138.075658 323.186384 145.641447 L 328.543527 154.934211 C 315.736607 148.026316 281.082589 140.871711 239.564732 141.940789 C 205.664062 142.1875 169.503348 145.641447 151.339286 155.345395 L 157.784598 144.654605 C 172.684152 136.924342 207.756696 132.976974 239.481027 132.976974 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 239.983259 140.625 C 260.993304 140.542763 281.333705 141.776316 297.405134 144.654605 C 312.472098 147.450658 326.86942 151.644737 328.878348 153.947368 L 330.46875 156.743421 C 325.446429 153.453947 313.058036 149.835526 297.154018 146.792763 C 281.166295 143.832237 260.825893 142.845395 239.899554 142.845395 C 216.210937 142.763158 197.795759 144.078947 181.975446 146.792763 C 165.234375 149.917763 153.59933 154.440789 150.753348 156.496711 L 152.34375 153.536184 C 157.868304 150.740132 166.741071 147.286184 181.55692 144.654605 C 197.879464 141.611842 216.545759 140.789474 239.983259 140.625 Z M 239.983259 132.154605 C 260.072545 132.072368 279.910714 133.223684 295.396205 136.019737 C 307.617188 138.322368 319.670759 142.105263 324.107143 145.394737 L 326.450893 149.095395 C 322.516741 144.654605 307.617188 140.542763 294.475446 138.157895 C 279.073661 135.526316 260.072545 134.457237 239.983259 134.292763 C 218.889509 134.375 199.386161 135.690789 184.235491 138.404605 C 169.754464 141.200658 160.46317 144.407895 156.529018 146.957237 L 158.537946 143.914474 C 163.978795 141.036184 172.767857 138.404605 183.816964 136.266447 C 199.135045 133.470395 218.722098 132.154605 239.983259 132.154605 Z M 239.983259 132.154605 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 289.118304 241.282895 C 270.954241 237.828947 252.706473 237.335526 239.899554 237.5 C 178.543527 238.240132 158.705357 250.164474 156.277902 253.700658 L 151.674107 246.217105 C 167.327009 234.950658 200.725446 228.536184 240.234375 229.194079 C 260.742188 229.523026 278.487723 230.921053 293.387277 233.799342 L 289.118304 241.282895 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.55"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 239.564732 236.430921 C 256.640625 236.677632 273.465402 237.417763 289.620536 240.460526 L 288.448661 242.516447 C 273.381696 239.720395 257.393973 238.651316 239.648437 238.733553 C 216.964286 238.569079 194.029018 240.707237 174.107143 246.463816 C 167.829241 248.190789 157.366071 252.220395 156.277902 255.592105 L 155.106027 253.618421 C 155.524554 251.644737 161.802455 247.532895 173.521205 244.243421 C 196.456473 237.746711 217.801339 236.595395 239.564732 236.430921 Z M 240.31808 227.878289 C 258.063616 228.207237 276.311384 229.029605 294.05692 232.565789 L 292.885045 234.703947 C 276.813616 231.496711 261.495536 230.427632 240.485491 230.098684 C 217.717634 230.180921 193.610491 231.743421 171.679688 238.157895 C 164.564732 240.213816 152.34375 244.654605 152.008929 248.190789 L 150.837054 246.134868 C 151.08817 242.927632 161.635045 238.733553 171.177455 236.019737 C 193.27567 229.523026 217.466518 227.878289 240.31808 227.878289 Z M 240.31808 227.878289 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 327.539062 247.368421 L 320.172991 258.799342 L 298.995536 239.967105 L 244.001116 202.960526 L 181.975446 168.996711 L 149.832589 157.976974 L 156.696429 145.230263 L 159.040179 143.996711 L 178.962054 149.013158 L 245.00558 182.976974 L 283.007812 206.990132 L 314.983259 230.016447 L 328.041295 244.983553 Z M 327.539062 247.368421 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="evenodd"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.573"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 148.660714 158.305921 C 154.268973 154.440789 195.78683 172.944079 239.229911 199.095395 C 282.50558 225.411184 323.856027 255.016447 320.172991 260.608553 L 318.917411 262.5 L 318.331473 262.993421 C 318.498884 262.911184 319.084821 262.088816 318.331473 260.032895 C 316.489955 254.029605 287.109375 230.592105 238.392857 201.151316 C 190.93192 172.861842 151.339286 155.838816 147.405134 160.690789 Z M 329.129464 247.203947 C 332.645089 240.131579 294.140625 211.184211 246.428571 182.976974 C 197.628348 155.263158 162.472098 138.980263 156.026786 143.832237 L 154.603795 146.463816 C 154.603795 146.628289 154.6875 146.299342 154.938616 146.052632 C 156.110491 144.983553 158.035714 145.065789 158.956473 145.065789 C 170.00558 145.230263 201.646205 159.786184 245.926339 185.197368 C 265.345982 196.546053 328.041295 236.677632 327.790179 248.026316 C 327.790179 248.930921 327.873884 249.177632 327.455357 249.671053 Z M 329.129464 247.203947 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        strokeWidth="0.67037"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 180.636161 211.019737 C 180.636161 227.302632 187.248884 242.023026 198.046875 252.713816 C 208.844866 263.569079 223.577009 270.3125 239.899554 270.3125 C 256.222098 270.3125 271.121652 263.651316 281.919643 252.960526 C 292.633929 242.1875 299.330357 227.384868 299.330357 211.101974 L 299.330357 131.907895 L 180.552455 131.743421 Z M 180.636161 211.019737 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,0%,0%)"
        fillOpacity="1"
        strokeWidth="0.50734"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 182.8125 211.101974 L 182.8125 211.184211 C 182.8125 226.726974 189.257812 240.871711 199.553571 251.151316 C 209.933036 261.513158 224.246652 267.845395 239.815848 267.845395 C 255.46875 267.845395 269.698661 261.513158 279.99442 251.233553 C 290.373884 240.953947 296.735491 226.809211 296.735491 211.184211 L 296.735491 134.375 L 182.896205 134.375 L 182.8125 211.101974 M 273.800223 157.401316 L 273.883929 206.25 L 273.800223 211.430921 C 273.800223 212.828947 273.716518 214.309211 273.549107 215.707237 C 272.628348 223.4375 269.112723 230.098684 263.839286 235.361842 C 257.645089 241.529605 249.107143 245.3125 239.732143 245.3125 C 230.440848 245.3125 222.154018 241.365132 215.959821 235.197368 C 209.598214 228.865132 205.915179 220.230263 205.915179 211.266447 L 205.915179 157.236842 Z M 273.800223 157.401316 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 8.875 7.335938 C 8.882812 7.074219 9.066406 7.011719 9.066406 7.011719 C 9.066406 7.007812 9.261719 7.078125 9.261719 7.335938 L 8.875 7.335938 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.830357 147.697368 L 186.160714 154.029605 L 190.262277 154.029605 C 190.345982 148.766447 194.280134 147.944079 194.363839 147.944079 C 194.447545 147.944079 198.297991 149.095395 198.465402 154.029605 L 202.566964 154.029605 L 201.813616 147.615132 Z M 186.830357 147.697368 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 185.825893 154.029605 L 202.81808 154.029605 C 203.152902 154.029605 203.404018 154.440789 203.404018 154.851974 C 203.404018 155.263158 203.152902 155.592105 202.81808 155.592105 L 185.825893 155.592105 C 185.491071 155.592105 185.239955 155.263158 185.239955 154.851974 C 185.239955 154.440789 185.491071 154.029605 185.825893 154.029605 Z M 185.825893 154.029605 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 192.020089 154.029605 C 192.020089 150.740132 194.280134 149.753289 194.280134 149.753289 C 194.280134 149.753289 196.623884 150.740132 196.623884 154.029605 L 192.020089 154.029605 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.24442 145.065789 L 202.483259 145.065789 C 202.81808 145.065789 203.069196 145.394737 203.069196 145.723684 C 203.069196 146.134868 202.81808 146.463816 202.483259 146.463816 L 186.24442 146.463816 C 185.909598 146.463816 185.574777 146.134868 185.574777 145.723684 C 185.574777 145.394737 185.909598 145.065789 186.24442 145.065789 Z M 186.24442 145.065789 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.579241 146.463816 L 202.064732 146.463816 C 202.399554 146.463816 202.65067 146.792763 202.65067 147.203947 C 202.65067 147.532895 202.399554 147.861842 202.064732 147.861842 L 186.579241 147.861842 C 186.24442 147.861842 185.993304 147.532895 185.993304 147.203947 C 185.993304 146.792763 186.24442 146.463816 186.579241 146.463816 Z M 186.579241 146.463816 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 191.601562 135.855263 L 192.773438 135.855263 L 192.773438 136.759868 L 193.694196 136.759868 L 193.694196 135.855263 L 194.949777 135.855263 L 194.949777 136.759868 L 195.870536 136.759868 L 195.870536 135.855263 L 197.126116 135.855263 L 197.126116 137.911184 C 197.126116 138.157895 196.875 138.404605 196.540179 138.404605 L 192.103795 138.404605 C 191.852679 138.404605 191.601562 138.157895 191.601562 137.828947 Z M 191.601562 135.855263 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 196.205357 138.569079 L 196.456473 144.983553 L 192.1875 144.983553 L 192.438616 138.569079 L 196.205357 138.569079 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.93192 141.529605 L 191.09933 145.065789 L 186.914062 145.065789 L 187.081473 141.529605 Z M 190.93192 141.529605 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.9535 141.529605 L 191.037205 145.065789 L 186.935643 145.065789 L 187.103054 141.529605 Z M 190.9535 141.529605 "
        transform="matrix(0.0466667,0,0,0.0475,0.495087,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.328125 139.0625 L 187.5 139.0625 L 187.5 139.884868 L 188.337054 139.884868 L 188.337054 139.0625 L 189.592634 139.0625 L 189.592634 139.884868 L 190.513393 139.884868 L 190.513393 139.0625 L 191.685268 139.0625 L 191.685268 141.036184 C 191.685268 141.365132 191.434152 141.529605 191.183036 141.529605 L 186.830357 141.529605 C 186.579241 141.529605 186.328125 141.282895 186.328125 141.036184 Z M 186.328125 139.0625 "
        transform="matrix(0.0466667,0,0,0.0475,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.266 139.0625 L 187.52158 139.0625 L 187.52158 139.884868 L 188.358634 139.884868 L 188.358634 139.0625 L 189.614214 139.0625 L 189.614214 139.884868 L 190.451268 139.884868 L 190.451268 139.0625 L 191.706848 139.0625 L 191.706848 141.036184 C 191.706848 141.365132 191.455732 141.529605 191.204616 141.529605 L 186.851937 141.529605 C 186.600821 141.529605 186.266 141.282895 186.266 141.036184 Z M 186.266 139.0625 "
        transform="matrix(0.0466667,0,0,0.0475,0.495087,0)"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 9.046875 6.679688 C 9.046875 6.648438 9.089844 6.648438 9.089844 6.679688 L 9.089844 6.753906 L 9.046875 6.753906 L 9.046875 6.679688 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 8.800781 6.785156 C 8.800781 6.757812 8.839844 6.753906 8.839844 6.785156 L 8.839844 6.839844 L 8.800781 6.839844 L 8.800781 6.785156 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 9.296875 6.785156 C 9.296875 6.757812 9.335938 6.753906 9.335938 6.785156 L 9.335938 6.839844 L 9.296875 6.839844 L 9.296875 6.785156 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 8.875 9.535156 C 8.882812 9.273438 9.066406 9.210938 9.066406 9.210938 C 9.066406 9.210938 9.261719 9.277344 9.261719 9.539062 L 8.875 9.535156 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.830357 147.676911 L 186.160714 154.009147 L 190.262277 154.009147 C 190.345982 148.828226 194.280134 147.923621 194.363839 147.923621 C 194.447545 147.923621 198.297991 149.074937 198.465402 154.009147 L 202.566964 154.009147 L 201.813616 147.676911 Z M 186.830357 147.676911 "
        transform="matrix(0.0466667,0,0,0.0475,0,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 185.825893 154.091384 L 202.81808 154.091384 C 203.152902 154.091384 203.404018 154.420332 203.404018 154.831516 C 203.404018 155.2427 203.152902 155.653884 202.81808 155.653884 L 185.825893 155.653884 C 185.491071 155.653884 185.239955 155.2427 185.239955 154.831516 C 185.239955 154.420332 185.491071 154.091384 185.825893 154.091384 Z M 185.825893 154.091384 "
        transform="matrix(0.0466667,0,0,0.0475,0,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 192.020089 154.009147 C 192.020089 150.719674 194.280134 149.815068 194.280134 149.815068 C 194.280134 149.815068 196.623884 150.719674 196.623884 154.009147 L 192.020089 154.009147 "
        transform="matrix(0.0466667,0,0,0.0475,0,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.24442 145.045332 L 202.483259 145.045332 C 202.81808 145.045332 203.069196 145.374279 203.069196 145.785463 C 203.069196 146.114411 202.81808 146.443358 202.483259 146.443358 L 186.24442 146.443358 C 185.909598 146.443358 185.574777 146.114411 185.574777 145.785463 C 185.574777 145.374279 185.909598 145.045332 186.24442 145.045332 Z M 186.24442 145.045332 "
        transform="matrix(0.0466667,0,0,0.0475,0,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.579241 146.443358 L 202.064732 146.443358 C 202.399554 146.443358 202.65067 146.772305 202.65067 147.183489 C 202.65067 147.594674 202.399554 147.841384 202.064732 147.841384 L 186.579241 147.841384 C 186.24442 147.841384 185.993304 147.594674 185.993304 147.183489 C 185.993304 146.772305 186.24442 146.443358 186.579241 146.443358 Z M 186.579241 146.443358 "
        transform="matrix(0.0466667,0,0,0.0475,0,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 191.601562 135.917042 L 192.773438 135.917042 L 192.773438 136.739411 L 193.694196 136.739411 L 193.694196 135.834805 L 194.949777 135.834805 L 194.949777 136.739411 L 195.870536 136.739411 L 195.870536 135.834805 L 197.126116 135.834805 L 197.126116 137.890726 C 197.126116 138.219674 196.875 138.384147 196.540179 138.384147 L 192.103795 138.384147 C 191.852679 138.384147 191.601562 138.137437 191.601562 137.890726 Z M 191.601562 135.917042 "
        transform="matrix(0.0466667,0,0,0.0475,0,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 196.205357 138.548621 L 196.456473 145.045332 L 192.1875 145.045332 L 192.438616 138.548621 L 196.205357 138.548621 "
        transform="matrix(0.0466667,0,0,0.0475,0,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.93192 141.591384 L 191.09933 145.045332 L 186.914062 145.045332 L 187.081473 141.591384 Z M 190.93192 141.591384 "
        transform="matrix(0.0466667,0,0,0.0475,0,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.9535 141.591384 L 191.037205 145.045332 L 186.935643 145.045332 L 187.103054 141.591384 Z M 190.9535 141.591384 "
        transform="matrix(0.0466667,0,0,0.0475,0.495087,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.328125 139.042042 L 187.5 139.042042 L 187.5 139.946647 L 188.337054 139.946647 L 188.337054 139.042042 L 189.592634 139.042042 L 189.592634 139.946647 L 190.513393 139.946647 L 190.513393 139.042042 L 191.685268 139.042042 L 191.685268 141.015726 C 191.685268 141.344674 191.434152 141.591384 191.183036 141.591384 L 186.830357 141.591384 C 186.579241 141.591384 186.328125 141.344674 186.328125 141.015726 Z M 186.328125 139.042042 "
        transform="matrix(0.0466667,0,0,0.0475,0,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.266 139.042042 L 187.52158 139.042042 L 187.52158 139.946647 L 188.358634 139.946647 L 188.358634 139.042042 L 189.614214 139.042042 L 189.614214 139.946647 L 190.451268 139.946647 L 190.451268 139.042042 L 191.706848 139.042042 L 191.706848 141.015726 C 191.706848 141.344674 191.455732 141.591384 191.204616 141.591384 L 186.851937 141.591384 C 186.600821 141.591384 186.266 141.344674 186.266 141.015726 Z M 186.266 139.042042 "
        transform="matrix(0.0466667,0,0,0.0475,0.495087,2.200191)"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 9.046875 8.878906 C 9.046875 8.847656 9.089844 8.847656 9.089844 8.878906 L 9.089844 8.953125 L 9.046875 8.953125 L 9.046875 8.878906 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 8.800781 8.984375 C 8.800781 8.957031 8.839844 8.957031 8.839844 8.984375 L 8.839844 9.042969 L 8.800781 9.042969 L 8.800781 8.984375 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 9.296875 8.984375 C 9.296875 8.957031 9.335938 8.957031 9.335938 8.984375 L 9.335938 9.042969 L 9.296875 9.042969 L 9.296875 8.984375 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 9.71875 11.824219 C 9.539062 11.636719 9.625 11.460938 9.625 11.460938 C 9.625 11.457031 9.8125 11.363281 9.992188 11.546875 L 9.71875 11.824219 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.780545 147.681936 L 186.110268 154.009679 L 190.275329 154.00256 C 190.306201 148.781577 194.23222 147.950161 194.349555 147.951613 C 194.466889 147.953065 198.286193 149.115007 198.429762 154.044814 L 202.594823 154.037695 L 201.798107 147.633129 Z M 186.780545 147.681936 "
        transform="matrix(0.0328817,-0.0337058,0.0331145,0.0334689,-1.649268,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 185.817244 154.06472 L 202.829492 154.040599 C 203.181495 154.044956 203.418038 154.399886 203.420537 154.869253 C 203.48139 155.280675 203.13126 155.628344 202.779257 155.623988 L 185.884344 155.64956 C 185.473361 155.585806 185.178463 155.288822 185.175964 154.819454 C 185.232446 154.409484 185.465241 154.060363 185.817244 154.06472 Z M 185.817244 154.06472 "
        transform="matrix(0.0328817,-0.0337058,0.0331145,0.0334689,-1.649268,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 192.035346 154.024342 C 192.017857 150.738772 194.300569 149.769684 194.300569 149.769684 C 194.300569 149.769684 196.652256 150.737462 196.669745 154.023032 L 192.035346 154.024342 "
        transform="matrix(0.0328817,-0.0337058,0.0331145,0.0334689,-1.649268,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.238486 145.035211 L 202.488372 145.060322 C 202.840376 145.064679 203.076919 145.419609 203.078792 145.771634 C 203.080666 146.123659 202.788892 146.413383 202.495868 146.468424 L 186.245982 146.443312 C 185.893978 146.438956 185.59908 146.141971 185.597207 145.789945 C 185.594708 145.320578 185.886483 145.030854 186.238486 145.035211 Z M 186.238486 145.035211 "
        transform="matrix(0.0328817,-0.0337058,0.0331145,0.0334689,-1.649268,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.53963 146.505613 L 202.085509 146.522012 C 202.378533 146.466972 202.673431 146.763957 202.734284 147.175379 C 202.677803 147.585349 202.386029 147.875073 202.093005 147.930114 L 186.547125 147.913715 C 186.253477 147.851414 185.958579 147.554429 185.956705 147.202403 C 185.954207 146.733036 186.245982 146.443312 186.53963 146.505613 Z M 186.53963 146.505613 "
        transform="matrix(0.0328817,-0.0337058,0.0331145,0.0334689,-1.649268,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 191.528172 135.889954 L 192.818851 135.905928 L 192.823223 136.72732 L 193.703544 136.796882 L 193.698547 135.858148 L 194.989226 135.874122 L 194.935243 136.753459 L 195.873919 136.765076 L 195.810567 135.884287 L 197.101246 135.900261 L 197.111865 137.895071 C 197.054759 138.187699 196.821339 138.419479 196.586671 138.416574 L 192.127961 138.361392 C 191.834937 138.416433 191.599019 138.178845 191.53879 137.884764 Z M 191.528172 135.889954 "
        transform="matrix(0.0328817,-0.0337058,0.0331145,0.0334689,-1.649268,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 196.176937 138.587504 L 196.44596 145.044207 L 192.163564 145.049874 L 192.422234 138.541035 L 196.176937 138.587504 "
        transform="matrix(0.0328817,-0.0337058,0.0331145,0.0334689,-1.649268,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.913126 141.573044 L 191.107554 145.036805 L 186.942493 145.043924 L 187.041089 141.525122 Z M 190.913126 141.573044 "
        transform="matrix(0.0328817,-0.0337058,0.0331145,0.0334689,-1.649268,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.921958 141.528452 L 191.058031 145.050157 L 186.951325 144.999332 L 187.050545 141.597872 Z M 190.921958 141.528452 "
        transform="matrix(0.0328817,-0.0337058,0.0331145,0.0334689,-1.300426,12.710092)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.323965 139.052232 L 187.49731 139.066753 L 187.501682 139.888146 L 188.382003 139.957708 L 188.377006 139.018974 L 189.608705 138.975551 L 189.613702 139.914285 L 190.493398 139.866505 L 190.489026 139.045113 L 191.720726 139.001689 L 191.731344 140.9965 C 191.733218 141.348525 191.440819 141.520907 191.147795 141.575948 L 186.865399 141.581615 C 186.571751 141.519314 186.277478 141.339671 186.334584 141.047042 Z M 186.323965 139.052232 "
        transform="matrix(0.0328817,-0.0337058,0.0331145,0.0334689,-1.649268,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.274442 139.065585 L 187.506142 139.022161 L 187.511139 139.960896 L 188.390835 139.913116 L 188.385838 138.974382 L 189.618162 139.0483 L 189.564179 139.927638 L 190.502855 139.939255 L 190.497858 139.000521 L 191.671203 139.015042 L 191.681821 141.009853 C 191.683695 141.361878 191.450275 141.593657 191.156627 141.531356 L 186.874231 141.537023 C 186.581208 141.592063 186.28631 141.295078 186.285061 141.060395 Z M 186.274442 139.065585 "
        transform="matrix(0.0328817,-0.0337058,0.0331145,0.0334689,-1.300426,12.710092)"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 9.382812 11.238281 C 9.359375 11.21875 9.390625 11.1875 9.410156 11.210938 L 9.460938 11.261719 L 9.433594 11.289062 L 9.382812 11.238281 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 9.28125 11.492188 C 9.261719 11.472656 9.289062 11.445312 9.308594 11.464844 L 9.347656 11.503906 L 9.320312 11.53125 L 9.28125 11.492188 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 9.632812 11.132812 C 9.609375 11.113281 9.636719 11.085938 9.660156 11.105469 L 9.699219 11.144531 L 9.671875 11.171875 L 9.632812 11.132812 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 11.007812 7.335938 C 11.015625 7.074219 11.199219 7.011719 11.199219 7.011719 C 11.199219 7.007812 11.398438 7.078125 11.394531 7.335938 L 11.007812 7.335938 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.819682 147.697368 L 186.150039 154.029605 L 190.251602 154.029605 C 190.335307 148.766447 194.269459 147.944079 194.353164 147.944079 C 194.43687 147.944079 198.287316 149.095395 198.454727 154.029605 L 202.556289 154.029605 L 201.802941 147.615132 Z M 186.819682 147.697368 "
        transform="matrix(0.0466667,0,0,0.0475,2.133311,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 185.815218 154.029605 L 202.807405 154.029605 C 203.142227 154.029605 203.477048 154.440789 203.477048 154.851974 C 203.477048 155.263158 203.142227 155.592105 202.807405 155.592105 L 185.815218 155.592105 C 185.480396 155.592105 185.22928 155.263158 185.22928 154.851974 C 185.22928 154.440789 185.480396 154.029605 185.815218 154.029605 Z M 185.815218 154.029605 "
        transform="matrix(0.0466667,0,0,0.0475,2.133311,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 192.009414 154.029605 C 192.009414 150.740132 194.269459 149.753289 194.269459 149.753289 C 194.269459 149.753289 196.613209 150.740132 196.613209 154.029605 L 192.009414 154.029605 "
        transform="matrix(0.0466667,0,0,0.0475,2.133311,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.233745 145.065789 L 202.472584 145.065789 C 202.807405 145.065789 203.058521 145.394737 203.058521 145.723684 C 203.058521 146.134868 202.807405 146.463816 202.472584 146.463816 L 186.233745 146.463816 C 185.898923 146.463816 185.564102 146.134868 185.564102 145.723684 C 185.564102 145.394737 185.898923 145.065789 186.233745 145.065789 Z M 186.233745 145.065789 "
        transform="matrix(0.0466667,0,0,0.0475,2.133311,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.568566 146.463816 L 202.054057 146.463816 C 202.388879 146.463816 202.7237 146.792763 202.7237 147.203947 C 202.7237 147.532895 202.388879 147.861842 202.054057 147.861842 L 186.568566 147.861842 C 186.233745 147.861842 185.982629 147.532895 185.982629 147.203947 C 185.982629 146.792763 186.233745 146.463816 186.568566 146.463816 Z M 186.568566 146.463816 "
        transform="matrix(0.0466667,0,0,0.0475,2.133311,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 191.590887 135.855263 L 192.762762 135.855263 L 192.762762 136.759868 L 193.683521 136.759868 L 193.683521 135.855263 L 194.939102 135.855263 L 194.939102 136.759868 L 195.859861 136.759868 L 195.859861 135.855263 L 197.115441 135.855263 L 197.115441 137.911184 C 197.115441 138.157895 196.864325 138.404605 196.529504 138.404605 L 192.176825 138.404605 C 191.842004 138.404605 191.590887 138.157895 191.590887 137.828947 Z M 191.590887 135.855263 "
        transform="matrix(0.0466667,0,0,0.0475,2.133311,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 196.194682 138.569079 L 196.445798 144.983553 L 192.176825 144.983553 L 192.427941 138.569079 L 196.194682 138.569079 "
        transform="matrix(0.0466667,0,0,0.0475,2.133311,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.921245 141.529605 L 191.088655 145.065789 L 186.987093 145.065789 L 187.070798 141.529605 Z M 190.921245 141.529605 "
        transform="matrix(0.0466667,0,0,0.0475,2.133311,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.942825 141.529605 L 191.110236 145.065789 L 186.924968 145.065789 L 187.092379 141.529605 Z M 190.942825 141.529605 "
        transform="matrix(0.0466667,0,0,0.0475,2.628397,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.31745 139.0625 L 187.489325 139.0625 L 187.489325 139.884868 L 188.410084 139.884868 L 188.410084 139.0625 L 189.581959 139.0625 L 189.581959 139.884868 L 190.502718 139.884868 L 190.502718 139.0625 L 191.674593 139.0625 L 191.674593 141.036184 C 191.674593 141.365132 191.423477 141.529605 191.172361 141.529605 L 186.819682 141.529605 C 186.568566 141.529605 186.31745 141.282895 186.31745 141.036184 Z M 186.31745 139.0625 "
        transform="matrix(0.0466667,0,0,0.0475,2.133311,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.33903 139.0625 L 187.510905 139.0625 L 187.510905 139.884868 L 188.347959 139.884868 L 188.347959 139.0625 L 189.603539 139.0625 L 189.603539 139.884868 L 190.524298 139.884868 L 190.524298 139.0625 L 191.696173 139.0625 L 191.696173 141.036184 C 191.696173 141.365132 191.445057 141.529605 191.193941 141.529605 L 186.841262 141.529605 C 186.590146 141.529605 186.33903 141.282895 186.33903 141.036184 Z M 186.33903 139.0625 "
        transform="matrix(0.0466667,0,0,0.0475,2.628397,0)"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 11.183594 6.679688 C 11.179688 6.648438 11.222656 6.648438 11.222656 6.679688 L 11.222656 6.753906 L 11.183594 6.753906 L 11.183594 6.679688 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 10.933594 6.785156 C 10.933594 6.757812 10.972656 6.753906 10.972656 6.785156 L 10.972656 6.839844 L 10.933594 6.839844 L 10.933594 6.785156 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 11.429688 6.785156 C 11.429688 6.757812 11.46875 6.753906 11.46875 6.785156 L 11.46875 6.839844 L 11.429688 6.839844 L 11.429688 6.785156 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 13.515625 7.335938 C 13.507812 7.074219 13.324219 7.011719 13.324219 7.011719 C 13.324219 7.007812 13.125 7.078125 13.128906 7.335938 L 13.515625 7.335938 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.8232 147.697368 L 186.153557 154.029605 L 190.25512 154.029605 C 190.338825 148.766447 194.272977 147.944079 194.356682 147.944079 C 194.440387 147.944079 198.290834 149.095395 198.458245 154.029605 L 202.559807 154.029605 L 201.806459 147.615132 Z M 186.8232 147.697368 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 185.818736 154.029605 L 202.810923 154.029605 C 203.145745 154.029605 203.480566 154.440789 203.480566 154.851974 C 203.480566 155.263158 203.145745 155.592105 202.810923 155.592105 L 185.818736 155.592105 C 185.483914 155.592105 185.232798 155.263158 185.232798 154.851974 C 185.232798 154.440789 185.483914 154.029605 185.818736 154.029605 Z M 185.818736 154.029605 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 192.012932 154.029605 C 192.012932 150.740132 194.272977 149.753289 194.272977 149.753289 C 194.272977 149.753289 196.616727 150.740132 196.616727 154.029605 L 192.012932 154.029605 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.237262 145.065789 L 202.476102 145.065789 C 202.810923 145.065789 203.062039 145.394737 203.062039 145.723684 C 203.062039 146.134868 202.810923 146.463816 202.476102 146.463816 L 186.237262 146.463816 C 185.902441 146.463816 185.56762 146.134868 185.56762 145.723684 C 185.56762 145.394737 185.902441 145.065789 186.237262 145.065789 Z M 186.237262 145.065789 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.572084 146.463816 L 202.057575 146.463816 C 202.392396 146.463816 202.643512 146.792763 202.643512 147.203947 C 202.643512 147.532895 202.392396 147.861842 202.057575 147.861842 L 186.572084 147.861842 C 186.237262 147.861842 185.986146 147.532895 185.986146 147.203947 C 185.986146 146.792763 186.237262 146.463816 186.572084 146.463816 Z M 186.572084 146.463816 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 191.594405 135.855263 L 192.76628 135.855263 L 192.76628 136.759868 L 193.687039 136.759868 L 193.687039 135.855263 L 194.94262 135.855263 L 194.94262 136.759868 L 195.863379 136.759868 L 195.863379 135.855263 L 197.118959 135.855263 L 197.118959 137.911184 C 197.118959 138.157895 196.867843 138.404605 196.533021 138.404605 L 192.180343 138.404605 C 191.845521 138.404605 191.594405 138.157895 191.594405 137.828947 Z M 191.594405 135.855263 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 196.1982 138.569079 L 196.449316 144.983553 L 192.180343 144.983553 L 192.431459 138.569079 L 196.1982 138.569079 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.924762 141.529605 L 191.092173 145.065789 L 186.906905 145.065789 L 187.074316 141.529605 Z M 190.924762 141.529605 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.946343 141.529605 L 191.030048 145.065789 L 186.928486 145.065789 L 187.095896 141.529605 Z M 190.946343 141.529605 "
        transform="matrix(-0.0466667,0,0,0.0475,21.895204,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.320968 139.0625 L 187.492843 139.0625 L 187.492843 139.884868 L 188.413602 139.884868 L 188.413602 139.0625 L 189.585477 139.0625 L 189.585477 139.884868 L 190.506236 139.884868 L 190.506236 139.0625 L 191.678111 139.0625 L 191.678111 141.036184 C 191.678111 141.365132 191.426995 141.529605 191.175879 141.529605 L 186.8232 141.529605 C 186.572084 141.529605 186.320968 141.282895 186.320968 141.036184 Z M 186.320968 139.0625 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.258843 139.0625 L 187.514423 139.0625 L 187.514423 139.884868 L 188.351477 139.884868 L 188.351477 139.0625 L 189.607057 139.0625 L 189.607057 139.884868 L 190.444111 139.884868 L 190.444111 139.0625 L 191.699691 139.0625 L 191.699691 141.036184 C 191.699691 141.365132 191.448575 141.529605 191.197459 141.529605 L 186.84478 141.529605 C 186.593664 141.529605 186.342548 141.282895 186.342548 141.036184 Z M 186.258843 139.0625 "
        transform="matrix(-0.0466667,0,0,0.0475,21.895204,0)"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 13.339844 6.679688 C 13.34375 6.648438 13.300781 6.648438 13.300781 6.679688 L 13.300781 6.753906 L 13.339844 6.753906 L 13.339844 6.679688 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 13.589844 6.785156 C 13.589844 6.757812 13.550781 6.753906 13.550781 6.785156 L 13.550781 6.839844 L 13.589844 6.839844 L 13.589844 6.785156 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 13.09375 6.785156 C 13.09375 6.757812 13.054688 6.753906 13.054688 6.785156 L 13.054688 6.839844 L 13.09375 6.839844 L 13.09375 6.785156 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 13.515625 9.535156 C 13.507812 9.273438 13.324219 9.210938 13.324219 9.210938 C 13.324219 9.210938 13.125 9.277344 13.128906 9.539062 L 13.515625 9.535156 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.8232 147.676911 L 186.153557 154.009147 L 190.25512 154.009147 C 190.338825 148.828226 194.272977 147.923621 194.356682 147.923621 C 194.440387 147.923621 198.290834 149.074937 198.458245 154.009147 L 202.559807 154.009147 L 201.806459 147.676911 Z M 186.8232 147.676911 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 185.818736 154.091384 L 202.810923 154.091384 C 203.145745 154.091384 203.480566 154.420332 203.480566 154.831516 C 203.480566 155.2427 203.145745 155.653884 202.810923 155.653884 L 185.818736 155.653884 C 185.483914 155.653884 185.232798 155.2427 185.232798 154.831516 C 185.232798 154.420332 185.483914 154.091384 185.818736 154.091384 Z M 185.818736 154.091384 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 192.012932 154.009147 C 192.012932 150.719674 194.272977 149.815068 194.272977 149.815068 C 194.272977 149.815068 196.616727 150.719674 196.616727 154.009147 L 192.012932 154.009147 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.237262 145.045332 L 202.476102 145.045332 C 202.810923 145.045332 203.062039 145.374279 203.062039 145.785463 C 203.062039 146.114411 202.810923 146.443358 202.476102 146.443358 L 186.237262 146.443358 C 185.902441 146.443358 185.56762 146.114411 185.56762 145.785463 C 185.56762 145.374279 185.902441 145.045332 186.237262 145.045332 Z M 186.237262 145.045332 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.572084 146.443358 L 202.057575 146.443358 C 202.392396 146.443358 202.643512 146.772305 202.643512 147.183489 C 202.643512 147.594674 202.392396 147.841384 202.057575 147.841384 L 186.572084 147.841384 C 186.237262 147.841384 185.986146 147.594674 185.986146 147.183489 C 185.986146 146.772305 186.237262 146.443358 186.572084 146.443358 Z M 186.572084 146.443358 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 191.594405 135.917042 L 192.76628 135.917042 L 192.76628 136.739411 L 193.687039 136.739411 L 193.687039 135.834805 L 194.94262 135.834805 L 194.94262 136.739411 L 195.863379 136.739411 L 195.863379 135.834805 L 197.118959 135.834805 L 197.118959 137.890726 C 197.118959 138.219674 196.867843 138.384147 196.533021 138.384147 L 192.180343 138.384147 C 191.845521 138.384147 191.594405 138.137437 191.594405 137.890726 Z M 191.594405 135.917042 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 196.1982 138.548621 L 196.449316 145.045332 L 192.180343 145.045332 L 192.431459 138.548621 L 196.1982 138.548621 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.924762 141.591384 L 191.092173 145.045332 L 186.906905 145.045332 L 187.074316 141.591384 Z M 190.924762 141.591384 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.946343 141.591384 L 191.030048 145.045332 L 186.928486 145.045332 L 187.095896 141.591384 Z M 190.946343 141.591384 "
        transform="matrix(-0.0466667,0,0,0.0475,21.895204,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.320968 139.042042 L 187.492843 139.042042 L 187.492843 139.946647 L 188.413602 139.946647 L 188.413602 139.042042 L 189.585477 139.042042 L 189.585477 139.946647 L 190.506236 139.946647 L 190.506236 139.042042 L 191.678111 139.042042 L 191.678111 141.015726 C 191.678111 141.344674 191.426995 141.591384 191.175879 141.591384 L 186.8232 141.591384 C 186.572084 141.591384 186.320968 141.344674 186.320968 141.015726 Z M 186.320968 139.042042 "
        transform="matrix(-0.0466667,0,0,0.0475,22.390291,2.200191)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.258843 139.042042 L 187.514423 139.042042 L 187.514423 139.946647 L 188.351477 139.946647 L 188.351477 139.042042 L 189.607057 139.042042 L 189.607057 139.946647 L 190.444111 139.946647 L 190.444111 139.042042 L 191.699691 139.042042 L 191.699691 141.015726 C 191.699691 141.344674 191.448575 141.591384 191.197459 141.591384 L 186.84478 141.591384 C 186.593664 141.591384 186.342548 141.344674 186.342548 141.015726 Z M 186.258843 139.042042 "
        transform="matrix(-0.0466667,0,0,0.0475,21.895204,2.200191)"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 13.339844 8.878906 C 13.34375 8.847656 13.300781 8.847656 13.300781 8.878906 L 13.300781 8.953125 L 13.339844 8.953125 L 13.339844 8.878906 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 13.589844 8.984375 C 13.589844 8.957031 13.550781 8.957031 13.550781 8.984375 L 13.550781 9.042969 L 13.589844 9.042969 L 13.589844 8.984375 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 13.09375 8.984375 C 13.09375 8.957031 13.054688 8.957031 13.054688 8.984375 L 13.054688 9.042969 L 13.09375 9.042969 L 13.09375 8.984375 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 12.671875 11.824219 C 12.851562 11.636719 12.765625 11.460938 12.765625 11.460938 C 12.765625 11.457031 12.578125 11.363281 12.398438 11.546875 L 12.671875 11.824219 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.775502 147.676857 L 186.105225 154.0046 L 190.270286 153.997481 C 190.301158 148.776498 194.227178 147.945082 194.344512 147.946534 C 194.461846 147.947986 198.28115 149.109928 198.424719 154.039736 L 202.58978 154.032616 L 201.852044 147.687447 Z M 186.775502 147.676857 "
        transform="matrix(-0.0328817,-0.0337058,-0.0331145,0.0334689,24.039559,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 185.812201 154.059641 L 202.824449 154.035521 C 203.176452 154.039877 203.412995 154.394807 203.415494 154.864174 C 203.476347 155.275596 203.126217 155.623265 202.774214 155.618909 L 185.879301 155.644481 C 185.527297 155.640125 185.17342 155.283743 185.229901 154.873773 C 185.227403 154.404406 185.460198 154.055284 185.812201 154.059641 Z M 185.812201 154.059641 "
        transform="matrix(-0.0328817,-0.0337058,-0.0331145,0.0334689,24.039559,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 192.030303 154.019264 C 192.012814 150.733694 194.295526 149.764606 194.295526 149.764606 C 194.295526 149.764606 196.647213 150.732383 196.664702 154.017953 L 192.030303 154.019264 "
        transform="matrix(-0.0328817,-0.0337058,-0.0331145,0.0334689,24.039559,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.233443 145.030132 L 202.483329 145.055244 C 202.835333 145.0596 203.071876 145.41453 203.073749 145.766555 C 203.075623 146.118581 202.842828 146.467702 202.490825 146.463345 L 186.240939 146.438233 C 185.888935 146.433877 185.594037 146.136892 185.592164 145.784867 C 185.589665 145.3155 185.88144 145.025776 186.233443 145.030132 Z M 186.233443 145.030132 "
        transform="matrix(-0.0328817,-0.0337058,-0.0331145,0.0334689,24.039559,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.534587 146.500535 L 202.080466 146.516934 C 202.37349 146.461893 202.668388 146.758878 202.729241 147.1703 C 202.67276 147.58027 202.380986 147.869994 202.087962 147.925035 L 186.542082 147.908636 C 186.248434 147.846335 185.953536 147.54935 185.951662 147.197325 C 186.008144 146.787355 186.240939 146.438233 186.534587 146.500535 Z M 186.534587 146.500535 "
        transform="matrix(-0.0328817,-0.0337058,-0.0331145,0.0334689,24.039559,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 191.523129 135.884875 L 192.813808 135.900849 L 192.81818 136.722241 L 193.698501 136.791804 L 193.693504 135.853069 L 194.984183 135.869043 L 194.9302 136.74838 L 195.868876 136.759998 L 195.805524 135.879208 L 197.096203 135.895182 L 197.106822 137.889992 C 197.108696 138.242018 196.816296 138.4144 196.581628 138.411496 L 192.122918 138.356313 C 191.829894 138.411354 191.593976 138.173766 191.533747 137.879686 Z M 191.523129 135.884875 "
        transform="matrix(-0.0328817,-0.0337058,-0.0331145,0.0334689,24.039559,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 196.171894 138.582426 L 196.440917 145.039128 L 192.158521 145.044795 L 192.417191 138.535957 L 196.171894 138.582426 "
        transform="matrix(-0.0328817,-0.0337058,-0.0331145,0.0334689,24.039559,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.908083 141.567965 L 191.102511 145.031726 L 186.93745 145.038845 L 187.095025 141.579441 Z M 190.908083 141.567965 "
        transform="matrix(-0.0328817,-0.0337058,-0.0331145,0.0334689,24.039559,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 190.975895 141.58277 L 191.052988 145.045079 L 186.946282 144.994253 L 187.045502 141.592793 Z M 190.975895 141.58277 "
        transform="matrix(-0.0328817,-0.0337058,-0.0331145,0.0334689,23.690717,12.710092)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.318922 139.047153 L 187.492267 139.061675 L 187.496639 139.883067 L 188.37696 139.952629 L 188.371963 139.013895 L 189.603662 138.970472 L 189.608659 139.909206 L 190.488355 139.861426 L 190.483983 139.040034 L 191.715683 138.996611 L 191.726301 140.991421 C 191.728175 141.343446 191.494755 141.575226 191.142752 141.570869 L 186.860356 141.576536 C 186.566708 141.514235 186.272435 141.334592 186.329541 141.041963 Z M 186.318922 139.047153 "
        transform="matrix(-0.0328817,-0.0337058,-0.0331145,0.0334689,24.039559,13.067677)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        strokeWidth="0.5"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(0%,0%,0%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 186.269399 139.060506 L 187.501099 139.017083 L 187.506096 139.955817 L 188.385792 139.908037 L 188.380795 138.969303 L 189.613119 139.043222 L 189.559136 139.922559 L 190.497812 139.934176 L 190.492815 138.995442 L 191.725139 139.06936 L 191.735758 141.064171 C 191.678652 141.356799 191.445232 141.588578 191.151584 141.526277 L 186.869188 141.531944 C 186.576165 141.586985 186.281267 141.29 186.280018 141.055316 Z M 186.269399 139.060506 "
        transform="matrix(-0.0328817,-0.0337058,-0.0331145,0.0334689,23.690717,12.710092)"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 13.007812 11.238281 C 13.027344 11.21875 13 11.1875 12.980469 11.210938 L 12.929688 11.261719 L 12.957031 11.289062 L 13.007812 11.238281 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 13.109375 11.492188 C 13.128906 11.472656 13.101562 11.445312 13.082031 11.464844 L 13.042969 11.503906 L 13.070312 11.53125 L 13.109375 11.492188 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 12.757812 11.132812 C 12.777344 11.113281 12.753906 11.085938 12.730469 11.105469 L 12.691406 11.144531 L 12.71875 11.171875 L 12.757812 11.132812 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,20%,60%)"
        fillOpacity="1"
        d="M 10.855469 9.613281 C 10.855469 9.71875 10.894531 9.816406 10.960938 9.886719 C 11.023438 9.953125 11.109375 9.996094 11.207031 9.996094 C 11.304688 9.996094 11.390625 9.953125 11.453125 9.886719 C 11.519531 9.816406 11.558594 9.71875 11.558594 9.613281 L 11.558594 9.101562 L 10.859375 9.101562 L 10.855469 9.613281 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.085938 9.296875 C 11.085938 9.335938 11.054688 9.367188 11.015625 9.367188 C 10.976562 9.367188 10.949219 9.335938 10.949219 9.296875 C 10.949219 9.257812 10.976562 9.226562 11.015625 9.226562 C 11.054688 9.226562 11.085938 9.257812 11.085938 9.296875 Z M 11.085938 9.296875 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.472656 9.296875 C 11.472656 9.335938 11.441406 9.367188 11.40625 9.367188 C 11.367188 9.367188 11.335938 9.335938 11.335938 9.296875 C 11.335938 9.257812 11.367188 9.226562 11.40625 9.226562 C 11.441406 9.226562 11.472656 9.257812 11.472656 9.296875 Z M 11.472656 9.296875 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.28125 9.488281 C 11.28125 9.527344 11.25 9.558594 11.210938 9.558594 C 11.171875 9.558594 11.140625 9.527344 11.140625 9.488281 C 11.140625 9.449219 11.171875 9.417969 11.210938 9.417969 C 11.25 9.417969 11.28125 9.449219 11.28125 9.488281 Z M 11.28125 9.488281 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.085938 9.6875 C 11.085938 9.726562 11.054688 9.757812 11.015625 9.757812 C 10.976562 9.757812 10.949219 9.726562 10.949219 9.6875 C 10.949219 9.648438 10.976562 9.617188 11.015625 9.617188 C 11.054688 9.617188 11.085938 9.648438 11.085938 9.6875 Z M 11.085938 9.6875 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.472656 9.683594 C 11.472656 9.722656 11.441406 9.757812 11.40625 9.757812 C 11.367188 9.757812 11.335938 9.722656 11.335938 9.683594 C 11.335938 9.648438 11.367188 9.613281 11.40625 9.613281 C 11.441406 9.613281 11.472656 9.648438 11.472656 9.683594 Z M 11.472656 9.683594 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,20%,60%)"
        fillOpacity="1"
        d="M 10.855469 8.378906 C 10.855469 8.484375 10.894531 8.578125 10.960938 8.648438 C 11.023438 8.71875 11.109375 8.761719 11.207031 8.761719 C 11.304688 8.761719 11.390625 8.71875 11.453125 8.648438 C 11.519531 8.582031 11.558594 8.484375 11.558594 8.378906 L 11.558594 7.867188 L 10.859375 7.867188 L 10.855469 8.378906 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.085938 8.0625 C 11.085938 8.101562 11.054688 8.132812 11.015625 8.132812 C 10.976562 8.132812 10.949219 8.101562 10.949219 8.0625 C 10.949219 8.023438 10.976562 7.992188 11.015625 7.992188 C 11.054688 7.992188 11.085938 8.023438 11.085938 8.0625 Z M 11.085938 8.0625 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.472656 8.0625 C 11.472656 8.101562 11.441406 8.132812 11.40625 8.132812 C 11.367188 8.132812 11.335938 8.101562 11.335938 8.0625 C 11.335938 8.023438 11.367188 7.992188 11.40625 7.992188 C 11.441406 7.992188 11.472656 8.023438 11.472656 8.0625 Z M 11.472656 8.0625 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.28125 8.25 C 11.28125 8.289062 11.25 8.320312 11.210938 8.320312 C 11.171875 8.320312 11.140625 8.289062 11.140625 8.25 C 11.140625 8.210938 11.171875 8.179688 11.210938 8.179688 C 11.25 8.179688 11.28125 8.210938 11.28125 8.25 Z M 11.28125 8.25 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.085938 8.449219 C 11.085938 8.488281 11.054688 8.519531 11.015625 8.519531 C 10.976562 8.519531 10.949219 8.488281 10.949219 8.449219 C 10.949219 8.410156 10.976562 8.378906 11.015625 8.378906 C 11.054688 8.378906 11.085938 8.410156 11.085938 8.449219 Z M 11.085938 8.449219 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.472656 8.449219 C 11.472656 8.488281 11.441406 8.519531 11.40625 8.519531 C 11.367188 8.519531 11.335938 8.488281 11.335938 8.449219 C 11.335938 8.410156 11.367188 8.378906 11.40625 8.378906 C 11.441406 8.378906 11.472656 8.410156 11.472656 8.449219 Z M 11.472656 8.449219 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,20%,60%)"
        fillOpacity="1"
        d="M 9.886719 9.613281 C 9.886719 9.71875 9.925781 9.816406 9.988281 9.886719 C 10.050781 9.953125 10.140625 9.996094 10.238281 9.996094 C 10.332031 9.996094 10.421875 9.953125 10.484375 9.886719 C 10.546875 9.816406 10.585938 9.71875 10.585938 9.613281 L 10.585938 9.101562 L 9.886719 9.101562 L 9.886719 9.613281 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 10.117188 9.296875 C 10.117188 9.335938 10.085938 9.367188 10.046875 9.367188 C 10.007812 9.367188 9.976562 9.335938 9.976562 9.296875 C 9.976562 9.257812 10.007812 9.226562 10.046875 9.226562 C 10.085938 9.226562 10.117188 9.257812 10.117188 9.296875 Z M 10.117188 9.296875 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 10.503906 9.296875 C 10.503906 9.335938 10.472656 9.367188 10.433594 9.367188 C 10.394531 9.367188 10.363281 9.335938 10.363281 9.296875 C 10.363281 9.257812 10.394531 9.226562 10.433594 9.226562 C 10.472656 9.226562 10.503906 9.257812 10.503906 9.296875 Z M 10.503906 9.296875 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 10.308594 9.488281 C 10.308594 9.527344 10.277344 9.558594 10.238281 9.558594 C 10.203125 9.558594 10.171875 9.527344 10.171875 9.488281 C 10.171875 9.449219 10.203125 9.417969 10.238281 9.417969 C 10.277344 9.417969 10.308594 9.449219 10.308594 9.488281 Z M 10.308594 9.488281 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 10.117188 9.6875 C 10.117188 9.726562 10.085938 9.757812 10.046875 9.757812 C 10.007812 9.757812 9.976562 9.726562 9.976562 9.6875 C 9.976562 9.648438 10.007812 9.617188 10.046875 9.617188 C 10.085938 9.617188 10.117188 9.648438 10.117188 9.6875 Z M 10.117188 9.6875 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 10.503906 9.683594 C 10.503906 9.722656 10.472656 9.757812 10.433594 9.757812 C 10.394531 9.757812 10.363281 9.722656 10.363281 9.683594 C 10.363281 9.648438 10.394531 9.613281 10.433594 9.613281 C 10.472656 9.613281 10.503906 9.648438 10.503906 9.683594 Z M 10.503906 9.683594 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,20%,60%)"
        fillOpacity="1"
        d="M 11.824219 9.613281 C 11.824219 9.71875 11.863281 9.816406 11.925781 9.886719 C 11.992188 9.953125 12.078125 9.996094 12.175781 9.996094 C 12.273438 9.996094 12.359375 9.953125 12.421875 9.886719 C 12.484375 9.816406 12.523438 9.71875 12.523438 9.613281 L 12.523438 9.101562 L 11.824219 9.101562 L 11.824219 9.613281 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 12.054688 9.296875 C 12.054688 9.335938 12.023438 9.367188 11.984375 9.367188 C 11.945312 9.367188 11.914062 9.335938 11.914062 9.296875 C 11.914062 9.257812 11.945312 9.226562 11.984375 9.226562 C 12.023438 9.226562 12.054688 9.257812 12.054688 9.296875 Z M 12.054688 9.296875 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 12.441406 9.296875 C 12.441406 9.335938 12.410156 9.367188 12.375 9.367188 C 12.335938 9.367188 12.304688 9.335938 12.304688 9.296875 C 12.304688 9.257812 12.335938 9.226562 12.375 9.226562 C 12.410156 9.226562 12.441406 9.257812 12.441406 9.296875 Z M 12.441406 9.296875 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 12.246094 9.488281 C 12.246094 9.527344 12.21875 9.558594 12.179688 9.558594 C 12.140625 9.558594 12.109375 9.527344 12.109375 9.488281 C 12.109375 9.449219 12.140625 9.417969 12.179688 9.417969 C 12.21875 9.417969 12.246094 9.449219 12.246094 9.488281 Z M 12.246094 9.488281 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 12.054688 9.6875 C 12.054688 9.726562 12.023438 9.757812 11.984375 9.757812 C 11.945312 9.757812 11.914062 9.726562 11.914062 9.6875 C 11.914062 9.648438 11.945312 9.617188 11.984375 9.617188 C 12.023438 9.617188 12.054688 9.648438 12.054688 9.6875 Z M 12.054688 9.6875 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 12.441406 9.683594 C 12.441406 9.722656 12.410156 9.757812 12.371094 9.757812 C 12.335938 9.757812 12.304688 9.722656 12.304688 9.683594 C 12.304688 9.648438 12.335938 9.613281 12.371094 9.613281 C 12.410156 9.613281 12.441406 9.648438 12.441406 9.683594 Z M 12.441406 9.683594 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,20%,60%)"
        fillOpacity="1"
        d="M 10.855469 10.839844 C 10.855469 10.945312 10.894531 11.039062 10.960938 11.109375 C 11.023438 11.179688 11.109375 11.222656 11.207031 11.222656 C 11.304688 11.222656 11.390625 11.179688 11.453125 11.109375 C 11.519531 11.039062 11.558594 10.945312 11.558594 10.839844 L 11.558594 10.328125 L 10.859375 10.328125 L 10.855469 10.839844 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.085938 10.523438 C 11.085938 10.5625 11.054688 10.59375 11.015625 10.59375 C 10.976562 10.59375 10.949219 10.5625 10.949219 10.523438 C 10.949219 10.484375 10.976562 10.453125 11.015625 10.453125 C 11.054688 10.453125 11.085938 10.484375 11.085938 10.523438 Z M 11.085938 10.523438 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.472656 10.523438 C 11.472656 10.5625 11.441406 10.59375 11.40625 10.59375 C 11.367188 10.59375 11.335938 10.5625 11.335938 10.523438 C 11.335938 10.484375 11.367188 10.453125 11.40625 10.453125 C 11.441406 10.453125 11.472656 10.484375 11.472656 10.523438 Z M 11.472656 10.523438 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.28125 10.710938 C 11.28125 10.75 11.25 10.78125 11.210938 10.78125 C 11.171875 10.78125 11.140625 10.75 11.140625 10.710938 C 11.140625 10.671875 11.171875 10.640625 11.210938 10.640625 C 11.25 10.640625 11.28125 10.671875 11.28125 10.710938 Z M 11.28125 10.710938 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.085938 10.910156 C 11.085938 10.949219 11.054688 10.980469 11.015625 10.980469 C 10.976562 10.980469 10.949219 10.949219 10.949219 10.910156 C 10.949219 10.871094 10.976562 10.839844 11.015625 10.839844 C 11.054688 10.839844 11.085938 10.871094 11.085938 10.910156 Z M 11.085938 10.910156 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 11.472656 10.910156 C 11.472656 10.949219 11.441406 10.980469 11.40625 10.980469 C 11.367188 10.980469 11.335938 10.949219 11.335938 10.910156 C 11.335938 10.871094 11.367188 10.839844 11.40625 10.839844 C 11.441406 10.839844 11.472656 10.871094 11.472656 10.910156 Z M 11.472656 10.910156 "
      />
    </g>
  </svg>
)

export { PortugueseFlag }
