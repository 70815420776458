import { useEffect, useState } from 'react'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import {
  Button,
  CloseButton,
  Heading,
  MobileDrawer,
  MobileDrawerClose,
  MobileDrawerContent,
  MobileDrawerOverlay,
  MobileDrawerPortal,
  MobileDrawerTrigger,
  Tag,
  Text,
} from 'ui'

import { MobileModalProps } from './types'

export const MobileModal = ({
  children,
  triggerIcon: Icon,
  title,
  isCancelDisabled = false,
  onCancel,
  onSubmit,
  onCancelText,
  onSubmitText,
  activeFilterCount,
  showModal: open = false,
  onOpenChange = () => {},
  isLoading = false,
}: MobileModalProps) => {
  const [showModal, setShowModal] = useState(open)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setShowModal(open)
  }, [open])
  useEffect(() => {
    setIsMounted(true)
  }, [])

  const onSubmitHandler = () => {
    onSubmit()
    onOpenChange(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.code === 'Enter') {
      onOpenChange(true)
    }
  }

  const conditionalTranslationKeys = {
    showResults: 'global.showResults',
    delete: 'global.delete',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    isMounted && (
      <MobileDrawer
        shouldScaleBackground
        open={showModal}
        onOpenChange={onOpenChange}
      >
        <MobileDrawerTrigger
          asChild
          onClick={() => onOpenChange(true)}
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          <div className="flex items-center gap-2 font-bold transition-all scale-100 rounded-lg hover:bg-primary-red-50 hover:text-primary-red-900 hover:scale-110 ">
            <Icon /> <Text>{title}</Text>
            {activeFilterCount && (
              <Tag className="flex items-center justify-center w-6 h-6 p-1 font-bold rounded-full bg-primary-red-900 text-neutral-white">
                {activeFilterCount}
              </Tag>
            )}
          </div>
        </MobileDrawerTrigger>
        <MobileDrawerOverlay className="fixed inset-0 z-10 bg-neutral-gray-100" />
        <MobileDrawerPortal>
          <MobileDrawerContent className="z-50 flex flex-col justify-between">
            {/*  HEADER */}
            <div className="absolute top-0 z-40 grid w-full grid-cols-3 px-6 py-3 text-neutral-white rounded-t-2xl bg-primary-red-900">
              <MobileDrawerClose
                asChild
                className="w-auto"
                onClick={() => onOpenChange(false)}
              >
                <CloseButton />
              </MobileDrawerClose>
              <Heading className="justify-self-center" as="h3">
                {title}
              </Heading>
            </div>

            {/*  CONTENT */}
            <div className="overflow-auto h-[calc(100vh-174px)] pt-12">
              {children}
            </div>

            {/* FOOTER */}
            <div className="flex flex-col p-6 space-y-6 shadow-xl bg-neutral-white">
              <Button
                type="submit"
                intent="primary"
                isLoading={isLoading}
                onClick={onSubmitHandler}
              >
                {onSubmitText || translations.showResults}
              </Button>
              <Button
                type="button"
                intent="secondary"
                onClick={onCancel}
                isDisabled={isCancelDisabled || isLoading}
                className="border-2"
              >
                {onCancelText || translations.delete}
              </Button>
            </div>
          </MobileDrawerContent>
        </MobileDrawerPortal>
      </MobileDrawer>
    )
  )
}
