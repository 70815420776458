import { cva } from 'class-variance-authority'

export const ItemStyles = {
  root: cva([
    'text-base text-secondary-black-900 flex items-center h-16 px-6 relative select-none leading-10',
    'hover:bg-neutral-gray-200',
    'radix-disabled:opacity-50',
    'data-[state=checked]:bg-primary-red-900 data-[state=checked]:text-neutral-white data-[state=checked]:font-bold',
    'focus:outline-none',
  ]),
}
