import { LocationCard } from '@/components/LocationCard'
import { DrupalNodeItinerary } from '@/components/Node/components/Itinerary/types'
import { LIST_TYPE_ITINERARY } from '@/components/Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'
import { useItineraryProvider } from '@/components/RoutesList/components/List/ItineraryProvider'
import { useMapListProvider } from '@/components/RoutesList/components/List/MapListProvider'

const Itinerary = () => {
  const {
    state: { currentRoute },
    setCurrentRoute,
  } = useMapListProvider()
  const { isSelected, addItinerary, removeItinerary } = useItineraryProvider()

  return (
    currentRoute && (
      <LocationCard
        className="absolute bottom-2 left-2"
        onCloseClick={() => {
          setCurrentRoute(null)
        }}
        title={currentRoute.title}
        description={currentRoute.body}
        location={currentRoute.text_address}
        image={currentRoute.computed_image}
        tags={currentRoute.term_services}
        link={currentRoute?.link?.uri ?? ''}
        urlGMaps={currentRoute.geofield_address?.url}
        viewMode={LIST_TYPE_ITINERARY}
        onAddedClick={() => addItinerary(currentRoute as DrupalNodeItinerary)}
        onRemoveClick={() => removeItinerary(currentRoute.id)}
        isAdded={isSelected(currentRoute.id)}
        gmapsUrl={currentRoute.gmaps_url}
      />
    )
  )
}

export { Itinerary }
