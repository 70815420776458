import { TaxonomyTerm } from '@/components/TaxonomyTerm'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Bike } from 'lucide-react'
import { DrupalTaxonomyTerm } from 'next-drupal'
import { InfoTag, InfoTagBody, InfoTagHeader } from 'ui'

const Circuit = ({ term }: { term: DrupalTaxonomyTerm }) => {
  const conditionalTranslationKeys = {
    circuit: 'global.circuit',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return term.id === 'missing' ? (
    <></>
  ) : (
    <InfoTag>
      <InfoTagHeader>
        <Bike />
        {translations.circuit}
      </InfoTagHeader>
      <InfoTagBody>
        <TaxonomyTerm term={term} />
      </InfoTagBody>
    </InfoTag>
  )
}

export { Circuit }
