import { DrupalParagraphItineraryMapProps } from './types'
import { Default } from './viewmodes/Default'

const ItineraryMap = ({
  paragraph,
  nodeTitle,
  viewMode = 'default',
  ...rest
}: DrupalParagraphItineraryMapProps) => (
  <div className={`${paragraph.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        default:
          return (
            <Default paragraph={paragraph} nodeTitle={nodeTitle} {...rest} />
          )
      }
    })()}
  </div>
)

export { ItineraryMap }
