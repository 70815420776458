import { List, ListHeader } from '@/components/List'
import { VIEW_MODE_ITINERARY } from '@/components/List/components/Header/constants'
import { ListContent } from '@/components/RoutesList/components'

import { LIST_TYPE_ITINERARY } from './constants'
import { DrupalParagraphItineraryMapDefaultProps } from './types'

const Default = ({ paragraph }: DrupalParagraphItineraryMapDefaultProps) => (
  <div className="container">
    <List
      type={LIST_TYPE_ITINERARY}
      initialState={{
        page: 1,
        results: paragraph.itineraries,
        facets: paragraph.facets,
        total_items: paragraph.itineraries?.length,
      }}
    >
      <ListHeader viewMode={VIEW_MODE_ITINERARY} />
      <ListContent />
    </List>
  </div>
)

export { Default }
