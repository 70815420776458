import {
  VIEW_MODE_DYNAMIC,
  VIEW_MODE_ITINERARY,
  VIEW_MODE_ITINERARY_BASIC,
  VIEW_MODE_ITINERARY_MOBILE,
  VIEW_MODE_MEGAMENU,
  VIEW_MODE_MOBILE,
} from './constants'
import { LocationCardProps } from './types'
import {
  Default,
  DynamicMap,
  Itinerary,
  ItineraryBasic,
  Megamenu,
  Mobile,
} from './viewmode'
import { ItineraryMobile } from './viewmode/ItineraryMobile'

const LocationCard = ({ viewMode, ...rest }: LocationCardProps) => {
  switch (viewMode) {
    case VIEW_MODE_MOBILE:
      return <Mobile {...rest} />
    case VIEW_MODE_MEGAMENU:
      return <Megamenu {...rest} />
    case VIEW_MODE_ITINERARY:
      return <Itinerary {...rest} />
    case VIEW_MODE_ITINERARY_MOBILE:
      return <ItineraryMobile {...rest} />
    case VIEW_MODE_ITINERARY_BASIC:
      return <ItineraryBasic {...rest} />
    case VIEW_MODE_DYNAMIC:
      return <DynamicMap {...rest} />
    default:
      return <Default {...rest} />
  }
}

LocationCard.displayName = 'LocationCard'

export { LocationCard }
