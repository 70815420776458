import { cn } from 'utils-tailwindcss'

import { GradientImageStyles } from './GradientImage.styles'
import { GradientImageProps } from './types'

export const GradientImage = ({
  children,
  image,
  variant,
  className,
}: GradientImageProps) => (
  <div
    className={cn(GradientImageStyles.wrapper(), className)}
    style={{
      backgroundImage: `url('${image}')`,
    }}
  >
    <div className={cn(GradientImageStyles.gradient({ variant }))}>
      {children}
    </div>
  </div>
)
