import { forwardRef, HTMLAttributes } from 'react'
import { cn } from 'utils-tailwindcss'

import { ContentStyles } from './Content.styles'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn(ContentStyles(), className)} {...props}>
      {children}
    </div>
  )
)

Content.displayName = 'Content'

export { Content }
