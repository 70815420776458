import { useMemo, useRef } from 'react'
import dynamic from 'next/dynamic'
import { DrupalNodeRoute } from '@/components/Node/components/Route/types'
import { getIcon } from '@/components/Paragraph/components/ItineraryMap/viewmodes/Default/utils/helpers'
import { LAT_LON_MADRID } from '@/content/constants'
import { useWidth } from 'utils'

import { useItineraryProvider } from '../../../List/ItineraryProvider'
import { useMapListProvider } from '../../../List/MapListProvider'
import { getPosition } from '../../helper'

const DynamicMap = dynamic(() => import('ui/src/components/maps/Map/Map'), {
  ssr: false,
})

const DynamicMapMarker = dynamic(
  () => import('ui/src/components/maps/Map/components/Marker'),
  {
    ssr: false,
  }
)

export const Itinerary = () => {
  const { isMobile } = useWidth()
  const {
    state: { currentRoute },
    listState: { results },
    setCurrentRoute,
  } = useMapListProvider()
  const {
    isSelected,
    state: { selected, showItineraries },
  } = useItineraryProvider()

  const position = useRef<[number, number]>()
  const currentPosition = useMemo(() => {
    if (!!currentRoute) {
      position.current = getPosition(
        currentRoute.geofield_address.latlon ?? LAT_LON_MADRID
      )
    }
    return position.current
  }, [currentRoute])

  return (
    <div className="relative w-full h-full">
      <DynamicMap
        scrollWheelZoom={true}
        height={isMobile() ? '100vh' : '807px'}
        width="auto"
        zoom={14}
        doubleClickZoom={false}
        className="z-0 rounded-l-none shadow-sm"
        calculateCenter
        center={
          currentPosition
            ? isMobile()
              ? [currentPosition[0] - 0.001, currentPosition[1]]
              : [currentPosition[0], currentPosition[1] - 0.003]
            : LAT_LON_MADRID
        }
      >
        {(showItineraries ? selected : results)?.map(
          (route: DrupalNodeRoute) => {
            return (
              <DynamicMapMarker
                key={route.id}
                position={
                  getPosition(route.geofield_address.latlon) || LAT_LON_MADRID
                }
                Icon={getIcon(route.type)}
                extrernalIcon={
                  !!route.term_category?.resourceIdObjMeta
                    ? route.term_category?.resourceIdObjMeta.icon_url
                    : undefined
                }
                isActive={currentRoute?.id === route.id || isSelected(route.id)}
                eventHandlers={{
                  click: () => {
                    setCurrentRoute(route)
                  },
                }}
              />
            )
          }
        )}
      </DynamicMap>
    </div>
  )
}
