import { Carousel } from '@/components/Carousel'
import { CKEditor } from '@/components/CKEditor'
import { Node } from '@/components/Node/Node'
import { Paragraph } from '@/components/Paragraph'
import { VIEW_MODE_CARD_BASIC } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'

import { DrupalNodeTouristPlan } from '../../../TouristPlan/types'
import { DrupalNodePlaceFullProps } from './types'

const Full = ({ node }: DrupalNodePlaceFullProps) => {
  const conditionalTranslationKeys = {
    village: 'node.place.village',
    findYourPlan: 'node.place.findYourPlan',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      {node.body && (
        <div className="py-12 bg-neutral-gray-100 md:py-16 ">
          <div className="container">
            <div>
              <CKEditor text={node.body} />
            </div>
          </div>
        </div>
      )}
      <div className="my-12 md:my-16">
        {node.paragraphs?.map((paragraph) => {
          return (
            <Paragraph
              key={paragraph.id}
              title={node.title}
              paragraph={paragraph}
            />
          )
        })}
      </div>
      {!!node.touristplan_by_place?.filter(
        (touristPlan) => touristPlan.status && touristPlan.id !== 'missing'
      )?.length && (
        <div className="custom-container">
          <Carousel
            title={translations.village}
            subtitle={translations.findYourPlan}
          >
            {node.touristplan_by_place
              .filter(
                (touristPlan) =>
                  touristPlan.status && touristPlan.id !== 'missing'
              )
              .map((content: DrupalNodeTouristPlan) => (
                <Node
                  key={content.id}
                  node={{ ...content, viewMode: VIEW_MODE_CARD_BASIC }}
                />
              ))}
          </Carousel>
        </div>
      )}
    </>
  )
}

export { Full }
