import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

const useEnhancedEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect

export const useWidth = () => {
  const [size, setSize] = useState([1920, 1080])

  useEnhancedEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const isMobile = useCallback(() => (size[0] < 768 ? true : false), [size])
  return { width: size[0], height: size[1], isMobile }
}
