import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cn } from 'utils-tailwindcss'

import { Label as LabelComponent } from '../../../../atoms'
import { useFormField } from '../../useFormField'
import { LabelStyles } from './Label.styles'

const Label = forwardRef<
  ElementRef<typeof LabelPrimitive.Root>,
  ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField()

  return (
    <LabelComponent
      ref={ref}
      className={cn(error && LabelStyles(), className)}
      htmlFor={formItemId}
      {...props}
    />
  )
})

Label.displayName = 'Label'

export { Label }
