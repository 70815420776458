import { ImageStyle, MOCK_FILE_DERIVATIVE } from '@/components/ImageStyle'
import { MapPinIcon } from 'lucide-react'
import { CardTitle, Card as CardUI, Text } from 'ui'

import { BREAKPOINTS } from './constants'
import { DrupalNodePlaceCardProps } from './types'

const Card = ({ node }: DrupalNodePlaceCardProps) => (
  <CardUI key={node.id} className="w-[17rem] md:w-[21.625rem]">
    <CardTitle className="flex items-center px-3 py-2 no-underline md:px-6 bg-primary-red-900 text-neutral-white">
      {/* //TODO: revisar de donde viene el tipo de itinerario a crear */}
      <Text className="text-sm font-bold">Gastronomia</Text>
    </CardTitle>
    <div className="flex flex-row justify-between px-3 py-2 md:px-6">
      <div className="flex flex-col gap-3 w-fit">
        <Text className="text-xs font-semibold md:text-md w-fit">
          {node.title}
        </Text>
        <Text className="flex gap-2 text-xs md:text-sm w-fit">
          <MapPinIcon /> {node.title}
        </Text>
      </div>
      <ImageStyle
        breakpoints={BREAKPOINTS}
        file={MOCK_FILE_DERIVATIVE}
        className="rounded w-fit"
      />
    </div>
  </CardUI>
)
export { Card }
