import { useState } from 'react'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Checkbox, FormControl, FormField, FormItem, useFormField } from 'ui'
import { cn } from 'utils-tailwindcss'

import {
  ACTIVITIES_ROUTES_FILTER,
  DIFICULTIES_ROUTES_FILTER,
  FACETS_ICONS,
} from '../../constants'
import { FilterLabel } from '../FilterLabel'
import { selectedArray } from './helpers'
import { CheckboxFilterProps } from './types'

export const CheckboxField = ({ id, label, terms }: CheckboxFilterProps) => {
  const { setValue } = useFormField()
  const [allSelected, setAllSelected] = useState(false)

  const conditionalTranslationKeys = {
    selectAll: 'filters.selectAll',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <FormField
      name={id}
      defaultValue={[]}
      render={({ field: { value, name } }) => {
        const setAll = () => {
          setAllSelected(!allSelected)
          let newArray: string[] = [...value]
          newArray = !allSelected
            ? terms.map((item) =>
                !item.props?.disabled
                  ? item.values.count !== 0
                    ? item.values.value
                    : ''
                  : ''
              )
            : []
          setValue(name, newArray)
        }

        return (
          <div className="relative left-1 w-full top-[2px]">
            <FormItem className="flex flex-col">
              {label && (
                <div className="pb-2">
                  <FilterLabel label={label}>
                    {FACETS_ICONS[id]?.()}
                  </FilterLabel>
                </div>
              )}
              <FormControl className="space-y-0">
                <div className="flex flex-col gap-3 mt-0">
                  {id !== ACTIVITIES_ROUTES_FILTER &&
                    id !== DIFICULTIES_ROUTES_FILTER && (
                      <div className="flex gap-4">
                        <Checkbox
                          id={`${id}-select-all`}
                          checked={allSelected}
                          onCheckedChange={setAll}
                        />
                        <label htmlFor={`${id}-select-all`}>
                          {translations.selectAll}
                        </label>
                      </div>
                    )}
                  {terms.map(({ values, props }) => (
                    <div className="flex items-center gap-4" key={values.value}>
                      <Checkbox
                        id={values.value}
                        value={values.value}
                        checked={value?.includes(values.value)}
                        disabled={values.count === 0}
                        className="aria-checked:font-semibold"
                        onCheckedChange={() =>
                          setValue(name, selectedArray(values.value, value))
                        }
                        {...props}
                      />
                      <label
                        className={cn(
                          'text-text-medium',
                          value.count === 0 && 'text-neutral-gray-300',
                          value?.includes(values.value) && 'font-semibold'
                        )}
                        htmlFor={values.value}
                      >
                        {values.label} {`(${values.count})`}
                      </label>
                    </div>
                  ))}
                </div>
              </FormControl>
            </FormItem>
          </div>
        )
      }}
    />
  )
}
