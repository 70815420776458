import { Carousel } from '@/components/Carousel'
import { CKEditor } from '@/components/CKEditor'
import { Node } from '@/components/Node/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { Paragraph } from '@/components/Paragraph'
import { VIEW_MODE_BASIC_CAROUSEL_3COL } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'

import { DrupalNodeTouristPlanFullProps } from './types'

const Full = ({ node }: DrupalNodeTouristPlanFullProps) => {
  const conditionalTranslationKeys = {
    bestSurroundings: 'node.event.full.theBestInTheSurroundings',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      {/* @ts-ignore - body, image */}
      <div className="w-full py-12 bg-neutral-gray-100">
        <div className="container mt-12 bg-neutral-gray-100 md:mt-0">
          <div className="col-span-2">
            <CKEditor text={node.body} />
          </div>
        </div>
      </div>
      <div>
        <div className="my-12 md:my-16">
          {node.paragraphs?.map((paragraph) => (
            <Paragraph key={paragraph.id} paragraph={paragraph} />
          ))}
        </div>
        {!!node.surrounding_areas?.length && (
          <div className="py-12">
            <div className="custom-container">
              <Carousel title={translations.bestSurroundings}>
                {node.surrounding_areas.map((content: BundleDrupalNode) => (
                  <Node
                    key={content.id}
                    node={{
                      ...content,
                      viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL,
                    }}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export { Full }
