import dynamic from 'next/dynamic'

import { DrupalMediaRemoteVideo } from '../../types'

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

const Default = ({ media }: DrupalMediaRemoteVideo) => (
  <ReactPlayer
    url={media.media_oembed_video || media.video_url}
    volume={1}
    width={'auto'}
    height={'auto'}
    controls
    className="aspect-video rounded-lg overflow-hidden "
  />
)

export { Default }
