'use client'

import { createElement } from 'react'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Share2Icon } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from 'ui'

import { SOCIAL_NETWORKS } from './constants'

const ShareDropdown = ({ title }) => (
  <Popover>
    <PopoverTrigger>
      <div className="flex items-center gap-1 cursor-pointer">
        <div className="flex gap-3 text-base font-bold cursor-pointer text-neutral-white">
          <Share2Icon color="#FFF" />
          <FormattedMessage id="global.share" />
        </div>
      </div>
    </PopoverTrigger>
    <PopoverContent className="flex flex-col gap-1 p-0 py-2 font-semibold text-secondary-black-900 border-neutral-gray-300">
      <ul>
        {SOCIAL_NETWORKS.map((socialNetwork) => (
          <li className="list-none" key={socialNetwork.id}>
            {createElement(socialNetwork.Component, {
              ...socialNetwork,
              title,
            })}
          </li>
        ))}
      </ul>
    </PopoverContent>
  </Popover>
)

export { ShareDropdown }
