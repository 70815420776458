import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { Menu, Search, X } from 'lucide-react'

import { MadridLogo } from '../../../../../../apps/turismo/src/components/MadridLogo'
import { HeaderMobileFinder, HeaderMobileMenu } from './components'

const HeaderMobile = ({ ...args }) => (
  <NavigationMenu.Root className="w-screen">
    <NavigationMenu.List className="flex justify-between px-6 py-3 align-middle bg-primary-red-900">
      <NavigationMenu.Item className="flex justify-center">
        <NavigationMenu.Trigger className="block group text-neutral-white">
          <Menu className="group-data-[state=open]:hidden block " />
          <X className=" group-data-[state=open]:block hidden " />
        </NavigationMenu.Trigger>
        <NavigationMenu.Content className="bg-secondary-black-900">
          <HeaderMobileMenu />
        </NavigationMenu.Content>
      </NavigationMenu.Item>
      <MadridLogo />
      <NavigationMenu.Item className="flex justify-center text-neutral-white">
        <NavigationMenu.Trigger className="block group">
          <Search className="group-data-[state=open]:hidden block " />
          <X className=" group-data-[state=open]:block hidden " />
        </NavigationMenu.Trigger>
        <NavigationMenu.Content className="bg-secondary-black-900">
          <HeaderMobileFinder />
        </NavigationMenu.Content>
      </NavigationMenu.Item>
    </NavigationMenu.List>

    {/* NavigationMenu.Content will be rendered here when active */}
    <NavigationMenu.Viewport />
  </NavigationMenu.Root>
)

export { HeaderMobile }
