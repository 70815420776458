import {
  NEXT_PAGE_AGENDA,
  NEXT_PAGE_AGENDA_SEARCHER,
  NEXT_PAGE_CICLA_MADRID,
  NEXT_PAGE_CICLA_MADRID_SEARCHER,
  NEXT_PAGE_CONTACT,
  NEXT_PAGE_GRAN_TOUR,
  NEXT_PAGE_ROUTE,
  NEXT_PAGE_ROUTE_SEARCHER,
  NEXT_PAGE_SEARCHER,
} from '@/content/constants'

import {
  AgendaActivitiesPage,
  AgendaPage,
  CiclaMadridPage,
  CiclaMadridSearcherPage,
  ContactPage,
  GranTourPage,
  RoutesPage,
  RoutesSearcherPage,
  SearcherPage,
} from './components'

export const NEXT_PAGES = {
  [NEXT_PAGE_AGENDA]: AgendaPage,
  [NEXT_PAGE_AGENDA_SEARCHER]: AgendaActivitiesPage,
  [NEXT_PAGE_ROUTE]: RoutesPage,
  [NEXT_PAGE_ROUTE_SEARCHER]: RoutesSearcherPage,
  [NEXT_PAGE_CONTACT]: ContactPage,
  [NEXT_PAGE_CICLA_MADRID]: CiclaMadridPage,
  [NEXT_PAGE_CICLA_MADRID_SEARCHER]: CiclaMadridSearcherPage,
  [NEXT_PAGE_GRAN_TOUR]: GranTourPage,
  [NEXT_PAGE_SEARCHER]: SearcherPage,
}
