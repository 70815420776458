import React from 'react'
import Link from 'next/link'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Language } from '@/components/PublicLayout/usePublicLayout'
import { ChevronDownIcon, Globe } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from 'ui'
import { cn } from 'utils-tailwindcss'

import { SpainFlag } from './components'
import { useDropdown } from './useDropdown'

const Dropdown = ({
  isMobile = false,
  languages,
}: {
  isMobile?: boolean
  languages: Language[]
}) => {
  const {
    state: { open, languageSelected },
    actions: { setOpen, setSelected },
  } = useDropdown()

  return (
    <div className="gap-12">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger
          aria-label={`${FormattedMessage({
            id: 'global.selectLanguage',
          })} ${
            !!languageSelected?.id &&
            FormattedMessage({ id: languageSelected.name })
          }`}
        >
          <div className="flex items-center gap-1 cursor-pointer">
            <div
              className={cn('flex items-center gap-2 px-2', isMobile && 'pl-0')}
            >
              {!isMobile ? (
                React.createElement(languageSelected?.icon ?? SpainFlag)
              ) : (
                <Globe aria-roledescription="presentation" aria-hidden="true" />
              )}
              <span>
                <FormattedMessage
                  id={languageSelected?.name ?? 'global.spanish'}
                />
              </span>
            </div>
            <ChevronDownIcon
              className="w-4 h-4 md:h-6 md:w-6"
              role="presentation"
              aria-hidden="true"
            />
          </div>
        </PopoverTrigger>
        {
          <PopoverContent className="flex flex-col gap-1 p-0 py-2 font-semibold text-secondary-black-900 border-neutral-gray-300">
            {languages
              .sort((a, b) => a.id.localeCompare(b.id))
              .map(({ id, name, icon, href }) => (
                <div
                  key={id}
                  className="flex items-center gap-2 px-2 py-1"
                  onClick={(e) => {
                    e.stopPropagation()
                    setSelected(id)
                    setOpen(false)
                  }}
                  aria-labelledby={id}
                >
                  {React.createElement(icon)}
                  <Link
                    locale={id}
                    href={href ?? '/home'}
                    className="no-underline"
                    aria-current={id === languageSelected?.id}
                  >
                    <span lang={languageSelected?.id ?? 'es'}>
                      <FormattedMessage id={name} />
                    </span>
                  </Link>
                </div>
              ))}
          </PopoverContent>
        }
      </Popover>
    </div>
  )
}

export { Dropdown }
