import { INITIAL_STATE } from './constants'
import { ListProvider } from './ListProvider'
import { ListProps } from './types'

const List = ({ children, initialState, ...rest }: ListProps) => {
  return (
    <ListProvider
      initialState={{ ...INITIAL_STATE, ...initialState }}
      {...rest}
    >
      <div className="flex flex-col w-full mb-8 pt-18">{children}</div>
    </ListProvider>
  )
}

export { List }
