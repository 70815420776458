import { NoResults } from '@/components/List'
import { useListProvider } from '@/components/List/ListProvider'
import { Node } from '@/components/Node'
import { HighlightCardSkeleton } from '@/components/PublicLayout/components/Header/components/Megamenu/components/HighlightCard'
import { VIEW_MODE_BASIC_CAROUSEL_3COL } from '@/content/constants'

const LandingList = () => {
  const {
    state: { results, isLoading },
  } = useListProvider()

  return (
    <div className="grid w-full grid-cols-1 gap-2 mt-12 lg:gap-12 lg:bg-neutral-white md:grid-cols-2 xl:grid-cols-3 justify-items-center">
      {results?.length !== 0 &&
        results?.map((r, key) => {
          return (
            <div className="w-full" key={key}>
              <Node node={{ ...r, viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL }} />
            </div>
          )
        })}
      {isLoading &&
        Array(6)
          .fill(0)
          .map((_, index) => <HighlightCardSkeleton key={index} />)}
      {!isLoading && !!!results?.length && <NoResults />}
    </div>
  )
}

export { LandingList }
