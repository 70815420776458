import { FormattedMessage } from '@/components/FormattedMessage'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { formattedDateToStringLabel } from '@/components/List/utils/helpers'
import { useRouter } from 'next-translate-routes'
import { Button, Text } from 'ui'
import { cn } from 'utils-tailwindcss'

import { FooterProps } from './types'

const Footer = ({
  date,
  reset,
  isPending,
  close,
  dataLength,
  numberOfMonths,
  isDisabled = false,
}: FooterProps) => {
  const { locale } = useRouter()

  return (
    <div className="flex gap-4 md:gap-0 flex-col md:flex-row w-full">
      <Text
        className={cn(
          'w-full pl-18 font-semibold text-primary-red-900',
          numberOfMonths === 1 && 'pl-6'
        )}
      >
        {formattedDateToStringLabel(locale ?? 'es', date)}
      </Text>
      <div className="flex pl-6 md:pl-0 md:justify-end w-full gap-6 md:pr-18">
        <Button intent="secondary" onClick={reset} isDisabled={isDisabled}>
          <FormattedMessage id="global.delete" />
        </Button>
        <Button
          intent="primary"
          className="truncate"
          isLoading={isPending}
          onClick={close}
        >
          <FormattedRichMessage
            id={dataLength ? 'searcher.showTotalResults' : 'global.showResults'}
            values={{ total: dataLength }}
          />
        </Button>
      </div>
    </div>
  )
}

export { Footer }
