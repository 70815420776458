import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { Facets } from '@/components/List/types'
import { CheckboxField } from '@/components/RoutesList/components/CheckboxField'
import { SliderField } from '@/components/RoutesList/components/SliderField'
import {
  DISTANCE_ROUTES_FILTER,
  DURATION_ROUTES_FILTER,
} from '@/components/RoutesList/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Button, Card, CardContent, Form, Separator } from 'ui'

import { useFilters } from './useFilters'

export const Filters = () => {
  const {
    state: { showFilters, topFilters, bottomFilters, isPending, data, form },
    actions: { onSubmitFilter, onResetForm, fetch },
  } = useFilters()

  const animationStyle = showFilters
    ? 'animate-in fade-in-0 zoom-in-95 slide-in-from-top-2'
    : 'animate-out fade-out-0 zoom-out-95'

  const conditionalTranslationKeys = {
    deleteFilters: 'global.deleteFilters',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    showFilters && (
      <Card
        className={`
    ${animationStyle}    
    h-fit w-full max-h-[44rem] overflow-y-auto lg:w-full absolute top-[84px] z-50 bg-neutral-white`}
      >
        <CardContent className="p-10">
          <Form {...form}>
            <form
              className="w-full"
              onSubmit={form.handleSubmit(onSubmitFilter)}
              onChange={() => fetch()}
            >
              <div className="flex flex-col">
                <div className="flex flex-row flex-wrap justify-between w-full gap-12 pb-10 lg:flex-nowrap">
                  {topFilters?.map(({ label, ...filter }: Facets) =>
                    filter.id === DURATION_ROUTES_FILTER ||
                    filter.id === DISTANCE_ROUTES_FILTER ? (
                      <SliderField
                        key={filter.id}
                        label={label || ''}
                        {...filter}
                      />
                    ) : (
                      <CheckboxField
                        key={filter.id}
                        label={label || ''}
                        {...filter}
                      />
                    )
                  )}
                </div>
                {!!bottomFilters?.length && (
                  <>
                    <Separator className="lg:my-0 bg-neutral-gray-300" />
                    <div className="flex gap-16 pt-6 w-fit">
                      {bottomFilters?.map(({ ...filter }: Facets) => (
                        <CheckboxField key={filter.id} {...filter} />
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div className="flex items-center justify-end mt-8 gap-x-10">
                <Button type="reset" intent="secondary" onClick={onResetForm}>
                  {translations.deleteFilters}
                </Button>
                <Button type="submit" intent="primary" isLoading={isPending}>
                  <FormattedRichMessage
                    id="searcher.showTotalResults"
                    values={{ total: data }}
                  />
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    )
  )
}
