import { CardBasic } from '@/components/CardBasic'

const Basic = ({ paragraph_content }: { paragraph_content }) => (
  <CardBasic
    title={paragraph_content.title}
    image={paragraph_content.image}
    url={
      paragraph_content.document?.file?.uri.url ?? paragraph_content.link?.uri
    }
    externalLink={!!paragraph_content.document ? true : false}
    popup={!!paragraph_content.popup}
    description={paragraph_content.body}
  />
)

export { Basic }
