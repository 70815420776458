import { ComponentPropsWithoutRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { ContentBodyStyles } from './ContentBody.styles'

const ContentBody = ({
  children,
  className,
}: ComponentPropsWithoutRef<'div'>) => {
  return <div className={cn(ContentBodyStyles(), className)}>{children}</div>
}

export { ContentBody }
