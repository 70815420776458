import { useMemo } from 'react'
import Link from 'next/link'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { Node } from '@/components/Node'
import { DrupalNodeLanding } from '@/components/Node/components/Landing/types'
import { BundleDrupalNode } from '@/components/Node/types'
import { VIEW_MODE_BASIC_CAROUSEL_3COL } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import {
  Heading,
  Navigation,
  NavigationFooterItem,
  NavigationItem,
  Separator,
} from 'ui'

import { HighlightCardSkeleton } from '../HighlightCard'
import { WhatToDoProps } from './types'
import { useWhatToDo } from './useWhatToDo'

const WhatToDo = ({ categories, isMobile = false }: WhatToDoProps) => {
  const {
    state: { categorySelected, selected, isLoading, itemsSelected },
    actions: { setSelected },
  } = useWhatToDo(
    categories?.items ?? [],
    categories.title,
    categories.id,
    isMobile
  )

  const items = useMemo(
    () =>
      isMobile ? categorySelected?.items ?? categories.items : categories.items,
    [categorySelected, categories, isMobile]
  )

  const conditionalTranslationKeys = {
    highlights: 'global.highlights',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  const mappedItemsSelected = itemsSelected?.map((obj2) => {
    const match = categories.items?.find((obj1) => obj1.content?.id === obj2.id)
    if (match) {
      return {
        ...obj2,
        title: match.title,
      }
    }

    const match2Id = categories.items
      ?.map((item) => item?.items)
      .filter(Boolean)

    match2Id.forEach((subArray) => {
      subArray?.forEach((subItem) => {
        if (subItem?.content?.id === obj2.id) {
          obj2.title = subItem.title
        }
      })
    })

    return obj2
  })

  return (
    <>
      <Heading className="mt-6 mb-8 md:hidden">
        {categorySelected?.title ?? categories.title}
      </Heading>
      <div className="h-full md:grid md:grid-cols-3 md:gap-12">
        <Navigation
          onValueChange={(id: string) => {
            setSelected(id)
          }}
          role="tablist"
          aria-orientation="vertical"
        >
          {items
            ?.filter((item) => !item.promoted)
            ?.map((category, key) =>
              category.items && category.items.length > 0 ? (
                <NavigationItem
                  id={category.id}
                  key={key}
                  role="tab"
                  aria-selected={selected === category?.id}
                  aria-controls={category.id}
                  className="text-left"
                >
                  {category.title}
                </NavigationItem>
              ) : (
                <NavigationItem key={key} id={category.id}>
                  <Link href={category.url ?? ''}>{category.title}</Link>
                </NavigationItem>
              )
            )}
          <NavigationFooterItem>
            <Link href={categories?.url ?? ''}>
              <FormattedRichMessage
                id="megamenu.accessTo"
                values={{ name: categories.title }}
              />
            </Link>
          </NavigationFooterItem>
        </Navigation>
        <Separator className="mt-8 mb-6 bg-neutral-gray-300 md:hidden" />
        <div
          className="md:col-span-2"
          role="tabpanel"
          id={categorySelected?.id}
        >
          <div className="flex flex-row justify-between">
            <Heading as="h3" className="mb-8 font-semibold">
              {translations.highlights}
            </Heading>
            {!!selected && categorySelected?.title !== categories.title && (
              <Link href={categorySelected?.url ?? ''}>
                <FormattedRichMessage
                  id="megamenu.accessTo"
                  values={{ name: categorySelected?.title }}
                />
              </Link>
            )}
          </div>
          <div className="grid grid-cols-2 gap-6 md:grid-cols-3 md:gap-8 h-fit md:max-h-[600px] md:overflow-y-auto custom-scroll">
            {isLoading
              ? Array(6)
                  .fill(0)
                  .map((_, index) => <HighlightCardSkeleton key={index} />)
              : mappedItemsSelected?.map(
                  (child: BundleDrupalNode, key: number) => {
                    return (
                      <Node
                        key={key}
                        node={
                          {
                            ...child,
                            viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL,
                            className: 'bg-opacity-0',
                          } as unknown as DrupalNodeLanding
                        }
                      />
                    )
                  }
                )}
          </div>
        </div>
      </div>
    </>
  )
}

export { WhatToDo }
