import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from 'utils-tailwindcss'

import { TabListStyles } from './TabList.styles'

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...rest }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(TabListStyles(), className)}
    {...rest}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

export { TabsList }
