import { MinusIcon, PlusIcon } from 'lucide-react'
import { Button, FormControl, SliderThumb, Slider as SliderUI } from 'ui'

import { SliderProps } from './types'

export const Slider = ({
  value,
  onValueChange,
  min = 0,
  max = 10,
}: SliderProps) => {
  const onPlusClick = () => {
    let [firstValue, secondValue] = value

    if (secondValue < 10) {
      secondValue++
    } else if (firstValue < 10) {
      firstValue++
    }
    onValueChange([firstValue, secondValue])
  }

  const onMinusClick = () => {
    let [firstValue, secondValue] = value

    if (firstValue > 0) {
      firstValue--
    } else if (secondValue > 0) {
      secondValue--
    }
    onValueChange([firstValue, secondValue])
  }
  return (
    <FormControl className="pt-2">
      <div className="flex flex-col">
        <div className="flex justify-between pb-3">
          <Button
            type="button"
            intent="link"
            className="no-underline"
            isDisabled={value[0] === 0 && value[1] === 0}
            onClick={onMinusClick}
          >
            <MinusIcon />
          </Button>
          <Button
            type="button"
            intent="link"
            className="no-underline"
            isDisabled={value[0] === 10 && value[1] === 10}
            onClick={onPlusClick}
          >
            <PlusIcon />
          </Button>
        </div>
        <SliderUI
          min={min}
          max={max}
          value={value}
          onValueChange={onValueChange}
        >
          <SliderThumb />
          <SliderThumb />
        </SliderUI>
      </div>
    </FormControl>
  )
}
