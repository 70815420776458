import { CustomExperience } from './components/CustomExperience'
import { Difficulty } from './components/Difficulty'
import { EventCategory } from './components/EventCategory'
import { ItineraryCategory } from './components/ItineraryCategory'
import { ItineraryServices } from './components/ItineraryServices'
import { ItineraryType } from './components/ItineraryType'
import { Municipality } from './components/Municipality'
import { RouteActivity } from './components/RouteActivity'
import { RouteCategory } from './components/RouteCategory'
import { RouteType } from './components/RouteType'
import { Todotogo } from './components/Todotogo'
import { TouristPlanType } from './components/TouristPlanType'

export const TERM = 'taxonomy_term'
export const TERM_TODOTOGO = 'taxonomy_term--todotogo'
export const TERM_EVENT_CATEGORY = 'taxonomy_term--event_category'
export const TERM_MUNICIPALITY = 'taxonomy_term--municipality'
export const TERM_REGION = 'taxonomy_term--region'
export const TERM_ESTABLISHMENT_CATEGORY =
  'taxonomy_term--establishment_category'
export const TERM_TOURIST_POINT_CATEGORY =
  'taxonomy_term--tourist_point_category'
export const TERM_TOURIST_PLAN_CATEGORY = 'taxonomy_term--tourist_plan_category'
export const TERM_TOURIST_PLAN_TYPE = 'taxonomy_term--plan_type'
export const TERM_ESTABLISHMENT_TYPE = 'taxonomy_term--establishment_type'
export const TERM_EVENT_TYPE = 'taxonomy_term--event_type'
export const TERM_ROUTE_ACTIVITY = 'taxonomy_term--route_activity'
export const TERM_ROUTE_LEVEL = 'taxonomy_term--route_level'
export const TERM_ROUTE_TYPE = 'taxonomy_term--route_type'
export const TERM_ITINERARY_CATEGORY = 'taxonomy_term--itinerary_category'
export const TERM_ITINERARY_SERVICES = 'taxonomy_term--itinerary_services'
export const TERM_ITINERARY_TYPE = 'taxonomy_term--itinerary_type'
export const TERM_CUSTOM_EXPERIENCE = 'taxonomy_term--custom_experience'
export const TERM_ROUTE_CATEGORY = 'taxonomy_term--route_category'
export const TERM_AREA = 'taxonomy_term--area'
export const TERM_CUSINE_TYPE = 'taxonomy_term--cuisine_type'
export const TERM_ESTABLISHMENT_SERVICES =
  'taxonomy_term--establishment_services'
export const TERM_HOTEL_CHAIN = 'taxonomy_term--hotel_chain'
export const TERM_PROGRAM = 'taxonomy_term--program'
export const TERM_PROGRAMS = 'taxonomy_term--programs'
export const TERM_PROPOSAL = 'taxonomy_term--proposal'
export const TERM_ACCOMMODATION_TYPE = 'taxonomy_term--accommodation_type'

export const TAXONOMY_TERMS = {
  [TERM_TODOTOGO]: Todotogo,
  [TERM_ROUTE_CATEGORY]: RouteCategory,
  [TERM_EVENT_CATEGORY]: EventCategory,
  [TERM_MUNICIPALITY]: Municipality,
  [TERM_ROUTE_LEVEL]: Difficulty,
  [TERM_TOURIST_PLAN_TYPE]: TouristPlanType,
  [TERM_ROUTE_TYPE]: RouteType,
  [TERM_ITINERARY_CATEGORY]: ItineraryCategory,
  [TERM_ITINERARY_SERVICES]: ItineraryServices,
  [TERM_ITINERARY_TYPE]: ItineraryType,
  [TERM_CUSTOM_EXPERIENCE]: CustomExperience,
  [TERM_ROUTE_ACTIVITY]: RouteActivity,
}
