const ItalianFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    width="28px"
    height="19px"
    viewBox="0 0 28 19"
    version="1.1"
    role="presentation"
    aria-hidden="true"
  >
    <g id="surface1">
      <rect
        x="0"
        y="0"
        width="28"
        height="19"
        fill="rgb(0%,57.254902%,27.45098%)"
        fillOpacity="1"
        stroke="none"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        d="M 9.332031 0 L 28 0 L 28 19 L 9.332031 19 Z M 9.332031 0 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(80.784314%,16.862745%,21.568627%)"
        fillOpacity="1"
        d="M 18.667969 0 L 28 0 L 28 19 L 18.667969 19 Z M 18.667969 0 "
      />
    </g>
  </svg>
)

export { ItalianFlag }
