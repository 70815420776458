import { useCallback, useEffect, useState } from 'react'
import { fetchIndexDrupal } from '@/components/List/services/api'
import { Facets, Filter } from '@/components/List/types'
import { BundleDrupalNode } from '@/components/Node/types'
import { debounce } from '@/components/RoutesList/helpers/debounce'
import { convertFilters } from '@/components/RoutesList/helpers/utils'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { HookOutput } from 'utils'

import { useMapListProvider } from '../../../../MapListProvider'

type useFilterResult = HookOutput<
  {
    showFilters: boolean
    form: UseFormReturn<FieldValues, any, undefined>
    results?: BundleDrupalNode[]
    facets?: Facets[]
    activeFilters?: Filter[]
    isPending: boolean
    total?: number
  },
  {
    onResetForm: () => void
    onSubmitFilter: (
      _data: Record<string, string | string[] | number[]>
    ) => void
    fetch: () => void
    setShowFilters: (_show: boolean) => void
    removeFilterFromList: (_filters: Filter[] | undefined) => void
  }
>

export const useFilters = (): useFilterResult => {
  const {
    state: { showFilters: internalOpen, form },
    listState: { facets, query, activeFilters, total_items },
    type,
    onSubmitFilter,
    onResetForm: reset,
    removeFilterFromList,
  } = useMapListProvider()

  const [isPending, setIsPending] = useState(false)
  const [showFilters, setShowFilters] = useState(internalOpen)
  const [total, setTotal] = useState<number | undefined>(total_items)

  const filterFetch = useCallback(
    async (data): Promise<number | undefined> => {
      try {
        const filters = convertFilters(data, facets)
        const response = await fetchIndexDrupal(
          { activeFilters: filters, query: query, per_page: 1 },
          type,
          true
        )
        return response.total_items
      } catch (error) {
        console.error('Error en convertFilter: ', error)
        return 0
      }
    },
    [facets, query, type]
  )

  const fetch = useCallback(async () => {
    setIsPending(true)
    debounce(() => {
      filterFetch(form.getValues()).then((response) => {
        setTotal(response)
        setIsPending(false)
      })
    }, 700)
  }, [form, filterFetch])

  const onResetForm = () => {
    reset()
    setShowFilters(false)
  }

  useEffect(() => {
    setTotal(total_items)
  }, [total_items])

  return {
    state: { isPending, total, form, activeFilters, showFilters, facets },
    actions: {
      onSubmitFilter,
      onResetForm,
      fetch,
      setShowFilters,
      removeFilterFromList,
    },
  }
}
