import { SpainFlag, UnitedKingdomFlag } from './components'

/* @Todo: dropdown, idiomas comentados */
export const LANGUAGES = [
  {
    id: 'es',
    name: 'global.spanish',
    icon: SpainFlag,
  },
  {
    id: 'en',
    name: 'global.english',
    icon: UnitedKingdomFlag,
  },
  /*  {
    id: 'fr',
    name: 'global.french',
    icon: FrenchFlag,
  },
  {
    id: 'it',
    name: 'global.italian',
    icon: ItalianFlag,
  },
  {
    id: 'pt-pt',
    name: 'global.portuguese',
    icon: PortugueseFlag,
  },
  {
    id: 'de',
    name: 'global.german',
    icon: GermanFlag,
  },
  {
    id: 'ko',
    name: 'global.korean',
    icon: SouthKoreaFlag,
  },
  {
    id: 'zh-hans',
    name: 'global.chinese',
    icon: ChineseFlag,
  },
  {
    id: 'ja',
    name: 'global.japanese',
    icon: JapaneseFlag,
  }, */
]
