import { CardBasic } from '@/components/CardBasic'

import { DrupalNodeLandingBasictProps } from './types'

const Basic = ({ node, className }: DrupalNodeLandingBasictProps) => (
  <CardBasic
    title={node.title}
    image={node.computed_image}
    url={node.path?.alias}
    className={className}
  />
)

export { Basic }
