import { VIEW_MODE_DEFAULT } from '@/content/constants'

import { DrupalMediaImageProps } from './types'
import { Default } from './viewmodes/Default'

const Image = ({
  viewMode = VIEW_MODE_DEFAULT,
  ...rest
}: DrupalMediaImageProps) => {
  switch (viewMode) {
    default:
      return <Default {...rest} />
  }
}

export { Image }
