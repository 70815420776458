import { forwardRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { Skeleton } from '../Skeleton'
import { CardStyles } from './Card.styles'
import { CardProps } from './types'

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ className, children, intent, isEmpty, ...rest }, ref) => (
    <>
      {isEmpty == undefined || !isEmpty ? (
        <div
          ref={ref}
          className={cn(CardStyles.root({ intent }), className)}
          {...rest}
        >
          {children}
        </div>
      ) : (
        <div className={cn(CardStyles.skeletonRoot({ intent }))}>
          <Skeleton className="w-full h-full" />
          <div className={cn(CardStyles.skeletonContent({ intent }))}>
            <Skeleton className="h-3 w-6/6" />
            <Skeleton className="w-full h-2 " />
            <Skeleton className="w-full h-2 " />
          </div>
        </div>
      )}
    </>
  )
)

Card.displayName = 'Card'

export { Card }
