import { MOCK_FILE_DERIVATIVE } from '@/components/CardRoute/mock'

import 'ui'

import { CKEditor } from '@/components/CKEditor'
import { ContentPopup } from '@/components/ContentPopup'
import { FormattedMessage } from '@/components/FormattedMessage'
import { DrupalMedia } from 'next-drupal'
import {
  CardImage,
  CardTitle,
  Heading,
  ImageViewer,
  ImageViewerContent,
  ImageViewerTrigger,
} from 'ui'

import { Media } from '../../Media'
import { BREAKPOINTS } from '../constants'
import { CardBasicContentProps } from '../types'

const Content = ({
  title,
  image,
  popup,
  description,
}: CardBasicContentProps) => {
  if (!popup) {
    return (
      <>
        {image && (
          <CardImage>
            <Media
              media={image || MOCK_FILE_DERIVATIVE}
              // @ts-ignore
              breakpoints={BREAKPOINTS}
            />
          </CardImage>
        )}
        <CardTitle className="md:pb-4">
          <span className="font-bold">{title}</span>
        </CardTitle>
      </>
    )
  }
  return (
    <CardImage>
      <ImageViewer>
        <ImageViewerTrigger
          className="w-full text-left hover:underline focus:border-2 focus:border-secondary-black-900 rounded-md focus:p-[2px]"
          aria-label={FormattedMessage({
            id: 'paragraph.gallery.enlarge',
          })}
        >
          <Media
            media={(image || MOCK_FILE_DERIVATIVE) as DrupalMedia}
            // @ts-ignore
            breakpoints={BREAKPOINTS}
          />
          <CardTitle className="pb-4">
            <span className="font-bold">{title}</span>
          </CardTitle>
        </ImageViewerTrigger>
        <ImageViewerContent>
          <ContentPopup
            title={
              <Heading as="h3" className="font-semibold">
                {title}
              </Heading>
            }
            body={!!description && <CKEditor text={description} />}
            image={
              <Media
                media={(image || MOCK_FILE_DERIVATIVE) as DrupalMedia}
                // @ts-ignore
                breakpoints={BREAKPOINTS}
              />
            }
          />
        </ImageViewerContent>
      </ImageViewer>
    </CardImage>
  )
}

export { Content }
