import { useMemo } from 'react'
import { CICLA_MADRID_INDEX } from '@/components/List/constants'
import { RoutesList } from '@/components/RoutesList'
import { Title } from '@/components/Title'
import { DrupalFieldTextFormat } from 'utils-drupal'

import { DrupalParagraphCiclaMadridSearchProps } from '../../types'

const Default = ({
  paragraph,
}: {
  paragraph: DrupalParagraphCiclaMadridSearchProps
}) => {
  const urlCiclaSearch = useMemo(
    () => paragraph.cicla_search?.[0]?.path.alias || '',
    [paragraph]
  )

  return (
    <div className="container pt-8">
      <Title
        title={paragraph.title}
        subtitle={
          typeof paragraph.subtitle !== 'string'
            ? paragraph.subtitle
            : ({
                value: paragraph.subtitle,
              } as unknown as DrupalFieldTextFormat)
        }
        description={paragraph.body}
      />

      <div className="-mt-16">
        <RoutesList
          withResults={false}
          type={CICLA_MADRID_INDEX}
          initialState={{
            showResults: false,
            urlResults: urlCiclaSearch,
          }}
        />
      </div>
    </div>
  )
}

export { Default }
