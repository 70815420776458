import { createElement } from 'react'
import { VIEW_MODE_DEFAULT } from '@/content/constants'

import { MEDIAS } from './constants'
import { BundleDrupalMedia, MediaType } from './types'

const Media = ({ media, ...rest }: { media: BundleDrupalMedia }) => {
  /// TODO: QUITAR ESTO CUANDO EL BACK LO TENGA COMO OBLIGATORIO
  if (!!!media) {
    return <></>
  }

  const bundle = MEDIAS[media?.type as MediaType]
  if (!!!bundle) {
    console.error(`No existe este media ${media.type}`)
    return <></>
  }

  const viewMode = media?.viewMode ?? VIEW_MODE_DEFAULT
  return (
    <div id={media.id} className={`${media.type} view-mode-${viewMode}`}>
      {createElement(bundle, {
        media: media,
        viewMode: viewMode,
        ...rest,
      })}
    </div>
  )
}

export { Media }
