import { useState } from 'react'
import { Carousel } from '@/components/Carousel'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Node } from '@/components/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { Title } from '@/components/Title'
import { VIEW_MODE_BASIC_CAROUSEL_3COL } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import {
  Carousel as CarouselUI,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'ui'
import { DrupalFieldTextFormat } from 'utils-drupal'
import { cn } from 'utils-tailwindcss'

import { DrupalParagraphTagsProps } from '../../types'
import { CheckBoxList } from './components'

const Default = ({ paragraph }: DrupalParagraphTagsProps) => {
  const [villages, setVillages] = useState([])

  const TERMS_NAME = paragraph.terms
    .filter(
      (unpublished) =>
        unpublished.id !== 'missing' && unpublished.type !== 'unknow'
    )
    .map((term) => term.id)

  const TAGS_OBJECT = TERMS_NAME.reduce((acc, term) => {
    acc[term] = paragraph.contents_tags.filter((node) => {
      return Array.isArray(node[paragraph.content_type.term_reference_field])
        ? !!node[paragraph.content_type.term_reference_field].find(
            (taxonomy) => taxonomy.id === term
          )
        : node[paragraph.content_type.term_reference_field]?.id === term
    })

    if (villages.length > 0) {
      acc[term] = acc[term].filter((node) => {
        return villages.some(
          (village) => village === node.term_municipality?.id
        )
      })
    }

    return acc
  }, {})

  const TAGS_WITH_CONTENT = Object.keys(TAGS_OBJECT)

  const conditionalTranslationKeys = {
    villages: 'global.villages',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div className="my-18">
      <div className="container">
        <Title
          title={paragraph.title}
          subtitle={
            typeof paragraph.subtitle !== 'string'
              ? paragraph.subtitle
              : ({ value: paragraph.subtitle } as DrupalFieldTextFormat)
          }
          description={
            typeof paragraph.body !== 'string'
              ? paragraph.body
              : ({ value: paragraph.body } as DrupalFieldTextFormat)
          }
        />
      </div>
      <Tabs defaultValue={TAGS_WITH_CONTENT[0]}>
        <TabsList>
          <div className="container flex-wrap hidden gap-6 md:flex md:-mt-[20px] md:-mb-10">
            {TAGS_WITH_CONTENT.map((id, index) => {
              const term = paragraph.terms.find((term) => term.id === id).name

              const disabled = !!!TAGS_OBJECT[id].length

              return (
                <TabsTrigger
                  key={index}
                  value={id}
                  className={cn(disabled ? 'bg-neutral-gray-300' : '')}
                  disabled={disabled}
                >
                  {typeof term === 'object' ? term.value : term}
                </TabsTrigger>
              )
            })}
            {!!paragraph?.computed_villages?.length && (
              <div>
                <CheckBoxList
                  computed_villages={paragraph?.computed_villages}
                  label={translations.villages}
                  setVillages={setVillages}
                  villages={villages}
                />
              </div>
            )}
          </div>
          <div className="container md:hidden">
            <CarouselUI cols="auto">
              {TAGS_WITH_CONTENT.map((id, index) => {
                const term = paragraph.terms.find((term) => term.id === id).name
                const disabled = !!!TAGS_OBJECT[id].length
                return (
                  <TabsTrigger
                    key={index}
                    value={id}
                    className={cn(disabled ? 'bg-neutral-gray-300' : '')}
                    disabled={disabled}
                  >
                    {typeof term === 'object' ? term.value : term}
                  </TabsTrigger>
                )
              })}
            </CarouselUI>
            {!!paragraph?.computed_villages?.length && (
              <div className="mt-4">
                <CheckBoxList
                  computed_villages={[...(paragraph.computed_villages ?? [])]}
                  label={FormattedMessage({ id: 'global.villages' })}
                  setVillages={setVillages}
                  villages={villages}
                />
              </div>
            )}
          </div>

          <div className="custom-container">
            {TERMS_NAME.map((term, index) => {
              const SELECTED_NODES = TAGS_OBJECT[term]

              return (
                <TabsContent key={index} value={term} tabIndex={-1}>
                  <Carousel>
                    {SELECTED_NODES.map((content: BundleDrupalNode) => (
                      <Node
                        key={content.id}
                        node={{
                          ...content,
                          viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL,
                        }}
                      />
                    ))}
                  </Carousel>
                </TabsContent>
              )
            })}
          </div>
        </TabsList>
      </Tabs>
    </div>
  )
}

export { Default }
