import { cva } from 'class-variance-authority'

export const TriggerStyles = {
  root: cva(
    [
      'flex items-center flex-1 justify-between text-left py-3 md:text-lg text-base font-semibold text-secondary-black-900',
    ],
    {
      variants: {
        icon: {
          down: '[&[data-state=open]>svg]:rotate-180 [&[data-state=closed]>svg]:rotate-0',
          right:
            '[&[data-state=open]>svg]:rotate-0 [&[data-state=closed]>svg]:-rotate-90',
          plus: '[&[data-state=open]>svg>path:last-child]:hidden [&[data-state=closed]>svg]:rotate-90',
          none: '[&>svg]:hidden cursor-default',
        },
      },
      defaultVariants: {
        icon: 'down',
      },
    }
  ),
  icon: cva('transition-transform duration-200 min-w-[16px]'),
  header: cva('flex'),
}
