import { Media } from '@/components/Media'
import { MapPin } from 'lucide-react'
import { Heading, Text } from 'ui'

import { BREAKPOINTS } from './constants'
import { CardProps } from './types'

export const BasicTeaser = ({ node }: CardProps) => (
  <div className="flex items-start justify-start w-full p-2">
    <div className="flex flex-col w-full gap-2">
      <Heading as="h3" className="max-w-[258px] text-base text-start">
        {node.title}
      </Heading>
      <div className="flex flex-col items-start gap-2 sm:items-center sm:flex-row">
        <Text className="text-sm">
          <MapPin size={17} className="text-neutral-gray-500" />
          {node.text_address}
        </Text>
      </div>
    </div>
    <div className="w-[7.5rem]">
      {/* @ts-ignore */}
      <Media media={node.computed_image} breakpoints={BREAKPOINTS} />
    </div>
  </div>
)
