import Link from 'next/link'
import { CKEditor } from '@/components/CKEditor'
import { ContentPopup } from '@/components/ContentPopup'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Media } from '@/components/Media'
import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ExternalLink, LinkIcon, PlusCircle } from 'lucide-react'
import {
  Card,
  CardContent,
  CardImage,
  CardSubtitle,
  CardTitle,
  Heading,
  ImageViewer,
  ImageViewerContent,
  ImageViewerTrigger,
} from 'ui'
import { useWidth } from 'utils'
import { cn } from 'utils-tailwindcss'

import { CARD_BIG } from '../../constants'
import { BREAKPOINTS, BREAKPOINTS_SMALL } from '../constants'
import { DrupalParagraphGalleryCarouselProps } from '../types'

const Layout = ({
  paragraph,
  type,
  index,
  activeSlideIndex,
  url,
  showMore,
  target,
}: DrupalParagraphGalleryCarouselProps) => {
  const { isMobile } = useWidth()

  const conditionalTranslationKeys = {
    showMore: 'paragraph.customText.showMore',
    externalLink: 'global.externalLink',
    internalLink: 'global.internalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  switch (type) {
    case CARD_BIG:
      return (
        <Card className="rounded-b-none shadow-none bg-neutral-gray-100">
          <CardImage>
            <Media
              media={{
                ...(paragraph.computed_image || paragraph.image),
                viewMode: VIEW_MODE_DEFAULT,
              }}
              // @ts-ignore
              breakpoints={BREAKPOINTS}
            />
          </CardImage>
          <CardContent className="flex flex-col gap-3 px-4">
            <CardTitle className="no-underline">
              <Heading as="h3" className="no-underline">
                {paragraph.title}
              </Heading>
            </CardTitle>
            <CardSubtitle className="flex flex-col w-full gap-3">
              <div className="line-clamp-4 text-text-medium">
                <CKEditor text={paragraph.body} />
              </div>
              <ImageViewer>
                <ImageViewerTrigger
                  className="flex justify-end w-full hover:underline"
                  aria-label={FormattedMessage({
                    id: 'paragraph.gallery.enlarge',
                  })}
                >
                  <PlusCircle />
                </ImageViewerTrigger>
                <ImageViewerContent>
                  <ContentPopup
                    image={
                      <Media
                        media={{
                          ...(paragraph.computed_image || paragraph.image),
                          viewMode: VIEW_MODE_DEFAULT,
                        }}
                        // @ts-ignore
                        breakpoints={BREAKPOINTS}
                      />
                    }
                    body={<CKEditor text={paragraph.body} />}
                    title={
                      <Heading as="h3" className="font-semibold">
                        {paragraph.title}
                      </Heading>
                    }
                  />
                </ImageViewerContent>
              </ImageViewer>
              <div className="flex gap-1">
                {url && (
                  <>
                    {target === '_blank' ? (
                      <ExternalLink
                        width={isMobile() ? '1rem' : '1.5rem'}
                        aria-roledescription="presentation"
                        aria-hidden="true"
                      />
                    ) : (
                      <LinkIcon
                        width={isMobile() ? '1rem' : '1.5rem'}
                        aria-roledescription="presentation"
                        aria-hidden="true"
                      />
                    )}
                    <Link
                      href={url}
                      className="font-semibold"
                      target={target}
                      aria-label={translations.showMore}
                      aria-describedby={
                        target === '_blank'
                          ? translations.externalLink
                          : translations.internalLink
                      }
                    >
                      {showMore}
                    </Link>
                    {target === '_blank' ? (
                      <p className="sr-only">{translations.newWindow}</p>
                    ) : null}
                  </>
                )}
              </div>
            </CardSubtitle>
          </CardContent>
        </Card>
      )

    default:
      return (
        <div
          className={`${
            index === activeSlideIndex && 'border-primary-red-900 border-2'
          } w-[289px] rounded-lg`}
        >
          <Card key={paragraph.id} className={cn('cursor-pointer rounded')}>
            <CardImage>
              <Media
                media={{
                  ...(paragraph.computed_image || paragraph.image),
                  viewMode: VIEW_MODE_DEFAULT,
                }}
                // @ts-ignore
                breakpoints={BREAKPOINTS_SMALL}
              />
            </CardImage>
          </Card>
        </div>
      )
  }
}

export { Layout }
