import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Button, Progress, Text } from 'ui'

import { usePaginator } from './usePaginator'

const Paginator = () => {
  const {
    state: { isLoading, totalCount, actualShowed },
    actions: { onClickMoreResults },
  } = usePaginator()

  const conditionalTranslationKeys = {
    showMoreResults: 'pagination.showMoreResults',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    totalCount !== 0 && (
      <div className="flex flex-col w-full md:w-auto container">
        <div className="flex flex-col self-center w-auto">
          <Text className="mb-3 text-center">
            <FormattedRichMessage
              id="pagination.count"
              values={{ length: actualShowed, total: totalCount }}
            />
          </Text>
          <Progress max={totalCount} value={actualShowed} />
        </div>
        {totalCount !== actualShowed && (
          <Button
            className="w-full mt-6"
            onClick={onClickMoreResults}
            isLoading={isLoading}
            intent="secondary"
          >
            {translations.showMoreResults}
          </Button>
        )}
      </div>
    )
  )
}
export { Paginator }
