const ChineseFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    width="28px"
    height="19px"
    viewBox="0 0 28 19"
    version="1.1"
    role="presentation"
    aria-hidden="true"
  >
    <g id="surface1">
      <rect
        x="0"
        y="0"
        width="28"
        height="19"
        fill="rgb(93.333333%,10.980392%,14.509804%)"
        fillOpacity="1"
        stroke="none"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 4.667969 1.898438 L 6.3125 7.054688 L 2.003906 3.871094 L 7.328125 3.871094 L 3.019531 7.054688 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 9.699219 1.027344 L 9.542969 2.824219 L 8.628906 1.277344 L 10.261719 1.984375 L 8.53125 2.390625 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 11.871094 3.136719 L 11.039062 4.734375 L 10.789062 2.945312 L 12.023438 4.242188 L 10.277344 3.933594 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 12.078125 6.324219 L 10.679688 7.4375 L 11.167969 5.699219 L 11.773438 7.398438 L 10.300781 6.390625 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,100%,0%)"
        fillOpacity="1"
        d="M 9.664062 7.660156 L 9.582031 9.464844 L 8.605469 7.957031 L 10.265625 8.59375 L 8.554688 9.070312 "
      />
    </g>
  </svg>
)

export { ChineseFlag }
