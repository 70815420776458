import { createElement } from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { LucideIcon } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

const ExternalLink = ({
  id,
  icon,
  language,
}: {
  id: string
  icon: LucideIcon
  language: string
  title: string
}) => {
  const url = usePathname()
  return (
    <Link
      rel="noopener noreferrer"
      href={
        (FormattedRichMessage({
          id: `node.share.${id}`,
          values: {
            url: `${process.env.NEXT_PUBLIC_NEXTJS_BASE_URL}${url}`,
          },
        }) as string) ?? ''
      }
      className={cn(
        'flex items-center gap-2 px-3 py-1 cursor-pointer no-underline hover:bg-neutral-gray-200 hover:border-2 hover:border-secondary-black-900'
      )}
    >
      {createElement(icon, { role: 'true', 'aria-hidden': 'true' })}
      <span>{language}</span>
    </Link>
  )
}
export { ExternalLink }
