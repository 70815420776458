import { AgendaList } from '@/components/AgendaList'
import { Carousel } from '@/components/Carousel'
import { Grid } from '@/components/Grid'
import { NODE_EVENT_BASE } from '@/components/Node/constants'
import { Node } from '@/components/Node/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import {
  NEXT_PAGE_AGENDA_SEARCHER,
  VIEW_MODE_CARD_ACTIVITY,
  VIEW_MODE_CARD_BASIC,
} from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'

import { DrupalNodeNextAgendaPageProps } from './types'

const AgendaPage = ({ node }: DrupalNodeNextAgendaPageProps) => {
  const conditionalTranslationKeys = {
    highlightActivities: 'searcher.agenda.highlightActivities',
    highlightActivitiesBody: 'searcher.agenda.highlightActivitiesBody',
    upcomingActivities: 'searcher.agenda.upcomingActivities',
    upcomingActivitiesBody: 'searcher.agenda.upcomingActivitiesBody',
    freeActivities: 'searcher.agenda.freeActivities',
    freeActivitiesBody: 'searcher.agenda.freeActivitiesBody',
    ofInterest: 'node.ofInterest',
    cannotMiss: 'searcher.agenda.cannotMiss',
    happeningNow: 'searcher.agenda.happeningNow',
    mustSeeEvents: 'searcher.agenda.mustSeeEvents',
    communityHighlights: 'searcher.agenda.communityHighlights',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <div className="container">
        <AgendaList
          withResults={false}
          type={NODE_EVENT_BASE}
          initialState={{
            showResults: false,
            facets: node.facets,
            urlResults: node.contents?.find(
              (nextPage) => nextPage.mapping === NEXT_PAGE_AGENDA_SEARCHER
            )?.path.alias,
          }}
          isSearcher={false}
        />
      </div>
      {!!node.promoteActivities?.length && (
        <div className="custom-container">
          <Carousel
            title={translations.cannotMiss}
            subtitle={translations.mustSeeEvents}
          >
            {node.promoteActivities.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_ACTIVITY }}
                key={content.id}
              />
            ))}
          </Carousel>
        </div>
      )}
      {!!node.highlightedsActivities?.length && (
        <div className="custom-container">
          <Carousel
            title={translations.happeningNow}
            subtitle={translations.communityHighlights}
          >
            {node.highlightedsActivities.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_ACTIVITY }}
                key={content.id}
              />
            ))}
          </Carousel>
        </div>
      )}
      {!!node.upcomingActivities?.length && (
        <div className="custom-container">
          <Carousel
            title={translations.upcomingActivities}
            subtitle={translations.upcomingActivitiesBody}
          >
            {node.upcomingActivities.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_ACTIVITY }}
                key={content.id}
              />
            ))}
          </Carousel>
        </div>
      )}
      {!!node.freeActivities?.length && (
        <div className="custom-container">
          <Carousel
            title={translations.freeActivities}
            subtitle={translations.freeActivitiesBody}
          >
            {node.freeActivities.map((content: BundleDrupalNode) => (
              <Node
                key={content.id}
                node={{ ...content, viewMode: VIEW_MODE_CARD_ACTIVITY }}
              />
            ))}
          </Carousel>
        </div>
      )}
      {!!node.ofInterest?.length && (
        <div className="py-16 mt-8 px-28 bg-neutral-gray-100">
          <Grid title={translations.ofInterest} redLine={true} cols={3}>
            {node.ofInterest.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_BASIC }}
                key={content.id}
              />
            ))}
          </Grid>
        </div>
      )}
    </>
  )
}

export { AgendaPage }
