import { useRef, useState } from 'react'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Pause, PlayCircleIcon } from 'lucide-react'
import { Button } from 'ui'

import { DrupalMediaVideoDefaultProps } from './types'

const Default = ({ media }: DrupalMediaVideoDefaultProps) => {
  const [isPlaying, setIsPlaying] = useState(true)
  /* const [isMuted, setIsMuted] = useState(true) */

  const videoRef = useRef<HTMLVideoElement>(null)

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying)
    if (videoRef.current) {
      videoRef.current.paused
        ? videoRef.current.play()
        : videoRef.current.pause()
    }
  }

  /*   const toggleMuteUnmute = () => {
    setIsMuted(!isMuted)
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted
    }
  }
 */
  return (
    <>
      <video
        ref={videoRef}
        loop
        muted
        autoPlay
        className="absolute top-[35%] md:top-0 aspect-video md:h-[80vh] md:object-center md:object-cover"
        height="80vh"
        width="100%"
        title="Escenas de la ciudad de Madrid mostrando su vida urbana y diferentes paisajes"
      >
        <source src={media.video.uri.url} type="video/mp4" />
      </video>

      <div className="container absolute bottom-0 left-0 right-0 z-10 justify-center hidden w-full m-auto mb-6 md:mb-10 md:flex">
        <Button
          intent="tertiary"
          className="text-neutral-white"
          onClick={togglePlayPause}
          aria-label={FormattedMessage({ id: 'media.video.play' })}
        >
          {!isPlaying ? (
            <PlayCircleIcon width="2rem" height="2rem" />
          ) : (
            <Pause width="2rem" height="2rem" />
          )}
        </Button>
        {/*  <Button
          intent="tertiary"
          className="text-neutral-white"
          onClick={toggleMuteUnmute}
          aria-label={FormattedMessage({ id: 'media.video.muted' })}
        >
          {isMuted ? (
            <VolumeXIcon width="2rem" height="2rem" />
          ) : (
            <Volume2Icon width="2rem" height="2rem" />
          )}
        </Button> */}
      </div>
    </>
  )
}

export { Default }
