import { FormattedMessage } from '@/components/FormattedMessage'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { History } from 'lucide-react'
import { InfoTag, InfoTagBody, InfoTagHeader } from 'ui'

const Duration = ({ node }) => (
  <InfoTag>
    <InfoTagHeader>
      <History />
      <FormattedMessage id="global.duration" />
    </InfoTagHeader>
    <InfoTagBody>
      <FormattedRichMessage
        id="node.route.full.duration"
        values={{ time: node.duration }}
      />
    </InfoTagBody>
  </InfoTag>
)

export { Duration }
