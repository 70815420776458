import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { GRAN_TOUR_INDEX } from '@/components/List/constants'
import { useListProvider } from '@/components/List/ListProvider'
import { Title } from '@/components/Title'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Text } from 'ui'
import { DrupalFieldTextFormat } from 'utils-drupal'

import { ActiveFilterList } from '../../../ActiveFilterList'
import { SearchForm } from '../../../SearchForm'

const RoutesDefault = ({ type }: { type?: string }) => {
  const {
    state: { activeFilters, query },
    removeFilterFromList,
  } = useListProvider()

  const conditionalTranslationKeys = {
    summaryTitle: 'ciclaMadrid.granTourSearcher.summaryTitle',
    summarySubtitle: 'ciclaMadrid.granTourSearcher.summarySubtitle',
    summaryDescription: 'ciclaMadrid.granTourSearcher.summaryDescription',
    findYourAdventure: 'searcher.routes.findYourAdventure',
    textForFiltersInMobile: 'searcher.textForFiltersInMobile',
    searcherPlaceholder: 'searcher.routes.searcherPlaceholder',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return type === GRAN_TOUR_INDEX ? (
    <Title
      title={translations.summaryTitle}
      subtitle={
        {
          value: translations.summarySubtitle,
        } as DrupalFieldTextFormat
      }
      description={
        {
          value: translations.summaryDescription,
        } as DrupalFieldTextFormat
      }
    />
  ) : (
    <div className="flex flex-col gap-8 px-6 sm:px-0">
      <div className="flex flex-col items-center justify-between w-full lg:flex-row">
        <Title
          className="lg:pb-0"
          title={translations.findYourAdventure}
          subtitle={
            {
              value: translations.textForFiltersInMobile,
            } as DrupalFieldTextFormat
          }
        />
        <SearchForm placeholder={translations.searcherPlaceholder} />
      </div>
      {!!query && (
        <Text className="hidden text-lg font-semibold md:flex">
          <FormattedRichMessage
            id="searcher.activeQueryText"
            values={{ query }}
          />
        </Text>
      )}
      {!!activeFilters?.length && (
        <div className="hidden w-full md:flex">
          <ActiveFilterList
            filters={activeFilters}
            onDeleteFilter={removeFilterFromList}
          />
        </div>
      )}
    </div>
  )
}

export { RoutesDefault }
