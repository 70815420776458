import { FormattedMessage } from '@/components/FormattedMessage'
import { RadioGroupField } from '@/components/RoutesList/components/RadioGroupField'
import { ArrowDownUp } from 'lucide-react'
import { Button, Form, Popover, PopoverContent, PopoverTrigger } from 'ui'

import { useSortFilters } from './useSortFilter'

export const SortFilter = () => {
  const {
    state: { showSort, sortForm, activeFilterCount, sortFilter },
    actions: { deleteSortFilter, onSubmit, toogleFilters },
  } = useSortFilters()

  return (
    <Popover open={showSort}>
      <PopoverTrigger asChild>
        <Button
          onClick={() => {
            toogleFilters(false, !showSort)
          }}
          intent="link"
          className="flex gap-2 p-0 no-underline"
        >
          <ArrowDownUp />
          <FormattedMessage id="global.sort" />
          {!!activeFilterCount && (
            <span className="flex items-center justify-center w-8 h-8 font-semibold rounded-full bg-primary-red-900 text-neutral-white">
              {activeFilterCount}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-6 rounded-xl">
        <Form {...sortForm}>
          <form onSubmit={sortForm.handleSubmit(onSubmit)}>
            <RadioGroupField {...sortFilter} />
            <div className="flex items-center justify-end gap-10 mt-10">
              <Button
                type="reset"
                intent="secondary"
                className="border-2"
                onClick={deleteSortFilter}
              >
                <FormattedMessage id="global.delete" />
              </Button>
              <Button type="submit" intent="primary">
                <FormattedMessage id="global.apply" />
              </Button>
            </div>
          </form>
        </Form>
      </PopoverContent>
    </Popover>
  )
}
