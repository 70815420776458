import { ElementRef, forwardRef } from 'react'
import Link from 'next/link'
import { CKEditor } from '@/components/CKEditor'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Media } from '@/components/Media'
import {
  Button,
  Card,
  CardContent,
  CardImage,
  CardSubtitle,
  CardTitle,
  CloseButton,
  Heading,
} from 'ui'
import { cn } from 'utils-tailwindcss'

import { BREAKPOINTS } from '../../constants'
import { LocationCardProps } from '../../types'

const Megamenu = forwardRef<ElementRef<typeof Card>, LocationCardProps>(
  ({ className, description, image, title, link, onCloseClick }, ref) => (
    <Card
      ref={ref}
      className={cn(
        'h-fit max-w-[21rem] w-full md:min-w-fit border max-h-[31rem]',
        className
      )}
    >
      <CardContent>
        <CardTitle className="w-full pt-0 no-underline ">
          {title && (
            <div className="flex items-start justify-between mb-2">
              <Heading as="h3" className="text-lg font-bold max-w-[17.1875rem]">
                {title}
              </Heading>
              <CloseButton onClick={onCloseClick} />
            </div>
          )}
        </CardTitle>
        {image && (
          <CardImage className="self-center rounded h-fit w-fit">
            <div className="rounded w-[18.75rem]">
              <Media
                //@ts-ignore
                breakpoints={BREAKPOINTS}
                media={image}
              />
            </div>
          </CardImage>
        )}

        <CardSubtitle className="flex flex-col gap-6 py-3 ">
          <CKEditor
            text={description!}
            className="overflow-hidden text-xs font-normal text-text-medium line-clamp-3"
          />

          <Link className="no-underline" href={link || ''}>
            <Button intent="secondary">
              <FormattedMessage id="global.knowMore" />
            </Button>
          </Link>
        </CardSubtitle>
      </CardContent>
    </Card>
  )
)

Megamenu.displayName = 'Megamenu'

export { Megamenu }
