import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { BaseRouter } from 'next/dist/shared/lib/router/router'
import { useRouter } from 'next-translate-routes'
import { CalendarDateRange, closeHandler } from 'ui'
import { HookOutput, useWidth } from 'utils'

import { DATE_FILTER } from '../../constants'
import { useListProvider } from '../../ListProvider'
import { Filter } from '../../types'
import { formattedDateToStringLabel } from '../../utils/helpers'
import { dateFetch } from './services/api'

type useDatePickerResult = HookOutput<
  Pick<BaseRouter, 'locale'> & {
    date?: CalendarDateRange
    data?: number
    popoverRef: RefObject<closeHandler>
    isPending: boolean
    isDisabled: boolean
    showResults: boolean | undefined
    showCalendars: boolean
  },
  {
    resetDate: () => void
    setDate: Dispatch<SetStateAction<CalendarDateRange | undefined>>
    close: () => void
    numberOfMonths: () => number
    setIsOpen: Dispatch<SetStateAction<boolean>>
    mobileClose: () => void
    setShowCalendars: Dispatch<SetStateAction<boolean>>
  }
>

export const useDatePicker = (): useDatePickerResult => {
  const { width } = useWidth()
  const [date, setDate] = useState<CalendarDateRange | undefined>(undefined)
  const { locale } = useRouter()
  const popoverRef = useRef<closeHandler>(null)
  const notInitialRender = useRef(false)
  const [data, setData] = useState<number>(0)
  const [isPending, setIsPending] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [showCalendars, setShowCalendars] = useState<boolean>(!!date)

  const {
    state: { activeFilters, showResults, query },
    addFilters,
    removeFilter,
  } = useListProvider()

  const resetDate = () => {
    setDate(undefined)
  }

  const numberOfMonths = useCallback((): number => {
    return width >= 950 ? 2 : 1
  }, [width])

  const fetch = useCallback(async () => {
    setIsPending(true)
    dateFetch({
      per_page: 1,
      query,
      activeFilters: [
        ...(activeFilters?.filter((fil) => fil.type !== DATE_FILTER) ?? []),
        {
          values: {
            value: formattedDateToStringLabel(locale ?? 'es', date),
            label: formattedDateToStringLabel(locale ?? 'es', date),
          },
          date: date,
          type: DATE_FILTER,
        },
      ],
    }).then((response) => {
      setData(response ?? 0)
      setIsPending(false)
    })
  }, [date, activeFilters, locale, query])

  useEffect(() => {
    if (notInitialRender.current) {
      !!date && fetch()
    } else {
      notInitialRender.current = true
    }
  }, [date, fetch])

  const close = () => {
    if (!!date) {
      const filter: Filter = {
        values: {
          value: formattedDateToStringLabel(locale ?? 'es', date),
          label: formattedDateToStringLabel(locale ?? 'es', date),
        },
        date: date,
        type: DATE_FILTER,
      }
      addFilters(filter)
    } else {
      removeFilter(DATE_FILTER, true)
    }
    popoverRef.current?.close()
  }

  const mobileClose = useCallback(() => {
    setDate(undefined)
    removeFilter(DATE_FILTER, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsDisabled(!!!date)
    setShowCalendars(!!date)
  }, [date])

  useEffect(() => {
    const dateIndex = activeFilters?.findIndex(
      (fil) => fil.type === DATE_FILTER
    )

    const isNotThereDateInActiveFilters =
      date !== undefined && (dateIndex === undefined || dateIndex === -1)

    const ThereIsDateInActiveFiltersAndIsDifferentToSelectedDate =
      activeFilters !== undefined &&
      dateIndex !== undefined &&
      dateIndex !== -1 &&
      activeFilters[dateIndex].date !== undefined &&
      formattedDateToStringLabel(
        'es',
        activeFilters[dateIndex].date ?? undefined
      ) !== formattedDateToStringLabel('es', date)

    if (isNotThereDateInActiveFilters) {
      setDate(undefined)
    } else if (ThereIsDateInActiveFiltersAndIsDifferentToSelectedDate) {
      notInitialRender.current = false
      setDate(activeFilters[dateIndex].date ?? { from: new Date() })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, isOpen])

  return {
    state: {
      date,
      data,
      popoverRef,
      isPending,
      isDisabled,
      locale,
      showResults,
      showCalendars,
    },
    actions: {
      resetDate,
      setDate,
      close,
      numberOfMonths,
      setIsOpen,
      mobileClose,
      setShowCalendars,
    },
  }
}
