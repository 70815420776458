import { PictureProps } from './types'

const Picture = ({ children, sources }: PictureProps) => (
  <picture>
    {sources.map((source, index) => (
      <source key={index} {...source} />
    ))}
    {children}
  </picture>
)

export { Picture }
