import { Search, X } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { Button } from '../../../Button'
import { IconItemStyles } from './IconItem.styles'
import { IconItemsProps } from './types'

const IconItem = ({
  menuValue,
  value,
  onValueChange = () => {},
  className,
}: IconItemsProps) => (
  <li className={cn(IconItemStyles.Root(), className)}>
    {value !== menuValue ? (
      <Button
        intent="link"
        aria-label="Open search"
        onClick={() => {
          onValueChange(menuValue)
        }}
        className={cn([IconItemStyles.Search(), '!ml-2', 'xl:!ml-10'])}
        aria-haspopup="dialog"
      >
        <Search
          height={32}
          width={32}
          role="presentation"
          aria-hidden="true"
          stroke="white"
        />
      </Button>
    ) : (
      <Button
        intent="link"
        aria-label="Close search"
        onClick={() => {
          onValueChange('')
        }}
        className={cn(IconItemStyles.Close())}
      >
        <X
          height={32}
          width={32}
          role="presentation"
          aria-hidden="true"
          stroke="white"
        />
      </Button>
    )}
  </li>
)
export { IconItem }
