import { CardTeaser } from '@/components/CardTeaser'

import { DrupalNodePlaceTeaserProps } from './types'

const Teaser = ({ node, size }: DrupalNodePlaceTeaserProps) => (
  <CardTeaser
    title={node.title}
    image={node.computed_image}
    url={node.path?.alias}
    description={node.body}
    size={size}
  />
)

export { Teaser }
