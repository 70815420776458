import { ElementRef, forwardRef } from 'react'
import { CKEditor } from '@/components/CKEditor'
import { Media } from '@/components/Media'
import { MapPin } from 'lucide-react'
import { Card, CardContent, CardImage, CardSubtitle, CardTitle, Text } from 'ui'
import { cn } from 'utils-tailwindcss'

import { MOBILE_BREAKPOINTS } from '../../constants'
import { LocationCardProps } from '../../types'

const DynamicMap = forwardRef<ElementRef<typeof Card>, LocationCardProps>(
  ({ className, location, image, title, description }, ref) => {
    return (
      <Card
        ref={ref}
        className={cn('border max-h-[8rem] w-[17rem]', className)}
      >
        <CardContent className="flex flex-col w-full p-0">
          {title && (
            <CardTitle className="flex items-start justify-between w-full py-1 pl-3 pr-2 text-neutral-white bg-primary-red-900">
              <Text className="text-xs font-bold max-w-[17rem]">{title}</Text>
            </CardTitle>
          )}
          <div className="flex flex-row justify-between w-full px-1 pb-1">
            <CardSubtitle className="flex flex-col text-xs text-text-medium">
              {description && (
                <CKEditor text={description} className="line-clamp-2" />
              )}
              {location && (
                <div className="flex items-center gap-2 text-xs text-text-medium">
                  <MapPin size={16} className="text-primary-red-900" />
                  <Text>{location}</Text>
                </div>
              )}
            </CardSubtitle>
            <CardImage className="self-start rounded h-fit w-fit">
              <div className="rounded w-[5.065rem]">
                {!!image && (
                  <Media
                    //@ts-ignore
                    breakpoints={MOBILE_BREAKPOINTS}
                    media={image}
                  />
                )}
              </div>
            </CardImage>
          </div>
        </CardContent>
      </Card>
    )
  }
)

DynamicMap.displayName = 'Default'

export { DynamicMap }
