'use client'

import { ChevronRight } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { ItemStyles } from './Item.styles'
import { ItemProps } from './types'

const Item = ({
  activeItem,
  onItemClick,
  id,
  children,
  className,
  icon,
  chevron = true,
  ...rest
}: ItemProps) => (
  <button
    className={cn(ItemStyles.root({ active: id === activeItem }), className)}
    tabIndex={0}
    onClick={() => {
      if (onItemClick && chevron) {
        onItemClick(id)
      }
    }}
    {...rest}
  >
    <div className="flex items-center gap-3">
      {icon && (
        <span className="grid w-10 h-10 rounded place-content-center text-neutral-white bg-secondary-black-900">
          {icon}
        </span>
      )}

      <span className="font-semibold">{children}</span>
    </div>
    {chevron && (
      <ChevronRight
        className="w-4 h-4"
        role="presentation"
        aria-hidden="true"
      />
    )}
  </button>
)

Item.displayName = 'NavigationItem'

export { Item }
