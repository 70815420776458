import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Text } from 'ui'

import { FilterTags } from '../../../FilterTags'
import { HeaderProps } from './types'

const Header = ({ label, selected, showResults }: HeaderProps) => {
  const conditionalTranslationKeys = {
    placeholder: 'searcher.placeholder',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return showResults ? (
    label
  ) : selected.length > 0 ? (
    <FilterTags tags={selected?.map((item) => item.values.label!)} />
  ) : (
    <Text className="py-1">{translations.placeholder}</Text>
  )
}

export { Header }
