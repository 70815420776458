import * as SelectPrimitive from '@radix-ui/react-select'
import { cn } from 'utils-tailwindcss'

import { ItemStyles } from './Item.styles'
import { ItemProps } from './types'

export const Item = ({ value, id, className, ...rest }: ItemProps) => (
  <SelectPrimitive.Item
    value={id ?? value.toLowerCase()}
    className={cn(ItemStyles.root(), className)}
    {...rest}
  >
    <SelectPrimitive.ItemText>{value}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
)
