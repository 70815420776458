import { useMemo } from 'react'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { useListProvider } from '@/components/List/ListProvider'
import { FormField, FormItem } from 'ui'

import { ControlledSlider, FilterLabel } from '..'
import { DURATION_ROUTES_FILTER, FACETS_ICONS } from '../../constants'
import { SliderFilterProps } from './types'

export const SliderField = ({ id, label = '', min = 0 }: SliderFilterProps) => {
  const {
    state: { facets },
  } = useListProvider()

  const maxValue = useMemo(() => {
    const values: number[] = facets
      ?.find((facet) => facet.id === id)
      ?.terms.filter((term) => !!term.values.count)
      .map((term) => Math.round(parseFloat(term.values.value))) ?? [0]
    const maximo = Math.max(...values)
    return id === DURATION_ROUTES_FILTER ? maximo / 60 : maximo
  }, [facets, id])

  const unitText = id === DURATION_ROUTES_FILTER ? ':00' : ''
  const unit = id === DURATION_ROUTES_FILTER ? 'h' : 'km'

  return (
    <FormField
      name={id}
      defaultValue={[0, 0]}
      render={({ field }) => (
        <div className="w-full">
          <FormItem className="w-full">
            <div className="flex flex-col gap-4">
              {label && (
                <FilterLabel label={label}>{FACETS_ICONS[id]()}</FilterLabel>
              )}
              <div className="flex items-center gap-2 text-text-medium">
                <FormattedRichMessage
                  id="searcher.routes.rangeFiltersLabelText"
                  values={{
                    b: (chunks) => <b className="font-semibold">{chunks}</b>,
                    initial: `${field.value[0]}${unitText}`,
                    final: `${field.value[1]}${unitText}`,
                    unit: unit,
                  }}
                />
              </div>
              <ControlledSlider
                min={min}
                max={maxValue}
                value={field.value}
                onValueChange={field.onChange}
              />
            </div>
          </FormItem>
        </div>
      )}
    />
  )
}
