import { forwardRef } from 'react'
import { Slot } from '@radix-ui/react-slot'

import { ButtonStyles } from './Button.styles'
import { ButtonProps } from './types'

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return <Comp className={ButtonStyles({ className })} ref={ref} {...props} />
  }
)
Button.displayName = 'Button'

export { Button }
