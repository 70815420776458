import { Link } from '@/components/Link'
import { Paragraph } from '@/components/Paragraph/Paragraph'
import { Title } from '@/components/Title'
import {
  VIEW_MODE_CARD_TEASER,
  VIEW_MODE_CARD_TEASER_HORIZONTAL,
  VIEW_MODE_CARD_TEASER_LONG,
} from '@/content/constants'
import { internalLink } from '@/lib/utils/internalLink'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ArrowRight, ExternalLink } from 'lucide-react'
import { DrupalFieldTextFormat } from 'utils-drupal'

import { DrupalParagraphContent } from '../../types'
import { DrupalParagraphCardsHighlightProps } from './types'

const Highlight = ({ paragraph }: DrupalParagraphCardsHighlightProps) => {
  const INTERNAL_LINK = paragraph.link && internalLink(paragraph.link.uri)

  const conditionalTranslationKeys = {
    externalLink: 'global.externalLink',
    internalLink: 'global.internalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div className="my-18 bg-neutral-white">
      <div className="container">
        <Title
          title={paragraph.title}
          subtitle={
            typeof paragraph.subtitle !== 'string'
              ? paragraph.subtitle
              : ({ value: paragraph.subtitle } as DrupalFieldTextFormat)
          }
          description={
            typeof paragraph.body !== 'string'
              ? paragraph.body
              : ({ value: paragraph.body } as unknown as DrupalFieldTextFormat)
          }
        />
        {paragraph.paragraph_contents![0] && (
          <>
            <div className="hidden xl:block">
              <div className="grid grid-cols-2 grid-rows-3 pt-6 md:gap-6">
                <div className="grid row-start-1 row-end-4">
                  <Paragraph
                    paragraph={{
                      ...paragraph.paragraph_contents![0],
                      content_viewmode: VIEW_MODE_CARD_TEASER_LONG,
                      popup: paragraph.bool_popup,
                    }}
                  />
                </div>
                {paragraph
                  .paragraph_contents!.slice(1, 4)
                  .map((content: DrupalParagraphContent) => (
                    <Paragraph
                      key={content.id}
                      paragraph={{
                        ...content,
                        content_viewmode: VIEW_MODE_CARD_TEASER_HORIZONTAL,
                      }}
                    />
                  ))}
              </div>
              {paragraph.paragraph_contents!.length > 0 && (
                <div className="grid grid-cols-2 pt-12 md:gap-12">
                  {paragraph
                    .paragraph_contents!.slice(4)
                    .map((content: any) => (
                      <Paragraph
                        key={content.id}
                        paragraph={{
                          ...content,
                          content_viewmode: VIEW_MODE_CARD_TEASER_HORIZONTAL,
                        }}
                      />
                    ))}
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 gap-6 xl:hidden md:grid-cols-2">
              {paragraph.paragraph_contents?.map((content) => (
                <Paragraph
                  key={content.id}
                  paragraph={{
                    ...content,
                    content_viewmode: VIEW_MODE_CARD_TEASER,
                  }}
                />
              ))}
            </div>
          </>
        )}
      </div>
      {paragraph.link && (
        <div className="container mt-12 text-right md:mt-0">
          <Link
            target={INTERNAL_LINK ? '_self' : '_blank'}
            href={paragraph.link.uri}
            intent="link"
            className="font-semibold underline"
            aria-label={paragraph.link.title}
            aria-describedby={
              INTERNAL_LINK
                ? translations.internalLink
                : translations.externalLink
            }
          >
            {paragraph.link.title}
            {INTERNAL_LINK ? (
              <ArrowRight
                aria-roledescription="presentation"
                aria-hidden="true"
              />
            ) : (
              <ExternalLink
                aria-roledescription="presentation"
                aria-hidden="true"
              />
            )}
          </Link>
          {!INTERNAL_LINK ? (
            <p className="sr-only">{translations.newWindow}</p>
          ) : null}
        </div>
      )}
    </div>
  )
}
export { Highlight }
