import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { CalendarIcon } from 'lucide-react'
import { Calendar, FilterButton, Separator, Text } from 'ui'
import { FilterButtonLabel } from 'ui/src/components/molecules/FilterButton'
import { cn } from 'utils-tailwindcss'

import { Footer, Header } from './components'
import { useDatePicker } from './useDatePicker'

const DatePicker = () => {
  const {
    state: {
      date,
      data,
      popoverRef,
      isPending,
      isDisabled,
      locale,
      showResults,
    },
    actions: { resetDate, setDate, close, numberOfMonths, setIsOpen },
  } = useDatePicker()

  const conditionalTranslationKeys = {
    date: 'global.date',
    placeholder: 'searcher.placeholder',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div className={cn('flex flex-col', showResults ? '' : 'mb-4 lg:mb-0')}>
      {!showResults && (
        <Text className="mb-4 font-semibold">{translations.date}</Text>
      )}
      <FilterButton
        sideOffset={showResults ? 30 : 0}
        ref={popoverRef}
        onOpen={setIsOpen}
        icon={
          !showResults ? (
            <CalendarIcon role="presentation" aria-hidden="true" />
          ) : undefined
        }
      >
        <FilterButtonLabel>
          <Header showResults={showResults} date={date} locale={locale} />
        </FilterButtonLabel>
        <div
          className={cn(
            'w-full h-fit',
            numberOfMonths() === 1 && 'max-w-[700px] w-[80vw]'
          )}
        >
          <Calendar
            mode="range"
            onSelect={(date) => {
              setDate(date)
            }}
            selected={date}
            className="w-full h-full border-0 shadow-transparent"
            numberOfMonths={numberOfMonths()}
            rounded={false}
            border={false}
          />
          <Separator color="gray" className="mt-0" />
          <Footer
            close={close}
            isPending={isPending}
            reset={resetDate}
            dataLength={data}
            date={date}
            numberOfMonths={numberOfMonths()}
            isDisabled={isDisabled}
          />
        </div>
      </FilterButton>
    </div>
  )
}

export { DatePicker }
