import { useState } from 'react'
import { FormattedMessage } from '@/components/FormattedMessage'
import { formattedDateToStringLabel } from '@/components/List/utils/helpers'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { CalendarIcon, ChevronLeftIcon } from 'lucide-react'
import { Button, Calendar, Checkbox, Text } from 'ui'
import { cn } from 'utils-tailwindcss'

import { useDatePicker } from '../../useDatePicker'

const MobileDatePicker = () => {
  const {
    state: { date, isDisabled, locale, showCalendars },
    actions: { setDate, close, mobileClose, setShowCalendars },
  } = useDatePicker()
  const [openCalendarModal, setOpenCalendarModal] = useState<boolean>(false)

  const conditionalTranslationKeys = {
    from: 'global.from',
    select: 'global.select',
    to: 'global.to',
    back: 'global.back',
    dates: 'global.dates',
    when: 'global.when',
    mon: 'global.mon',
    tue: 'global.tue',
    wed: 'global.wed',
    thu: 'global.thu',
    fri: 'global.fri',
    sat: 'global.sat',
    sun: 'global.sun',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <div className="flex flex-col mt-6 ml-2 space-y-4">
        <div className="flex items-center gap-4">
          <Checkbox
            id={'flexible'}
            checked={!showCalendars}
            onCheckedChange={(checked) => {
              checked && setShowCalendars(false)
              checked && mobileClose()
            }}
          />
          <label className={cn('text-text-medium')} htmlFor={'flexible'}>
            <FormattedMessage id="searcher.flexible" />
          </label>
        </div>
        <div className="flex items-center gap-4">
          <Checkbox
            id={'accurate'}
            checked={showCalendars}
            onCheckedChange={(checked) => setShowCalendars(!!checked)}
          />
          <label className={cn('text-text-medium')} htmlFor={'accurate'}>
            <FormattedMessage id="searcher.accurate" />
          </label>
        </div>
        {showCalendars && (
          <div className="flex flex-col w-min">
            <div className="flex flex-row items-center">
              {translations.from}
              <Button
                intent={'secondary'}
                type="button"
                className="ml-3 font-normal truncate bg-neutral-gray-100 outline-neutral-gray-400 "
                onClick={() => setOpenCalendarModal(true)}
              >
                <CalendarIcon />
                {date?.from
                  ? formattedDateToStringLabel(locale ?? 'es', {
                      ...date,
                      to: undefined,
                    })
                  : translations.select}
              </Button>
            </div>
            <div className="flex flex-row items-center mt-4">
              {translations.to}
              <Button
                intent={'secondary'}
                fullWidth
                type="button"
                className="ml-3 font-normal truncate bg-neutral-gray-100 outline-neutral-gray-400"
                onClick={() => setOpenCalendarModal(true)}
              >
                <CalendarIcon />
                {date?.to
                  ? formattedDateToStringLabel(locale ?? 'es', {
                      from: date.to,
                      to: undefined,
                    })
                  : translations.select}
              </Button>
            </div>
          </div>
        )}
      </div>
      {openCalendarModal && (
        <div className="absolute right-0 z-50 w-full h-screen top-10 bg-neutral-white">
          <div className="fixed top-0 z-50 flex flex-row w-full p-3 bg-primary-red-900 text-neutral-white">
            <div className="flex flex-row items-center w-fit">
              <ChevronLeftIcon />
              <Button
                intent={'link'}
                type="button"
                onClick={() => setOpenCalendarModal(false)}
                className="pl-2 text-neutral-white"
              >
                {translations.back}
              </Button>
            </div>
            <Text className="m-auto text-lg font-semibold">
              {translations.dates}
            </Text>
          </div>

          <div className="w-full p-6 overflow-auto h-[calc(100vh-172px)]">
            <Text className="pb-6 mt-16 text-lg font-semibold text-text-dark">
              {translations.when}
            </Text>
            <div className="flex flex-row justify-between px-6 pt-6">
              <Text className="font-bold text-neutral-gray-500">
                {translations.mon}
              </Text>
              <Text className="font-bold text-neutral-gray-500">
                {translations.tue}
              </Text>
              <Text className="font-bold text-neutral-gray-500">
                {translations.wed}
              </Text>
              <Text className="font-bold text-neutral-gray-500">
                {translations.thu}
              </Text>
              <Text className="font-bold text-neutral-gray-500">
                {translations.fri}
              </Text>
              <Text className="font-bold text-neutral-gray-500">
                {translations.sat}
              </Text>
              <Text className="font-bold text-neutral-gray-500">
                {translations.sun}
              </Text>
            </div>
            <Calendar
              hideHead
              showOutsideDays
              mode="range"
              onSelect={(date) => {
                setDate(date)
              }}
              selected={date}
              className="w-full border-0 shadow-transparent"
              rounded={false}
              border={false}
              numberOfMonths={2}
            />
          </div>
          <div className="fixed bottom-0 flex flex-col w-full p-6 text-neutral-white">
            <Button
              intent={'secondary'}
              type="button"
              isDisabled={isDisabled}
              fullWidth
              onClick={() => {
                setOpenCalendarModal(false)
                mobileClose()
              }}
            >
              <FormattedMessage id="global.deleteFilters" />
            </Button>
            <Button
              intent={'primary'}
              fullWidth
              type="button"
              isDisabled={isDisabled}
              onClick={() => {
                setOpenCalendarModal(false)
                close()
              }}
              className="mt-6"
            >
              <FormattedMessage id="global.apply" />
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
export { MobileDatePicker }
