import Link from 'next/link'
import { FacebookIcon, InstagramIcon, YoutubeIcon } from 'lucide-react'

import { TwitterIcon } from './components'

const SocialNetworks = () => (
  <ul className="flex gap-6 pb-3 mt-1">
    <li className="p-2 rounded-full bg-neutral-white">
      <Link href="https://www.facebook.com/TurismoMadrid" aria-label="Facebook">
        <FacebookIcon className="text-text-dark" />
      </Link>
    </li>
    <li className="p-2 rounded-full bg-neutral-white">
      <Link
        href="https://www.instagram.com/turismocmadrid/"
        aria-label="Instagram"
      >
        <InstagramIcon className="text-text-dark" />
      </Link>
    </li>

    <li className="p-2 rounded-full bg-neutral-white">
      {/**
       * @TODO
       * Nombre de la red twitter o X ???
       */}
      <Link href="https://twitter.com/Turismomadrid" aria-label="X">
        <TwitterIcon className="w-6 h-6" />
      </Link>
    </li>
    <li className="p-2 rounded-full bg-neutral-white">
      <Link
        href="http://www.youtube.com/user/turismomadrid"
        aria-label="YouTube"
      >
        <YoutubeIcon className="text-text-dark" />
      </Link>
    </li>
  </ul>
)

export { SocialNetworks }
