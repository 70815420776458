import Link from 'next/link'
import { Card as CardUI } from 'ui'

import { Content } from './components'
import { CardVerticalBasicProps } from './types'

const CardVerticalBasic = ({
  title,
  image,
  url,
  type,
  popup = false,
  description,
}: CardVerticalBasicProps) => (
  <CardUI className="h-full bg-transparent focus:border-2 ">
    {!url || type === 'node--establishment' || popup ? (
      <Content
        title={title}
        image={image}
        popup={popup}
        description={description}
      />
    ) : (
      <Link
        href={url}
        className="flex flex-col h-full no-underline hover:underline focus:border-2 focus:border-secondary-black-900 rounded-md focus:p-[2px]"
      >
        <Content title={title} image={image} />
      </Link>
    )}
  </CardUI>
)

export { CardVerticalBasic }
