import {
  SCALE_WEBP_600x150,
  SCALE_WEBP_600x400,
  SCALE_WEBP_768x1024,
} from '@/content/constants'

import { ImageStyleBreakPoints } from '../ImageStyle'

export const BREAKPOINTS_SMALL: ImageStyleBreakPoints = {
  default: {
    id: SCALE_WEBP_600x400,
  },
}

export const BREAKPOINTS_HORIZONTAL: ImageStyleBreakPoints = {
  default: {
    id: SCALE_WEBP_600x150,
  },
}

export const BREAKPOINTS_LONG: ImageStyleBreakPoints = {
  default: {
    id: SCALE_WEBP_768x1024,
  },
}

export const BREAKPOINT_CAROUSEL_3COL: ImageStyleBreakPoints = {
  default: {
    id: SCALE_WEBP_600x400,
  },
}

export const BREAKPOINTS = {
  horizontal: BREAKPOINTS_HORIZONTAL,
  long: BREAKPOINTS_LONG,
  carousel_3col: BREAKPOINT_CAROUSEL_3COL,
}
