// eslint-disable-next-line unused-imports/no-unused-vars
enum SubMenuItems {
  // eslint-disable-next-line unused-imports/no-unused-vars
  DEFAULT = 'default',
  // eslint-disable-next-line unused-imports/no-unused-vars
  WHAT = 'what',
  // eslint-disable-next-line unused-imports/no-unused-vars
  WHERE = 'where',
  // eslint-disable-next-line unused-imports/no-unused-vars
  PLAN = 'plan',
}

export { SubMenuItems }
