import { cva } from 'class-variance-authority'

export const SpinnerStyles = cva(
  'border-2 rounded-full border-t-transparent border-r-transparent animate-[spin_500ms_linear_infinite]',
  {
    variants: {
      size: {
        xs: 'w-2 h-2',
        sm: 'w-3 h-3',
        md: 'w-4 h-4',
        lg: 'w-10 h-10',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
)
