import { FormattedMessage } from '@/components/FormattedMessage'
import { Calendar } from 'lucide-react'
import { InfoTag, InfoTagBody, InfoTagHeader } from 'ui'

import { DrupalNodeEvent } from '../../../../types'
import { generateDateFormatted } from './helpers'

const DateTag = ({ node }: { node: DrupalNodeEvent }) => {
  const formattedDate: string[] = generateDateFormatted(node.occurrences)
  return (
    <InfoTag>
      <InfoTagHeader>
        <Calendar />
        <FormattedMessage id="global.date" />
      </InfoTagHeader>
      <InfoTagBody className="flex w-max">
        {formattedDate.map((date, index) => (
          <div key={index} className="">
            <span>{date}</span>
            {formattedDate.length > 1 && index < formattedDate.length - 1 && (
              <span className="mx-1">-</span>
            )}
          </div>
        ))}
      </InfoTagBody>
    </InfoTag>
  )
}

export { DateTag }
