export const TOURIST_POINT_FIELDS = [
  'path',
  'title',
  'term_category',
  'text_phone',
  'geofield',
  'int_zipcode',
  'list',
  'text_long_schedule',
  'link',
  'body',
  'body_a11y',
  'text_flong_additional_info',
  'text_social',
  'bool_deleted',
  'media',
  'metatags',
  'moderation_state',
  'status',
  'computed_image',
]
export const TOURIST_POINT_CARD_FIELDS = [
  'geofield_address',
  'status',
  'type',
  'path',
  'title',
  'term_municipality',
  'term_category',
  'term_programs',
  'computed_image',
  'gmaps_url',
  'body',
]
export const TOURIST_POINT_ROUTE_FIELDS = [
  'geofield_address',
  'status',
  'type',
  'path',
  'title',
  'text_address',
  'body',
  'computed_image',
  'gmaps_url',
]
export const TOURIST_POINT_CARD_INCLUDES = [
  'term_municipality',
  'term_category',
  'term_programs',
  'computed_image',
  'computed_image.image',
]

export const TOURIST_POINT_INCLUDES = ['term_category', 'computed_image']
