import { State } from './types'

export const ACTION_SET_QUERY = 'SET_QUERY'
export const ACTION_SET_PAGE = 'SET_PAGE'
export const ACTION_SET_STATE = 'SET_STATE'
export const ACTION_SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS'
export const ACTION_SET_RESULTS = 'SET_RESULTS'
export const ACTION_SET_TOTAL_ITEMS = 'SET_TOTAL_ITEMS'
export const ACTION_SET_IS_LOADING = 'SET_IS_LOADING'

export const CATEGORIES_TYPE = 'events_categories'

export const GENERAL_INDEX = 'general_search'
export const CICLA_MADRID_INDEX = 'cicla-madrid'
export const GRAN_TOUR_INDEX = 'gran-tour'
export const DATE_FILTER = 'date'
export const GENERAL_TYPE = 'general_type'
export const EVENTS = 'Eventos'
export const EXHIBITIONS = 'Exposiciones'

export const INITIAL_STATE: State = {
  page: 1,
  per_page: 24,
  isLoading: false,
  showResults: true,
  tabs: EVENTS,
}

export const translations = {
  es: {
    landing: 'Pagina general',
    route: 'Ruta',
    place: 'Lugar',
    event: 'Evento',
    tourist_plan: 'Plan turístico',
  },
  en: {
    landing: 'General page',
    route: 'Route',
    place: 'Place',
    event: 'Event',
    tourist_plan: 'Tourist plan',
  },
  fr: {
    landing: 'Page générale',
    route: 'Route',
    place: 'Lieu',
    event: 'Événement',
    tourist_plan: 'Plan touristique',
  },
  de: {
    landing: 'Allgemeine Seite',
    route: 'Route',
    place: 'Ort',
    event: 'Ereignis',
    tourist_plan: 'Touristischer Plan',
  },
  'zh-hans': {
    landing: '常规页面',
    route: '路线',
    place: '地方',
    event: '事件',
    tourist_plan: '旅游计划',
  },
  ja: {
    landing: '一般ページ',
    route: 'ルート',
    place: '場所',
    event: 'イベント',
    tourist_plan: '観光計画',
  },
  it: {
    landing: 'Pagina generale',
    route: 'Percorso',
    place: 'Luogo',
    event: 'Evento',
    tourist_plan: 'Piano turistico',
  },
  'pt-pt': {
    landing: 'Página geral',
    route: 'Rota',
    place: 'Lugar',
    event: 'Evento',
    tourist_plan: 'Plano turístico',
  },
  ko: {
    landing: '일반 페이지',
    route: '경로',
    place: '장소',
    event: '이벤트',
    tourist_plan: '관광 계획',
  },
}
