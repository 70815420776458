'use client'

import { ElementRef, forwardRef } from 'react'
import { Root } from '@radix-ui/react-radio-group'
import { cn } from 'utils-tailwindcss'

import { RadioGroupStyles } from './RadioGroup.styles'
import { RadioGroupProps } from './types'

const RadioGroup = forwardRef<ElementRef<typeof Root>, RadioGroupProps>(
  ({ children, className, ...props }, ref) => (
    <Root className={cn(RadioGroupStyles(), className)} ref={ref} {...props}>
      {children}
    </Root>
  )
)

RadioGroup.displayName = Root.displayName

export { RadioGroup }
