export const getDrupalFunction = async (server, paramsServer, paramsClient) => {
  try {
    if (typeof window === 'undefined') {
      let drupalFunction

      const url = await require('./getDrupalUrl')
      const index = await require('./getIndexDrupal')
      const tax = await require('./getDrupalTaxonomies')
      const des = await require('./getDrupalDeserialize')

      const drupalFunctionConst = {
        getDrupalUrl: url.getDrupalUrl,
        getIndexDrupal: index.getIndexDrupal,
        getDrupalDeserialize: des.getDrupalDeserialize,
        getDrupalTaxonomies: tax.getDrupalTaxonomies,
      }

      drupalFunction = drupalFunctionConst[server]

      return drupalFunction(paramsServer)
    } else {
      return await fetch(paramsClient.url, {
        body: paramsClient.body,
        method: 'POST',
      }).then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw new Error(
          `Error in response. DrupalFunction: ${paramsClient.url}, ${res.status}`
        )
      })
    }
  } catch (error) {
    console.error('Error en getDrupalFunction: ', error)
    return error
  }
}
