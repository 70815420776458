import { cva } from 'class-variance-authority'

export const LabelStyles = cva(
  [
    'text-md font-normal leading-none',
    'peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  ],
  {
    variants: {
      isRequired: {
        true: "after:content-['*'] after:text-red-600",
      },
      disabled: {
        true: ['opacity-50 pointer-events-none'],
      },
    },
  }
)
