import { useCallback, useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'
import { DrupalNode } from 'next-drupal'
import { useRouter } from 'next-translate-routes'
import { HookOutput } from 'utils'

import { LANGUAGES } from './components/Header/components/LanguageMenu/components/Dropdown/constants'

export type Language = {
  id: string
  icon: () => JSX.Element
  name: string
  href: string
}

type usePublicLayoutResult = HookOutput<
  {
    languages: Language[]
  },
  {}
>

export const usePublicLayout = (entity?: DrupalNode): usePublicLayoutResult => {
  const [languages, setLanguages] = useState<Language[]>([])
  const pathName = usePathname()

  const { locale } = useRouter()

  const getUrl = useCallback(
    async (locale) => {
      if (entity && pathName !== '/') {
        const path = await fetch('/api/get-resource-path', {
          body: JSON.stringify({
            locale: locale,
            defaultLocale: 'es',
            id: entity?.id,
            type: entity.type,
          }),
          method: 'POST',
        }).then((res) => res.json())

        return path
      } else {
        return pathName === '/' ? '/home' : pathName
      }
    },
    [entity, pathName]
  )

  const getLanguagesPath = useCallback(async () => {
    const languagesWithPath: Language[] = []

    const path = await getUrl(locale)

    for (const language of LANGUAGES) {
      languagesWithPath.push({ ...language, href: path[language.id] })
    }

    setLanguages(languagesWithPath)
  }, [getUrl])

  useEffect(() => {
    getLanguagesPath()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  return {
    state: {
      languages,
    },
    actions: {},
  }
}
