import { useState } from 'react'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { MapIcon, MenuIcon } from 'lucide-react'
import { Button } from 'ui'

import { Map } from '../../components'
import { useMapProvider } from '../../MapProvider'
import { List } from './components/List'

export const Mobile = () => {
  const {
    state: { touristicPoints },
  } = useMapProvider()
  const [list, setList] = useState(true)

  const conditionalTranslationKeys = {
    seeList: 'villageMap.seeList',
    seeMap: 'villageMap.seeMap',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    touristicPoints?.length && (
      <>
        <Button
          className="fixed z-10 bottom-24 right-4"
          onClick={() => setList(!list)}
        >
          {list ? (
            <>
              <MenuIcon />
              {translations.seeList}
            </>
          ) : (
            <>
              <MapIcon />
              {translations.seeMap}
            </>
          )}
        </Button>
        {!list ? <List /> : <Map />}
      </>
    )
  )
}
