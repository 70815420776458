import { FormattedMessage } from '@/components/FormattedMessage'
import { MobileFilterList } from '@/components/List/components'

import { useFilters } from './useFilters'

export const Filters = () => {
  const {
    state: { form, showFilters, facets, activeFilters, total, isPending },
    actions: {
      onSubmitFilter,
      setShowFilters,
      onResetForm,
      removeFilterFromList,
      fetch,
    },
  } = useFilters()

  return (
    <MobileFilterList
      filters={facets}
      activeFilters={activeFilters || []}
      onSubmit={form.handleSubmit(onSubmitFilter)}
      onCancel={onResetForm}
      showModal={showFilters}
      onOpenChange={setShowFilters}
      form={form}
      onFormChage={fetch}
      onDeleteActiveFilter={(filter) => {
        removeFilterFromList(filter)
        setShowFilters(false)
      }}
      onSubmitText={FormattedMessage({
        id: 'searcher.showTotalResults',
        values: { total: total },
      })}
      isLoading={isPending}
    />
  )
}
