import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Trash2 } from 'lucide-react'
import { Button, CloseButton, Tag, Text } from 'ui'

import { Filter } from '../../types'
import { FilterTagListProps } from './types'

export const ActiveFilterList = ({
  filters,
  onDeleteFilter,
}: FilterTagListProps) => {
  const removeFilter = (filter: Filter) => {
    const newFilters = filters?.filter(
      (item: Filter) => item.values.value !== filter.values.value
    )
    onDeleteFilter(newFilters?.length ? newFilters : undefined)
  }

  const conditionalTranslationKeys = {
    appliedFilters: 'global.appliedFilters',
    deleteFilters: 'global.deleteFilters',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    filters?.length! > 0 && (
      <div className="flex flex-wrap items-center gap-y-3 gap-x-3">
        <Text className="flex flex-wrap self-start pt-2 mr-6 w-fit">
          {translations.appliedFilters} :
        </Text>

        {filters?.map((filter: Filter) => (
          <Tag
            key={filter.values.value}
            className="flex w-auto gap-4 px-4 py-2"
          >
            {filter?.values.label}
            <CloseButton onClick={() => removeFilter(filter)} />
          </Tag>
        ))}

        {filters?.length! > 1 && (
          <Button
            className="flex flex-wrap items-center gap-2 ml-2 no-underline"
            intent="link"
            onClick={() => onDeleteFilter(undefined)}
          >
            <Trash2 role="presentation" aria-hidden="true" />
            {translations.deleteFilters}
          </Button>
        )}
      </div>
    )
  )
}
