'use client'

import { ElementRef, forwardRef } from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cn } from 'utils-tailwindcss'

import { LabelStyles } from './Label.styles'
import { LabelProps } from './types'

const Label = forwardRef<ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, isRequired = false, disabled = false, ...props }, ref) => (
    <LabelPrimitive.Root
      ref={ref}
      className={cn(LabelStyles({ isRequired, disabled }), className)}
      {...props}
    />
  )
)

Label.displayName = LabelPrimitive.Root.displayName

export { Label }
