import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { BundleDrupalNode } from '@/components/Node/types'
import { useRouter } from 'next-translate-routes'
import { HookOutput } from 'utils'
import { DrupalMenuLinkContents } from 'utils-drupal'

type UseWhatToDoResult = HookOutput<
  {
    selected: string
    categorySelected?: DrupalMenuLinkContents
    isLoading: boolean
    itemsSelected?: BundleDrupalNode[]
  },
  {
    setSelected: Dispatch<SetStateAction<string>>
    setIsLoading: Dispatch<SetStateAction<boolean>>
    setItemsSelected: Dispatch<SetStateAction<BundleDrupalNode[] | undefined>>
  }
>

export const useWhatToDo = (
  initialCategories: DrupalMenuLinkContents[],
  title: string,
  id: string,
  isMobile: boolean
): UseWhatToDoResult => {
  const [selected, setSelected] = useState('')
  const { locale } = useRouter()
  const [categorySelected, setCategorySelected] = useState<
    DrupalMenuLinkContents | undefined
  >({
    id: id,
    title: title,
    items: initialCategories,
  } as DrupalMenuLinkContents)
  const [isLoading, setIsLoading] = useState(false)
  const [itemsSelected, setItemsSelected] = useState<
    BundleDrupalNode[] | undefined
  >(undefined)

  useEffect(() => {
    if (selected !== '') {
      const category =
        initialCategories.find((cat) => cat.id == selected) ??
        categorySelected?.items?.find((cat) => cat.id == selected)
      if (isMobile) {
        category?.items?.filter((item) => item.promoted).length &&
          setCategorySelected(category)
      } else {
        setCategorySelected(category)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCategories, selected, isMobile])

  useEffect(() => {
    if (categorySelected?.title !== title) {
      setCategorySelected({
        id: id,
        title: title,
        items: initialCategories,
      } as DrupalMenuLinkContents)
      setSelected('')
      setItemsSelected(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, initialCategories])

  const getMenuItems = async (id: string) => {
    setIsLoading(true)
    const items = await fetch('/api/get-menu-items', {
      method: 'POST',
      body: JSON.stringify({ id: id, locale: locale }),
    }).then((res) => res.json())
    setItemsSelected(items.map((item) => item.content))
  }

  useEffect(() => {
    categorySelected && getMenuItems(categorySelected?.id)
  }, [categorySelected])

  useEffect(() => {
    itemsSelected && setIsLoading(false)
  }, [itemsSelected])

  return {
    state: { selected, categorySelected, isLoading, itemsSelected },
    actions: { setSelected, setIsLoading, setItemsSelected },
  }
}
