import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { Label } from 'ui'

import { SearchFeaturedContentProps } from './types'

const SearchFeaturedContent = ({
  subqueue,
  viewMode = VIEW_MODE_DEFAULT,
}: SearchFeaturedContentProps) => (
  <div className={`${subqueue.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        default:
          return <Label>{subqueue.name}</Label>
      }
    })()}
  </div>
)

export { SearchFeaturedContent }
