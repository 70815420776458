import { Node } from '@/components/Node'
import { contentTouristPointType } from '@/components/Node/components/Route/types'
import { VIEW_MODE_CARD_LOCATION } from '@/content/constants'
import { Card, CardContent, CardTitle, CloseButton, Text } from 'ui'

export const LocationCard = ({
  touristicPoint,
  onCloseClick,
}: {
  onCloseClick?: () => void
  touristicPoint: contentTouristPointType
}) => (
  <Card className="border w-[21rem] max-h-[32rem] overflow-y-auto custom-scroll">
    <CardContent>
      <CardTitle className="w-full pt-0 no-underline">
        <div className="flex items-start justify-between mb-2">
          <Text className="text-lg font-bold max-w-[17.1875rem]">
            {touristicPoint.title}
          </Text>
          {onCloseClick && <CloseButton onClick={onCloseClick} />}
        </div>
      </CardTitle>
      <Node
        node={{
          ...touristicPoint,
          viewMode: VIEW_MODE_CARD_LOCATION,
        }}
      />
    </CardContent>
  </Card>
)
