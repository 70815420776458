import { CardActivity } from '@/components/CardActivity'

import { DrupalNodeActivityProps } from './types'

const Activity = ({ node, size }: DrupalNodeActivityProps) => (
  <CardActivity
    title={node.title}
    image={node.computed_image}
    url={node.path?.alias}
    size={size}
  />
)

export { Activity }
