import { forwardRef, HTMLAttributes, useId } from 'react'
import { cn } from 'utils-tailwindcss'

import { FormItemContext } from '../../useFormField'
import { ItemStyles } from './Item.styles'

const Item = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const id = useId()

    return (
      <FormItemContext.Provider value={{ id }}>
        <div ref={ref} className={cn(ItemStyles())} {...props} />
      </FormItemContext.Provider>
    )
  }
)

Item.displayName = 'Item'

export { Item }
