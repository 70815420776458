import { MOCK_FILE_DERIVATIVE } from '@/components/CardRoute/mock'
import { CardImage, CardTitle } from 'ui'

import { Media } from '../../Media'
import { BREAKPOINTS } from '../constants'
import { CardBasicContentProps } from '../types'

const Content = ({ title, image }: CardBasicContentProps) => (
  <>
    {image && (
      <CardImage className="w-[9rem]">
        <Media
          media={image || MOCK_FILE_DERIVATIVE}
          // @ts-ignore
          breakpoints={BREAKPOINTS}
        />
      </CardImage>
    )}
    <CardTitle className="flex h-full pt-1">
      <span className="self-start mb-0 text-sm font-bold line-clamp-2">
        {title}
      </span>
    </CardTitle>
  </>
)

export { Content }
