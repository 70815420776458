import { useState } from 'react'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { Button } from 'ui'
import { DrupalFieldTextFormat, DrupalFieldTextLongFormat } from 'utils-drupal'
import { cn } from 'utils-tailwindcss'

import { CKEditor } from '../CKEditor'

const ShowMoreCkEditor = ({
  description,
  className,
}: {
  description?: DrupalFieldTextFormat | DrupalFieldTextLongFormat
  className?: string
}) => {
  const [showMore, setShowMore] = useState(false)

  const conditionalTranslationKeys = {
    showMore: 'global.showMore',
    showLess: 'global.showLess',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )
  return (
    <>
      {description && (
        <div className={className}>
          <CKEditor
            className={cn(
              ` ${
                !showMore
                  ? 'line-clamp-6 md:line-clamp-[15] lg:line-clamp-none bg-gradient-to-b from-text-dark to-neutral-gray-300 bg-clip-text text-[transparent] lg:text-text-dark '
                  : 'line-clamp-none'
              }`
            )}
            text={description}
          />
          <div className="block lg:hidden relative">
            <Button
              intent="tertiary"
              className="px-2 mb-8 mt-2 "
              onClick={() => setShowMore(!showMore)}
            >
              {!showMore ? (
                <>
                  <ChevronDown />
                  {translations.showMore}
                </>
              ) : (
                <>
                  <ChevronUp />
                  {translations.showLess}
                </>
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export { ShowMoreCkEditor }
