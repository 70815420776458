import { AttachedDocument } from '@/components/AttachedDocument'
import { CKEditor } from '@/components/CKEditor'
import { Link } from '@/components/Link'
import { internalLink } from '@/lib/utils/internalLink'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { GradientImage, Heading } from 'ui'

import { DrupalParagraphImgCustomTextProps } from '../../types'

const Default = ({
  paragraph,
}: {
  paragraph: DrupalParagraphImgCustomTextProps
}) => {
  const TEMPORAL_BACKGROUND_IMAGE =
    paragraph.image.image?.resourceIdObjMeta?.imageDerivatives?.links
      .scale_webp_1280x720?.href

  const INTERNAL_LINK = paragraph.link && internalLink(paragraph.link.uri)

  const conditionalTranslationKeys = {
    externalLink: 'global.externalLink',
    internalLink: 'global.internalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div className="my-16">
      <GradientImage
        variant="noBackground"
        image={TEMPORAL_BACKGROUND_IMAGE}
        className="min-h-[396px]"
      >
        <div className="container min-h-[396px] py-16 flex flex-col gap-8 items-start justify-end bg-neutral-gray-400/40 overflow-hidden">
          <Heading className="text-neutral-white">{paragraph.title}</Heading>
          <div className="text-neutral-white">
            <CKEditor text={paragraph.body} />
          </div>
          {paragraph.link && (
            <>
              <Link
                href={paragraph.link?.uri}
                target={INTERNAL_LINK ? '_self' : '_blank'}
                className="bg-neutral-white text-secondary-black-900 "
                aria-label={paragraph.link?.title}
                aria-describedby={
                  INTERNAL_LINK
                    ? translations.internalLink
                    : translations.externalLink
                }
              >
                {!!paragraph.link?.title
                  ? paragraph.link?.title
                  : paragraph.link?.uri}
              </Link>
              {!INTERNAL_LINK ? (
                <p className="sr-only">{translations.newWindow}</p>
              ) : null}
            </>
          )}
        </div>
      </GradientImage>
      {!!paragraph.documents?.length && (
        <div className="container mt-10">
          <AttachedDocument documents={paragraph.documents} />
        </div>
      )}
    </div>
  )
}

export { Default }
