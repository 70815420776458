import { Carousel } from '@/components/Carousel'
import { Grid } from '@/components/Grid'
import { GRAN_TOUR_INDEX } from '@/components/List/constants'
import { Node } from '@/components/Node/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { RoutesList } from '@/components/RoutesList'
import {
  NEXT_PAGE_CICLA_MADRID_SEARCHER,
  VIEW_MODE_CARD_BASIC,
  VIEW_MODE_CARD_ROUTE,
} from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Link } from 'next-translate-routes'

import { DrupalNodeNextGranTourPageProps } from './types'

const GranTourPage = ({ node }: DrupalNodeNextGranTourPageProps) => {
  const conditionalTranslationKeys = {
    highlightsStages: 'ciclaMadrid.granTourSearcher.highlightsStages',
    highlightsStagesSubtitle:
      'ciclaMadrid.granTourSearcher.highlightsStagesSubtitle',
    knowAllRoutes: 'ciclaMadrid.granTourSearcher.knowAllRoutes',
    knowAllRoutesSubtitle: 'ciclaMadrid.granTourSearcher.knowAllRoutesSubtitle',
    knowAllRoutesDescription:
      'ciclaMadrid.granTourSearcher.knowAllRoutesDescription',
    mapExplore: 'searcher.routes.mapExplore',
    ofInterest: 'node.ofInterest',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <div className="container">
        <RoutesList
          initialState={{ ...node.response, per_page: 50 }}
          type={GRAN_TOUR_INDEX}
        />
      </div>
      {!!node.highlightsRoutes?.length && (
        <div className="py-16 mt-24 bg-neutral-gray-100">
          <Grid
            title={translations.highlightsStages}
            subtitle={{
              format: '',
              summary: '',
              processed: '',
              value: translations.highlightsStagesSubtitle,
            }}
            redLine={true}
            cols={3}
          >
            {node.highlightsRoutes.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_ROUTE }}
                key={content.id}
              />
            ))}
          </Grid>
        </div>
      )}
      {!!node.allRoutes?.length && (
        <div className="custom-container">
          <Carousel
            title={translations.knowAllRoutes}
            subtitle={{
              format: '',
              summary: '',
              processed: '',
              value: translations.knowAllRoutesSubtitle,
            }}
            body={{
              format: '',
              summary: '',
              processed: '',
              value: translations.knowAllRoutesDescription,
            }}
            link={
              <div className="container flex justify-end w-full">
                <Link
                  href={
                    node.contents?.find(
                      (nextPage) =>
                        nextPage.mapping === NEXT_PAGE_CICLA_MADRID_SEARCHER
                    )?.path.alias ?? ''
                  }
                  className="text-lg font-bold"
                >
                  {translations.mapExplore}
                </Link>
              </div>
            }
          >
            {node.allRoutes.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_ROUTE }}
                key={content.id}
              />
            ))}
          </Carousel>
        </div>
      )}
      {!!node.landinds?.length && (
        <div className="py-16 px-28 bg-neutral-gray-100">
          <Grid title={translations.ofInterest} redLine={true} cols={2}>
            {node.landinds.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_BASIC }}
                key={content.id}
              />
            ))}
          </Grid>
        </div>
      )}
    </>
  )
}

export { GranTourPage }
