export const NETX_PAGES_FIELDS = [
  'path',
  'title',
  'status',
  'metatags',
  'moderation_state',
  'mapping',
  'breadcrumbs',
  'computed_image',
  'contents',
]

export const NETX_PAGES_INCLUDES = ['computed_image', 'contents']
