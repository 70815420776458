import { cva } from 'class-variance-authority'

export const ContentStyles = cva(
  'bg-neutral-gray-200 absolute left-0 w-full top-[7.5rem] py-16 z-50',

  {
    variants: {
      visible: { true: 'visible', false: 'hidden' },
    },
  }
)
