const SouthKoreaFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28px"
    height="19px"
    viewBox="0 0 28 19"
    version="1.1"
    role="presentation"
    aria-hidden="true"
  >
    <g id="surface1">
      <rect
        x="0"
        y="0"
        width="28"
        height="19"
        fill="rgb(100%,100%,100%)"
        fillOpacity="1"
        stroke="none"
      />
      <path
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 4.292969 5.765625 L 6.882812 1.816406 L 7.527344 2.253906 L 4.941406 6.207031 Z M 5.261719 6.425781 L 7.851562 2.472656 L 8.5 2.914062 L 5.910156 6.863281 Z M 6.234375 7.085938 L 8.824219 3.132812 L 9.46875 3.570312 L 6.882812 7.523438 Z M 6.234375 7.085938 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 18.53125 15.429688 L 21.117188 11.476562 L 21.765625 11.914062 L 19.175781 15.867188 Z M 19.5 16.085938 L 22.089844 12.136719 L 22.738281 12.574219 L 20.148438 16.527344 Z M 20.472656 16.746094 L 23.058594 12.792969 L 23.707031 13.234375 L 21.117188 17.183594 Z M 20.472656 16.746094 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(100%,100%,100%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M -0.00469202 17.000727 L -0.000293263 27.005614 "
        transform="matrix(0.215717,-0.329353,0.323575,0.219569,14,9.5)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(80.392157%,18.039216%,22.745098%)"
        fillOpacity="1"
        d="M 10.117188 6.863281 C 11.546875 4.683594 14.445312 4.09375 16.589844 5.546875 C 18.734375 7.003906 19.3125 9.953125 17.882812 12.136719 Z M 10.117188 6.863281 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(0%,27.843137%,62.745098%)"
        fillOpacity="1"
        d="M 10.117188 6.863281 C 8.6875 9.046875 9.265625 11.996094 11.410156 13.453125 C 13.554688 14.90625 16.453125 14.316406 17.882812 12.136719 C 18.597656 11.042969 18.308594 9.570312 17.234375 8.839844 C 16.164062 8.113281 14.714844 8.410156 14 9.5 Z M 10.117188 6.863281 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(80.392157%,18.039216%,22.745098%)"
        fillOpacity="1"
        d="M 13.351562 6.207031 C 14.425781 6.933594 14.714844 8.410156 14 9.5 C 13.285156 10.589844 11.835938 10.886719 10.765625 10.160156 C 9.691406 9.429688 9.402344 7.957031 10.117188 6.863281 C 10.832031 5.773438 12.28125 5.480469 13.351562 6.207031 Z M 13.351562 6.207031 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 6.882812 17.183594 L 4.292969 13.234375 L 4.941406 12.792969 L 7.527344 16.746094 Z M 7.851562 16.527344 L 5.261719 12.574219 L 5.910156 12.136719 L 8.5 16.085938 Z M 8.824219 15.867188 L 6.234375 11.914062 L 6.882812 11.476562 L 9.46875 15.429688 Z M 8.824219 15.867188 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 21.117188 7.523438 L 18.53125 3.570312 L 19.175781 3.132812 L 21.765625 7.085938 Z M 22.089844 6.863281 L 19.5 2.914062 L 20.148438 2.472656 L 22.738281 6.425781 Z M 23.058594 6.207031 L 20.472656 2.253906 L 21.117188 1.816406 L 23.707031 5.765625 Z M 23.058594 6.207031 "
      />
      <path
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(100%,100%,100%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 0.00146624 -23.503515 L 0.0017595 -20.497352 M 0.00469202 17.000727 L -0.0017595 20.497352 M -0.00146624 23.503515 L 0.00439877 26.50132 "
        transform="matrix(-0.215717,-0.329353,0.323575,-0.219569,14,9.5)"
      />
    </g>
  </svg>
)

export { SouthKoreaFlag }
