import { SearchFeaturedContent } from './components/SearchFeaturedContent'

export const ENTITY_SUBQUEUE = 'entity_subqueue'
export const SUBQUEUE_SEARCH_FEATURED_CONTENT =
  'entity_subqueue--search_featured_content'

export const ENTITIES_SUBQUEUE = {
  [SUBQUEUE_SEARCH_FEATURED_CONTENT]: SearchFeaturedContent,
}

export const SUBQUEUE_GRANTOUR = 'entity_subqueue--ciclamadrid_grantour'

export const SUBQUEUE_PLACEHOLDER_ONEDAY_ROUTES =
  'entity_subqueue--ciclamadrid_oneday_routes'
