import Link from 'next/link'
import { cn } from 'utils-tailwindcss'

const Links = ({ className, link }) => (
  <ul className={cn(className, 'flex flex-col gap-3')}>
    {link.items &&
      link.items.map((item, index: number) => (
        <li key={index}>
          <Link href={item.url} className="text-xs lg:text-base">
            {item.title}
          </Link>
        </li>
      ))}
  </ul>
)

export { Links }
