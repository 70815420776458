import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { cn } from 'utils-tailwindcss'

import { DrupalParagraphInternalContentProps } from './types'
import { Full } from './viewmodes/Full'

const InternalContent = ({
  paragraph,
  viewMode = VIEW_MODE_DEFAULT,
  ...rest
}: DrupalParagraphInternalContentProps) => (
  <div
    className={cn(
      'h-full',
      `${paragraph.type} view-mode-${viewMode} ${
        paragraph.content?.id === 'missing' &&
        !paragraph.content?.status &&
        'hidden'
      }`
    )}
  >
    <Full paragraph_content={paragraph} {...rest} />
  </div>
)

export { InternalContent }
