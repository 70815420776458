import { cva } from 'class-variance-authority'

import { focusable } from '../../../utils/helpers'

export const InputStyles = cva(
  [
    'bg-neutral-white resize-none outline-none w-full border border-neutral-gray-400 px-6 py-4 rounded-lg text-sm',
    focusable(),
  ],
  {
    variants: {
      invalid: {
        true: ['border-primary-red-900 ring-2 ring-primary-red-900'],
      },
      disabled: {
        true: ['bg-neutral-gray-200 border-neutral-gray-400'],
      },
      clearable: {
        true: ['pr-10'],
      },
    },
    defaultVariants: {
      invalid: false,
      clearable: false,
    },
  }
)
