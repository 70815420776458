import { ComponentPropsWithoutRef } from 'react'
import { DialogClose } from '@radix-ui/react-dialog'
import { XIcon } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { HeaderStyles } from './Header.styles'

const Header = ({
  children,
  className,
  onClick,
}: ComponentPropsWithoutRef<'div'>) => (
  <div className={cn(HeaderStyles.root(), className)} onClick={onClick}>
    {children}
    <DialogClose className={HeaderStyles.close()}>
      <XIcon role="presentation" aria-hidden="true" />
    </DialogClose>
  </div>
)

export { Header }
