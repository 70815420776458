const Finder = () => (
  <div className=" text-neutral-gray-100">
    <h2>Buscador</h2>
    <form className="">
      <label>
        <input placeholder="Realiza tu busqueda" />
        <button className="text-neutral-gray-100">Buscar</button>
      </label>
    </form>
    <hr />
  </div>
)

export { Finder }
