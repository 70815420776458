import {
  VIEW_MODE_BASIC_CAROUSEL_3COL,
  VIEW_MODE_CARD_BIG_BASIC,
  VIEW_MODE_CARD_TEASER,
  VIEW_MODE_CARD_TEASER_HORIZONTAL,
  VIEW_MODE_CARD_TEASER_LONG,
  VIEW_MODE_DEFAULT,
  VIEW_MODE_TEASER_CAROUSEL_3COL,
} from '@/content/constants'

import { DrupalNodeExperienceProps } from './types'
import { Basic, Teaser } from './viewmodes'

const Experience = ({
  viewMode = VIEW_MODE_DEFAULT,
  ...rest
}: DrupalNodeExperienceProps) => {
  switch (viewMode) {
    case VIEW_MODE_CARD_BIG_BASIC:
      return <Basic {...rest} />
    case VIEW_MODE_CARD_TEASER:
      return <Teaser {...rest} size={'small'} />
    case VIEW_MODE_CARD_TEASER_LONG:
      return <Teaser {...rest} size={'long'} />
    case VIEW_MODE_CARD_TEASER_HORIZONTAL:
      return <Teaser {...rest} size={'horizontal'} />
    case VIEW_MODE_TEASER_CAROUSEL_3COL:
      return <Teaser {...rest} size="carousel_3col" />
    case VIEW_MODE_BASIC_CAROUSEL_3COL:
      return <Basic {...rest} />
    default:
      return <Basic {...rest} />
  }
}

export { Experience }
