import { ElementRef, forwardRef } from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'
import { cn } from 'utils-tailwindcss'

import { ThumbStyles } from './Thumb.styles'

const Thumb = forwardRef<
  ElementRef<typeof SliderPrimitive.Thumb>,
  SliderPrimitive.SliderThumbProps
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Thumb
    ref={ref}
    className={cn(ThumbStyles(), className)}
    {...props}
  />
))

Thumb.displayName = 'Thumb'

export { Thumb }
