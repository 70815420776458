import { Facets, Filter } from '@/components/List/types'

import {
  DISTANCE_ROUTES_FILTER,
  DURATION_ROUTES_FILTER,
  SORT_FILTER,
} from '../constants'
import { getSortFilter } from './getSortFilter'

function formatDuration(hours: number[]): string {
  const formattedHours = hours.map((hour) =>
    hour < 10 ? `0${hour}:00` : `${hour}:00`
  )
  return formattedHours.join(' - ')
}

export const convertFilters = (
  selectedFilters: Record<string, string | string[] | number[]>,
  filters?: Facets[]
) => {
  const resultArray: Filter[] = []

  for (const key in selectedFilters) {
    if (Object.prototype.hasOwnProperty.call(selectedFilters, key)) {
      const selectedValues = selectedFilters[key]
      const matchingItem = filters?.find((item) => item.id === key)

      if (key === SORT_FILTER) {
        const sort = getSortFilter(selectedValues as string)
        sort && resultArray.push(sort)
      }

      if (matchingItem) {
        let selectedLabels: Filter[] = []
        if (
          (key === DISTANCE_ROUTES_FILTER || key === DURATION_ROUTES_FILTER) &&
          ((selectedValues[0] as number) > 0 ||
            (selectedValues[1] as number) > 0)
        ) {
          const label =
            key === DISTANCE_ROUTES_FILTER
              ? `${selectedValues[0]} - ${selectedValues[1]} km`
              : `${formatDuration(selectedValues as number[])} h`
          selectedLabels.push({
            values: {
              value: key,
              label,
            },
            type: matchingItem.id,
            range: selectedValues as number[],
          })
        } else if (matchingItem.terms) {
          selectedLabels = matchingItem.terms.filter((item) =>
            (selectedValues as string[]).includes(item.values.value)
          )
        }

        resultArray.push(
          ...selectedLabels.map((label) => ({
            ...label,
            type: key,
          }))
        )
      }
    }
  }
  return resultArray
}

export const convertActiveFiltersToFormFilter = (
  activeFilters: Filter[]
): {
  type: string
  data: string[]
}[] => {
  const filters: Map<string, string[]> = new Map()

  activeFilters.forEach((item) => {
    item.type !== DURATION_ROUTES_FILTER && item.type !== DISTANCE_ROUTES_FILTER
      ? filters.has(item.type!)
        ? filters.get(item.type!)?.push(item.values.value)
        : filters.set(item.type!, [item.values.value])
      : filters.set(item.type!, [...(item.range as [])])
  })

  return Array.from(filters, ([type, data]) => ({
    type,
    data,
  }))
}
