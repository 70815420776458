import dynamic from 'next/dynamic'
import { Carousel } from '@/components/Carousel'
import { Grid } from '@/components/Grid'
import { LocationCard } from '@/components/LocationCard'
import { VIEW_MODE_DYNAMIC } from '@/components/LocationCard/constants'
import { DrupalNodeRoute } from '@/components/Node/components/Route/types'
import { NODE_ROUTE_BASE } from '@/components/Node/constants'
import { Node } from '@/components/Node/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { RoutesList } from '@/components/RoutesList'
import { getRoutesIcon } from '@/components/RoutesList/components/List/helpers'
import { getPosition } from '@/components/RoutesList/components/Map/helper'
import {
  CULTURE_ROUTES_FILTER,
  GASTRONOMY_ROUTES_FILTER,
  NATURAL_ROUTES_FILTER,
} from '@/components/RoutesList/constants'
import {
  LAT_LON_MADRID,
  NEXT_PAGE_ROUTE_SEARCHER,
  VIEW_MODE_BASIC_CAROUSEL_3COL,
  VIEW_MODE_CARD_BASIC,
  VIEW_MODE_CARD_ROUTE,
} from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Link } from 'next-translate-routes'
import { Button, Text } from 'ui'
import { DrupalFieldTextFormat } from 'utils-drupal'

import { DrupalNodeNextRoutesPageProps } from './types'

const DynamicMap = dynamic(() => import('ui/src/components/maps/Map/Map'), {
  ssr: false,
})
const DynamicMapMarker = dynamic(
  () => import('ui/src/components/maps/Map/components/Marker/Marker'),
  {
    ssr: false,
  }
)

const RoutesPage = ({ node }: DrupalNodeNextRoutesPageProps) => {
  const routeSearcherUrl =
    node.contents?.find(
      (nextPage) => nextPage.mapping === NEXT_PAGE_ROUTE_SEARCHER
    )?.path.alias ?? ''

  const conditionalTranslationKeys = {
    highlightsRoutes: 'searcher.routes.highlightsRoutes',
    highlightsRoutesBody: 'searcher.routes.highlightsRoutesBody',
    gastronomicRoutes: 'searcher.routes.gastronomicRoutes',
    gastronomicRoutesDescription:
      'searcher.routes.gastronomicRoutesDescription',
    viewGastronomicRoutes: 'searcher.routes.viewGastronomicRoutes',
    culturalRoutes: 'searcher.routes.culturalRoutes',
    viewAllRoutes: 'searcher.routes.viewAllRoutes',
    culturalRoutesDescription: 'searcher.routes.culturalRoutesDescription',
    viewCulturalRoutes: 'searcher.routes.viewCulturalRoutes',
    naturalRoutes: 'searcher.routes.naturalRoutes',
    naturalRoutesDescription: 'searcher.routes.naturalRoutesDescription',
    viewNaturalRoutes: 'searcher.routes.viewNaturalRoutes',
    routes: 'global.routes',
    title: 'searcher.routes.title',
    subtitle: 'searcher.routes.subtitle',
    mapExplore: 'searcher.routes.mapExplore',
    ofInterest: 'node.ofInterest',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <div className="container">
        <RoutesList
          withResults={false}
          type={NODE_ROUTE_BASE}
          initialState={{
            showResults: false,
            urlResults: routeSearcherUrl,
          }}
        />
      </div>
      <div className="">
        {!!node.highlightsRoutes?.length && (
          <Carousel
            className="custom-container"
            title={translations.highlightsRoutes}
            subtitle={translations.highlightsRoutesBody}
            link={
              <div className="container flex justify-end w-full -mt-6 font-bold md:mt-8">
                <Link href={routeSearcherUrl}>
                  {translations.viewAllRoutes}
                </Link>
              </div>
            }
          >
            {node.highlightsRoutes.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_ROUTE }}
                key={content.id}
              />
            ))}
          </Carousel>
        )}

        {!!node.gastronomicRoutes?.length && (
          <Carousel
            className="custom-container"
            title={translations.gastronomicRoutes}
            subtitle={translations.gastronomicRoutesDescription}
            link={
              <div className="container flex justify-end w-full -mt-6 font-bold md:mt-8">
                <Link
                  href={
                    !!routeSearcherUrl
                      ? `${routeSearcherUrl}?${encodeURI(
                          node.categories?.[GASTRONOMY_ROUTES_FILTER].map(
                            (tax) =>
                              `${tax.label}[term_categories]=${tax.value}`
                          ).join('&') ?? ''
                        )}`
                      : ''
                  }
                >
                  {translations.viewGastronomicRoutes}
                </Link>
              </div>
            }
          >
            {node.gastronomicRoutes.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL }}
                key={content.id}
              />
            ))}
          </Carousel>
        )}
        {!!node.culturalRoutes?.length && (
          <Carousel
            className="custom-container"
            title={translations.culturalRoutes}
            subtitle={translations.culturalRoutesDescription}
            link={
              <div className="container flex justify-end w-full -mt-6 font-bold md:mt-8">
                <Link
                  href={
                    !!routeSearcherUrl
                      ? `${routeSearcherUrl}?${encodeURI(
                          node.categories?.[CULTURE_ROUTES_FILTER].map(
                            (tax) =>
                              `${tax.label}[term_categories]=${tax.value}`
                          ).join('&') ?? ''
                        )}`
                      : ''
                  }
                >
                  {translations.viewCulturalRoutes}
                </Link>
              </div>
            }
          >
            {node.culturalRoutes.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL }}
                key={content.id}
              />
            ))}
          </Carousel>
        )}
        {!!node.naturalRoutes?.length && (
          <Carousel
            className="custom-container"
            title={translations.naturalRoutes}
            subtitle={translations.naturalRoutesDescription}
            link={
              <div className="container flex justify-end w-full -mt-6 font-bold md:mt-8">
                <Link
                  href={
                    !!routeSearcherUrl
                      ? `${routeSearcherUrl}?${encodeURI(
                          node.categories?.[NATURAL_ROUTES_FILTER].map(
                            (tax) =>
                              `${tax.label}[term_categories]=${tax.value}`
                          ).join('&') ?? ''
                        )}`
                      : ''
                  }
                >
                  {translations.viewNaturalRoutes}
                </Link>
              </div>
            }
          >
            {node.naturalRoutes.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL }}
                key={content.id}
              />
            ))}
          </Carousel>
        )}
        <div className="container flex flex-col w-full py-10 md:flex-row">
          <DynamicMap
            scrollWheelZoom={false}
            height={'348px'}
            className="z-0 rounded-xl"
            center={LAT_LON_MADRID}
            width="100%"
            zoom={8}
          >
            {node.map?.map((route: DrupalNodeRoute) => {
              return (
                <DynamicMapMarker
                  key={route.id}
                  isActive={false}
                  Icon={getRoutesIcon(
                    route.term_categories?.map((cat) => cat.name) ?? [],
                    node.categories
                  )}
                  position={
                    getPosition(route?.text_initial_point) ?? LAT_LON_MADRID
                  }
                >
                  <LocationCard
                    viewMode={VIEW_MODE_DYNAMIC}
                    title={translations.routes}
                    image={route.images?.[0]}
                    location={route.text_address}
                    description={
                      { value: route.title } as DrupalFieldTextFormat
                    }
                  />
                </DynamicMapMarker>
              )
            })}
          </DynamicMap>
          <div className="flex flex-col items-start justify-center w-full gap-6 p-0 mt-6 md:mt-0 md:p-10 bg-neutral-white">
            <Text className="text-3xl font-bold">{translations.title}</Text>
            <Text>{translations.subtitle}</Text>
            <Button>
              <Link className="no-underline" href={routeSearcherUrl}>
                {translations.mapExplore}
              </Link>
            </Button>
          </div>
        </div>
      </div>
      {!!node.ofInterest?.length && (
        <div className="py-16 bg-neutral-gray-100">
          <Grid title={translations.ofInterest} redLine={true} cols={4}>
            {node.ofInterest.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_BASIC }}
                key={content.id}
              />
            ))}
          </Grid>
        </div>
      )}
    </>
  )
}

export { RoutesPage }
