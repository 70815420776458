import { DrupalParagraphVillageMap } from './types'
import { Default } from './viewmodes/Default'

const VillageMap = ({
  paragraph,
  viewMode = 'default',
  ...rest
}: {
  paragraph: DrupalParagraphVillageMap
  viewMode?: string
}) => (
  <div className={`${paragraph.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        default:
          return <Default paragraph={paragraph} {...rest} />
      }
    })()}
  </div>
)

export { VillageMap }
