import Image from 'next/image'
import { FormattedMessage } from '@/components/FormattedMessage'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Star } from 'lucide-react'
import { Link } from 'next-translate-routes'
import { Heading, Navigation, NavigationItem, Text } from 'ui'
import { DrupalMenuLinkContents } from 'utils-drupal'

const MainMenuMobile = ({
  setSelected,
  megamenu,
}: {
  setSelected: (_id) => void
  megamenu: DrupalMenuLinkContents[]
}) => {
  const conditionalTranslationKeys = {
    title: 'global.agenda',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div>
      <Heading as="h2" className="mt-6 mb-8">
        <FormattedMessage id="global.menu" />
      </Heading>
      <Navigation onValueChange={(id: string) => setSelected(id)}>
        {megamenu.map((menu) => (
          <NavigationItem
            key={menu.id}
            id={menu.id}
            chevron={!!menu.items?.length}
            className="font-semibold first:pt-0"
            icon={
              !!menu.image ? (
                <Image
                  src={menu.image.uri.url}
                  alt="menu icon"
                  height={20}
                  width={20}
                />
              ) : (
                <Star />
              )
            }
          >
            {menu.items?.length ? (
              menu.title
            ) : (
              <Link href={menu.url} className="no-underline">
                <Text className="text-base">{translations.title}</Text>
              </Link>
            )}
          </NavigationItem>
        ))}
      </Navigation>
    </div>
  )
}

export { MainMenuMobile }
