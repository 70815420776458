import { DateOptions } from './types'

const OPTIONS = {
  weekday: 'short' as 'short',
  month: 'short' as 'short',
  day: 'numeric' as 'numeric',
}
export const formattedDate = (
  date: Date,
  locale: string,
  options?: DateOptions
): string => {
  const dateString = date?.toLocaleDateString(locale, options ?? OPTIONS)
  return dateString.charAt(0).toUpperCase() + dateString.slice(1)
}
