import { Node } from '@/components/Node'
import { VIEW_MODE_CARD_LOCATION } from '@/content/constants'
import { Card, CardContent, CardTitle, Text } from 'ui'

import { useMapProvider } from '../../../../MapProvider'

export const List = () => {
  const {
    state: { touristicPoints },
  } = useMapProvider()

  return (
    <div className="flex flex-col w-full gap-4">
      {touristicPoints.map((touristicPoint) => (
        <Card className="w-full border">
          <CardContent>
            <CardTitle className="w-full pt-0 no-underline ">
              <div className="flex items-start justify-between mb-2">
                <Text className="text-lg font-bold max-w-[17.1875rem]">
                  {touristicPoint.title}
                </Text>
              </div>
            </CardTitle>
            <Node
              node={{
                ...touristicPoint,
                viewMode: VIEW_MODE_CARD_LOCATION,
              }}
            />
          </CardContent>
        </Card>
      ))}
    </div>
  )
}
