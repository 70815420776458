const UnitedKingdomFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28px"
    height="19px"
    viewBox="0 0 38 19"
    version="1.1"
    role="presentation"
    aria-hidden="true"
  >
    <defs>
      <clipPath id="clip1">
        <path d="M 19 9.5 L 38 9.5 L 38 19 Z M 19 9.5 L 19 19 L 0 19 Z M 19 9.5 L 0 9.5 L 0 0 Z M 19 9.5 L 19 0 L 38 0 Z M 19 9.5 " />
      </clipPath>
    </defs>
    <g id="surface1">
      <rect
        x="0"
        y="0"
        width="38"
        height="19"
        fill="rgb(0.392157%,12.941176%,41.176471%)"
        fillOpacity="1"
        stroke="none"
      />
      <path
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        strokeWidth="6"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(100%,100%,100%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 0 0 L 60 30 M 60 0 L 0 30 "
        transform="matrix(0.633333,0,0,0.633333,0,0)"
      />
      <g clipPath="url(#clip1)" clipRule="nonzero">
        <path
          fillRule="nonzero"
          fill="rgb(0%,0%,0%)"
          fillOpacity="1"
          strokeWidth="4"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          stroke="rgb(78.431373%,6.27451%,18.039216%)"
          strokeOpacity="1"
          strokeMiterlimit="4"
          d="M 0 0 L 60 30 M 60 0 L 0 30 "
          transform="matrix(0.633333,0,0,0.633333,0,0)"
        />
      </g>
      <path
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        strokeWidth="10"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(100%,100%,100%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 30 0 L 30 30 M 0 15 L 60 15 "
        transform="matrix(0.633333,0,0,0.633333,0,0)"
      />
      <path
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        strokeWidth="6"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        stroke="rgb(78.431373%,6.27451%,18.039216%)"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 30 0 L 30 30 M 0 15 L 60 15 "
        transform="matrix(0.633333,0,0,0.633333,0,0)"
      />
    </g>
  </svg>
)

export { UnitedKingdomFlag }
