import { cva } from 'class-variance-authority'

export const IconItemStyles = {
  Root: cva([
    'h-full text-neutral-white',
    '[&>button]:cursor-pointer [&>button]:h-full [&>button]:flex [&>button]:justify-center [&>button]:items-center [&>button]:py-3 [&>button]:px-4 [&>button]:rounded-md [&>button]:ml-12',
  ]),
  Search: cva('bg-text-dark '),
  Close: cva('bg-primary-red-900'),
}
