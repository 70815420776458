import { forwardRef } from 'react'
import { getCleanText } from '@/lib/utils/getCleanText'
import { cn } from 'utils-tailwindcss'

import { CKEditorProps } from './types'

const CKEditor = forwardRef<HTMLDivElement, CKEditorProps>(
  ({ className, text, cleanText, ...rest }, ref) =>
    (text?.processed || text?.value) && (
      <div
        suppressHydrationWarning
        ref={ref}
        dangerouslySetInnerHTML={{
          __html: !!cleanText
            ? getCleanText(text.processed || text.value)
            : text.processed || text.value,
        }}
        className={cn(className, 'ckeditor [&>p]:break-words')}
        {...rest}
      />
    )
)

CKEditor.displayName = 'CKEditor'

export { CKEditor }
