import { FormattedMessage } from '@/components/FormattedMessage'
import { Link } from '@/components/Link'

export const CustomizeExperienceButton = ({ href }: { href: string }) => (
  <Link
    href={href}
    size="sm"
    intent="secondary"
    className={'w-full md:z-20 md:absolute md:bottom-12 md:w-fit'}
  >
    <FormattedMessage id="paragraph.hero.customizeExperience" />
  </Link>
)
