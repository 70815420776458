import { SUBQUEUE_SEARCH_FEATURED_CONTENT } from '@/components/EntitySubqueue/constants'
import { GENERAL_INDEX } from '@/components/List/constants'
import {
  FILE_DOCUMENT,
  FILE_IMAGE,
  MEDIA_DOCUMENT,
  MEDIA_IMAGE,
} from '@/components/Media/constants'
import {
  MENU_LINK_MEGAMENU,
  NODE_ESTABLISHMENT,
  NODE_ESTABLISHMENT_CARD,
  NODE_EVENT,
  NODE_EVENT_CARD,
  NODE_EXPERIENCE,
  NODE_ITINERARY,
  NODE_LANDING,
  NODE_LANDING_CARD,
  NODE_PAGES,
  NODE_PLACE,
  NODE_PLACE_BASE,
  NODE_PLACE_CARD,
  NODE_ROUTE,
  NODE_ROUTE_BASE,
  NODE_ROUTE_CARD,
  NODE_TOURIST_PLAN,
  NODE_TOURIST_PLAN_CARD,
  NODE_TOURIST_POINT,
  NODE_TOURIST_POINT_CARD,
  NODE_USER_ITINERARY,
} from '@/components/Node/constants'
import { LIST_TYPE_ITINERARY } from '@/components/Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'
import {
  PARAGRAPH_ACCORDION,
  PARAGRAPH_ACCORDION_ITEM,
  PARAGRAPH_CARDS,
  PARAGRAPH_CUSTOM_TEXT,
  PARAGRAPH_EVENTS,
  PARAGRAPH_EXPERIENCE,
  PARAGRAPH_EXTERNAL_CONTENT,
  PARAGRAPH_GALLERY,
  PARAGRAPH_IMG_CUSTOM_TEXT,
  PARAGRAPH_INTERNAL_CONTENT,
  PARAGRAPH_ITINERARY_MAP,
  PARAGRAPH_PLACEHOLDER_ITINERARY_MAP,
  PARAGRAPH_REMOTE_VIDEO,
  PARAGRAPH_RESOURCE,
  PARAGRAPH_TAGS,
  PARAGRAPH_VILLAGE_MAP,
} from '@/components/Paragraph/constants'
import {
  MEGAMENU,
  MEGAMENU_CLIENT,
  SITEMAP_MEGAMENU,
} from '@/components/PublicLayout/components/Header/components/Megamenu/constants'
import {
  TERM_ACCOMMODATION_TYPE,
  TERM_AREA,
  TERM_CUSINE_TYPE,
  TERM_CUSTOM_EXPERIENCE,
  TERM_ESTABLISHMENT_CATEGORY,
  TERM_ESTABLISHMENT_SERVICES,
  TERM_ESTABLISHMENT_TYPE,
  TERM_EVENT_CATEGORY,
  TERM_EVENT_TYPE,
  TERM_HOTEL_CHAIN,
  TERM_ITINERARY_CATEGORY,
  TERM_ITINERARY_SERVICES,
  TERM_ITINERARY_TYPE,
  TERM_MUNICIPALITY,
  TERM_PROGRAM,
  TERM_PROGRAMS,
  TERM_PROPOSAL,
  TERM_REGION,
  TERM_ROUTE_ACTIVITY,
  TERM_ROUTE_CATEGORY,
  TERM_ROUTE_LEVEL,
  TERM_ROUTE_TYPE,
  TERM_TODOTOGO,
  TERM_TOURIST_PLAN_CATEGORY,
  TERM_TOURIST_PLAN_TYPE,
  TERM_TOURIST_POINT_CATEGORY,
} from '@/components/TaxonomyTerm/constants'
import { DrupalJsonApiParams } from 'drupal-jsonapi-params'

import { MEDIA_REMOTE_VIDEO } from './../components/Media/constants'
import { NODE_EVENT_BASE } from './../components/Node/constants'
import {
  CUSTOM_EXPERIENCE_FIELDS,
  CUSTOM_EXPERIENCE_INCLUDES,
} from './fields/custom_experience'
import {
  ESTABLISHMENT_CARD_FIELDS,
  ESTABLISHMENT_FIELDS,
  ESTABLISHMENT_INCLUDES,
  ESTABLISHMENT_ROUTE_FIELDS,
} from './fields/establishment'
import {
  EVENT_CARD_BASE_FIELDS,
  EVENT_CARD_BASE_INCLUDES,
  EVENT_CARD_FIELDS,
  EVENT_CARD_INCLUDES,
  EVENT_FIELDS,
  EVENT_INCLUDES,
} from './fields/event'
import { EXPERIENCE_FIELDS, EXPERIENCE_INCLUDES } from './fields/experience'
import {
  BASIC_TAXONOMY_FIELDS,
  CARD_BASIC_FIELDS,
  CARD_BASIC_IMAGE_FIELDS,
  FILE_DOCUMENT_FIELDS,
  FILE_IMAGE_FIELDS,
  MEDIA_DOCUMENT_FIELDS,
  MEDIA_IMAGE_FIELDS,
  MEDIA_REMOTE_VIDEO_FIELDS,
  PARAGRAPH_ACCORDION_FIELDS,
  PARAGRAPH_ACCORDION_ITEM_FIELDS,
  PARAGRAPH_CARD_FIELDS,
  PARAGRAPH_CUSTOM_EXPERIENCE_FIELDS,
  PARAGRAPH_CUSTOM_TEXT_FIELDS,
  PARAGRAPH_EVENT_FIELDS,
  PARAGRAPH_EXTERNAL_CONTENT_FIELDS,
  PARAGRAPH_GALLERY_FIELDS,
  PARAGRAPH_IMAGE_CUSTOM_TEXT_FIELDS,
  PARAGRAPH_INTERNAL_CONTENT_FIELDS,
  PARAGRAPH_ITINERARY_FIELDS,
  PARAGRAPH_MAP_FIELDS,
  PARAGRAPH_PLACEHOLDER_ITINERARY_MAP_FIELDS,
  PARAGRAPH_REMOTE_VIDEO_FIELDS,
  PARAGRAPH_RESOURCE_FIELDS,
  PARAGRAPH_TAGS_FIELDS,
  TAXONOMY_FIELDS,
  TAXONOMY_INCLUDES,
} from './fields/general'
import {
  GENERAL_SEARCH_FIELDS,
  GENERAL_SEARCH_INCLUDES,
} from './fields/general_search'
import {
  ITINERARY_CARD_FIELDS,
  ITINERARY_FIELDS,
  ITINERARY_INCLUDES,
} from './fields/itinerary'
import {
  LANDING_CARD_FIELDS,
  LANDING_CARD_INCLUDES,
  LANDING_FIELDS,
  LANDING_INCLUDES,
} from './fields/landing'
import {
  MEGAMENU_CLIENT_FIELDS,
  MEGAMENU_CLIENT_INCLUDES,
  MEGAMENU_FIELDS,
  MEGAMENU_INCLUDES,
  SITEMAP_MEGAMENU_FIELDS,
  SITEMAP_MEGAMENU_INCLUDES,
} from './fields/megamenu'
import { NETX_PAGES_FIELDS, NETX_PAGES_INCLUDES } from './fields/next_pages'
import {
  PLACE_CARD_FIELDS,
  PLACE_CARD_MEGAMENU_FIELDS,
  PLACE_FIELDS,
  PLACE_FIELDS_BASE,
  PLACE_INCLUDES,
  PLACE_INCLUDES_BASE,
} from './fields/place'
import {
  ROUTE_CARD_FIELDS,
  ROUTE_CARD_INCLUDES_BASE,
  ROUTE_FIELDS,
  ROUTE_FIELDS_BASE,
  ROUTE_INCLUDES,
} from './fields/route'
import {
  SUBQUEUE_SEARCH_FEATURED_CONTENT_FIELDS,
  SUBQUEUE_SEARCH_FEATURED_CONTENT_INCLUDES,
} from './fields/subqueue'
import { TODOTOGO_FIELDS, TODOTOGO_INCLUDES } from './fields/todotogo'
import {
  TOURIST_PLAN_CARD_BASIC_FIELDS,
  TOURIST_PLAN_CARD_FIELDS,
  TOURIST_PLAN_CARD_INCLUDES,
  TOURIST_PLAN_FIELDS,
  TOURIST_PLAN_INCLUDES,
} from './fields/tourist_plan'
import {
  TOURIST_POINT_CARD_FIELDS,
  TOURIST_POINT_CARD_INCLUDES,
  TOURIST_POINT_FIELDS,
  TOURIST_POINT_INCLUDES,
  TOURIST_POINT_ROUTE_FIELDS,
} from './fields/tourist_point'
import {
  USER_ITINERARY_FIELDS,
  USER_ITINERARY_INCLUDES,
} from './fields/user-itinerary'

export function getParams(
  name: string,
  mode: string | null = null
): DrupalJsonApiParams {
  const params = new DrupalJsonApiParams()

  name = mode ? `${name}--${mode}` : name
  switch (name) {
    case NODE_PLACE:
      params
        .addFields(name, PLACE_FIELDS)
        .addInclude(PLACE_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        .addFields(MEDIA_REMOTE_VIDEO, MEDIA_REMOTE_VIDEO_FIELDS)
        .addFields(MEDIA_DOCUMENT, MEDIA_DOCUMENT_FIELDS)
        .addFields(FILE_DOCUMENT, FILE_DOCUMENT_FIELDS)

        ///*************** TAXONOMIAS ****************
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_TODOTOGO, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_TOURIST_POINT_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_EVENT_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_REGION, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ESTABLISHMENT_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_TOURIST_PLAN_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_TOURIST_PLAN_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ESTABLISHMENT_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_EVENT_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_ACTIVITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_LEVEL, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_SERVICES, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_CUSTOM_EXPERIENCE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_AREA, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_CUSINE_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ESTABLISHMENT_SERVICES, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_HOTEL_CHAIN, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_PROGRAM, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_PROGRAMS, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_PROPOSAL, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ACCOMMODATION_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(PARAGRAPH_EVENTS, PARAGRAPH_EVENT_FIELDS)

        ///*************** PARAGRAPH ****************
        .addFields(PARAGRAPH_GALLERY, PARAGRAPH_GALLERY_FIELDS)
        .addFields(
          PARAGRAPH_INTERNAL_CONTENT,
          PARAGRAPH_INTERNAL_CONTENT_FIELDS
        )
        .addFields(
          PARAGRAPH_EXTERNAL_CONTENT,
          PARAGRAPH_EXTERNAL_CONTENT_FIELDS
        )
        .addFields(PARAGRAPH_ITINERARY_MAP, PARAGRAPH_ITINERARY_FIELDS)
        .addFields(PARAGRAPH_VILLAGE_MAP, PARAGRAPH_MAP_FIELDS)
        .addFields(PARAGRAPH_TAGS, PARAGRAPH_TAGS_FIELDS)
        .addFields(PARAGRAPH_CUSTOM_TEXT, PARAGRAPH_CUSTOM_TEXT_FIELDS)
        .addFields(
          PARAGRAPH_IMG_CUSTOM_TEXT,
          PARAGRAPH_IMAGE_CUSTOM_TEXT_FIELDS
        )
        .addFields(PARAGRAPH_REMOTE_VIDEO, PARAGRAPH_REMOTE_VIDEO_FIELDS)
        .addFields(PARAGRAPH_CARDS, PARAGRAPH_CARD_FIELDS)
        .addFields(PARAGRAPH_EXPERIENCE, PARAGRAPH_CUSTOM_EXPERIENCE_FIELDS)
        .addFields(PARAGRAPH_ACCORDION, PARAGRAPH_ACCORDION_FIELDS)
        .addFields(PARAGRAPH_ACCORDION_ITEM, PARAGRAPH_ACCORDION_ITEM_FIELDS)
        ///*************** NODES ****************
        .addFields(NODE_ROUTE, ROUTE_CARD_FIELDS)
        .addFields(NODE_ITINERARY, ITINERARY_CARD_FIELDS)
        .addFields(NODE_ESTABLISHMENT, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_EVENT, EVENT_CARD_FIELDS)
        .addFields(NODE_TOURIST_PLAN, TOURIST_PLAN_CARD_BASIC_FIELDS)
        .addFields(NODE_TOURIST_POINT, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_EXPERIENCE, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_LANDING, LANDING_CARD_FIELDS)
      break
    case NODE_PLACE_BASE:
      params
        .addFields(NODE_PLACE, PLACE_FIELDS_BASE)
        .addInclude(PLACE_INCLUDES_BASE)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
      break
    case NODE_PLACE_CARD:
      params
        .addFields(NODE_PLACE, PLACE_FIELDS_BASE)
        .addInclude(PLACE_INCLUDES_BASE)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
      break
    case NODE_ROUTE:
      params
        .addFields(name, ROUTE_FIELDS)
        .addInclude(ROUTE_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** NODES ****************
        .addFields(NODE_TOURIST_POINT, TOURIST_POINT_ROUTE_FIELDS)
        .addFields(NODE_ESTABLISHMENT, ESTABLISHMENT_ROUTE_FIELDS)

        ///*************** TAXONOMIAS ****************
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_LEVEL, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_ACTIVITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_CATEGORY, BASIC_TAXONOMY_FIELDS)
      break
    case NODE_ROUTE_CARD:
      params
        .addFields(NODE_ROUTE, ROUTE_FIELDS_BASE)
        .addInclude(ROUTE_CARD_INCLUDES_BASE)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** TAXONOMY ****************
        .addFields(TERM_ROUTE_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_LEVEL, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_ACTIVITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_TYPE, BASIC_TAXONOMY_FIELDS)
      break
    case NODE_ROUTE_BASE:
      params
        .addFields(NODE_ROUTE, ROUTE_CARD_FIELDS)
        .addInclude(ROUTE_CARD_INCLUDES_BASE)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** TAXONOMY ****************
        .addFields(TERM_ROUTE_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_LEVEL, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_ACTIVITY, BASIC_TAXONOMY_FIELDS)
      break
    case NODE_LANDING:
      params
        .addFields(name, LANDING_FIELDS)
        .addInclude(LANDING_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        .addFields(MEDIA_DOCUMENT, MEDIA_DOCUMENT_FIELDS)
        .addFields(FILE_DOCUMENT, FILE_DOCUMENT_FIELDS)
        ///*************** PARAGRAPH ****************
        .addFields(PARAGRAPH_GALLERY, PARAGRAPH_GALLERY_FIELDS)
        .addFields(
          PARAGRAPH_INTERNAL_CONTENT,
          PARAGRAPH_INTERNAL_CONTENT_FIELDS
        )
        .addFields(
          PARAGRAPH_EXTERNAL_CONTENT,
          PARAGRAPH_EXTERNAL_CONTENT_FIELDS
        )
        .addFields(PARAGRAPH_ITINERARY_MAP, PARAGRAPH_ITINERARY_FIELDS)
        .addFields(PARAGRAPH_VILLAGE_MAP, PARAGRAPH_MAP_FIELDS)
        .addFields(PARAGRAPH_TAGS, PARAGRAPH_TAGS_FIELDS)
        .addFields(PARAGRAPH_CUSTOM_TEXT, PARAGRAPH_CUSTOM_TEXT_FIELDS)
        .addFields(
          PARAGRAPH_IMG_CUSTOM_TEXT,
          PARAGRAPH_IMAGE_CUSTOM_TEXT_FIELDS
        )
        .addFields(PARAGRAPH_CARDS, PARAGRAPH_CARD_FIELDS)
        .addFields(PARAGRAPH_REMOTE_VIDEO, PARAGRAPH_REMOTE_VIDEO_FIELDS)
        .addFields(PARAGRAPH_EXPERIENCE, PARAGRAPH_CUSTOM_EXPERIENCE_FIELDS)
        .addFields(
          PARAGRAPH_PLACEHOLDER_ITINERARY_MAP,
          PARAGRAPH_PLACEHOLDER_ITINERARY_MAP_FIELDS
        )
        .addFields(PARAGRAPH_ACCORDION, PARAGRAPH_ACCORDION_FIELDS)
        .addFields(PARAGRAPH_ACCORDION_ITEM, PARAGRAPH_ACCORDION_ITEM_FIELDS)
        .addFields(PARAGRAPH_EVENTS, PARAGRAPH_EVENT_FIELDS)

        ///*************** TAXONOMIAS ****************
        .addFields(TERM_TODOTOGO, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_EVENT_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_REGION, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ESTABLISHMENT_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_TOURIST_PLAN_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_TOURIST_PLAN_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ESTABLISHMENT_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_EVENT_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_ACTIVITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_LEVEL, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_SERVICES, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_CUSTOM_EXPERIENCE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ROUTE_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_TOURIST_POINT_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_AREA, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_CUSINE_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ESTABLISHMENT_SERVICES, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_HOTEL_CHAIN, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_PROGRAM, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_PROGRAMS, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_PROPOSAL, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ACCOMMODATION_TYPE, BASIC_TAXONOMY_FIELDS)

        ///*************** NODES ****************
        .addFields(NODE_PLACE, PLACE_CARD_FIELDS)
        .addFields(NODE_ROUTE, ROUTE_CARD_FIELDS)
        .addFields(NODE_ITINERARY, ITINERARY_CARD_FIELDS)
        .addFields(NODE_ESTABLISHMENT, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_EVENT, EVENT_CARD_FIELDS)
        .addFields(NODE_TOURIST_PLAN, TOURIST_PLAN_CARD_BASIC_FIELDS)
        .addFields(NODE_TOURIST_POINT, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_EXPERIENCE, CARD_BASIC_IMAGE_FIELDS)

      break
    case NODE_LANDING_CARD:
      params
        .addFields(NODE_LANDING, LANDING_CARD_FIELDS)
        .addInclude(LANDING_CARD_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
      break
    case NODE_ESTABLISHMENT:
      params
        .addFields(name, ESTABLISHMENT_FIELDS)
        .addInclude(ESTABLISHMENT_INCLUDES)
      break
    case NODE_ESTABLISHMENT_CARD:
      params
        .addFields(NODE_ESTABLISHMENT, ESTABLISHMENT_CARD_FIELDS)
        .addInclude(ESTABLISHMENT_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** TAXONOMY ****************
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ACCOMMODATION_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_CUSINE_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_HOTEL_CHAIN, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ESTABLISHMENT_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_PROGRAMS, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ESTABLISHMENT_SERVICES, BASIC_TAXONOMY_FIELDS)
      break
    case NODE_TOURIST_POINT:
      params
        .addFields(name, TOURIST_POINT_FIELDS)
        .addInclude(TOURIST_POINT_INCLUDES)
      break
    case NODE_TOURIST_POINT_CARD:
      params
        .addFields(NODE_TOURIST_POINT, TOURIST_POINT_CARD_FIELDS)
        .addInclude(TOURIST_POINT_CARD_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** TAXONOMY ****************
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_TOURIST_POINT_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_PROGRAMS, BASIC_TAXONOMY_FIELDS)

      break
    case NODE_TOURIST_PLAN:
      params
        .addFields(name, TOURIST_PLAN_FIELDS)
        .addInclude(TOURIST_PLAN_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** TAXONOMY ****************
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_TOURIST_PLAN_TYPE, BASIC_TAXONOMY_FIELDS)
        //*************** PARAGRAPHS ****************
        .addFields(PARAGRAPH_RESOURCE, PARAGRAPH_RESOURCE_FIELDS)
        //*************** NODE ****************
        .addFields(NODE_EVENT, EVENT_CARD_FIELDS)
        .addFields(NODE_ESTABLISHMENT, ESTABLISHMENT_CARD_FIELDS)
        .addFields(NODE_TOURIST_POINT, TOURIST_POINT_CARD_FIELDS)

      break
    case NODE_TOURIST_PLAN_CARD:
      params
        .addFields(NODE_TOURIST_PLAN, TOURIST_PLAN_CARD_FIELDS)
        .addInclude(TOURIST_PLAN_CARD_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** TAXONOMY ****************
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_TOURIST_PLAN_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_PROGRAMS, BASIC_TAXONOMY_FIELDS)
      break
    case NODE_EVENT:
      params
        .addFields(name, EVENT_FIELDS)
        .addInclude(EVENT_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** TAXONOMY ****************
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_EVENT_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_EVENT_TYPE, BASIC_TAXONOMY_FIELDS)
      break
    case NODE_EVENT_BASE:
      params
        .addFields(NODE_EVENT, EVENT_CARD_BASE_FIELDS)
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addInclude(EVENT_CARD_BASE_INCLUDES)
      break
    case NODE_EVENT_CARD:
      params
        .addFields(NODE_EVENT, EVENT_CARD_FIELDS)
        .addInclude(EVENT_CARD_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** TAXONOMY ****************
        .addFields(TERM_EVENT_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_REGION, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_EVENT_TYPE, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_PROGRAM, BASIC_TAXONOMY_FIELDS)
      break
    case NODE_EXPERIENCE:
      params.addFields(name, EXPERIENCE_FIELDS).addInclude(EXPERIENCE_INCLUDES)
      break
    case NODE_USER_ITINERARY:
      params
        .addFields(NODE_USER_ITINERARY, USER_ITINERARY_FIELDS)
        .addInclude(USER_ITINERARY_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** NODES ****************
        .addFields(NODE_ITINERARY, ITINERARY_FIELDS)
        ///*************** TAXONOMIAS ****************
        .addFields(TERM_ITINERARY_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_SERVICES, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_TYPE, BASIC_TAXONOMY_FIELDS)
      break
    case NODE_ITINERARY:
      params
        .addFields(NODE_ITINERARY, ITINERARY_FIELDS)
        .addInclude(ITINERARY_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** NODES ****************
        ///*************** TAXONOMIAS ****************
        .addFields(TERM_ITINERARY_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_SERVICES, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_MUNICIPALITY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_TYPE, BASIC_TAXONOMY_FIELDS)
      break
    case NODE_PAGES:
      params
        .addFields(NODE_PAGES, NETX_PAGES_FIELDS)
        .addInclude(NETX_PAGES_INCLUDES)
      break
    case LIST_TYPE_ITINERARY:
      params
        .addFields(NODE_ITINERARY, ITINERARY_FIELDS)
        .addInclude(ITINERARY_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** TAXONOMIAS ****************
        .addFields(TERM_ITINERARY_CATEGORY, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_SERVICES, BASIC_TAXONOMY_FIELDS)
        .addFields(TERM_ITINERARY_TYPE, BASIC_TAXONOMY_FIELDS)
      break
    case TERM_TODOTOGO:
      params.addFields(name, TODOTOGO_FIELDS).addInclude(TODOTOGO_INCLUDES)
      break
    case GENERAL_INDEX:
      params
        .addFields(name, GENERAL_SEARCH_FIELDS)
        .addInclude(GENERAL_SEARCH_INCLUDES)
        ///*************** NODE ****************
        .addFields(NODE_LANDING, CARD_BASIC_FIELDS)
        .addFields(NODE_PLACE, CARD_BASIC_FIELDS)
        .addFields(NODE_ROUTE, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_ITINERARY, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_ESTABLISHMENT, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_EVENT, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_TOURIST_PLAN, TOURIST_PLAN_CARD_BASIC_FIELDS)
        .addFields(NODE_TOURIST_POINT, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_EXPERIENCE, CARD_BASIC_IMAGE_FIELDS)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
      break
    case SUBQUEUE_SEARCH_FEATURED_CONTENT:
      params
        .addFields(name, SUBQUEUE_SEARCH_FEATURED_CONTENT_FIELDS)
        .addInclude(SUBQUEUE_SEARCH_FEATURED_CONTENT_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** NODES ****************
        .addFields(NODE_LANDING, LANDING_CARD_FIELDS)
        .addFields(NODE_PLACE, PLACE_FIELDS_BASE)
        .addFields(NODE_ROUTE, ROUTE_FIELDS_BASE)
        .addFields(NODE_ITINERARY, ITINERARY_CARD_FIELDS)
        .addFields(NODE_ESTABLISHMENT, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_EVENT, EVENT_CARD_FIELDS)
        .addFields(NODE_TOURIST_PLAN, TOURIST_PLAN_CARD_BASIC_FIELDS)
        .addFields(NODE_TOURIST_POINT, CARD_BASIC_IMAGE_FIELDS)
        .addFields(NODE_EXPERIENCE, CARD_BASIC_IMAGE_FIELDS)
      break
    case MEGAMENU:
      params
        .addFields(MENU_LINK_MEGAMENU, MEGAMENU_FIELDS)
        .addInclude(MEGAMENU_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** NODES ****************
        .addFields(NODE_LANDING, CARD_BASIC_FIELDS)
        .addFields(NODE_PLACE, PLACE_CARD_FIELDS)
      break
    case MEGAMENU_CLIENT:
      params
        .addFields(MENU_LINK_MEGAMENU, MEGAMENU_CLIENT_FIELDS)
        .addInclude(MEGAMENU_CLIENT_INCLUDES)
        ///*************** MEDIA ****************
        .addFields(MEDIA_IMAGE, MEDIA_IMAGE_FIELDS)
        .addFields(FILE_IMAGE, FILE_IMAGE_FIELDS)
        ///*************** NODES ****************
        .addFields(NODE_LANDING, CARD_BASIC_FIELDS)
        .addFields(NODE_PLACE, PLACE_CARD_MEGAMENU_FIELDS)
      break
    case SITEMAP_MEGAMENU:
      params
        .addFields(MENU_LINK_MEGAMENU, SITEMAP_MEGAMENU_FIELDS)
        .addInclude(SITEMAP_MEGAMENU_INCLUDES)
      break
    case TERM_CUSTOM_EXPERIENCE:
      params
        .addFields(name, CUSTOM_EXPERIENCE_FIELDS)
        .addInclude(CUSTOM_EXPERIENCE_INCLUDES)
        ///*************** NODE ****************
        .addFields(NODE_LANDING, LANDING_CARD_FIELDS)
      break
    case TERM_ROUTE_CATEGORY:
      params.addFields(name, TAXONOMY_FIELDS).addInclude(TAXONOMY_INCLUDES)
      break
    case TERM_ITINERARY_CATEGORY:
      params.addFields(name, TAXONOMY_FIELDS).addInclude(TAXONOMY_INCLUDES)
      break
  }
  return params
}
