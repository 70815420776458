import { Title } from '@/components/Title'
import { Carousel as CarouselUI } from 'ui'
import { DrupalFieldTextFormat } from 'utils-drupal'
import { cn } from 'utils-tailwindcss'

import { CarouselProps } from './types'

const Carousel = ({
  title,
  body,
  subtitle,
  children,
  cols,
  link,
  className,
  redLine,
}: CarouselProps) => {
  if (!children.length) return null

  return (
    <div className={cn('my-12 md:my-18 py-1', className)}>
      {(title || body) && (
        <Title
          title={title}
          subtitle={
            typeof subtitle !== 'string'
              ? subtitle
              : ({ value: subtitle } as DrupalFieldTextFormat)
          }
          description={
            typeof body !== 'string'
              ? body
              : ({ value: body } as DrupalFieldTextFormat)
          }
          redLine={redLine}
        />
      )}

      <CarouselUI cols={cols} accountant={true}>
        {children}
      </CarouselUI>
      {link}
    </div>
  )
}

export { Carousel }
