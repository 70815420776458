import { cva } from 'class-variance-authority'

export const FilterTagStyles = {
  root: cva([
    'text-sm font-normal inline-flex items-center justify-center px-3 py-1 gap-3 cursor-pointer rounded-md bg-neutral-gray-200',
    'md:text-base md:py-2 md:px-4',
    'hover:ring-2 hover:ring-neutral-gray-400',
  ]),
  close: cva(['active:text-primary-red-900']),
}
