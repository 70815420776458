import { cva } from 'class-variance-authority'

export const SeparatorStyles = cva('', {
  variants: {
    orientation: {
      horizontal: 'h-[1px] w-full',
      vertical: 'h-full w-[1px]',
    },
    size: {
      empty: '',
      sm: '',
      md: '',
      lg: '',
    },
    color: {
      white: 'bg-neutral-white',
      black: 'bg-text-medium',
      gray: 'bg-neutral-gray-300',
    },
  },
  compoundVariants: [
    {
      orientation: 'horizontal',
      size: 'sm',
      class: 'h-[1px] my-6 lg:my-[1.25rem]',
    },
    {
      orientation: 'horizontal',
      size: 'md',
      class: 'h-[2px] my-6 lg:my-12',
    },
    {
      orientation: 'horizontal',
      size: 'lg',
      class: 'h-[1px] my-10',
    },
    {
      orientation: 'vertical',
      size: 'sm',
      class: 'w-[1px] mx-6',
    },
    {
      orientation: 'vertical',
      size: 'md',
      class: 'w-[2px] mx-12',
    },
  ],
  defaultVariants: {
    orientation: 'horizontal',
    size: 'md',
    color: 'black',
  },
})
