import { ElementRef, forwardRef } from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { cn } from 'utils-tailwindcss'

import { ItemStyles } from './Item.styles'
import { AccordionItemProps } from './types'

const Item = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  AccordionItemProps
>(({ className, disabled, ...rest }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn(ItemStyles.item({ disabled }), className)}
    disabled={disabled}
    {...rest}
  />
))

Item.displayName = 'AccordionItem'

export { Item }
