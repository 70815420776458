'use client'

import { ElementRef, forwardRef } from 'react'
import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { X } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { Button, Input } from '../../atoms'
import { SearcherProps } from './types'

const Searcher = forwardRef<
  ElementRef<typeof SeparatorPrimitive.Root>,
  SearcherProps
>(
  (
    {
      className,
      defaultValue,
      value,
      text,
      onValueChange = () => {},
      onSearchClick = () => {},
      onClear,
      ariaLabel,
      ...rest
    },
    ref
  ) => {
    const [inputValue = '', setValue] = useControllableState({
      prop: value as string,
      defaultProp: defaultValue as string,
      onChange: onValueChange,
    })

    const onChange = (value: string) => {
      setValue(value)
      onValueChange && onValueChange(value)
    }

    const onClick = () => {
      onSearchClick && onSearchClick(inputValue)
    }

    return (
      <div
        ref={ref}
        className={cn(
          'relative flex w-full h-16 bg-neutral-white rounded-md',
          className
        )}
      >
        <Input
          className="py-2 pl-3 pr-2 placeholder-text-dark"
          type="search"
          value={inputValue}
          defaultValue={defaultValue}
          onValueChange={onChange}
          {...rest}
        />
        <div className="absolute flex items-center justify-center right-[10px] top-2 gap-3">
          {inputValue && (
            <button type="submit" onClick={onClear} aria-label={ariaLabel}>
              <X />
            </button>
          )}
          <Button intent="secondary" size="sm" onClick={onClick}>
            {text}
          </Button>
        </div>
      </div>
    )
  }
)

Searcher.displayName = 'Searcher'

export { Searcher }
