import { Children, cloneElement } from 'react'
import { cn } from 'utils-tailwindcss'

import { ListStyles } from './List.styles'
import { ListProps } from './types'

const List = ({ children, value, onValueChange, className }: ListProps) => {
  return (
    <ul className={cn(ListStyles(), className)}>
      {Children.map(children, (child) => (
        <>
          {!!child &&
            (!!child?.props?.menuValue
              ? cloneElement(child, {
                  value,
                  onValueChange,
                })
              : cloneElement(child, {
                  value,
                }))}
        </>
      ))}
    </ul>
  )
}

export { List }
