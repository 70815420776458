import { FACETS_ICONS } from '@/components/RoutesList/constants'
import { Utensils } from 'lucide-react'

export const getRoutesIcon = (
  type: string[],
  categories?: Record<string, { label: string; value: string }[]>
) => {
  let icon = Utensils
  !!categories &&
    Object.keys(categories).forEach((category) => {
      type.forEach((type) => {
        if (categories[category].map((cat) => cat.label).includes(type)) {
          icon = FACETS_ICONS[category]
        }
      })
    })
  return icon
}
