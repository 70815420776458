import Link from 'next/link'
import { Card as CardUI } from 'ui'
import { cn } from 'utils-tailwindcss'

import { NODE_ESTABLISHMENT } from '../Node/constants'
import { Content } from './components'
import { CardBasicProps } from './types'

const CardSmallBasic = ({
  title,
  image,
  url,
  className,
  type,
}: CardBasicProps) => (
  <CardUI
    className={cn('h-full flex flex-col items-center p-2 w-[10rem]', className)}
  >
    {!url || type === NODE_ESTABLISHMENT ? (
      <Content title={title} image={image} />
    ) : (
      <Link
        href={url}
        className="flex flex-col items-center h-full no-underline hover:underline text-center"
      >
        <Content title={title} image={image} />
      </Link>
    )}
  </CardUI>
)

export { CardSmallBasic }
