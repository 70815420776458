import { forwardRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { InputStyles } from './Input.styles'
import { InputProps } from './types'

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      invalid,
      disabled,
      clearable,
      onValueChange = () => {},
      ...rest
    },
    ref
  ) => (
    <input
      ref={ref}
      className={cn(
        InputStyles({
          invalid,
          disabled,
          clearable,
        }),
        className
      )}
      disabled={disabled}
      onChange={(e) => onValueChange(e.target.value)}
      {...rest}
    />
  )
)

Input.displayName = 'Input'

export { Input }
