import { BookmarkIcon, MapPinIcon } from 'lucide-react'
import { Checkbox, FilterButton, Text } from 'ui'
import { FilterButtonLabel } from 'ui/src/components/molecules/FilterButton'
import { cn } from 'utils-tailwindcss'

import { CATEGORIES_TYPE } from '../../constants'
import { Footer, Header } from './components'
import { CheckBoxFilter } from './types'
import { useCheckBoxList } from './useCheckBoxList'

const CheckBoxList = ({ label, id, terms }: CheckBoxFilter) => {
  const {
    state: { closePopoverButtonRef, isDisabled, selected, showResults },
    actions: { toogleSelected, resetSelected, isSelected, close, setIsOpen },
  } = useCheckBoxList(id)

  const filteredTerms = terms.filter((term) => term.values.count !== 0)

  filteredTerms.sort((a, b) => {
    const labelA = a.values?.label?.toUpperCase() || ''
    const labelB = b.values?.label?.toUpperCase() || ''
    return labelA.localeCompare(labelB)
  })

  return (
    <div className={cn('flex flex-col ', showResults ? '' : 'mb-4 lg:mb-0')}>
      {!showResults && <Text className="mb-4 font-semibold">{label}</Text>}
      <FilterButton
        sideOffset={showResults ? 30 : -1}
        key={'popoverButton'}
        ref={closePopoverButtonRef}
        onOpen={setIsOpen}
        icon={
          !showResults ? (
            id === CATEGORIES_TYPE ? (
              <BookmarkIcon role="presentation" aria-hidden="true" />
            ) : (
              <MapPinIcon role="presentation" aria-hidden="true" />
            )
          ) : undefined
        }
      >
        <FilterButtonLabel>
          <Header label={label} selected={selected} showResults={showResults} />
        </FilterButtonLabel>
        <div className="max-h-[28.5rem] overflow-hidden overflow-y-auto custom-scroll p-4 space-y-4 w-fit">
          {filteredTerms?.map((item, key) => (
            <div className="flex items-center space-x-4" key={key}>
              <Checkbox
                id={item.values.value}
                checked={isSelected(item.values.value)}
                onCheckedChange={() => toogleSelected(item)}
                disabled={item.values.count === 0}
              />
              <label
                htmlFor={item.values.value}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {`${item.values.label} (${item.values.count})`}
              </label>
            </div>
          ))}
          <Footer reset={resetSelected} close={close} isDisabled={isDisabled} />
        </div>
      </FilterButton>
    </div>
  )
}

export { CheckBoxList }
