'use client'

import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react'
import { createGroupChildren } from 'utils'

import { Button, Popover, PopoverContent, PopoverTrigger } from '../../atoms'
import { FilterButtonLabel } from './components'
import { closeHandler, props } from './types'

const groupChildren = createGroupChildren({ label: FilterButtonLabel })

const FilterButton = forwardRef<closeHandler, props>(
  ({ children, onOpen, isDisabled, isLoading, sideOffset, icon }, ref) => {
    const [isOpen, setIsOpen] = useState(false)
    const { label, rest } = groupChildren(children)

    useImperativeHandle(
      ref,
      () => {
        return {
          close: () => {
            setIsOpen(false)
          },
        }
      },
      []
    )
    const refButton = useRef<HTMLButtonElement>(null)

    return (
      <Popover
        open={isOpen}
        onOpenChange={() =>
          setIsOpen((open) => {
            onOpen && onOpen(!open)
            return !open
          })
        }
      >
        <PopoverTrigger asChild>
          <Button
            isDisabled={isDisabled}
            isLoading={isLoading}
            ref={refButton}
            intent="filter"
          >
            {label?.at(0)}
            {icon ? (
              icon
            ) : isOpen ? (
              <ChevronUpIcon role="presentation" aria-hidden="true" />
            ) : (
              <ChevronDownIcon role="presentation" aria-hidden="true" />
            )}
          </Button>
        </PopoverTrigger>

        {isOpen && (
          <PopoverContent
            style={{
              width: 'fit-content',
              minWidth: refButton.current?.offsetWidth,
            }}
            avoidCollisions={true}
            sideOffset={sideOffset}
            align="start"
            collisionPadding={20}
            side="bottom"
          >
            {rest}
          </PopoverContent>
        )}
      </Popover>
    )
  }
)

FilterButton.displayName = 'FilterButton'
export { FilterButton }
