import { ImageStyleBreakPoints } from '@/components/ImageStyle'
import { SCALE_WEBP_1280x720 } from '@/content/constants'
import { Bookmark, Calendar, Link, MapPinIcon } from 'lucide-react'

export const BREAKPOINTS: ImageStyleBreakPoints = {
  default: {
    id: SCALE_WEBP_1280x720,
  },
}

export const INFORMATION = [
  { id: 'category', icon: Bookmark, title: 'global.category' },
  { id: 'date', icon: Calendar, title: 'global.date' },
  { id: 'municipality', icon: MapPinIcon, title: 'global.municipality' },
  { id: 'link', icon: Link, title: 'global.link' },
]
