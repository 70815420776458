import { FormattedMessage } from '@/components/FormattedMessage'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { MobileFilterList } from '@/components/List/components/MobileFilterList'
import { SearchForm } from '@/components/List/components/SearchForm'
import { Text } from 'ui'

import { useMobile } from './useMobile'

export const Mobile = () => {
  const {
    state: {
      isPending,
      total,
      form,
      activeFilters,
      showFilters,
      facets,
      total_items,
    },
    actions: {
      onSubmitFilter,
      onResetForm,
      fetch,
      setShowFilters,
      onDeleteActiveFilter,
    },
  } = useMobile()

  return (
    <div className="w-full">
      <div className="container">
        <div className="container flex flex-col w-full">
          <SearchForm />
          <Text className="my-6 text-base font-semibold ">
            <FormattedMessage id="searcher.textForFiltersInMobile" />
          </Text>
        </div>
      </div>
      <div className="flex flex-row justify-between px-6 py-3 bg-neutral-gray-200">
        {total_items && (
          <Text>
            <FormattedRichMessage
              id={
                total_items > 1
                  ? 'searcher.global.results'
                  : 'searcher.global.result'
              }
              values={{ total: total_items }}
            />
          </Text>
        )}
        <MobileFilterList
          filters={facets}
          activeFilters={activeFilters ?? []}
          onSubmit={form.handleSubmit(onSubmitFilter)}
          isLoading={isPending}
          onFormChage={fetch}
          onCancel={onResetForm}
          showModal={showFilters}
          onOpenChange={setShowFilters}
          form={form}
          onDeleteActiveFilter={onDeleteActiveFilter}
          onSubmitText={
            FormattedRichMessage({
              id: total ? 'searcher.showTotalResults' : 'global.showResults',
              values: { total: total },
            }) as string
          }
        />
      </div>
    </div>
  )
}
