import { ReactElement, useMemo } from 'react'
import Link from 'next/link'
import { FormattedMessage } from '@/components/FormattedMessage'
import { MadridLogo } from '@/components/MadridLogo'
import { useRouter } from 'next-translate-routes'
import {
  Heading,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuContentBody,
  NavigationMenuContentHeader,
  NavigationMenuIconItem,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuLogo,
  Separator,
  Text,
} from 'ui'

import { Explore } from './components/Explore'
import { WhatToDo } from './components/WhatToDo'
import { WhereToGo } from './components/WhereToGo'
import { SEARCH } from './constants'
import { MegamenuProps, MegamenuType } from './types'
import { useMegamenu } from './useMegamenu'

const Megamenu = ({ megamenu }: MegamenuProps) => {
  const {
    state: { value },
    actions: { setValue },
  } = useMegamenu()

  const { locale } = useRouter()

  const menuChildren = useMemo(
    () =>
      //@ts-ignore
      megamenu?.menu?.map((menu) =>
        !!menu.items?.length ? (
          <NavigationMenuItem
            menuValue={menu.id}
            key={menu.id}
            ariaHasPopup="dialog"
            className="ml-4 xl:ml-10"
          >
            {menu.title}
          </NavigationMenuItem>
        ) : (
          <li key={menu.id} className="self-center ml-4 xl:ml-10">
            <Link
              href={menu.url}
              className="self-center text-lg font-semibold no-underline hover:text-primary-red-900"
            >
              {menu.title}
            </Link>
          </li>
        )
      ) as ReactElement,
    [megamenu]
  )

  return (
    <NavigationMenu
      className="z-50"
      value={value}
      onValueChange={(currentValue) =>
        setValue(currentValue === value ? '' : currentValue)
      }
    >
      <NavigationMenuLogo>
        <Link
          href="/home"
          locale={locale}
          className="flex w-full h-full gap-10 px-12 py-4 no-underline"
        >
          <MadridLogo />
          <Text className="hidden lg:block">
            Visit
            <span className="font-extrabold">Madrid</span>
          </Text>
        </Link>
      </NavigationMenuLogo>
      <NavigationMenuList>
        {menuChildren}
        <NavigationMenuIconItem menuValue={SEARCH} />
      </NavigationMenuList>
      <NavigationMenuContent
        role="dialog"
        ariaLabelledby={FormattedMessage({ id: 'global.searcher' })}
      >
        <MenuContent id={value} menu={megamenu} onValueChange={setValue} />
      </NavigationMenuContent>
    </NavigationMenu>
  )
}

export { Megamenu }

const MenuContent = ({
  id,
  menu,
  onValueChange,
}: {
  id: string
  menu: MegamenuType
  onValueChange: (_value) => void
}) => {
  const activeOption = menu.menu?.find((menu) => menu.id === id)
  switch (id) {
    case SEARCH:
      return (
        <>
          <NavigationMenuContentHeader
            closable={false}
            onValueChange={onValueChange}
          >
            <Heading className="text-left" id={id}>
              <FormattedMessage id="global.searcher" />
            </Heading>
          </NavigationMenuContentHeader>
          <NavigationMenuContentBody>
            <Explore
              highlights={menu.search.items}
              url={menu.search?.path}
              ariaLabelledby={id}
            />
          </NavigationMenuContentBody>
        </>
      )

    default:
      return (
        !!activeOption && (
          <>
            <NavigationMenuContentHeader onValueChange={onValueChange}>
              <Heading>{activeOption.title}</Heading>
            </NavigationMenuContentHeader>
            <NavigationMenuContentBody>
              <Separator size="empty" className="my-8 bg-neutral-gray-300" />
              {!activeOption.map ? (
                <WhatToDo categories={activeOption} />
              ) : (
                <WhereToGo categories={activeOption} />
              )}
            </NavigationMenuContentBody>
          </>
        )
      )
  }
}
