import { ImageStyleBreakPoints } from '@/components/ImageStyle'
import { SCALE_WEBP_600x400 } from '@/content/constants'
import {
  Activity,
  Bookmark,
  Heart,
  History,
  Link,
  MapPinIcon,
  PersonStanding,
  Ruler,
} from 'lucide-react'

export const INFORMATION = [
  { id: 'activity', icon: PersonStanding, title: 'global.activity' },
  { id: 'category', icon: Bookmark, title: 'global.category' },
  { id: 'pointsOfInterest', icon: Heart, title: 'global.pointsOfInterest' },
  { id: 'difficulty', icon: Activity, title: 'global.difficulty' },
  { id: 'longitude', icon: Ruler, title: 'global.distance' },
  { id: 'duration', icon: History, title: 'global.duration' },
  { id: 'origin', icon: MapPinIcon, title: 'global.origin' },
  { id: 'link', icon: Link, title: 'global.link' },
]

export const BREAKPOINTS: ImageStyleBreakPoints = {
  default: {
    id: SCALE_WEBP_600x400,
  },
}
