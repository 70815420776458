import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { Media } from '@/components/Media'
import { Heading, Text } from 'ui'

import { BREAKPOINTS } from './constants'
import { CardRouteProps } from './types'

const BasicTeaser = ({ node }: CardRouteProps) => {
  return (
    <div className="flex flex-row items-center justify-between w-full gap-2">
      <div className="flex flex-col w-full gap-2">
        <Heading as="h3" className="max-w-[258px] text-base font-bold">
          {node.title}
        </Heading>
        <div className="flex flex-col items-start gap-2 sm:items-center sm:flex-row">
          <Text className="text-sm">
            <FormattedRichMessage
              id="searcher.routes.distance"
              values={{
                b: (chunks) => <b className="font-semibold">{chunks}:</b>,
                distance: `${Number(node.decimal_kmeters)}`,
              }}
            />
          </Text>
          <span className="hidden w-1 h-1 rounded-full bg-text-dark sm:flex" />
          <Text className="text-sm">
            <FormattedRichMessage
              id="searcher.routes.duration"
              values={{
                b: (chunks) => <b className="font-semibold">{chunks}:</b>,
                duration: `${node.duration_hours}:${node.duration_minutes}`,
                unit: '´',
              }}
            />
          </Text>
        </div>
      </div>
      {node.computed_image && (
        <div className="w-[10.5rem]">
          <Media
            media={
              node.computed_image ??
              node.images?.find((img) => img.image?.links !== undefined)
            }
            //@ts-ignore
            breakpoints={BREAKPOINTS}
          />
        </div>
      )}
    </div>
  )
}

export { BasicTeaser }
