import { useState } from 'react'
import { cn } from 'utils-tailwindcss'

import { FormattedMessage } from '../FormattedMessage'

const JumpHeader = () => {
  const [visible, setVisible] = useState(false)

  const handleFocus = () => {
    setVisible(true)
  }

  const handleBlur = () => {
    setVisible(false)
  }

  return (
    <a
      onFocus={handleFocus} // Muestra el mensaje cuando recibe foco
      onBlur={handleBlur} // Oculta el mensaje cuando pierde foco
      className={cn(
        `focus:border-2 w-fit ${
          visible ? 'h-auto opacity-100 p-2 m-2' : 'h-0 opacity-0'
        }`
      )}
      tabIndex={0}
      href="#jump-header"
    >
      {FormattedMessage({ id: 'global.skipToMainContent' })}
    </a>
  )
}

export { JumpHeader }
