import { CardRoute } from '@/components/CardRoute'

import { CardRouteProps } from './types'

const Route = ({ node }: CardRouteProps) => {
  const hours =
    Math.floor(node.duration / 60) > 0 ? Math.floor(node.duration / 60) : '00'

  const minutes =
    node.duration % 60 < 10 ? '0' + (node.duration % 60) : node.duration % 60

  const formatDuration = `${hours}:${minutes} h`
  return (
    <CardRoute
      title={node.title}
      image={
        node.computed_image ??
        node.images?.find((img) => img.image?.links !== undefined)
      }
      url={node.path?.alias}
      decimal_kmeters={node.decimal_kmeters}
      term_category={node.term_category}
      duration={formatDuration}
      location={node.text_address}
    />
  )
}

export { Route }
