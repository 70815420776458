import { ElementRef, forwardRef } from 'react'
import Link from 'next/link'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Media } from '@/components/Media'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import {
  CheckCircle2,
  CopyIcon,
  ExternalLinkIcon,
  MapPin,
  MinusCircleIcon,
  PlusCircleIcon,
} from 'lucide-react'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardImage,
  CardSubtitle,
  CardTitle,
  CloseButton,
  Tag,
  Text,
} from 'ui'
import { cn } from 'utils-tailwindcss'

import { MOBILE_BREAKPOINTS } from '../../constants'
import { LocationCardProps } from '../../types'
import { useCopyPath } from '../../useCopyPath'

const ItineraryMobile = forwardRef<ElementRef<typeof Card>, LocationCardProps>(
  (
    {
      className,
      location,
      image,
      tags,
      title,
      link,
      onCloseClick,
      onRemoveClick,
      onAddedClick,
      isAdded,
      gmapsUrl,
    },
    ref
  ) => {
    const {
      state: { showMessage },
      actions: { copyPath },
    } = useCopyPath(gmapsUrl || '')
    const handleClick = () => {
      copyPath()
    }

    const conditionalTranslationKeys = {
      copyToClipboard: 'searcher.routes.copyToClipboard',
      copied: 'searcher.routes.copied',
      knowMore: 'global.knowMore',
      addToMyItinerary: 'itinerary.addToMyItinerary',
      removeFromMyItinerary: 'itinerary.removeFromMyItinerary',
      externalLink: 'global.externalLink',
      newWindow: 'global.newWindow',
    }

    const translations: { [key: string]: string } = useCustomTranslations(
      conditionalTranslationKeys
    )

    return (
      <Card
        ref={ref}
        className={cn('h-fit min-w-[21rem] max-w-fit border', className)}
      >
        <CardContent className="gap-3 p-6">
          <CardTitle className="w-full pt-0 no-underline ">
            {title && (
              <div className="flex items-start justify-between mb-2">
                <Text className="text-lg font-bold max-w-[17.1875rem]">
                  {title}
                </Text>
                <CloseButton onClick={onCloseClick} />
              </div>
            )}
          </CardTitle>
          <div className="flex gap-4">
            {tags?.map((item) => (
              <Tag className="px-3 py-1 truncate" key={item.id}>
                {item.name}
              </Tag>
            ))}
          </div>
          <div className="flex flex-col gap-1">
            {location && (
              <div className="flex items-center gap-1 text-sm text-text-medium">
                <MapPin
                  size={16}
                  className="text-primary-red-900"
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
                <Text>{location}</Text>
              </div>
            )}

            {gmapsUrl && (
              <div className="relative inline-block">
                <Button
                  intent="tertiary"
                  onClick={handleClick}
                  className="gap-1 p-0 text-xs font-semibold w-fit"
                >
                  <CopyIcon
                    size={16}
                    aria-roledescription="presentation"
                    aria-hidden="true"
                  />
                  {translations.copyToClipboard}
                </Button>
                {showMessage && (
                  <Alert>
                    <CheckCircle2 height="1rem" />
                    {translations.copied}
                  </Alert>
                )}
              </div>
            )}
            <Link
              href={gmapsUrl ?? 'https://www.google.com/maps'}
              target="_blank"
              className="flex flex-row gap-1 text-xs font-semibold"
              aria-label={FormattedMessage({
                id: 'searcher.routes.openInGMaps',
              })}
              aria-describedby={translations.externalLink}
            >
              <ExternalLinkIcon
                size={16}
                aria-roledescription="presentation"
                aria-hidden="true"
              />
              <FormattedMessage id="searcher.routes.openInGMaps" />
            </Link>
            <p className="sr-only">{translations.newWindow}</p>
          </div>
          {image && (
            <CardImage className="flex flex-row justify-around w-full rounded h-fit">
              <div className="w-[8.5rem]">
                <Media
                  //@ts-ignore
                  breakpoints={MOBILE_BREAKPOINTS}
                  media={image}
                />
              </div>
            </CardImage>
          )}

          <CardSubtitle className="flex flex-col gap-6 py-3 text-xs text-text-medium">
            {link && (
              <>
                <Link
                  href={link || ''}
                  target="_blank"
                  className="flex flex-row gap-3 font-semibold text-secondary-black-900"
                  aria-label={translations.knowMore}
                  aria-describedby={translations.externalLink}
                >
                  <ExternalLinkIcon
                    aria-roledescription="presentation"
                    aria-hidden="true"
                  />
                  {translations.knowMore}
                </Link>
                <p className="sr-only">{translations.newWindow}</p>
              </>
            )}
            {!isAdded ? (
              <Button
                onClick={onAddedClick}
                intent={'tertiary'}
                className="justify-start pl-0"
              >
                <PlusCircleIcon
                  size={22}
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
                {translations.addToMyItinerary}
              </Button>
            ) : (
              <Button
                onClick={onRemoveClick}
                fullWidth={true}
                intent="tertiary"
                className="justify-start pl-0"
              >
                <MinusCircleIcon
                  size={22}
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
                {translations.removeFromMyItinerary}
              </Button>
            )}
          </CardSubtitle>
        </CardContent>
      </Card>
    )
  }
)

ItineraryMobile.displayName = 'ItineraryMobile'

export { ItineraryMobile }
