import { List, ListHeader } from '../List'
import {
  VIEW_MODE_ROUTES_DEFAULT,
  VIEW_MODE_ROUTES_WITHOUT_RESULTS,
} from '../List/components/Header/constants'
import { ListContent } from './components'
import { RoutesListProps } from './types'

const RoutesList = ({
  initialState,
  children,
  withResults = true,
  type,
  categories,
  ...rest
}: RoutesListProps) => (
  <List initialState={initialState} type={type} {...rest}>
    <ListHeader
      type={type}
      viewMode={
        withResults
          ? VIEW_MODE_ROUTES_DEFAULT
          : VIEW_MODE_ROUTES_WITHOUT_RESULTS
      }
    />
    {withResults && <ListContent categories={categories} />}
    {children}
  </List>
)
export { RoutesList }
