import { Paragraph } from '@/components/Paragraph/Paragraph'

import { DrupalParagraphTabsWrapper } from '../../types'

const Default = ({ paragraph }: { paragraph: DrupalParagraphTabsWrapper }) => (
  <div className="my-18">
    <div className="container">
      {paragraph.paragraph_contents.map((paragraph_content) => (
        <Paragraph key={paragraph_content.id} paragraph={paragraph_content} />
      ))}
    </div>
  </div>
)

export { Default }
