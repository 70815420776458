import { FormattedMessage } from '@/components/FormattedMessage'
import { useSearchForm } from '@/components/List/components/SearchForm/useSearchForm'
import { Heading, Searcher as SearcherUI } from 'ui'

export const Searcher = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  const {
    state: { querySearch },
    actions: { setQuerySearch, fetch },
  } = useSearchForm()

  return (
    <div className="flex flex-col items-center justify-between px-6 md:flex-row md:p-0">
      <div>
        <Heading as="h2">{title}</Heading>
        <Heading as="h3" className="pt-4 pb-6">
          {description}
        </Heading>
      </div>
      <SearcherUI
        text={FormattedMessage({ id: 'global.search' })}
        className="max-w-[26rem] w-full"
        value={querySearch}
        placeholder={FormattedMessage({ id: 'routeMap.searchPlaceOrRoute' })}
        onValueChange={setQuerySearch}
        onSearchClick={fetch}
      />
    </div>
  )
}
