import Link from 'next/link'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Language } from '@/components/PublicLayout/usePublicLayout'
import { useRouter } from 'next-translate-routes'

import { Dropdown } from './components/Dropdown'

const Menu = ({ languages }: { languages: Language[] }) => {
  const { locale } = useRouter()

  return (
    <div className="flex justify-end w-full gap-12 py-4 font-semibold text-neutral-white">
      <Link locale={locale} href="/crea-tu-itinerario" className="no-underline">
        <FormattedMessage id="global.createYourItinerary" />
      </Link>
      <Link locale={locale} href="/profesionales" className="no-underline">
        <FormattedMessage id="global.professionals" />
      </Link>
      <Dropdown languages={languages} />
    </div>
  )
}

export { Menu }
