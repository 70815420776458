import { FACETS_TYPE, TYPE_ROUTE } from '@/components/RoutesList/constants'
import { DrupalTaxonomyTermRouteCategory } from '@/components/TaxonomyTerm/components/RouteCategory/types'
import { TERM_ROUTE_CATEGORY } from '@/components/TaxonomyTerm/constants'
import { drupal } from '@/lib/drupal'
import { getParams } from '@/lib/get-params'
import { DrupalClient } from 'next-drupal'
import { getTaxonomyCollection } from 'utils-drupal'

export const getRoutesCategories = async (
  locale: string,
  defaultLocale: string
): Promise<Record<string, { label: string; value: string }[]>> => {
  const { tree } = await getTaxonomyCollection<
    DrupalTaxonomyTermRouteCategory,
    DrupalClient
  >(
    drupal,
    TERM_ROUTE_CATEGORY,
    getParams(TERM_ROUTE_CATEGORY).getQueryObject(),
    locale,
    defaultLocale,
    true
  )

  const categories: Record<string, { label: string; value: string }[]> = {}
  FACETS_TYPE[TYPE_ROUTE].forEach((categoryId) => {
    const categoriesId =
      tree
        .find((tax) => tax.id === categoryId)
        ?.children?.map((child) => ({
          label: child.name,
          value: child.drupal_internal__tid,
        })) ?? []

    const father = tree?.find((tax) => tax.id === categoryId)

    if (father) {
      categoriesId.push({
        label: father.name,
        value: father.drupal_internal__tid,
      })
    }

    categories[categoryId] = categoriesId
  })
  return categories
}
