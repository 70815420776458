import { useCallback, useEffect, useState } from 'react'
import { DATE_FILTER } from '@/components/List/constants'
import { useListProvider } from '@/components/List/ListProvider'
import { fetchIndexDrupal } from '@/components/List/services/api'
import { Facets, Filter } from '@/components/List/types'
import { NODE_EVENT_BASE } from '@/components/Node/constants'
import { BundleDrupalNode } from '@/components/Node/types'
import { convertFilters } from '@/components/RoutesList/helpers/utils'
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form'
import { HookOutput } from 'utils'

type useMobileResults = HookOutput<
  {
    showFilters: boolean
    form: UseFormReturn<FieldValues, any, undefined>
    results?: BundleDrupalNode[]
    facets?: Facets[]
    activeFilters?: Filter[]
    isPending: boolean
    total?: number
    total_items?: number
  },
  {
    onResetForm: () => void
    onDeleteActiveFilter: (_filters?: Filter[]) => void
    onSubmitFilter: (
      _data: Record<string, string | string[] | number[]>
    ) => void
    fetch: () => void
    setShowFilters: (_show: boolean) => void
  }
>

export const useMobile = (): useMobileResults => {
  const {
    state: { facets, activeFilters, total_items, query },
    removeFilterFromList,
    addFilters,
  } = useListProvider()

  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [total, setTotal] = useState(total_items)
  const [isPending, setIsPending] = useState(false)
  const form = useForm()

  const onResetForm = () => {
    removeFilterFromList(undefined)
    form.reset()
    setShowFilters(false)
  }

  const filterFetch = useCallback(
    async (data): Promise<number | undefined> => {
      try {
        const filters = convertFilters(data, facets)
        const dateFilter = activeFilters?.find(
          (filter) => filter.type === DATE_FILTER
        )
        const response = await fetchIndexDrupal(
          {
            per_page: 1,
            activeFilters: !!dateFilter ? [...filters, dateFilter] : filters,
            query: query,
          },
          NODE_EVENT_BASE,
          true
        )
        return response.total_items
      } catch (error) {
        console.error('Error en convertFilters: ', error)
        return 0
      }
    },
    [facets, query, activeFilters]
  )

  const fetch = useCallback(async () => {
    setIsPending(true)

    filterFetch(form.getValues()).then((response) => {
      setTotal(response)
      setIsPending(false)
    })
  }, [form, filterFetch])

  useEffect(() => {
    setTotal(total_items)
  }, [total_items])

  const onSubmitFilter = () => {
    const filters: Filter[] = []
    const values = form.getValues()
    const types = Object.keys(values)
    types.forEach((key) => {
      values[key].forEach((element) => {
        filters.push({ type: key, values: { value: element, label: element } })
      })
    })

    types.length && addFilters(filters, Object.keys(values))
  }

  const onDeleteActiveFilter = (filter?: Filter[]) => {
    removeFilterFromList(filter)
    form.reset()
    setShowFilters(false)
  }

  return {
    state: {
      isPending,
      total,
      form,
      activeFilters,
      showFilters,
      facets,
      total_items,
    },
    actions: {
      onSubmitFilter,
      onResetForm,
      fetch,
      setShowFilters,
      onDeleteActiveFilter,
    },
  }
}
