import { VIEW_MODE_DEFAULT } from '@/content/constants'

import { List, ListContent, ListFooter, ListHeader } from '../List'
import { VIEW_MODE_WITHOUT_RESULTS } from '../List/components/Header/constants'
import { AgendaListProps } from './types'

const AgendaList = ({
  initialState,
  children,
  withResults = true,
  isSearcher,
  ...rest
}: AgendaListProps) => (
  <List initialState={initialState} {...rest}>
    <ListHeader
      viewMode={withResults ? VIEW_MODE_DEFAULT : VIEW_MODE_WITHOUT_RESULTS}
    />
    <ListContent isSearcher={isSearcher}>{children}</ListContent>
    {withResults && <ListFooter />}
  </List>
)

export { AgendaList }
