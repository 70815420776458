import { X } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { Button } from '../../../Button'
import { ContentHeaderStyles } from './ContentHeader.styles'
import { ContentHeaderProps } from './types'

const ContentHeader = ({
  children,
  onValueChange = () => {},
  closable = true,
}: ContentHeaderProps) => {
  return (
    <div className={cn(ContentHeaderStyles.Root())}>
      <span className={cn(ContentHeaderStyles.Text())}>{children}</span>
      {closable && (
        <Button
          intent="link"
          aria-label="Close menu"
          onClick={() => {
            onValueChange('')
          }}
        >
          <X className={cn(ContentHeaderStyles.Icon())} />
        </Button>
      )}
    </div>
  )
}

export { ContentHeader }
