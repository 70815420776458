export const LANDING_FIELDS = [
  'path',
  'title',
  'status',
  'body',
  'metatags',
  'moderation_state',
  'paragraphs',
  'paragraphs.paragraphs',
  'paragraphs.paragraph_contents',
  'paragraphs.contents',
  'paragraphs.tabs',
  'text_address',
  'breadcrumbs',
  'computed_image',
  'term_categories',
  'interesting_block',
]

export const LANDING_CARD_FIELDS = [
  'path',
  'status',
  'title',
  'body',
  'term_categories',
  'text_address',
  'computed_image',
  'bool_exclude',
]
export const LANDING_CARD_INCLUDES = ['computed_image']

export const LANDING_INCLUDES = [
  'computed_image',
  'term_categories',
  'paragraphs',
  // img_custom_text
  'paragraphs.image',
  // cards & gallery (external content & internal content)
  'paragraphs.paragraph_contents',
  'paragraphs.paragraph_contents.image',
  'paragraphs.paragraph_contents.content',
  'paragraphs.paragraph_contents.content.contents',
  'paragraphs.paragraph_contents.content.term_category',
  'paragraphs.paragraph_contents.content.computed_image',
  'paragraphs.paragraph_contents.content.term_municipality',
  'paragraphs.paragraph_contents.document.file',
  //video
  'paragraphs.video',
  // itinerary
  'paragraphs.content',
  // itinerary & tags & map (normal)
  'paragraphs.contents',
  'paragraphs.contents.computed_image',
  'paragraphs.contents.term_category',
  // tags
  'paragraphs.terms',
  'paragraphs.computed_villages',
  'paragraphs.contents_tags',
  'paragraphs.contents_tags.computed_image',
  // accordion & map (multipoint)
  'paragraphs.paragraphs',
  // accordion
  'paragraphs.paragraphs.images',
  // map (multipoint)
  'paragraphs.paragraphs.content',
  'paragraphs.paragraphs.content.computed_image',
  'paragraphs.paragraphs.contents',
  'paragraphs.paragraphs.contents.computed_image',

  // custom_text & img_custom_text
  'paragraphs.documents.file',

  // tabs
  'paragraphs.tabs',
  'paragraphs.tabs.image',
  // tabs -> remote_video
  'paragraphs.tabs.video',
  // tabs -> img & img custom_text
  'paragraphs.tabs.documents.file',
  // tabs -> accordion
  'paragraphs.tabs.paragraphs',
  'paragraphs.tabs.paragraphs.images',

  //  tabs -> wrapper
  'paragraphs.tabs.paragraph_contents',
  //  tabs -> wrapper -> external-video
  'paragraphs.tabs.paragraph_contents.video',
  // tabs -> wrapper -> img_custom_text & (external content)
  'paragraphs.tabs.paragraph_contents.image',

  // tabs -> cards & gallery (external content & internal content)
  'paragraphs.tabs.paragraph_contents.content',
  'paragraphs.tabs.paragraph_contents.content.contents',
  'paragraphs.tabs.paragraph_contents.content.term_category',
  'paragraphs.tabs.paragraph_contents.content.computed_image',
  'paragraphs.tabs.paragraph_contents.content.term_municipality',

  // tabs -> (external content) && wrapper -> img_custom_text o custom_tex
  'paragraphs.tabs.paragraph_contents.document.file',
  'paragraphs.tabs.paragraph_contents.documents.file',

  // tabs -> wrapper -> cards & gallery (external content & internal content)
  'paragraphs.tabs.paragraph_contents.paragraph_contents',
  'paragraphs.tabs.paragraph_contents.paragraph_contents.image',
  'paragraphs.tabs.paragraph_contents.paragraph_contents.content',
  'paragraphs.tabs.paragraph_contents.paragraph_contents.content.contents',
  'paragraphs.tabs.paragraph_contents.paragraph_contents.content.term_category',
  'paragraphs.tabs.paragraph_contents.paragraph_contents.content.computed_image',
  'paragraphs.tabs.paragraph_contents.paragraph_contents.content.term_municipality',

  // tabs -> wrapper -> accordeon
  'paragraphs.tabs.paragraph_contents.paragraphs',
  // tabs -> wrapper ->  accordeon
  'paragraphs.tabs.paragraph_contents.paragraphs.images',

  //paragraph placeholder
  'paragraphs.cicla_search',

  'paragraphs.grantour_search',
  'paragraphs.grantour_queue',
  'paragraphs.grantour_queue.items',
  'paragraphs.grantour_queue.items.computed_image',
  'paragraphs.grantour_queue.items.term_category',
  'paragraphs.grantour_queue.items.term_route_level',

  'paragraphs.oneday_queue',
  'paragraphs.oneday_queue.items',
  'paragraphs.oneday_queue.items.computed_image',

  //events
  'paragraphs.village_events',
  'paragraphs.village_events.computed_image',
]
