import { useMemo } from 'react'
import dynamic from 'next/dynamic'
import { Carousel } from '@/components/Carousel'
import { CKEditor } from '@/components/CKEditor'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Grid } from '@/components/Grid'
import { Link as CustomLink } from '@/components/Link'
import { CICLA_MADRID_INDEX } from '@/components/List/constants'
import { LocationCard } from '@/components/LocationCard'
import { VIEW_MODE_DYNAMIC } from '@/components/LocationCard/constants'
import { Node } from '@/components/Node/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { Paragraph } from '@/components/Paragraph'
import { RoutesList } from '@/components/RoutesList'
import { getPosition } from '@/components/RoutesList/components/Map/helper'
import { Title } from '@/components/Title'
import {
  LAT_LON_MADRID,
  NEXT_PAGE_CICLA_MADRID_SEARCHER,
  NEXT_PAGE_GRAN_TOUR,
  VIEW_MODE_BASIC_CAROUSEL_3COL,
  VIEW_MODE_CARD_BASIC,
  VIEW_MODE_CARD_ROUTE,
} from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { BikeIcon, ExternalLink } from 'lucide-react'
import { Link } from 'next-translate-routes'
import { Button, Heading, Text } from 'ui'
import { DrupalFieldTextFormat } from 'utils-drupal'

import { DrupalNodeNextCiclaMadridPageProps } from './types'

const DynamicMap = dynamic(() => import('ui/src/components/maps/Map/Map'), {
  ssr: false,
})
const DynamicMapMarker = dynamic(
  () => import('ui/src/components/maps/Map/components/Marker/Marker'),
  {
    ssr: false,
  }
)

const CiclaMadridPage = ({ node }: DrupalNodeNextCiclaMadridPageProps) => {
  const urlSearcher = useMemo(
    () =>
      node.contents?.find(
        (nextPage) => nextPage.mapping === NEXT_PAGE_CICLA_MADRID_SEARCHER
      )?.path.alias,
    [node]
  )
  const urlGranTour = useMemo(
    () =>
      node.contents?.find(
        (nextPage) => nextPage.mapping === NEXT_PAGE_GRAN_TOUR
      )?.path.alias,
    [node]
  )

  const conditionalTranslationKeys = {
    granTour: 'ciclaMadrid.granTour',
    granTourDescription: 'ciclaMadrid.granTourDescription',
    goToGranTour: 'ciclaMadrid.goToGranTour',
    oneDayRoutes: 'ciclaMadrid.oneDayRoutes',
    oneDayRoutesDescription: 'ciclaMadrid.oneDayRoutesDescription',
    mapExplore: 'searcher.routes.mapExplore',
    highlightsRoutes: 'searcher.routes.highlightsRoutes',
    highlightRoutesDescription: 'ciclaMadrid.highlightRoutesDescription',
    routes: 'global.routes',
    knowAllRoutes: 'ciclaMadrid.knowAllRoutes',
    knowAllRoutesDescription: 'ciclaMadrid.knowAllRoutesDescription',
    findYourRoute: 'ciclaMadrid.findYourRoute',
    title: 'ciclaMadrid.services.title',
    description: 'ciclaMadrid.services.description',
    knowMore: 'ciclaMadrid.services.knowMore',
    ofInterest: 'node.ofInterest',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )
  return (
    <>
      <div className="container pt-8">
        <Title
          className="pb-0 md:pb-0"
          title={FormattedMessage({ id: 'ciclaMadrid.summaryTitle' })}
          subtitle={
            {
              value: FormattedMessage({ id: 'ciclaMadrid.summaryTitle' }),
            } as DrupalFieldTextFormat
          }
          description={
            {
              value: FormattedMessage({
                id: 'ciclaMadrid.summaryDescription',
              }),
            } as DrupalFieldTextFormat
          }
        />
        <RoutesList
          withResults={false}
          type={CICLA_MADRID_INDEX}
          initialState={{
            showResults: false,
            urlResults: urlSearcher,
          }}
        />
      </div>
      <div className="custom-container">
        {!!node.granTour?.length && (
          <Carousel
            title={translations.granTour}
            body={translations.granTourDescription}
            link={
              <div className="flex justify-end w-full mt-8 font-bold">
                <Link href={urlGranTour ?? ''}>
                  {translations.goToGranTour}
                </Link>
              </div>
            }
          >
            {node.granTour.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_ROUTE }}
                key={content.id}
              />
            ))}
          </Carousel>
        )}
        {!!node.oneDay?.length && (
          <Carousel
            title={translations.oneDayRoutes}
            body={translations.oneDayRoutesDescription}
            link={
              <div className="flex justify-end w-full mt-8 font-bold">
                <Link
                  href={
                    !!urlSearcher
                      ? `${urlSearcher}?range[routes_duration]=00:00 - 02:00 h`
                      : ''
                  }
                >
                  {translations.mapExplore}
                </Link>
              </div>
            }
          >
            {node.oneDay.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL }}
                key={content.id}
              />
            ))}
          </Carousel>
        )}
        {!!node.highlights?.length && (
          <Carousel
            title={translations.highlightsRoutes}
            body={translations.highlightRoutesDescription}
          >
            {node.highlights.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL }}
                key={content.id}
              />
            ))}
          </Carousel>
        )}
        <div className="flex flex-col w-full py-10 md:flex-row">
          <DynamicMap
            scrollWheelZoom={false}
            height={'348px'}
            className="z-0 rounded-xl"
            width="100%"
            zoom={13}
            center={LAT_LON_MADRID}
          >
            {node.map?.map((route) => (
              <DynamicMapMarker
                key={route.id}
                isActive={false}
                Icon={BikeIcon}
                position={
                  getPosition(route?.text_initial_point) ?? LAT_LON_MADRID
                }
              >
                <LocationCard
                  viewMode={VIEW_MODE_DYNAMIC}
                  title={translations.routes}
                  image={route.images?.[0]}
                  location={route.text_address}
                  description={{ value: route.title } as DrupalFieldTextFormat}
                />
              </DynamicMapMarker>
            ))}
          </DynamicMap>
          <div className="flex flex-col items-start justify-center w-full gap-6 p-0 mt-6 md:mt-0 md:p-10 bg-neutral-white">
            <Text className="text-4xl font-bold">
              {translations.knowAllRoutes}
            </Text>
            <Text className="text-2xl font-semibold">
              {translations.knowAllRoutesDescription}
            </Text>
            <Text className="text-base">{translations.findYourRoute}</Text>
            <Button>
              <Link href={urlSearcher ?? ''} className="no-underline">
                {translations.mapExplore}
              </Link>
            </Button>
          </div>
        </div>
      </div>
      {!!node?.route_services && (
        <div className="py-12 my-12 bg-neutral-gray-100 md:py-16 md:my-16">
          <div className="container ">
            <Heading as="h2">{translations.title}</Heading>
            {!!node.route_services.body ? (
              <CKEditor text={node.route_services.body} />
            ) : (
              <Text className="block my-8">{translations.description}</Text>
            )}
            <CustomLink
              href={node.route_services.path.alias}
              intent={'link'}
              className="underline"
            >
              <ExternalLink className="" />
              {translations.knowMore}
            </CustomLink>
          </div>
        </div>
      )}
      {!!node.gallery && <Paragraph paragraph={node.gallery} />}
      {!!node.ofInterest?.length && (
        <div className="py-16 px-28 bg-neutral-gray-100">
          <Grid title={translations.ofInterest} redLine={true} cols={4}>
            {node.ofInterest.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_BASIC }}
                key={content.id}
              />
            ))}
          </Grid>
        </div>
      )}
    </>
  )
}

export { CiclaMadridPage }
