import { Skeleton as SkeletonUi } from 'ui/src/components/atoms/Skeleton'

const Skeleton = () => (
  <div className="w-full h-[160px] md:h-[200px] lg:h-[250px] flex flex-col items-center">
    <SkeletonUi className="w-full max-h-[200px] h-full" />
    <SkeletonUi className="w-24/12 max-h-[30px] h-full mt-4" />
  </div>
)

export { Skeleton }
