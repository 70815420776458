import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { GENERAL_INDEX } from '@/components/List/constants'
import { fetchIndexDrupal } from '@/components/List/services/api'
import { BundleDrupalNode } from '@/components/Node/types'
import { HookOutput } from 'utils'

type UseExploreResult = HookOutput<
  {
    query: string
    data: BundleDrupalNode[]
    isPending: boolean
    haveData: boolean
  },
  {
    setQuery: Dispatch<SetStateAction<string>>
    search: (_e: string | KeyboardEvent) => void
    clear: () => void
  }
>

export const useExplore = (): UseExploreResult => {
  const [query, setQuery] = useState('')
  const [data, setData] = useState<BundleDrupalNode[]>([])
  const [isPending, setIsPending] = useState(false)
  const [haveData, setHaveData] = useState(false)

  const fetch = async (query) => {
    setIsPending(true)
    setHaveData(false)
    const response = await fetchIndexDrupal(
      { page: 1, per_page: 8, query: query },
      GENERAL_INDEX,
      true
    )
    setData(response.results ?? [])
    setHaveData(!!!response.results?.length)
  }

  const search = (e: string | KeyboardEvent) => {
    if (typeof e === 'string' && e !== '') {
      fetch(e)
    } else if (typeof e !== 'string' && e.key === 'Enter' && query !== '') {
      fetch(query)
    }
  }
  const clear = () => {
    setQuery('')
    setData([])
    setHaveData(false)
  }

  useEffect(() => {
    setIsPending(false)
  }, [data])

  return {
    state: { query, data, isPending, haveData },
    actions: { setQuery, search, clear },
  }
}
