import { CardActivity } from '@/components/CardActivity'

import { DrupalNodeActivityProps } from './types'

const Activity = ({ node, size }: DrupalNodeActivityProps) => (
  <CardActivity
    title={node.title}
    image={
      node.computed_image ??
      node.images?.find((img) => img.image?.links !== undefined)
    }
    url={node.path?.alias}
    term_municipality={node.term_municipality}
    date={node.dates}
    size={size}
  />
)

export { Activity }
