export const EXPERIENCE_FIELDS = [
  'path',
  'title',
  'body',
  'status',
  'term',
  'paragraphs',
  'moderation_state',
  'computed_image',
]

export const EXPERIENCE_INCLUDES = ['term', 'paragraphs', 'computed_image']
