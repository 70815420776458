import Link from 'next/link'
import { AttachedDocument } from '@/components/AttachedDocument'
import { CKEditor } from '@/components/CKEditor'
import { Title } from '@/components/Title'
import { internalLink } from '@/lib/utils/internalLink'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ExternalLink, LinkIcon } from 'lucide-react'

import { DrupalParagraphCustomTextProps } from '../../types'

const Default = ({
  paragraph,
}: {
  paragraph: DrupalParagraphCustomTextProps
}) => {
  const INTERNAL_LINK = paragraph.link && internalLink(paragraph.link.uri)

  const conditionalTranslationKeys = {
    showMore: 'paragraph.customText.showMore',
    externalLink: 'global.externalLink',
    internalLink: 'global.internalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div className="container flex flex-col gap-8 my-12">
      <Title title={paragraph.title} />
      <CKEditor text={paragraph.body} />
      <div className="flex flex-col gap-3">
        {paragraph.link && (
          <>
            <Link
              target={INTERNAL_LINK ? '_self' : '_blank'}
              href={paragraph.link.uri}
              className="flex gap-3 font-bold no-underline"
              aria-label={paragraph.link.title}
              aria-describedby={
                INTERNAL_LINK
                  ? translations.internalLink
                  : translations.externalLink
              }
            >
              {INTERNAL_LINK ? (
                <LinkIcon
                  className="w-4 md:w-6"
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
              ) : (
                <ExternalLink
                  className="w-4 md:w-6"
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
              )}
              {paragraph.link.title || translations.showMore}
            </Link>
            {!INTERNAL_LINK ? (
              <p className="sr-only">{translations.newWindow}</p>
            ) : null}
          </>
        )}
        {!!paragraph.documents?.length && (
          <AttachedDocument documents={paragraph.documents} />
        )}
      </div>
    </div>
  )
}

export { Default }
