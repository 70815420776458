import {
  Accordion,
  AccordionItem,
  Cards,
  CiclaMadridSearch,
  CustomText,
  Events,
  Experience,
  ExternalContent,
  Gallery,
  Grantour,
  Hero,
  ImgCustomText,
  InternalContent,
  ItineraryMap,
  OneDayRoutes,
  RemoteVideo,
  Resource,
  Tabs,
  TabsWrapper,
  Tags,
  VillageMap,
} from './components'
import { CreateItineraryMap } from './components/CreateItineraryMap'
import { Stage } from './components/Stage'

export const PARAGRAPH_EVENTS = 'paragraph--events'
export const PARAGRAPH_GALLERY = 'paragraph--gallery'
export const PARAGRAPH_ITINERARY_MAP = 'paragraph--itinerary'
export const PARAGRAPH_PLACEHOLDER_ITINERARY_MAP =
  'paragraph--placeholder_itinerary_map'
export const PARAGRAPH_CARDS = 'paragraph--cards'
export const PARAGRAPH_HERO = 'paragraph--hero'
export const PARAGRAPH_EXPERIENCE = 'paragraph--customize_experience'
export const PARAGRAPH_VILLAGE_MAP = 'paragraph--map'
export const PARAGRAPH_ROUTE_MAP = 'paragraph--route--map'
export const PARAGRAPH_IMG_CUSTOM_TEXT = 'paragraph--image_custom_text'
export const PARAGRAPH_CUSTOM_TEXT = 'paragraph--custom_text'
export const PARAGRAPH_TAGS = 'paragraph--tags'
export const PARAGRAPH_STAGE = 'paragraph--stage'
export const PARAGRAPH_INTERNAL_CONTENT = 'paragraph--content'
export const PARAGRAPH_EXTERNAL_CONTENT = 'paragraph--external_content'
export const PARAGRAPH_REMOTE_VIDEO = 'paragraph--remote_video'
export const PARAGRAPH_TABS = 'paragraph--tabs'
export const PARAGRAPH_TABS_WRAPPER = 'paragraph--tab_wrapper'
export const PARAGRAPH_ACCORDION = 'paragraph--accordion'
export const PARAGRAPH_ACCORDION_ITEM = 'paragraph--accordion_item'
export const PARAGRAPH_RESOURCE = 'paragraph--resource'
export const PARAGRAPH_PLACEHOLDER_CICLAMADIRD_SEARCH =
  'paragraph--placeholder_ciclamadrid_search'
export const PARAGRAPH_PLACEHOLDER_GRANTOUR = 'paragraph--placeholder_grantour'
export const PARAGRAPH_PLACEHOLDER_ONEDAY_ROUTES =
  'paragraph--placeholder_oneday_routes'

export const PARAGRAPHS = {
  [PARAGRAPH_EVENTS]: Events,
  [PARAGRAPH_GALLERY]: Gallery,
  [PARAGRAPH_HERO]: Hero,
  [PARAGRAPH_EXPERIENCE]: Experience,
  [PARAGRAPH_ITINERARY_MAP]: ItineraryMap,
  [PARAGRAPH_VILLAGE_MAP]: VillageMap,
  [PARAGRAPH_CARDS]: Cards,
  [PARAGRAPH_IMG_CUSTOM_TEXT]: ImgCustomText,
  [PARAGRAPH_CUSTOM_TEXT]: CustomText,
  [PARAGRAPH_TAGS]: Tags,
  [PARAGRAPH_STAGE]: Stage,
  [PARAGRAPH_INTERNAL_CONTENT]: InternalContent,
  [PARAGRAPH_EXTERNAL_CONTENT]: ExternalContent,
  [PARAGRAPH_REMOTE_VIDEO]: RemoteVideo,
  [PARAGRAPH_PLACEHOLDER_ITINERARY_MAP]: CreateItineraryMap,
  [PARAGRAPH_TABS]: Tabs,
  [PARAGRAPH_TABS_WRAPPER]: TabsWrapper,
  [PARAGRAPH_ACCORDION]: Accordion,
  [PARAGRAPH_ACCORDION_ITEM]: AccordionItem,
  [PARAGRAPH_RESOURCE]: Resource,
  [PARAGRAPH_PLACEHOLDER_CICLAMADIRD_SEARCH]: CiclaMadridSearch,
  [PARAGRAPH_PLACEHOLDER_GRANTOUR]: Grantour,
  [PARAGRAPH_PLACEHOLDER_ONEDAY_ROUTES]: OneDayRoutes,
}
