import { useTranslations } from 'next-intl'

export const useCustomTranslations = (keys) => {
  const t = useTranslations()

  return Object.keys(keys).reduce((acc, key) => {
    acc[key] = t(keys[key])
    return acc
  }, {})
}
