import {
  VIEW_MODE_DEFAULT,
  VIEW_MODE_REMOTE_VIDEO_FULL,
} from '@/content/constants'

import { DrupalParagraphRemoteVideoProps } from './types'
import { Default } from './viewmodes/Default'
import { Full } from './viewmodes/Full'

const RemoteVideo = ({
  paragraph,
  viewMode = VIEW_MODE_DEFAULT,
  ...rest
}: DrupalParagraphRemoteVideoProps) => (
  <div className={`${paragraph.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        case VIEW_MODE_REMOTE_VIDEO_FULL:
          return <Full paragraph={paragraph} {...rest} />
        default:
          return <Default paragraph={paragraph} {...rest} />
      }
    })()}
  </div>
)

export { RemoteVideo }
