import { FormattedMessage } from '@/components/FormattedMessage'
import { DATE_FILTER } from '@/components/List/constants'
import { useListProvider } from '@/components/List/ListProvider'
import { Title } from '@/components/Title'
import { ArrowRightIcon } from 'lucide-react'
import { Link } from 'next-translate-routes'
import { Button, Text } from 'ui'
import { DrupalFieldTextFormat } from 'utils-drupal'

import { CheckBoxList } from '../../../CheckBoxList'
import { CheckBoxFilter } from '../../../CheckBoxList/types'
import { DatePicker } from '../../../DatePicker'

const WithoutResults = () => {
  const {
    state: { facets, urlResults },
    goToActivitiesPageWithFilters,
  } = useListProvider()

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full">
        <Title
          title={FormattedMessage({ id: 'searcher.agenda.homeTitle' })}
          subtitle={
            {
              value: FormattedMessage({ id: 'searcher.agenda.textForFilters' }),
            } as DrupalFieldTextFormat
          }
        />
        <div className="relative flex flex-col w-full p-8 border border-neutral-gray-200 lg:flex-row lg:justify-between h-fit ">
          <DatePicker />
          {facets
            ?.filter((facet) => facet.id !== DATE_FILTER)
            .map((filter: CheckBoxFilter, key: number) => (
              <CheckBoxList {...filter} key={key} />
            ))}

          <Button
            intent="primary"
            size="sm"
            className="w-full h-fit lg:w-fit md:justify-self-end md:self-end"
            onClick={() => goToActivitiesPageWithFilters()}
          >
            <FormattedMessage id="global.search" />
          </Button>
        </div>
      </div>
      <Link
        href={urlResults ?? ''}
        className="flex flex-row self-end gap-3 mt-8 w-fit"
      >
        <Text className="font-bold">
          <FormattedMessage id="searcher.agenda.viewAllActivities" />
        </Text>
        <ArrowRightIcon />
      </Link>
    </div>
  )
}
export { WithoutResults }
