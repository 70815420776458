import * as SelectPrimitive from '@radix-ui/react-select'
import { ChevronDown } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { TriggerStyles } from './Trigger.styles'
import { TriggerProps } from './types'

export const Trigger = ({
  placeholder,
  className,
  isDisabled = false,
  ...rest
}: TriggerProps) => (
  <SelectPrimitive.Trigger
    disabled={isDisabled}
    className={cn(TriggerStyles.root({ isDisabled }), className)}
    {...rest}
  >
    <SelectPrimitive.Value placeholder={placeholder} />
    <SelectPrimitive.Icon className={TriggerStyles.icon()}>
      <ChevronDown size={24} />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
)
