import { Language } from '@/components/PublicLayout/usePublicLayout'

import { MegamenuType } from '../../../Megamenu/types'
import { HeaderBase } from '../HeaderBase/HeaderBase'

export const InternalHeader = ({
  megamenu,
  languages,
}: {
  megamenu: MegamenuType
  languages: Language[]
}) => (
  <div className="absolute top-0 left-0 right-0 w-full">
    <HeaderBase megamenu={megamenu} languages={languages} />
  </div>
)
