import { VIEW_MODE_TEXT_WITH_IMAGE_FLOAT_RIGHT } from '@/content/constants'

import { DrupalParagraphImgCustomTextProps } from './types'
import { Default } from './viewmodes/Default'
import { ImageFloatRigh } from './viewmodes/ImageFloatRigh'

const ImgCustomText = ({
  paragraph,
  viewMode,
  ...rest
}: DrupalParagraphImgCustomTextProps) => (
  <div className={`${paragraph.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        case VIEW_MODE_TEXT_WITH_IMAGE_FLOAT_RIGHT:
          return <ImageFloatRigh paragraph={paragraph} {...rest} />
        default:
          return <Default paragraph={paragraph} {...rest} />
      }
    })()}
  </div>
)

export { ImgCustomText }
