import { Heading } from 'ui'
import { DrupalFieldTextFormat, DrupalFieldTextLongFormat } from 'utils-drupal'
import { cn } from 'utils-tailwindcss'

import { CKEditor } from '../CKEditor'

export const Title = ({
  title,
  subtitle,
  className,
  description,
  redLine = false,
}: {
  title?: string
  subtitle?: DrupalFieldTextFormat | DrupalFieldTextLongFormat
  description?: DrupalFieldTextFormat | DrupalFieldTextLongFormat
  className?: string
  redLine?: boolean
}) => (
  <div className={cn('flex flex-col gap-4 pb-4 md:pb-8 md:gap-8', className)}>
    {(title || subtitle) && (
      <div
        className={cn(
          'flex flex-col w-full gap-3',
          !redLine && 'after:bg-primary-red-900 after:flex after:w-16 after:h-1'
        )}
      >
        {title && <Heading as="h2">{title}</Heading>}
        {subtitle && (
          <CKEditor
            text={subtitle}
            className="text-lg font-semibold text-text-medium"
          />
        )}
      </div>
    )}
    {description && (
      <CKEditor className="text-text-medium" text={description} />
    )}
  </div>
)
