import { Dispatch, useEffect, useState } from 'react'
import { HookOutput } from 'utils'

type UseInteractiveMapResult = HookOutput<
  { hoveredPath: number | null; clickedPath: string[] },
  {
    setHoveredPath: Dispatch<React.SetStateAction<number | null>>
    onClick: (_id: string) => void
  }
>

export const useInteractiveMap = (
  value: string[],
  onPathClick: ((_id: string) => void) | undefined
): UseInteractiveMapResult => {
  const [hoveredPath, setHoveredPath] = useState<number | null>(null)
  const [clickedPath, setClickedPath] = useState<string[]>([])

  const onClick = (id: string) => {
    if (id) {
      setClickedPath([id])
      if (onPathClick) {
        onPathClick(id)
      }
    }
  }

  useEffect(() => {
    if (clickedPath.toString() !== value.toString()) {
      setClickedPath(value)
    }
  }, [clickedPath, value])

  return {
    state: { hoveredPath, clickedPath },
    actions: { setHoveredPath, onClick },
  }
}
