import { forwardRef } from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'
import { cn } from 'utils-tailwindcss'

import { SliderStyles } from './Slider.styles'
import { SliderProps } from './types'

const Slider = forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  SliderProps
>(({ className, children, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(SliderStyles.root(), className)}
    {...props}
  >
    <SliderPrimitive.Track className={SliderStyles.track()}>
      <SliderPrimitive.Range className={SliderStyles.range()} />
    </SliderPrimitive.Track>
    {children}
  </SliderPrimitive.Root>
))

Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
