import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { Label } from 'ui'

import { DrupalTaxonomyTermMunicipalityProps } from './types'

const Municipality = ({
  viewMode = VIEW_MODE_DEFAULT,
  term,
}: DrupalTaxonomyTermMunicipalityProps) => {
  switch (viewMode) {
    default:
      return <Label>{term?.name?.value}</Label>
  }
}

export { Municipality }
