import Link from 'next/link'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Card as CardUI } from 'ui'
import { cn } from 'utils-tailwindcss'

import { NODE_ESTABLISHMENT } from '../Node/constants'
import { Content } from './components'
import { CardBasicProps } from './types'

const CardBasic = ({
  title,
  image,
  url,
  className,
  type,
  externalLink = false,
  popup = false,
  description,
}: CardBasicProps) => {
  const conditionalTranslationKeys = {
    externalLinkDescribedby: 'global.externalLink',
    internalLink: 'global.internalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <CardUI className={cn('h-full bg-transparent', className)}>
      {!url || type === NODE_ESTABLISHMENT || popup ? (
        <Content
          title={title}
          image={image}
          popup={popup}
          description={description}
        />
      ) : (
        <>
          <Link
            href={url}
            className="flex flex-col h-full no-underline hover:underline"
            target={externalLink ? '_blank' : '_self'}
            aria-label={title}
            aria-describedby={
              externalLink
                ? translations.externalLinkDescribedby
                : translations.internalLink
            }
          >
            <Content title={title} image={image} />
          </Link>
          {externalLink ? (
            <p className="sr-only">{translations.newWindow}</p>
          ) : null}
        </>
      )}
    </CardUI>
  )
}

export { CardBasic }
