import { ReactNode } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { XIcon } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { ContentStyles } from './Content.styles'

export const Content = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay
        className={cn(ContentStyles.overlay(), className)}
      >
        <DialogPrimitive.Content className={ContentStyles.content()}>
          <div className={ContentStyles.image()}>
            <DialogPrimitive.Close
              className={ContentStyles.close()}
            ></DialogPrimitive.Close>
            {children}
            <div className={ContentStyles.closeButton()}>
              <XIcon />
            </div>
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Overlay>
    </DialogPrimitive.Portal>
  )
}
