'use client'

import { useEffect, useState } from 'react'
import { cn } from 'utils-tailwindcss'

import { CarouselStyles } from './Carousel.styles'
import { CarouselProps } from './types'
import {
  CarouselApi,
  CarouselContentPrimitive,
  CarouselItemPrimitive,
  CarouselNextPrimitive,
  CarouselPreviousPrimitive,
  CarouselPrimitive,
} from './useCarousel'

const Carousel = ({
  children,
  cols = 3,
  accountant = false,
  className,
  onSlideClick = () => {},
}: CarouselProps) => {
  const [api, setApi] = useState<CarouselApi>()
  const [current, setCurrent] = useState(0)
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (!api) {
      return
    }

    setCount(api.scrollSnapList().length)
    setCurrent(api.selectedScrollSnap() + 1)

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1)
      setCount(api.scrollSnapList().length)
    })
  }, [api])

  return (
    <CarouselPrimitive setApi={setApi}>
      <CarouselContentPrimitive
        className={cn(CarouselStyles.content(), className)}
      >
        {children.map((item: JSX.Element, index: number) => (
          <CarouselItemPrimitive
            onClick={() => onSlideClick(index)}
            className={cn(
              ` ${
                cols === 'auto'
                  ? 'basis-auto py-[2px]'
                  : 'basis-[89%] md:basis-[46%]'
              }  ${cols === 4 && 'lg:basis-[23%]'} ${
                cols === 3 && 'lg:basis-[28%]'
              } ${cols === 1 && 'lg:basis-1/2'}`
            )}
            key={index}
          >
            {item}
          </CarouselItemPrimitive>
        ))}
      </CarouselContentPrimitive>
      <CarouselPreviousPrimitive
        className={CarouselStyles.carouselNavigation()}
      />
      <CarouselNextPrimitive className={CarouselStyles.carouselNavigation()} />
      <div className={CarouselStyles.accountatntContainer()}>
        {accountant && (
          <div className={CarouselStyles.accountatnt()}>
            <span>{current}</span> / <span>{count}</span>
          </div>
        )}
      </div>
    </CarouselPrimitive>
  )
}

export { Carousel }
