import { createElement, useState } from 'react'
import { usePathname } from 'next/navigation'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { LucideIcon } from 'lucide-react'
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  Form,
  FormField,
  Input,
  Text,
  useForm,
} from 'ui'

const MailTo = ({
  icon,
  language,
  title,
}: {
  icon: LucideIcon
  language: string
  title: string
}) => {
  const url = usePathname()
  const [open, setOpen] = useState(false)
  const form = useForm({ reValidateMode: 'onChange' })

  const subject = FormattedRichMessage({
    id: 'node.share.subject',
    values: { title },
  }).toString()

  const body = FormattedRichMessage({
    id: 'node.share.body',
    values: {
      title,
      url: `${process.env.NEXT_PUBLIC_NEXTJS_BASE_URL}${url}`,
    },
  }).toString()

  const handleOnSubmit = (data) => {
    if (!!!data.color) {
      const mailtoURL = `mailto:${data.email}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`
      window.location.href = mailtoURL
      setOpen(false)
    }
  }

  const conditionalTranslationKeys = {
    textShare: 'node.share.textShare',
    emailShare: 'node.share.emailShare',
    emailExample: 'node.share.emailExample',
    error: 'itinerary.error',
    share: 'global.share',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <button
        className="flex items-center gap-2 px-3 py-1 text-base no-underline cursor-pointer hover:bg-neutral-gray-200 hover:border-2 hover:border-secondary-black-900 w-full"
        aria-label={language}
        onClick={() => setOpen((open) => !open)}
      >
        {createElement(icon, { role: 'true', 'aria-hidden': 'true' })}
        <span>{language}</span>
      </button>
      {open && (
        <Dialog
          open={open}
          intent={'white'}
          onOpenChange={() => {
            setOpen(false)
            form.reset()
          }}
        >
          <DialogHeader className="items-center pr-6 ">
            {translations.textShare}
          </DialogHeader>
          <DialogBody>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(handleOnSubmit)}
                className="flex flex-col pt-3 max-w-[460px] space-y-4"
              >
                <FormField
                  name="email"
                  render={(value) => (
                    <div className="relative">
                      <span className="absolute z-40 px-2 text-xs bg-neutral-white -top-2 left-3">
                        {translations.emailShare}
                      </span>
                      <Input
                        {...form.register('email', {
                          required: {
                            value: true,
                            message: 'itinerary.error',
                          },
                          pattern: {
                            value: new RegExp('^[^@]+@[^@]+\\.[^@]+$'),
                            message: 'itinerary.error',
                          },
                        })}
                        placeholder={translations.emailExample}
                        name="email"
                        className="placeholder-text-dark"
                      />
                      {value.fieldState.error && (
                        <Text className="text-primary-red-900">
                          {translations.error}
                        </Text>
                      )}
                    </div>
                  )}
                />
                <FormField
                  name="color"
                  render={() => (
                    <Input
                      className="invisible h-0 py-0"
                      {...form.register('color', {
                        minLength: {
                          value: 4,
                          message: 'itinerary.error',
                        },
                      })}
                      placeholder={translations.emailExample}
                      name="color"
                    />
                  )}
                />
                <Button type="submit" className="w-fit">
                  {translations.share}
                </Button>
              </form>
            </Form>
          </DialogBody>
        </Dialog>
      )}
    </>
  )
}
export { MailTo }
