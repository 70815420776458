import { HTMLAttributes } from 'react'
import { FormLabel, Heading } from 'ui'

type LabelProps = { label: string } & HTMLAttributes<HTMLParagraphElement>
export const FilterLabel = ({ label, children }: LabelProps) => (
  <FormLabel className="flex items-center gap-4 truncate">
    {children}
    <Heading as="h3">{label}</Heading>
  </FormLabel>
)
