import { CardActivity } from '@/components/CardActivity'

const Activity = ({
  paragraph_content,
  size,
}: {
  paragraph_content
  size?: 'carousel_3col'
}) => (
  <CardActivity
    title={paragraph_content.title}
    image={paragraph_content.image}
    url={paragraph_content.link?.uri}
    size={size}
    popup={!!paragraph_content.popup}
    description={paragraph_content.body}
  />
)

export { Activity }
