import { CardActivity } from '@/components/CardActivity'

import { DrupalNodeActivityProps } from './types'

const Activity = ({ node, size }: DrupalNodeActivityProps) => {
  const date = !!node.date_range
    ? new Date(Date.parse(node.date_range?.value)) ?? null
    : null
  return (
    <CardActivity
      title={node.title}
      image={node.computed_image}
      url={node.path?.alias}
      term_municipality={node.term_municipality}
      date={date}
      size={size}
    />
  )
}

export { Activity }
