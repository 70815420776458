import { VIEW_MODE_DEFAULT } from '@/content/constants'

import { DrupalParagraphExperienceProps } from './types'
import { Default } from './viewmodes/Default'

const Experience = ({
  paragraph,
  viewMode = VIEW_MODE_DEFAULT,
  ...rest
}: DrupalParagraphExperienceProps) => {
  return (
    <div className={`${paragraph.type} view-mode-${viewMode}`}>
      {(() => {
        switch (viewMode) {
          default:
            return <Default paragraph={paragraph} {...rest} />
        }
      })()}
    </div>
  )
}

export { Experience }
