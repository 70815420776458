'use client'

import { useTranslations } from 'next-intl'

const FormattedRichMessage = ({ id, values }: { id: string; values: any }) => {
  const t = useTranslations()

  return t.rich(id, values)
}

export { FormattedRichMessage }
