import { DrupalNodeItinerary } from '@/components/Node/components/Itinerary/types'

export const getItineraries = async (
  id: string,
  locale: string
): Promise<DrupalNodeItinerary[]> => {
  const response = await fetch('/api/get-itineraries', {
    body: JSON.stringify({ id, locale }),
    method: 'POST',
  })

  const itineraries = await response.json()

  return itineraries.contents ?? []
}
