import { LIST_TYPE_ITINERARY } from '@/components/Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'

import { Default } from './viewmodes/Default'
import { Itinerary } from './viewmodes/Itinerary'

const Current = ({ type }) => {
  const ViewMode = type === LIST_TYPE_ITINERARY ? Itinerary : Default
  return <ViewMode />
}

export { Current }
