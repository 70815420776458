import { ElementRef, forwardRef } from 'react'
import Link from 'next/link'
import { CKEditor } from '@/components/CKEditor'
import { ImageStyle } from '@/components/ImageStyle'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { CopyIcon, ExternalLinkIcon, MapPin } from 'lucide-react'
import { Button, Card, CardImage, CardSubtitle, Text } from 'ui'
import { useCopyToClipboard } from 'utils'

import { BREAKPOINTS } from './constants'
import { LocationCardProps } from './types'

const LocationCard = forwardRef<ElementRef<typeof Card>, LocationCardProps>(
  ({ node }, ref) => {
    const [_, copy] = useCopyToClipboard()

    const conditionalTranslationKeys = {
      openInGMaps: 'searcher.routes.openInGMaps',
      copyToClipboard: 'searcher.routes.copyToClipboard',
      externalLink: 'global.externalLink',
      newWindow: 'global.newWindow',
    }

    const translations: { [key: string]: string } = useCustomTranslations(
      conditionalTranslationKeys
    )

    return (
      <>
        <CardImage className="self-center w-full h-full rounded" ref={ref}>
          {node.computed_image && (
            <ImageStyle
              className="w-full h-full rounded"
              breakpoints={BREAKPOINTS}
              file={node.computed_image.image}
            />
          )}
          {node.text_address && (
            <div className="flex items-center gap-2 py-3 text-text-medium">
              <MapPin
                size={16}
                className="text-primary-red-900"
                aria-roledescription="presentation"
                aria-hidden="true"
              />
              <Text>{node.text_address}</Text>
            </div>
          )}
        </CardImage>
        {node.gmaps_url && (
          <>
            <Link
              href={node.gmaps_url}
              target="_blank"
              className="flex flex-row gap-3 font-semibold"
              aria-label={translations.openInGMaps}
              aria-describedby={translations.externalLink}
            >
              <ExternalLinkIcon
                aria-roledescription="presentation"
                aria-hidden="true"
              />
              {translations.openInGMaps}
            </Link>
            <p className="sr-only">{translations.newWindow}</p>
          </>
        )}
        {node.text_address && (
          <Button
            intent="tertiary"
            className="p-0"
            onClick={() => {
              copy(node.text_address ?? '')
            }}
          >
            <CopyIcon aria-roledescription="presentation" aria-hidden="true" />
            {translations.copyToClipboard}
          </Button>
        )}
        <CardSubtitle className="flex flex-col gap-6 py-3 text-text-medium">
          <CKEditor text={node.body} />
        </CardSubtitle>
      </>
    )
  }
)

LocationCard.displayName = 'LocationCard'

export { LocationCard }
