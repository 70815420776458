import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Language } from '@/components/PublicLayout/usePublicLayout'
import { useRouter } from 'next-translate-routes'
import { HookOutput } from 'utils'

import { LANGUAGES } from './constants'

type useDropdownResult = HookOutput<
  {
    selected: string
    open: boolean
    languageSelected: Language | undefined
  },
  {
    setSelected: Dispatch<SetStateAction<string>>
    setOpen: Dispatch<SetStateAction<boolean>>
  }
>

export const useDropdown = (): useDropdownResult => {
  const route = useRouter()
  const [selected, setSelected] = useState(route.locale ?? 'es')
  const [open, setOpen] = useState(false)
  const languageSelected: Language | undefined = useMemo(() => {
    if (route.locale && selected !== route.locale) {
      setSelected(route.locale)
      return LANGUAGES.find((lang) => lang.id === route.locale) as Language
    }
    return LANGUAGES.find((lang) => lang.id === selected) as Language
  }, [selected, route.locale])

  return {
    state: {
      selected,
      open,
      languageSelected,
    },
    actions: {
      setSelected,
      setOpen,
    },
  }
}
