import { VIEW_MODE_DEFAULT } from '@/content/constants'

import { DrupalParagraphResourceProps } from './types'
import { Default } from './viewmodes'

const Resource = ({
  paragraph,
  viewMode = VIEW_MODE_DEFAULT,
  ...rest
}: DrupalParagraphResourceProps) => (
  <div className={`${paragraph.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        default:
          return <Default paragraph={paragraph} {...rest} />
      }
    })()}
  </div>
)

export { Resource }
