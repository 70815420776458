import { FormattedMessage } from '@/components/FormattedMessage'
import { Ruler } from 'lucide-react'
import { InfoTag, InfoTagBody, InfoTagHeader } from 'ui'

const Distance = ({ node }) => (
  <InfoTag>
    <InfoTagHeader>
      <Ruler />
      <FormattedMessage id="global.distance" />
    </InfoTagHeader>
    <InfoTagBody>{node.decimal_kmeters} Km</InfoTagBody>
  </InfoTag>
)

export { Distance }
