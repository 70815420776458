import { DrupalFile } from 'next-drupal'

export const MOCK_FILE = (): DrupalFile => ({
  // JsonApiResource
  id: 'other-uuid-change-cause-of-hydratation',
  type: 'media--file',
  langcode: '',
  status: true,
  // DrupalFile
  drupal_internal__fid: '',
  changed: '0000-00-00T00:00:00+00:00',
  created: '0000-00-00T00:00:00+00:00',
  filename: 'lorem.png',
  uri: {
    value: 'public://2023-01/lorem.png',
    url: '/sites/default/files/2023-01/lorem.png',
  },
  filesize: 0,
  filemime: 'image/png',
})
