import { cva } from 'class-variance-authority'

export const ContentStyles = {
  content: cva([
    'relative',
    'flex w-full h-full overflow-hidden rounded-lg z-[999] items-center justify-center',
    'animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-18 ',
    'focus-visible:outline-none',
  ]),
  overlay: cva([
    'fixed inset-0 grid z-40',
    'bg-neutral-gray-200 bg-opacity-80 backdrop-blur-sm',
    'transition-all duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in',
  ]),
  close: cva([
    [
      'absolute right-8 z-50 top-0 rounded-sm opacity-70',

      'h-full w-full cursor-default',
    ],
  ]),
  image: cva(' items-center justify-center flex w-full h-auto max-h-full'),
  closeButton: cva(['self-start pr-8']),
}
