'use client'

import { useRouter } from 'next-translate-routes'
import { formattedDate } from 'utils'

import { Options } from './types'

const FormattedDate = ({ date, options }: { date: Date; options: Options }) => {
  const router = useRouter()

  return formattedDate(date, router.locale ?? 'es', options)
}

export { FormattedDate }
