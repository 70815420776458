import {
  VIEW_MODE_ACTIVITY_CAROUSEL_3COL,
  VIEW_MODE_BASIC_CAROUSEL_3COL,
  VIEW_MODE_BASIC_VERTICAL_CAROUSEL_3COL,
  VIEW_MODE_CARD_ACTIVITY,
  VIEW_MODE_CARD_BASIC_VERTICAL,
  VIEW_MODE_CARD_ROUTE,
  VIEW_MODE_CARD_TEASER,
  VIEW_MODE_CARD_TEASER_HORIZONTAL,
  VIEW_MODE_CARD_TEASER_LONG,
  VIEW_MODE_DEFAULT,
  VIEW_MODE_ROUTE_CAROUSEL_3COL,
  VIEW_MODE_TEASER_CAROUSEL_3COL,
} from '@/content/constants'

import { DrupalParagraphExternalContentProps } from './types'
import { Activity, Basic, Route, Teaser, VerticalBasic } from './viewmodes'

const ExternalContent = ({
  paragraph,
  viewMode = VIEW_MODE_DEFAULT,
  ...rest
}: DrupalParagraphExternalContentProps) => (
  <div className={`${paragraph.type} view-mode-${viewMode} h-full`}>
    {(() => {
      switch (paragraph.content_viewmode) {
        case VIEW_MODE_CARD_ACTIVITY:
          return <Activity paragraph_content={paragraph} {...rest} />

        case VIEW_MODE_BASIC_CAROUSEL_3COL:
          return <Basic paragraph_content={paragraph} {...rest} />
        case VIEW_MODE_BASIC_VERTICAL_CAROUSEL_3COL:
          return <VerticalBasic paragraph_content={paragraph} {...rest} />
        case VIEW_MODE_ACTIVITY_CAROUSEL_3COL:
          return (
            <Activity
              paragraph_content={paragraph}
              size="carousel_3col"
              {...rest}
            />
          )
        case VIEW_MODE_CARD_ROUTE:
          return <Route paragraph_content={paragraph} {...rest} />
        case VIEW_MODE_ROUTE_CAROUSEL_3COL:
          return <Route paragraph_content={paragraph} {...rest} />
        case VIEW_MODE_CARD_TEASER:
          return (
            <Teaser paragraph_content={paragraph} size={'small'} {...rest} />
          )
        case VIEW_MODE_TEASER_CAROUSEL_3COL:
          return (
            <Teaser
              paragraph_content={paragraph}
              size="carousel_3col"
              {...rest}
            />
          )
        case VIEW_MODE_CARD_TEASER_LONG:
          return (
            <Teaser paragraph_content={paragraph} size={'long'} {...rest} />
          )

        case VIEW_MODE_CARD_TEASER_HORIZONTAL:
          return (
            <Teaser
              paragraph_content={paragraph}
              size={'horizontal'}
              {...rest}
            />
          )

        case VIEW_MODE_CARD_BASIC_VERTICAL:
          return <VerticalBasic paragraph_content={paragraph} {...rest} />

        default:
          return <Basic paragraph_content={paragraph} {...rest} />
      }
    })()}
  </div>
)

export { ExternalContent }
