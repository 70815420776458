import { format } from 'date-fns'

export const generateDateFormatted = (occurrences?: string[]): string[] => {
  const dates: string[] = []
  if (occurrences?.[0]) {
    const startDate = new Date(occurrences[0])
    startDate.toString() !== 'Invalid Date' &&
      dates.push(format(startDate, 'dd/MM/yy'))
  }
  if (occurrences?.at(-1)) {
    const endDate = new Date(occurrences?.at(-1) ?? '')
    if (endDate.toString() !== 'Invalid Date') {
      const formatted = format(endDate, 'dd/MM/yy')
      formatted !== dates[0] && dates.push(formatted)
    }
  }

  return dates
}
