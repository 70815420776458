import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Filter } from '@/components/List/types'
import { DrupalNodeItinerary } from '@/components/Node/components/Itinerary/types'
import { HookOutput } from 'utils'

import { useItineraryProvider } from '../../../../ItineraryProvider'
import { useMapListProvider } from '../../../../MapListProvider'

type useNavigationResult = HookOutput<
  {
    showFilters: boolean
    isLoading?: boolean
    activeFilters?: Filter[]
    type: string
    total_items?: number
    showItineraries: boolean
    selected: DrupalNodeItinerary[]
    showItinerariesDialog: boolean
    showNavigation: boolean
  },
  {
    toogleFilters: (_showFilter: boolean, _showSort: boolean) => void
    toogleShowItineraries: () => void
    toogleShowItinerariesDialog: () => void
    setShowNavigation: Dispatch<SetStateAction<boolean>>
  }
>

export const useNavigation = (): useNavigationResult => {
  const {
    state: { showFilters },
    listState: { isLoading, activeFilters, results, total_items },
    type,
    setCurrentRoute,
    toogleFilters,
  } = useMapListProvider()
  const {
    state: { showItineraries, selected, showItinerariesDialog },
    toogleShowItineraries,
    toogleShowItinerariesDialog,
  } = useItineraryProvider()
  const [showNavigation, setShowNavigation] = useState(false)

  useEffect(() => {
    setShowNavigation(true)
  }, [])

  useEffect(() => {
    if (!results?.length) {
      setCurrentRoute(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  return {
    state: {
      showFilters,
      isLoading,
      activeFilters,
      type,
      total_items,
      showItineraries,
      selected,
      showItinerariesDialog,
      showNavigation,
    },
    actions: {
      toogleFilters,
      toogleShowItineraries,
      toogleShowItinerariesDialog,
      setShowNavigation,
    },
  }
}
