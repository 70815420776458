import { Grid as GlobalGrid } from '@/components/Grid'
import { Link } from '@/components/Link'
import { Paragraph } from '@/components/Paragraph/Paragraph'
import {
  VIEW_MODE_CARD_BASIC,
  VIEW_MODE_CARD_BIG_BASIC,
} from '@/content/constants'
import { internalLink } from '@/lib/utils/internalLink'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ArrowRight, ExternalLink } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { DrupalParagraphContent } from '../../types'
import { DrupalParagraphCardsGridProps } from './types'

const Grid = ({ paragraph, cols = 2 }: DrupalParagraphCardsGridProps) => {
  const INTERNAL_LINK = paragraph.link && internalLink(paragraph.link.uri)

  const conditionalTranslationKeys = {
    externalLink: 'global.externalLink',
    internalLink: 'global.internalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )
  return (
    <div
      className={cn(
        `${!!paragraph.village_filter && 'bg-neutral-gray-100 py-4'}`
      )}
    >
      <GlobalGrid
        className="container my-12 md:my-18"
        title={paragraph.title}
        body={paragraph.body}
        subtitle={paragraph.subtitle}
        cols={cols}
        redLine={!!paragraph.underlined}
      >
        {paragraph.paragraph_contents?.map(
          (paragraph_content: DrupalParagraphContent, index) => (
            <Paragraph
              key={index}
              paragraph={{
                ...paragraph_content,
                content_viewmode:
                  cols === 2
                    ? VIEW_MODE_CARD_BIG_BASIC
                    : paragraph.content_view_mode ?? VIEW_MODE_CARD_BASIC,
                cols: cols,
                popup: paragraph.bool_popup,
              }}
            />
          )
        )}
      </GlobalGrid>
      {paragraph.link && (
        <div className="container mb-12 text-right md:mb-18">
          <Link
            target={INTERNAL_LINK ? '_self' : '_blank'}
            href={paragraph.link.uri}
            intent="link"
            className="font-semibold underline"
            aria-label={paragraph.link.title}
            aria-describedby={
              INTERNAL_LINK
                ? translations.internalLink
                : translations.externalLink
            }
          >
            {paragraph.link.title}
            {INTERNAL_LINK ? (
              <ArrowRight
                aria-roledescription="presentation"
                aria-hidden="true"
              />
            ) : (
              <ExternalLink
                aria-roledescription="presentation"
                aria-hidden="true"
              />
            )}
          </Link>
          {!INTERNAL_LINK ? (
            <p className="sr-only">{translations.newWindow}</p>
          ) : null}
        </div>
      )}
    </div>
  )
}
export { Grid }
