import { Language } from '@/components/PublicLayout/usePublicLayout'

import { MegamenuType } from '../Megamenu/types'
import { HomeHeader } from './components/HomeHeader/HomeHeader'
import { InternalHeader } from './components/InternalHeader/InternalHeader'

export const DesktopHeader = ({
  isHome = false,
  ...rest
}: {
  isHome: boolean
  megamenu: MegamenuType
  languages: Language[]
}) => {
  return isHome ? <HomeHeader {...rest} /> : <InternalHeader {...rest} />
}
