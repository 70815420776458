import { ElementRef, forwardRef } from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { cn } from 'utils-tailwindcss'

import { AccordionStyles } from './Accordion.styles'
import { AccordionRootProps } from './types'

const Accordion = forwardRef<
  ElementRef<typeof AccordionPrimitive.Root>,
  AccordionRootProps
>(({ className, children, disabled = false, ...props }, ref) => (
  <AccordionPrimitive.Root
    ref={ref}
    className={cn(AccordionStyles.root({ disabled }), className)}
    disabled={disabled}
    {...props}
  >
    {children}
  </AccordionPrimitive.Root>
))

Accordion.displayName = 'Accordion'

export { Accordion }
