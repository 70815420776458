export const USER_ITINERARY_FIELDS = ['contents', 'type', 'title', 'status']

export const USER_ITINERARY_INCLUDES = [
  'contents',
  'contents.image',
  'contents.term_municipality',
  'contents.term_category',
  'contents.term_services',
  'contents.term_category',
  'contents.term_type',
]
