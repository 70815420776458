import { useMemo } from 'react'
import Link from 'next/link'
import { Carousel } from '@/components/Carousel'
import { Node } from '@/components/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { VIEW_MODE_CARD_BASIC } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'

import { DrupalParagraphOneDayRoutesProps } from '../../types'

const Default = ({
  paragraph,
}: {
  paragraph: DrupalParagraphOneDayRoutesProps
}) => {
  const ITEMS = paragraph.oneday_queue?.[0]?.items

  const urlCiclaOneDay = useMemo(
    () =>
      `${paragraph.cicla_search?.[0]?.path.alias}?range[routes_duration]=00:00 - 02:00 h` ||
      '',
    [paragraph]
  )

  const conditionalTranslationKeys = {
    mapExplore: 'searcher.routes.mapExplore',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      {!!ITEMS?.length && (
        <div className="custom-container">
          <Carousel
            title={paragraph.title}
            subtitle={paragraph.subtitle}
            body={paragraph.body}
            link={
              !!paragraph.cicla_search?.[0]?.path.alias && (
                <div className="container flex justify-end w-full mt-8 font-bold">
                  <Link href={urlCiclaOneDay} className="text-right">
                    {translations.mapExplore}
                  </Link>
                </div>
              )
            }
          >
            {ITEMS.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_BASIC }}
                key={content.id}
              />
            ))}
          </Carousel>
        </div>
      )}
    </>
  )
}

export { Default }
