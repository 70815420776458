import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import { ACTION_SET_STATE } from '@/components/List/constants'
import { useListProvider } from '@/components/List/ListProvider'
import { DrupalNodeItinerary } from '@/components/Node/components/Itinerary/types'
import { DrupalNodeRoute } from '@/components/Node/components/Route/types'
import { useForm } from 'react-hook-form'

import {
  SET_CURRENT_ROUTE,
  SET_PAGE,
  SET_SHOW_FILTERS,
  SET_SHOW_SORT_FILTERS,
  SORT_FILTER,
  TOOGLE_FILTERS,
} from '../../constants'
import {
  convertActiveFiltersToFormFilter,
  convertFilters,
} from '../../helpers/utils'
import { ContextProps, State } from './types'

export const reducer = (
  state: State,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action
  switch (type) {
    case SET_CURRENT_ROUTE:
      return { ...state, currentRoute: payload }
    case SET_SHOW_FILTERS:
      return { ...state, showFilters: payload }
    case SET_SHOW_SORT_FILTERS:
      return { ...state, showSort: payload }
    case TOOGLE_FILTERS:
      return {
        ...state,
        showSort: payload.showSort,
        showFilters: payload.showFilters,
      }
    case SET_PAGE:
      return { ...state, pageMobile: payload }
    case ACTION_SET_STATE:
      return { ...state, ...payload }
    default:
      return state
  }
}

const MapListContext = createContext<ContextProps | undefined>(undefined)
MapListContext.displayName = 'MapListContext'

const MapListProvider = ({
  children,
  categories,
}: {
  children: ReactNode
  categories?: Record<string, string[]>
}) => {
  const [state, dispatch] = useReducer(reducer, {
    form: useForm(),
    showFilters: false,
    showSort: false,
    pageMobile: 1,
    perPageMobile: 12,
    categories: categories,
  })

  const {
    addFilters,
    removeFilter,
    removeFilterFromList,
    state: listState,
    type,
  } = useListProvider()

  const setCurrentRoute = useCallback(
    (route: DrupalNodeRoute | DrupalNodeItinerary) => {
      dispatch({ type: SET_CURRENT_ROUTE, payload: route })
    },
    [dispatch]
  )

  const setShowFilters = (showFilter: boolean) => {
    dispatch({ type: SET_SHOW_FILTERS, payload: showFilter })
  }

  const setShowSort = (showSort: boolean) => {
    dispatch({ type: SET_SHOW_SORT_FILTERS, payload: showSort })
  }
  const toogleFilters = (showFilters: boolean, showSort: boolean) => {
    dispatch({
      type: TOOGLE_FILTERS,
      payload: { showFilters, showSort },
    })
  }
  const setPageMobile = useCallback((page: number) => {
    dispatch({ type: SET_PAGE, payload: page })
  }, [])

  const onSubmitFilter = useCallback(
    (data: Record<string, string | string[] | number[]>) => {
      const newFilters = convertFilters(data, listState.facets)
      const types = newFilters.map((filter) => filter.type!)
      if (!newFilters.length && !types.length) {
        removeFilter()
      } else {
        addFilters(newFilters, [...types])
        setShowFilters(false)
        setPageMobile(1)
      }
    },
    [addFilters, listState.facets, removeFilter, setPageMobile]
  )

  const onResetForm = useCallback(() => {
    const activeFilters =
      listState.activeFilters?.filter((fil) => fil.type === SORT_FILTER) ?? []
    removeFilterFromList(activeFilters)
    state.form.reset()
    setShowFilters(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeFilterFromList, state.form])

  useEffect(() => {
    setShowFilters(false)
    listState.activeFilters === undefined
      ? state.form.reset()
      : convertActiveFiltersToFormFilter(listState.activeFilters).forEach(
          (item) =>
            state.form.setValue(
              item.type,
              item.type === SORT_FILTER ? item.data[0] : item.data
            )
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listState.activeFilters])

  const value = useMemo(() => {
    return {
      state,
      listState,
      type,
      onResetForm,
      setCurrentRoute,
      setShowFilters,
      onSubmitFilter,
      setShowSort,
      toogleFilters,
      removeFilterFromList,
      removeFilter,
      setPageMobile,
    }
  }, [
    state,
    listState,
    type,
    onResetForm,
    setCurrentRoute,
    onSubmitFilter,
    removeFilterFromList,
    removeFilter,
    setPageMobile,
  ])

  return (
    <MapListContext.Provider value={value}>{children}</MapListContext.Provider>
  )
}

const useMapListProvider = () => {
  const ctxValue = useContext(MapListContext)
  if (!ctxValue)
    throw new Error(`Please wrap in an ${MapListContext.displayName} component`)
  const {
    state,
    listState,
    type,
    onResetForm,
    setCurrentRoute,
    setShowFilters,
    onSubmitFilter,
    setShowSort,
    toogleFilters,
    removeFilterFromList,
    removeFilter,
    setPageMobile,
  } = ctxValue
  return {
    state,
    listState,
    type,
    onResetForm,
    setCurrentRoute,
    setShowFilters,
    onSubmitFilter,
    setShowSort,
    toogleFilters,
    removeFilterFromList,
    removeFilter,
    setPageMobile,
  }
}

export { MapListProvider, useMapListProvider }
