import { Node } from '@/components/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { VIEW_MODE_BASIC_CAROUSEL_3COL } from '@/content/constants'

import { HighlightCardSkeleton } from '../../../HighlightCard'

const ResultCards = ({
  highlights,
  isPending,
}: {
  highlights: BundleDrupalNode[]
  isPending: boolean
}) => (
  <div className={'grid grid-cols-2 gap-4 md:grid-cols-4 md:gap-8'}>
    {isPending
      ? [0, 1, 2, 3].map((key) => <HighlightCardSkeleton key={key} />)
      : highlights.map((highlight, key) => (
          <Node
            key={key}
            node={{
              ...highlight,
              viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL,
              className: 'bg-opacity-0',
            }}
          />
        ))}
  </div>
)

export { ResultCards }
