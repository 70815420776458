import { ActivitiesList } from '@/components/ActivitiesList'
import { AgendaList } from '@/components/AgendaList'
import { Grid } from '@/components/Grid'
import { DATE_FILTER } from '@/components/List/constants'
import { formattedDateToStringLabel } from '@/components/List/utils/helpers'
import { NODE_EVENT_BASE } from '@/components/Node/constants'
import { Node } from '@/components/Node/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { VIEW_MODE_CARD_BASIC } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'

import { DrupalNodeNextAgendaActivitiesPageProps } from './types'

const AgendaActivitiesPage = ({
  node,
}: DrupalNodeNextAgendaActivitiesPageProps) => {
  const date = { from: new Date() }

  const conditionalTranslationKeys = {
    ofInterest: 'node.ofInterest',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <AgendaList
        initialState={{
          ...node.response,
          activeFilters: [
            {
              date: date,
              values: {
                value: formattedDateToStringLabel(node.locale ?? 'es', date),
                label: formattedDateToStringLabel(node.locale ?? 'es', date),
              },
              type: DATE_FILTER,
            },
          ],
          showResults: true,
          page: 1,
          per_page: 24,
        }}
        type={NODE_EVENT_BASE}
        isSearcher={true}
      >
        <ActivitiesList />
      </AgendaList>
      {!!node.ofInterest?.length && (
        <div className="py-16 bg-neutral-gray-100">
          <Grid title={translations.ofInterest} redLine={true} cols={3}>
            {node.ofInterest.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_BASIC }}
                key={content.id}
              />
            ))}
          </Grid>
        </div>
      )}
    </>
  )
}

export { AgendaActivitiesPage }
