import { FormattedMessage } from '@/components/FormattedMessage'
import { Text } from 'ui'
import { cn } from 'utils-tailwindcss'

const NoResults = ({ className }: { className?: string }) => (
  <div
    className={cn(
      `flex flex-col flex-wrap w-full col-span-2 pt-8 pl-2 md:h-full bg-neutral-white ${className}`
    )}
  >
    <Text className="text-lg font-semibold">
      <FormattedMessage id="searcher.noResults" />
    </Text>
    <Text className="mt-4 text-base ">
      <FormattedMessage id="searcher.searchAdvice" />
    </Text>
    <ul className="pl-8 mt-2 space-y-2 text-xs list-disc">
      <li>
        <FormattedMessage id="searcher.tryOthersFilters" />
      </li>
      <li>
        <FormattedMessage id="searcher.noSpecialCharacters" />
      </li>
      <li>
        <FormattedMessage id="searcher.checkTheSpelling" />
      </li>
      <li>
        <FormattedMessage id="searcher.trySynonyms" />
      </li>
    </ul>
  </div>
)

export { NoResults }
