import { DrupalParagraph } from 'next-drupal'
import { generateUuid } from 'utils'

export const MOCK_PARAGRAPH = (type: string): DrupalParagraph => ({
  // JsonApiResource
  id: generateUuid(),
  type: type,
  langcode: '',
  status: true,
  // DrupalParagraph
  drupal_internal__id: 0,
  drupal_internal__revision_id: 0,
})
