import { Children, cloneElement } from 'react'
import { cn } from 'utils-tailwindcss'

import { NavigationMenuStyles } from './NavigationMenu.styles'
import { NavigationMenuProps } from './types'

const NavigationMenu = ({
  children,
  value,
  onValueChange,
  className,
}: NavigationMenuProps) => {
  return (
    <div className={cn(NavigationMenuStyles.Root(), className)}>
      <div className={cn(NavigationMenuStyles.Div())}>
        {Children.map(children, (child) =>
          cloneElement(child, {
            value,
            onValueChange,
          })
        )}
      </div>
    </div>
  )
}

export { NavigationMenu }
