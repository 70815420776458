import { DrupalMedia } from 'next-drupal'

export const MOCK_MEDIA = (type: string): DrupalMedia => ({
  // JsonApiResource
  id: 'uuid-change-cause-of-hydratation',
  type: type,
  langcode: '',
  status: true,
  // DrupalMedia
  drupal_internal__mid: '',
  drupal_internal__vid: '',
  changed: '0000-00-00T00:00:00+00:00',
  created: '0000-00-00T00:00:00+00:00',
  name: `Lorem ipsum dolor `,
  description: `Lorem ipsum has been the industry's standard dummy text ever since on the others, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
  link: {
    title: 'Conoce más',
    uri: 'https://www.google.es',
    options: {
      attributes: {
        target: '_black',
      },
    },
  },
})
