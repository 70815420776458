import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { contentTouristPointType } from '@/components/Node/components/Route/types'
import { LAT_LON_MADRID, LAT_MADRID, LON_MADRID } from '@/content/constants'
import { useWidth } from 'utils'

import { useMapProvider } from '../../MapProvider'
import { LocationCard } from '../LocationCard'

const DynamicMap = dynamic(() => import('ui/src/components/maps/Map/Map'), {
  ssr: false,
})
const DynamicMapMarker = dynamic(
  () => import('ui/src/components/maps/Map/components/Marker/Marker'),
  {
    ssr: false,
  }
)
const DynamicMapRouting = dynamic(
  () => import('ui/src/components/maps/Map/components/Routing'),
  {
    ssr: false,
  }
)

export const Map = ({ isNavigationOpen }: { isNavigationOpen?: boolean }) => {
  const {
    state: { currentTouristicPoint, routes, touristicPoints },
    actions: { setCurrentTouristicPoint },
  } = useMapProvider()
  const { isMobile } = useWidth()
  const [center, setCenter] = useState<[number, number] | undefined>(
    routes[0]?.trkseg[0].points?.map(
      (point) => [Number(point.lat), Number(point.lon)] as [number, number]
    )[0] ?? LAT_LON_MADRID
  )

  useEffect(() => {
    currentTouristicPoint &&
      currentTouristicPoint.geofield_address &&
      setCenter([
        currentTouristicPoint.geofield_address?.lat || 0,
        currentTouristicPoint.geofield_address?.lon ||
          0 - (isNavigationOpen ? 0.025 : 0),
      ])
  }, [isNavigationOpen, currentTouristicPoint])

  return (
    <DynamicMap
      scrollWheelZoom={false}
      height={isMobile() ? '80vh' : '43.625rem'}
      className={`${isMobile() && 'rounded-none'} rounded z-0`}
      width="100%"
      calculateCenter
      center={center}
      zoom={14}
    >
      {touristicPoints.map((touristPoint: contentTouristPointType) => (
        <DynamicMapMarker
          key={touristPoint.id}
          isActive={touristPoint.id === currentTouristicPoint?.id}
          position={[
            touristPoint.geofield_address?.lat || LAT_MADRID,
            touristPoint.geofield_address?.lon || LON_MADRID,
          ]}
          eventHandlers={{
            click: () => {
              setCurrentTouristicPoint(touristPoint)
            },
          }}
        >
          {currentTouristicPoint && isMobile() && (
            <LocationCard
              onCloseClick={() => {
                setCurrentTouristicPoint(null)
              }}
              touristicPoint={currentTouristicPoint}
            />
          )}
        </DynamicMapMarker>
      ))}
      {routes?.map((trk) => (
        <DynamicMapRouting
          key={`${trk.name}-${trk.desc}`}
          points={trk.trkseg[0].points?.map((point) => [
            Number(point.lat),
            Number(point.lon),
          ])}
        />
      ))}
    </DynamicMap>
  )
}
