import { HTMLAttributes } from 'react'
import { cn } from 'utils-tailwindcss'

const Content = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLElement>) => (
  <main className={cn('w-full', className)} {...props}>
    {children}
  </main>
)

export { Content }
