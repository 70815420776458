import { Media } from '@/components/Media'
import { VIEW_MODE_DEFAULT } from '@/content/constants'

import { CustomizeExperienceButton, MobileVideo } from './components'
import { DrupalParagraphHeroDefaultProps } from './types'

const Default = ({ paragraph }: DrupalParagraphHeroDefaultProps) => (
  <div className="flex flex-col w-full">
    <div className="relative w-full">
      <div
        className="md:hidden h-[425px] bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url("${paragraph.image.image}")` }}
      >
        <Media media={{ ...paragraph.image }} />
        <div className="container absolute left-0 right-0 z-20 flex flex-col gap-3 bottom-4">
          <MobileVideo>
            <Media
              media={{ ...paragraph.video, viewMode: VIEW_MODE_DEFAULT }}
            />
          </MobileVideo>
          <CustomizeExperienceButton href="#customExperience" />
        </div>
      </div>
      <div className="hidden md:flex h-[80vh] w-full">
        <Media media={{ ...paragraph.video, viewMode: VIEW_MODE_DEFAULT }} />
        <div className="container ">
          <CustomizeExperienceButton href="#customExperience" />
        </div>
      </div>
    </div>
  </div>
)

export { Default }
