'use client'

import { DrupalTaxonomyTermCustomExperience } from '@/components/TaxonomyTerm/components/CustomExperience/types'
import { Select } from 'ui'
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
} from 'ui/src/components/atoms/Select'

import { SelectSectionProps } from './types'

export const SelectSection = ({
  id,
  label,
  ariaLabel,
  placeholder,
  options = [],
  disabled,
  onChange,
  ...rest
}: SelectSectionProps) => (
  <div className="flex flex-col w-full gap-4 ">
    <label
      className="text-sm font-bold md:text-lg md:font-semibold"
      htmlFor={id}
    >
      {label}
    </label>
    <Select onValueChange={onChange} {...rest}>
      <SelectTrigger
        aria-labelledby={ariaLabel}
        placeholder={placeholder}
        isDisabled={disabled}
        id={id}
      />
      <SelectContent ariaLabel={ariaLabel} className="z-40">
        {options.map((f: DrupalTaxonomyTermCustomExperience, i) => (
          <SelectItem
            value={f.name}
            id={f.id}
            key={`${f}-${i}`}
            className="hover:border-2 focus:bg-neutral-gray-300 border-secondary-black-900 focus:border-2"
          />
        ))}
      </SelectContent>
    </Select>
  </div>
)
