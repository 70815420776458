import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { Label } from 'ui'

import { DrupalTaxonomyTermTouristPlanTypeProps } from './types'

const TouristPlanType = ({
  viewMode = VIEW_MODE_DEFAULT,
  term,
}: DrupalTaxonomyTermTouristPlanTypeProps) => {
  switch (viewMode) {
    default:
      return <Label>{term.name}</Label>
  }
}

export { TouristPlanType }
