import { Carousel } from '@/components/Carousel'
import { Grid } from '@/components/Grid'
import { CICLA_MADRID_INDEX } from '@/components/List/constants'
import { Node } from '@/components/Node/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { RoutesList } from '@/components/RoutesList'
import { NEXT_PAGE_GRAN_TOUR, VIEW_MODE_CARD_ROUTE } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Link } from 'next-translate-routes'

import { DrupalNodeNextCiclaMadridSearcherPageProps } from './types'

const CiclaMadridSearcherPage = ({
  node,
}: DrupalNodeNextCiclaMadridSearcherPageProps) => {
  const conditionalTranslationKeys = {
    granTourTitle: 'ciclaMadrid.searcher.granTourTitle',
    granTourSubtitle: 'ciclaMadrid.searcher.granTourSubtitle',
    granTourDescription: 'ciclaMadrid.searcher.granTourDescription',
    knowMore: 'global.knowMore',
    highlights: 'ciclaMadrid.searcher.highlights',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <div className="container">
        <RoutesList
          initialState={{ ...node.response, per_page: 50 }}
          type={CICLA_MADRID_INDEX}
        />
        {!!node.granTour?.length && (
          <Carousel
            title={translations.granTourTitle}
            subtitle={{
              format: '',
              summary: '',
              processed: '',
              value: translations.granTourSubtitle,
            }}
            body={{
              format: '',
              summary: '',
              processed: '',
              value: translations.granTourDescription,
            }}
            link={
              <div className="flex justify-end w-full">
                <Link
                  href={
                    node.contents?.find(
                      (nextPage) => nextPage.mapping === NEXT_PAGE_GRAN_TOUR
                    )?.path.alias ?? ''
                  }
                  className="text-lg font-bold"
                >
                  {translations.knowMore}
                </Link>
              </div>
            }
          >
            {node.granTour.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_ROUTE }}
                key={content.id}
              />
            ))}
          </Carousel>
        )}
      </div>

      {!!node.highlightsRoutes?.length && (
        <div className="py-16 px-28 bg-neutral-gray-100">
          <Grid title={translations.highlights} redLine={true} cols={3}>
            {node.highlightsRoutes.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_ROUTE }}
                key={content.id}
              />
            ))}
          </Grid>
        </div>
      )}
    </>
  )
}

export { CiclaMadridSearcherPage }
