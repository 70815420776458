import { forwardRef, HTMLAttributes } from 'react'
import { cn } from 'utils-tailwindcss'

import { SubtitleStyles } from './Subtitle.styles'

const Subtitle = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn(SubtitleStyles(), className)} {...props}>
      {children}
    </div>
  )
)

Subtitle.displayName = 'Subtitle'

export { Subtitle }
