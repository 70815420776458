import { Desktop } from './components/Desktop'
import { Mobile } from './components/Mobile'

const Default = () => (
  <>
    <Mobile /> <Desktop />
  </>
)

export { Default }
