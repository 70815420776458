const ContentPopup = ({ image, body, title }) => (
  <>
    {body ? (
      <div
        className="
          grid gap-8 container
          grid-cols-1 overflow-y-auto
          lg:overflow-hidden
          lg:grid-cols-2 lg:grid-rows-[1fr 1fr]
          max-h-[90vh] lg:max-h-[100vh]
          z-50
          custom-scroll
          items-center
          "
      >
        <span>{title}</span>
        <div className="lg:row-start-2">{image}</div>
        <div className="w-full h-full lg:relative lg:row-start-2 lg:max-h-[42vh]">
          <div className="top-0 bottom-0 left-0 right-0 lg:absolute lg:overflow-y-auto custom-scroll">
            <div className="flex h-full">{body}</div>
          </div>
        </div>
      </div>
    ) : (
      <div className="container grid w-full place-items-center">
        <div className="w-[40vw] mb-8">{title}</div>
        <div className="w-[40vw]">{image}</div>
      </div>
    )}
  </>
)

export { ContentPopup }
