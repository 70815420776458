import { Fragment, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { LocationCard } from '@/components/LocationCard'
import { LAT_LON_MADRID, LAT_MADRID, LON_MADRID } from '@/content/constants'
import { useWidth } from 'utils'

import { useVillagesMapProvider } from '../../VillagesMapProvider'

const DynamicMap = dynamic(() => import('ui/src/components/maps/Map/Map'), {
  ssr: false,
})
const DynamicMapMarker = dynamic(
  () => import('ui/src/components/maps/Map/components/Marker/Marker'),
  {
    ssr: false,
  }
)

const getCenter = (
  isMobile,
  currentVillage,
  villagePositions
): [number, number] | null => {
  if (currentVillage && !currentVillage.isMultipoint) {
    return !isMobile
      ? [villagePositions.lat, villagePositions.lon - 0.03]
      : [
          currentVillage?.geofield_address?.lat || LAT_MADRID,
          currentVillage?.geofield_address?.lon || LON_MADRID,
        ]
  } else {
    return null
  }
}

const getZoom = (isMobile, currentVillage) => {
  return isMobile || (currentVillage && currentVillage.isMultipoint) ? 9 : 13
}

export const Map = () => {
  const {
    state: { currentVillage, villages },
    actions: { setCurrentVillage },
  } = useVillagesMapProvider()
  const { isMobile } = useWidth()
  const [villagePositions, setVillagePositions] = useState<{
    lat: number
    lon: number
  }>({
    lat: LAT_MADRID,
    lon: LON_MADRID,
  })

  useEffect(() => {
    if (currentVillage && !currentVillage.isMultipoint) {
      setVillagePositions({
        lat: currentVillage.geofield_address?.lat || LAT_MADRID,
        lon: currentVillage.geofield_address?.lon || LON_MADRID,
      })
    }
  }, [currentVillage])

  const [center, setCenter] = useState<[number, number]>(LAT_LON_MADRID)

  useEffect(() => {
    setCenter(
      getCenter(isMobile(), currentVillage, villagePositions) ?? LAT_LON_MADRID
    )
  }, [currentVillage, isMobile, villagePositions])

  const zoom = getZoom(isMobile, currentVillage)

  return (
    <DynamicMap
      height={isMobile() ? '80vh' : '47.8125rem'}
      className={`${isMobile() && 'rounded-none'} rounded -z-0`}
      width="100%"
      calculateCenter
      //@ts-ignore
      center={center}
      zoom={zoom}
    >
      {villages.map((village) => {
        return !village.isMultipoint ? (
          <DynamicMapMarker
            key={village.id}
            isActive={village.id === currentVillage?.id}
            position={[
              village.geofield_address?.lat || LAT_MADRID,
              village.geofield_address?.lon || LON_MADRID,
            ]}
            eventHandlers={{
              click: () => {
                setCurrentVillage(village)
              },
            }}
          >
            {currentVillage && isMobile() && (
              <LocationCard
                onCloseClick={() => {
                  setCurrentVillage(null)
                }}
                title={currentVillage.title}
                description={currentVillage.body}
                location={currentVillage.text_address}
                image={currentVillage.computed_image}
                link={currentVillage.path.alias}
              />
            )}
          </DynamicMapMarker>
        ) : (
          <Fragment key={village.id}>
            {currentVillage &&
              currentVillage.contents?.map((village) => (
                <DynamicMapMarker
                  key={village.id}
                  isActive={false}
                  position={[
                    village.geofield_address?.lat || LAT_MADRID,
                    village.geofield_address?.lon || LON_MADRID,
                  ]}
                  interactive={isMobile() ? true : false}
                >
                  {currentVillage && isMobile() && (
                    <LocationCard
                      onCloseClick={() => {
                        setCurrentVillage(null)
                      }}
                      title={currentVillage.title}
                      description={currentVillage.body}
                      location={currentVillage.text_address}
                      image={currentVillage.computed_image}
                      link={currentVillage.path.alias}
                    />
                  )}
                </DynamicMapMarker>
              ))}
          </Fragment>
        )
      })}
    </DynamicMap>
  )
}
