'use client'

import { useEffect, useMemo, useState } from 'react'
import Link from 'next/link'
import { FormattedMessage } from '@/components/FormattedMessage'
import { DrupalTaxonomyTermCustomExperience } from '@/components/TaxonomyTerm/components/CustomExperience/types'
import { cn } from 'utils-tailwindcss'

import { findExperienceValues, getTaxonomiesSorted } from '../utils/helpers'
import { SelectSection } from './SelectSection'

export const FindPlace = ({
  taxonomies,
}: {
  taxonomies?: DrupalTaxonomyTermCustomExperience[]
}) => {
  const [experiences, setExperiences] = useState<
    DrupalTaxonomyTermCustomExperience[]
  >([])
  const [url, setUrl] = useState('')
  const [experienceKey, setExperienceKey] = useState('')

  const [profileOption, setProfileOption] = useState('')
  const [experienceOption, setExperienceOption] = useState('')
  const taxonomiesSorted = useMemo(
    () => getTaxonomiesSorted(taxonomies ?? []),
    [taxonomies]
  )

  useEffect(() => {
    if (!!profileOption) {
      setExperiences(findExperienceValues(taxonomiesSorted, profileOption))
      setExperienceOption('')
      setExperienceKey(profileOption)
      setUrl('')
      // TODO: queda comentado porque no se sabe aun la funcionalidad que activará que el usuario seleccione un perfil.
      // localStorage.setItem(
      //   'profile_selected',
      //   taxonomiesSorted?.find((tax) => tax.id === profileOption)?.name ?? ''
      // )
    }
  }, [profileOption, taxonomiesSorted])

  useEffect(() => {
    const taxonomySelected = experiences.find(
      (option) => option.id === experienceOption
    )
    if (!!experienceOption && !!taxonomySelected) {
      setUrl(taxonomySelected.content?.path.alias ?? '')
    }
  }, [experienceOption, experiences])

  return (
    <>
      <SelectSection
        label={FormattedMessage({
          id: 'paragraph.experience.version',
        })}
        placeholder={FormattedMessage({ id: 'paragraph.experience.select' })}
        ariaLabel={FormattedMessage({ id: 'paragraph.experience.version' })}
        options={taxonomiesSorted}
        onChange={setProfileOption}
        disabled={!!!taxonomies?.length}
        id="version-madrid-id"
      />
      <SelectSection
        label={FormattedMessage({
          id: 'paragraph.experience.liveANewExperience',
        })}
        placeholder={FormattedMessage({ id: 'paragraph.experience.choose' })}
        ariaLabel={FormattedMessage({
          id: 'paragraph.experience.liveANewExperience',
        })}
        options={experiences}
        disabled={!profileOption}
        key={experienceKey}
        onChange={setExperienceOption}
        id="new-experience-id"
      />
      <Link
        href={url}
        className={cn(
          ` no-underline px-6 py-3 relative inline-flex items-center justify-center rounded-lg select-none h-auto font-bold gap-4 focus:outline-2 focus:outline-primary-red-900 focus:outline-offset-4 transition-all scale-100 hover:scale-110 text-neutral-white bg-secondary-black-900 focus:text-neutral-white focus:bg-primary-red-900 hover:bg-primary-red-50 hover:text-primary-red-900 text-base w-full md:w-fit ${
            !experienceOption &&
            !!!url &&
            'bg-neutral-gray-300 pointer-events-none cursor-not-allowed'
          }`
        )}
        target="_self"
        tabIndex={!experienceOption ? -1 : 0}
      >
        <FormattedMessage id="global.search" />
      </Link>
    </>
  )
}
