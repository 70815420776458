// Global
export const REVALIDATE = 60

// Viewmodes
export const VIEW_MODE_DEFAULT = 'default'
export const VIEW_MODE_FULL = 'full'
export const VIEW_MODE_SIMPLE = 'simple'
export const VIEW_MODE_BIG = 'big'
export const VIEW_MODE_GRID = 'grid'
export const VIEW_MODE_GRID_2COL = '2col'
export const VIEW_MODE_GRID_3COL = '3col'
export const VIEW_MODE_GRID_4COL = '4col'
export const VIEW_MODE_HIGHLIGHTED = 'highlighted'
export const VIEW_MODE_CAROUSEL = 'carousel'
export const VIEW_MODE_CAROUSEL_3COL = 'carousel_3col'
export const VIEW_MODE_CAROUSEL_4COL = 'carousel_4col'
export const VIEW_MODE_CARD_LOCATION = 'card_location'
export const VIEW_MODE_CARD_CALENDAR = 'card_calendar'
export const VIEW_MODE_CARD_DEFAULT = 'card_default'
export const VIEW_MODE_CARD_SIMPLE = 'card_simple'
export const VIEW_MODE_CARD_BASIC = 'basic'
export const VIEW_MODE_CARD_SMALL_BASIC = 'small_basic'
export const VIEW_MODE_CARD_BIG_BASIC = 'big_basic'
export const VIEW_MODE_CARD_BIG = 'card_big'
export const VIEW_MODE_CARD_ACTIVITY = 'activity'
export const VIEW_MODE_LABEL = 'label'
export const VIEW_MODE_CARD_TEASER = 'teaser'
export const VIEW_MODE_CARD_TEASER_LONG = 'teaser_long'
export const VIEW_MODE_CARD_TEASER_HORIZONTAL = 'teaser_horizontal'
export const VIEW_MODE_CARD_BASIC_VERTICAL = 'basic_vertical'
export const VIEW_MODE_CARD_ROUTE = 'route'
export const VIEW_MODE_TEASER_CAROUSEL_3COL = 'teaser_carousel_3col'
export const VIEW_MODE_BASIC_CAROUSEL_3COL = 'basic_carousel_3col'
export const VIEW_MODE_ACTIVITY_CAROUSEL_3COL = 'activity_carousel_3col'
export const VIEW_MODE_BASIC_VERTICAL_CAROUSEL_3COL =
  'basic_vertical_carousel_3col'
export const VIEW_MODE_ROUTE_CAROUSEL_3COL = 'route_carousel_3col'
export const VIEW_MODE_TEASER_CAROUSEL_4COL = 'teaser_carousel_4col'
export const VIEW_MODE_BASIC_CAROUSEL_4COL = 'basic_carousel_4col'
export const VIEW_MODE_ACTIVITY_CAROUSEL_4COL = 'activity_carousel_4col'
export const VIEW_MODE_BASIC_VERTICAL_CAROUSEL_4COL =
  'basic_vertical_carousel_4col'
export const VIEW_MODE_ROUTE_CAROUSEL_4COL = 'route_carousel_3col'
export const VIEW_MODE_BASIC_TEASER = 'basic_teaser'
export const VIEW_MODE_CARD_ITINERARY = 'itinerary'
export const VIEW_MODE_CARD_ITINERARY_CAROUSEL_3COL = 'itinerary_carousel_3col'
export const VIEW_MODE_REMOTE_VIDEO_FULL = 'full_width'
export const VIEW_MODE_TEXT_WITH_IMAGE_FLOAT_RIGHT =
  'text_with_image_float_right'
// Image Styles

//(16:9)
export const SCALE_WEBP_1280x720 = 'scale_webp_1280x720' ///si
//(3:2)
export const SCALE_WEBP_600x400 = 'scale_webp_600x400' ///si
// 4:1
export const SCALE_WEBP_600x150 = 'scale_webp_600x150' ///si
// 3:4
export const SCALE_WEBP_768x1024 = 'scale_webp_768x1024' ///si
// 16:3
export const SCALE_WEBP_1920x400 = 'scale_webp_1920x400' ///si
// 4:3
export const SCALE_WEBP_320x240 = 'scale_webp_320x240' ///si

// MAPA

export const LAT_MADRID = 40.41696
export const LON_MADRID = -3.70375
export const LAT_LON_MADRID: [number, number] = [LAT_MADRID, LON_MADRID]

/// next-pages

export const NEXT_PAGE_AGENDA = 'schedule'
export const NEXT_PAGE_AGENDA_SEARCHER = 'schedule_searcher'
export const NEXT_PAGE_ROUTE = 'route'
export const NEXT_PAGE_ROUTE_SEARCHER = 'route_searcher'
export const NEXT_PAGE_CONTACT = 'contact'
export const NEXT_PAGE_CICLA_MADRID = 'cicla_madrid'
export const NEXT_PAGE_CICLA_MADRID_SEARCHER = 'cicla_madrid_searcher'
export const NEXT_PAGE_GRAN_TOUR = 'cicla_madrid_gran_tour'
export const NEXT_PAGE_SEARCHER = 'general_searcher'
