import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { HookOutput } from 'utils'

import { useListProvider } from '../../ListProvider'

type UseSearchFormResult = HookOutput<
  { querySearch: string; clearable: boolean },
  {
    setQuerySearch: Dispatch<SetStateAction<string>>
    fetch: (_value: string) => void
    remove: () => void
  }
>

export const useSearchForm = (): UseSearchFormResult => {
  const [querySearch, setQuerySearch] = useState<string>('')
  const [clearable, setClearable] = useState(false)
  const {
    addFilters,
    removeFilter,
    state: { query },
  } = useListProvider()

  const fetch = (value: string) => {
    if (value !== '') {
      setClearable(true)
      addFilters({
        values: { value },
        type: 'query',
      })
    }
  }

  const remove = () => {
    setQuerySearch('')
    setClearable(false)
    removeFilter('query')
  }

  useEffect(() => {
    if (querySearch !== '' && !query) {
      setQuerySearch('')
      setClearable(false)
    } else if (query && querySearch !== query) {
      setQuerySearch(query ?? '')
      setClearable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return {
    state: { querySearch, clearable },
    actions: { setQuerySearch, fetch, remove },
  }
}
