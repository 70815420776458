import { FormattedMessage } from '@/components/FormattedMessage'
import { TaxonomyTerm } from '@/components/TaxonomyTerm'
import { Bookmark } from 'lucide-react'
import { DrupalTaxonomyTerm } from 'next-drupal'
import { InfoTag, InfoTagBody, InfoTagHeader } from 'ui'

const Categories = ({
  term,
}: {
  term: DrupalTaxonomyTerm | DrupalTaxonomyTerm[]
}) => (
  <InfoTag>
    <InfoTagHeader>
      <Bookmark />
      <FormattedMessage id="global.category" />
    </InfoTagHeader>
    <InfoTagBody>
      <TaxonomyTerm term={term} />
    </InfoTagBody>
  </InfoTag>
)

export { Categories }
