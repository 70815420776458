import { useMemo } from 'react'
import Link from 'next/link'
import { FormattedMessage } from '@/components/FormattedMessage'
import { MadridLogo } from '@/components/MadridLogo'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { useRouter } from 'next-translate-routes'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Heading,
  Separator,
  Text,
} from 'ui'
import { cn } from 'utils-tailwindcss'

import { Dropdown } from '../Header/components/LanguageMenu/components/Dropdown'
import { Links } from './components/Links'
import { SocialNetworks } from './components/SocialNetworks'

const Footer = ({ links, linksLegal, isContactPage, languages }) => {
  const linksHashtags = useMemo(
    () =>
      links?.length > 0 ? links.slice(links.length - 1, links.length) : [],
    [links]
  )
  const conditionalTranslationKeys = {
    questions: 'footer.questions',
    additional: 'footer.additional',
    contactUs: 'global.contactUs',
    generalLinks: 'footer.generalLinks',
    legalLinks: 'footer.legalLinks',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  const { locale } = useRouter()

  return (
    <>
      {!!!isContactPage && (
        <div className="bg-neutral-gray-200">
          <div className="container items-center justify-center py-6 md:py-10 md:flex md:gap-8">
            <div className="flex flex-col pb-6 md:items-center md:flex-row md:pb-0 text-text-medium md:gap-8">
              <Text className="pb-2 text-sm font-bold md:pb-0 md:text-lg">
                {translations.questions}
              </Text>
              <Text className="text-sm md:text-base">
                {translations.additional}
              </Text>
            </div>
            <Link
              locale={locale}
              href="/contacto"
              className="relative inline-flex items-center justify-center w-full gap-4 px-6 py-3 text-base font-bold no-underline transition-all scale-100 rounded-lg focus:outline-2 focus:outline-primary-red-900 focus:outline-offset-4 hover:scale-110 outline outline-2 text-secondary-black-900 border-secondary-black-900 bg-neutral-white hover:outline-none focus:text-neutral-white focus:bg-primary-red-900 focus:shadow-outline-black focus:border-primary-red-900 hover:bg-primary-red-50 hover:text-primary-red-900 disabled:text-neutral-gray-300 md:w-auto"
            >
              {translations.contactUs}
            </Link>
          </div>
        </div>
      )}
      <div className="container py-6 lg:py-12 text-neutral-white">
        <div className="flex justify-center pb-6 lg:pb-8">
          <MadridLogo />
        </div>
        <div className="md:hidden">
          <Accordion collapsible={true} type="single">
            {links &&
              links.slice(0, links.length - 1).map((link, index: number) => (
                <AccordionItem
                  key={index}
                  value={`${index}`}
                  className={cn(
                    index === 0 && 'border-t',
                    index === links.length - 2 && '!border-b'
                  )}
                >
                  <AccordionTrigger
                    className="text-sm font-semibold text-neutral-white"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    {link.title}
                  </AccordionTrigger>
                  <AccordionContent>
                    <Links className="text-sm lg:text-base" link={link} />
                  </AccordionContent>
                </AccordionItem>
              ))}
          </Accordion>
          <ul className="flex flex-col gap-3">
            {linksHashtags &&
              linksHashtags.map((linkHastag, index: number) => (
                <li
                  key={index}
                  className="flex flex-col items-center pt-6 pb-6"
                >
                  <div className="text-base font-semibold">
                    {linkHastag.title}
                  </div>
                  <div className="text-center">
                    <SocialNetworks />
                  </div>
                  <ul className="flex flex-col items-center gap-3">
                    {linkHastag.items &&
                      linkHastag.items.map((link, index: number) => (
                        <li key={index}>
                          <Link
                            href={link.url}
                            className="text-xs lg:text-base"
                          >
                            {link.title}
                          </Link>
                        </li>
                      ))}
                    <Dropdown isMobile languages={languages} />
                  </ul>
                </li>
              ))}
          </ul>
        </div>
        <nav aria-label={translations.generalLinks} className="hidden md:block">
          <ul className="flex justify-between">
            {links &&
              links.map((link, index: number) => (
                <li key={index}>
                  <Heading as="h3" className="pb-2 text-lg font-semibold">
                    {link.title}
                  </Heading>
                  {index === links.length - 1 && <SocialNetworks />}
                  <nav aria-label={link.title}>
                    <Links className="pb-3" link={link} />
                  </nav>
                  {index === links.length - 1 && (
                    <Dropdown isMobile languages={languages} />
                  )}
                </li>
              ))}
          </ul>
        </nav>
        <Separator color="white" size="sm" />
        <nav aria-label={translations.legalLinks} className="justify-center">
          <ul className="flex justify-center gap-2 lg:gap-12">
            {linksLegal?.map((link, index) => (
              <li key={index}>
                <Link href={link.url} className="text-xs font-bold md:text-sm">
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <Separator color="white" size="sm" />
        <div className="justify-center text-center lg:flex">
          <div className="flex justify-center">
            <Text className="text-sm">{`© ${new Date().getFullYear()}`}</Text>
            <Text className="before:content-['|'] before:pl-2 before:pr-2 text-sm">
              <FormattedMessage id="footer.tcm" />
            </Text>
          </div>
          <Text className="lg:before:content-['|'] lg:before:pl-2 lg:before:pr-2 text-sm">
            <FormattedMessage id="footer.rightsReseved" />
          </Text>
        </div>
      </div>
    </>
  )
}

export { Footer }
