import { cva } from 'class-variance-authority'

export const CardStyles = {
  root: cva('overflow-hidden rounded-t rounded-b', {
    variants: {
      intent: {
        vertical: 'bg-neutral-white',
        horizontal: 'flex bg-neutral-white',
        horizontalBox: ['flex bg-neutral-gray-200'],
        verticalBox: ['bg-neutral-gray-200'],
      },
    },
    defaultVariants: {
      intent: 'vertical',
    },
  }),
  skeletonRoot: cva('flex w-full h-full shadow-sm rounded-xl', {
    variants: {
      intent: {
        vertical: 'flex-col',
        horizontal: 'flex-row',
        horizontalBox: 'flex-row',
        verticalBox: 'flex-col',
      },
    },
    defaultVariants: {
      intent: 'vertical',
    },
  }),
  skeletonContent: cva('px-4 py-6 grid gap-1 grid-cols-1', {
    variants: {
      intent: {
        vertical: 'h-full',
        horizontal: 'w-full',
        horizontalBox: 'h-full',
        verticalBox: 'w-full',
      },
    },
    defaultVariants: {
      intent: 'vertical',
    },
  }),
}
