const MadridLogo = () => (
  <svg
    width="70"
    height="28"
    viewBox="0 0 70 28"
    className="w-auto"
    role="presentation"
    aria-hidden="true"
  >
    <path
      d="M7.48525 0.500122L5.74211 5.94847L0 6.00969L4.65521 9.31543L2.97359 14.6413L7.48525 11.3764L12.0789 14.5801L10.3153 9.25422L14.909 5.86685L9.2694 5.94847L7.48525 0.500122Z"
      fill="white"
    />
    <path
      d="M25.5517 0.500244L23.8085 5.9486L18.0664 6.00981L22.7216 9.31556L21.04 14.6415L25.5517 11.3765L30.1248 14.5803L28.3817 9.25434L32.9754 5.86697L27.3153 5.9486L25.5517 0.500244Z"
      fill="white"
    />
    <path
      d="M43.6815 0.500244L41.9384 5.9486L36.1963 6.00981L40.8515 9.31556L39.1699 14.6415L43.6815 11.3765L48.2752 14.5803L46.5116 9.25434L51.1053 5.86697L45.4862 5.9486L43.6815 0.500244Z"
      fill="white"
    />
    <path
      d="M34.5546 12.9071L32.8114 18.3555L27.0693 18.4167L31.7245 21.7224L30.0429 27.0483L34.5546 23.7834L39.1483 26.9871L37.4051 21.6612L41.9783 18.2942L36.3387 18.3555L34.5546 12.9071Z"
      fill="white"
    />
    <path
      d="M16.4686 12.9071L14.7255 18.3555L8.9834 18.4167L13.6386 21.7224L11.957 27.0483L16.4686 23.7834L21.0418 26.9871L19.2987 21.6612L23.8719 18.2942L18.2528 18.3555L16.4686 12.9071Z"
      fill="white"
    />
    <path
      d="M52.7245 12.9071L50.9814 18.3555L45.2393 18.4167L49.8945 21.7224L48.2128 27.0483L52.7245 23.7834L57.3182 26.9871L55.5545 21.6612L60.1482 18.2942L54.5292 18.3555L52.7245 12.9071Z"
      fill="white"
    />
    <path
      d="M61.8104 0.500244L60.0468 5.9486L54.3252 6.00981L58.9804 9.31556L57.2988 14.6415L61.8104 11.3765L66.3836 14.5803L64.6405 9.25434L69.2137 5.86697L63.5946 5.9486L61.8104 0.500244Z"
      fill="white"
    />
  </svg>
)

export { MadridLogo }
