import React, { useMemo } from 'react'
import { Carousel } from '@/components/Carousel'
import { CKEditor } from '@/components/CKEditor'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Grid } from '@/components/Grid'
import { Link } from '@/components/Link'
import { useCopyPath } from '@/components/LocationCard/useCopyPath'
import { Media } from '@/components/Media'
import { Node } from '@/components/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { Title } from '@/components/Title'
import {
  VIEW_MODE_DEFAULT,
  VIEW_MODE_ROUTE_CAROUSEL_3COL,
} from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import {
  CheckCircle2,
  CopyIcon,
  DownloadIcon,
  ExternalLinkIcon,
  Maximize2,
} from 'lucide-react'
import {
  Alert,
  Button,
  CardImage,
  Heading,
  ImageViewer,
  ImageViewerContent,
  ImageViewerTrigger,
  Text,
} from 'ui'

import {
  ActivityTag,
  CategoryTag,
  CircuitTag,
  DifficultyTag,
  DistanceTag,
  DurationTag,
  OriginTag,
  PointsOfInterestTag,
} from './components'
import { MapProvider, ResponsiveMap } from './components/Map'
import { BREAKPOINTS } from './constants'
import { DrupalNodeRouteFullProps } from './types'

const Full = ({ node }: DrupalNodeRouteFullProps) => {
  const {
    state: { showMessage },
    actions: { copyPath },
  } = useCopyPath(node.text_initial_point || '')
  const handleClick = () => {
    copyPath()
  }

  const touritsPoints = useMemo(
    () =>
      node.content_tourist_points?.filter(
        (point) =>
          point.id !== 'missing' &&
          point?.type !== 'node--area' &&
          point?.status
      ) ?? [],
    [node]
  )

  const conditionalTranslationKeys = {
    openInGMaps: 'searcher.routes.openInGMaps',
    copyToClipboard: 'searcher.routes.copyToClipboard',
    copied: 'searcher.routes.copied',
    photo: 'paragraph.gallery.photo',
    enlarge: 'paragraph.gallery.enlarge',
    file: 'node.route.full.file',
    closeRoutes: 'node.route.full.closeRoutes',
    externalLink: 'global.externalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <div className="container my-16">
        <div className="mb-10">
          <Title title={FormattedMessage({ id: 'global.information' })} />
        </div>
        <ul className="grid grid-cols-2 gap-10 md:grid-cols-4">
          {!!node.term_categories?.length && (
            <li>
              <CategoryTag term={node.term_categories} />
            </li>
          )}
          {!!touritsPoints?.length && (
            <li>
              <PointsOfInterestTag points={touritsPoints} />
            </li>
          )}
          <li>
            <DistanceTag node={node} />
          </li>
          <li>
            <DurationTag node={node} />
          </li>
          {node.term_route_level && (
            <li>
              <DifficultyTag term={node.term_route_level} />
            </li>
          )}
          {node.term_route_type && (
            <li>
              <CircuitTag term={node.term_route_type} />
            </li>
          )}
          {node.term_category && (
            <li>
              <ActivityTag term={node.term_category} />
            </li>
          )}
        </ul>
      </div>

      <div className="py-12 my-12 bg-neutral-gray-100 md:py-16 md:my-16">
        <div className="container ">
          <ul className="flex flex-row items-center gap-x-16">
            <li>
              {/** TODO: HAY QUE CAMBIAR LA DIRECCION QUE SE MUESTRA AQUI, CUANDO VENGA DESDE EL BACK */}
              <OriginTag node={node} />
            </li>
            {node.text_initial_point && (
              <>
                <Link
                  href={node.text_initial_point}
                  target="_blank"
                  intent={'link'}
                  className="flex flex-row gap-3 font-semibold w-fit"
                  aria-label={translations.openInGMaps}
                  aria-describedby={translations.externalLink}
                >
                  <ExternalLinkIcon
                    aria-roledescription="presentation"
                    aria-hidden="true"
                  />
                  {translations.openInGMaps}
                </Link>
                <p className="sr-only">{translations.newWindow}</p>
              </>
            )}
            {node.text_initial_point && (
              <li>
                <div className="relative inline-block">
                  <Button
                    intent="tertiary"
                    onClick={handleClick}
                    className="p-0"
                  >
                    <CopyIcon
                      aria-roledescription="presentation"
                      aria-hidden="true"
                    />
                    {translations.copyToClipboard}
                  </Button>
                  {showMessage && (
                    <Alert>
                      <CheckCircle2
                        height="1rem"
                        aria-roledescription="presentation"
                        aria-hidden="true"
                      />
                      {translations.copied}
                    </Alert>
                  )}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>

      <Grid cols={3} className="mb-12">
        {node.images?.map((media) => (
          <CardImage key={media.id} className="rounded-b bg-neutral-gray-100">
            <Media
              media={{ ...media, viewMode: VIEW_MODE_DEFAULT }}
              // @ts-ignore
              breakpoints={BREAKPOINTS}
            />

            <div className="px-4 py-3 text-base rounded-b bg-neutral-gray-100">
              <Text>
                {translations.photo}
                {': '}
                {media.image.resourceIdObjMeta.title}
              </Text>
            </div>
            <div>
              <ImageViewer>
                <ImageViewerTrigger
                  className="absolute p-2 rounded-full top-6 right-6 bg-neutral-white"
                  aria-label={translations.enlarge}
                >
                  <Maximize2 width={21} height={21} />
                </ImageViewerTrigger>
                <ImageViewerContent>
                  <div className="w-[40vw]">
                    <Media
                      key={media.id}
                      media={{ ...media, viewMode: VIEW_MODE_DEFAULT }}
                    />
                  </div>
                </ImageViewerContent>
              </ImageViewer>
            </div>
          </CardImage>
        ))}
      </Grid>

      <div className="container flex flex-col gap-4 pb-12">
        <Title
          title={FormattedMessage({ id: 'node.route.full.pointsOfInterest' })}
        />
        <MapProvider
          routes={node.gpx?.resourceIdObjMeta.trk ?? []}
          touristicPoints={touritsPoints}
        >
          <ResponsiveMap />
        </MapProvider>
        <Text className="text-lg font-semibold">
          <FormattedMessage id="node.route.full.descriptionOfPointsOfInterest" />
        </Text>
        {(node.gpx || node.file_kmz) && (
          <div className="flex gap-2">
            {node.gpx && (
              <Link
                href={node.gpx?.resourceIdObjMeta.public_url ?? ''}
                className="w-fit"
              >
                <DownloadIcon
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
                {translations.file} GPX
              </Link>
            )}
            {node.file_kmz && (
              <Link
                href={node.file_kmz?.uri?.url ?? ''}
                className="w-fit"
                intent="secondary"
              >
                <DownloadIcon
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
                {translations.file} KMZ
              </Link>
            )}
          </div>
        )}
      </div>
      <div className="py-12 my-12 bg-neutral-gray-100 md:py-16 md:my-16">
        <div className="container ">
          <Heading className="mb-8">
            <FormattedMessage id="global.description" />
          </Heading>
          <CKEditor text={node.body} />
        </div>
      </div>

      {node.nearby_routes?.length > 0 && (
        <div className="pb-12 custom-container">
          <Carousel title={translations.CLOSE_ROUTES}>
            {node.nearby_routes
              .filter((route) => route.id !== 'missing' && route?.status)
              .map((content: BundleDrupalNode) => (
                <Node
                  key={content.id}
                  node={{
                    ...content,
                    viewMode: VIEW_MODE_ROUTE_CAROUSEL_3COL,
                  }}
                />
              ))}
          </Carousel>
        </div>
      )}
    </>
  )
}

export { Full }
