import { forwardRef, HTMLAttributes } from 'react'
import { cn } from 'utils-tailwindcss'

import { useFormField } from '../../useFormField'
import { DescriptionStyles } from './Description.styles'

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField()

  return (
    <span
      ref={ref}
      id={formDescriptionId}
      className={cn(DescriptionStyles(), className)}
      {...props}
    />
  )
})

Description.displayName = 'Description'

export { Description }
