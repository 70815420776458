import { TaxonomyTerm } from '@/components/TaxonomyTerm'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Bookmark } from 'lucide-react'
import { DrupalTaxonomyTerm } from 'next-drupal'
import { InfoTag, InfoTagBody, InfoTagHeader } from 'ui'

const Category = ({
  term,
}: {
  term: DrupalTaxonomyTerm | DrupalTaxonomyTerm[]
}) => {
  const conditionalTranslationKeys = {
    category: 'global.category',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <InfoTag>
      <InfoTagHeader>
        <Bookmark />
        {translations.category}
      </InfoTagHeader>
      <InfoTagBody>
        <TaxonomyTerm term={term} />
      </InfoTagBody>
    </InfoTag>
  )
}

export { Category }
