import { FormattedMessage } from '@/components/FormattedMessage'
import { CheckBoxList } from '@/components/List/components/CheckBoxList'
import { CheckBoxFilter } from '@/components/List/components/CheckBoxList/types'
import { DatePicker } from '@/components/List/components/DatePicker'
import { SearchForm } from '@/components/List/components/SearchForm'
import { DATE_FILTER } from '@/components/List/constants'
import { useListProvider } from '@/components/List/ListProvider'
import { Text } from 'ui'

export const Desktop = () => {
  const {
    state: { facets },
  } = useListProvider()
  return (
    <div className="container flex-col hidden w-full md:flex lg:flex-row">
      <div className="flex flex-col justify-start w-full">
        <SearchForm />
        <Text className="mt-4 mb-4 lg:mt-12 lg:mb-0text-xl">
          <FormattedMessage id="searcher.textForFilters" />
        </Text>
      </div>
      <div className="flex flex-row gap-10 lg:self-end lg:justify-end w-fit h-fit">
        {facets?.map((filter: CheckBoxFilter, key: number) => {
          switch (filter.id) {
            case DATE_FILTER:
              return <DatePicker key={key} />
            default:
              return <CheckBoxList {...filter} key={key} />
          }
        })}
      </div>
    </div>
  )
}
