import { ComponentPropsWithoutRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { BodyStyles } from './Body.styles'

const Body = ({ children, className }: ComponentPropsWithoutRef<'div'>) => (
  <div className={cn(BodyStyles(), className)}>{children}</div>
)

export { Body }
