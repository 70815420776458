import { FormattedMessage } from '@/components/FormattedMessage'
import { Button } from 'ui'

import { FooterProps } from './types'

const Footer = ({ reset, close, isDisabled = false }: FooterProps) => (
  <div className="w-full pt-6">
    <Button
      intent="secondary"
      onClick={reset}
      isDisabled={isDisabled}
      className="mr-6"
    >
      <FormattedMessage id="global.delete" />
    </Button>
    <Button intent="primary" onClick={close}>
      <FormattedMessage id="global.apply" />
    </Button>
  </div>
)

export { Footer }
