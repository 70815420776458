import NextLink from 'next/link'
import { ButtonStyles } from 'ui/src/components/atoms/Button/Button.styles'
import { cn } from 'utils-tailwindcss'

import { LinkProps } from './types'

const Link = ({
  href,
  children,
  intent,
  className,
  size,
  target = '_self',
  locale = 'es',
}: LinkProps) => (
  <NextLink
    locale={locale}
    target={target}
    href={href}
    className={cn(
      ButtonStyles({
        intent,
        size,
      }),
      'no-underline',
      className
    )}
  >
    {children}
  </NextLink>
)

export { Link }
