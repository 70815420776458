import { CKEditor } from '@/components/CKEditor'
import { ContentPopup } from '@/components/ContentPopup'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Media } from '@/components/Media'
import { MapPin } from 'lucide-react'
import { DrupalMedia } from 'next-drupal'
import {
  CardImage,
  CardTitle,
  Heading,
  ImageViewer,
  ImageViewerContent,
  ImageViewerTrigger,
  Tag,
} from 'ui'

import { TaxonomyTerm } from '../../TaxonomyTerm'
import { BREAKPOINTS } from '../constants'
import { MOCK_FILE_DERIVATIVE } from '../mock'
import { CardRouteContentProps } from '../types'

const Content = ({
  title,
  image,
  duration,
  decimal_kmeters,
  term_category,
  location,
  popup,
  description,
}: CardRouteContentProps) => {
  if (!popup) {
    return (
      <>
        {image && (
          <CardImage>
            <Media
              media={image || MOCK_FILE_DERIVATIVE}
              //@ts-ignore
              breakpoints={BREAKPOINTS}
            />
          </CardImage>
        )}

        <div className="flex flex-col h-full gap-3 px-6 pb-6 no-underline border-b rounded-b border-x border-neutral-gray-400">
          <CardTitle className="no-underline ">
            <span className="font-bold">{title}</span>
          </CardTitle>
          <div className="flex gap-3">
            {decimal_kmeters && <Tag>{decimal_kmeters} km</Tag>}
            {term_category && (
              <Tag>
                <TaxonomyTerm term={term_category} />
              </Tag>
            )}
            {duration && <Tag>{duration}</Tag>}
          </div>
          {location && (
            <span className="flex items-center gap-2">
              <MapPin />
              {location}
            </span>
          )}
        </div>
      </>
    )
  }
  return (
    <CardImage>
      <ImageViewer>
        <ImageViewerTrigger
          className="w-full text-left hover:underline focus:border-2 focus:border-secondary-black-900 rounded-md focus:p-[2px]"
          aria-label={FormattedMessage({
            id: 'paragraph.gallery.enlarge',
          })}
        >
          <Media
            media={(image || MOCK_FILE_DERIVATIVE) as DrupalMedia}
            //@ts-ignore
            breakpoints={BREAKPOINTS}
          />
          <div className="flex flex-col h-full gap-3 px-6 pb-6 no-underline border-b rounded-b border-x border-neutral-gray-400">
            <CardTitle className="no-underline ">
              <span className="font-bold">{title}</span>
            </CardTitle>
            <div className="flex gap-3">
              {decimal_kmeters && <Tag>{decimal_kmeters} km</Tag>}
              {term_category && (
                <Tag>
                  <TaxonomyTerm term={term_category} />
                </Tag>
              )}
              {duration && <Tag>{duration}</Tag>}
            </div>
            {location && (
              <span className="flex items-center gap-2">
                <MapPin />
                {location}
              </span>
            )}
          </div>
        </ImageViewerTrigger>
        <ImageViewerContent>
          <ContentPopup
            title={
              <Heading as="h3" className="font-bold">
                {title}
              </Heading>
            }
            body={!!description && <CKEditor text={description} />}
            image={
              <Media
                media={(image || MOCK_FILE_DERIVATIVE) as DrupalMedia}
                //@ts-ignore
                breakpoints={BREAKPOINTS}
              />
            }
          />
        </ImageViewerContent>
      </ImageViewer>
    </CardImage>
  )
}

export { Content }
