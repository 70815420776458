import Link from 'next/link'
import { useCopyPath } from '@/components/LocationCard/useCopyPath'
import { Media } from '@/components/Media'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { CheckCircle2, Copy, ExternalLink, MapPinIcon } from 'lucide-react'
import {
  Alert,
  Button,
  CardImage,
  CardTitle,
  Card as CardUI,
  Heading,
  Text,
} from 'ui'

import { BREAKPOINTS } from './constants'
import { DrupalNodePlaceItinerarytProps } from './types'

const Itinerary = ({ node }: DrupalNodePlaceItinerarytProps) => {
  const {
    state: { showMessage },
    actions: { copyPath },
  } = useCopyPath(node.gmaps_url || '')
  const handleClick = () => {
    copyPath()
  }

  const conditionalTranslationKeys = {
    copyAddress: 'myItinerary.card.copyAddress',
    copied: 'searcher.routes.copied',
    openOnMaps: 'myItinerary.card.openOnMaps',
    externalLink: 'global.externalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <CardUI className="flex flex-col h-full">
      {node.computed_image && (
        <CardImage>
          <Media
            //@ts-ignore
            breakpoints={BREAKPOINTS}
            media={node.computed_image}
            className="rounded w-fit"
          />
        </CardImage>
      )}
      <div className="flex flex-col justify-around h-full gap-3 px-6 pb-6 border-b rounded-b border-x border-neutral-gray-400">
        <CardTitle>
          <Heading as="h3" className="font-bold">
            {node.title}
          </Heading>
        </CardTitle>
        <div>
          <Text className="flex gap-2 text-base w-fit">
            <MapPinIcon
              width="1rem"
              aria-roledescription="presentation"
              aria-hidden="true"
            />{' '}
            {node.term_municipality?.name.value}
          </Text>
          {node.gmaps_url && (
            <Button
              intent="tertiary"
              className="flex flex-row h-auto gap-2 p-0 text-base font-semibold pointer-events-auto w-fit"
              onClick={handleClick}
            >
              <div className="flex flex-row gap-2 py-2">
                <Copy
                  width="1rem"
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
                {translations.copyAddress}
              </div>
              {showMessage && (
                <Alert className="relative">
                  <CheckCircle2
                    height="1rem"
                    aria-roledescription="presentation"
                    aria-hidden="true"
                  />
                  {translations.copied}
                </Alert>
              )}
            </Button>
          )}
          {node.gmaps_url && (
            <>
              <Text className="flex gap-2 text-base font-semibold w-fit">
                <ExternalLink
                  width="1rem"
                  aria-roledescription="presentation"
                  aria-hidden="true"
                />
                <Link
                  href={node.gmaps_url ?? ''}
                  target="_blank"
                  aria-label={translations.openOnMaps}
                  aria-describedby={translations.externalLink}
                >
                  {translations.openOnMaps}
                </Link>
              </Text>
              <p className="sr-only">{translations.newWindow}</p>
            </>
          )}
        </div>
      </div>
    </CardUI>
  )
}

export { Itinerary }
