import { ElementRef, forwardRef } from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { X } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { CloseStyles } from './Close.styles'
import { CloseProps } from './types'

const Close = forwardRef<ElementRef<typeof PopoverPrimitive.Close>, CloseProps>(
  ({ className, ...props }, ref) => (
    <PopoverPrimitive.Close
      ref={ref}
      className={cn(CloseStyles.close(), className)}
      aria-label="Close"
      {...props}
    >
      <X className="w-4 h-4" />
    </PopoverPrimitive.Close>
  )
)

Close.displayName = PopoverPrimitive.Close.displayName

export { Close }
