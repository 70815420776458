import { useEffect, useState } from 'react'
import { Breadcrumbs } from '@/components/Breadcrumb'
import { MOCK_MEDIA_IMAGE } from '@/components/Media/components/Image/mocks'
import { FILE_IMAGE } from '@/components/Media/constants'
import { SCALE_WEBP_1920x400 } from '@/content/constants'
import { Heading } from 'ui'
import { useWidth } from 'utils'

import { ShareDropdown } from '../Header/components/ShareDropdown'
import { IntroProps } from './types'

export const IMG_MOCK = {
  ...MOCK_MEDIA_IMAGE(),
  image: {
    type: FILE_IMAGE,
    id: FILE_IMAGE,
    resourceIdObjMeta: {
      imageDerivatives: {
        links: {
          [SCALE_WEBP_1920x400]: {
            href: '/madrid_desktop.webp',
          },
          thumbnail: {
            href: '/madrid_desktop.webp',
          },
        },
      },
    },
  },
}

export const Intro = ({ title, image, initialBreadcrumbs }: IntroProps) => {
  const { isMobile } = useWidth()
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [image])

  const TEMPORAL_BACKGROUND_IMAGE = image?.image?.resourceIdObjMeta
    ?.imageDerivatives?.links
    ? image.image?.resourceIdObjMeta?.imageDerivatives?.links[
        SCALE_WEBP_1920x400
      ]?.href
    : IMG_MOCK.image

  const TEMPORAL_BACKGROUND_THUMBNAIL_IMAGE = image?.image?.resourceIdObjMeta
    ?.imageDerivatives?.links
    ? image.image.resourceIdObjMeta?.imageDerivatives?.links.thumbnail?.href
    : IMG_MOCK.image

  return (
    <div className="relative full">
      <div className="container">
        {isMobile() && (
          <Breadcrumbs
            className="py-3 bg-neutral-white"
            initialBreadcrumbs={initialBreadcrumbs}
          />
        )}
      </div>

      <div
        className={`${
          !isLoaded ? 'blur-[0px]' : 'before:content-none'
        } bg-cover bg-no-repeat before:absolute before:inset-0 before:opacity-0 before:bg-neutral-gray-200 before:content-[""] before:animate-pulse relative`}
        style={{
          backgroundImage: !isLoaded
            ? `url('${TEMPORAL_BACKGROUND_THUMBNAIL_IMAGE}')`
            : `url('${TEMPORAL_BACKGROUND_IMAGE}')`,
        }}
      >
        <div
          style={{
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.00) 28.13%, rgba(0, 0, 0, 0.00) 46.35%, rgba(0, 0, 0, 0.41) 62.5%, rgba(0, 0, 0, 0.90) 88.02%)',
          }}
        >
          <div className="container flex flex-col justify-end h-full pt-[10rem] md:pt-[15rem] pb-6">
            <Heading className="relative mb-3 text-neutral-white" as="h1">
              {title}
            </Heading>
            <div className="relative z-0 flex items-center md:justify-between">
              <div>
                {!isMobile() && (
                  <Breadcrumbs initialBreadcrumbs={initialBreadcrumbs} />
                )}
              </div>
              <div>
                <ShareDropdown title={title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
