import { Children, cloneElement, useEffect, useState } from 'react'
import { cn } from 'utils-tailwindcss'

import { ContentStyles } from './Content.styles'
import { ContentProps } from './types'

const Content = ({
  children,
  value,
  onValueChange = () => {},
  className,
  role,
  ariaLabelledby,
}: ContentProps) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    value ? setVisible(true) : setVisible(false)
  }, [value])

  const handleKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      setVisible(false)
    }
  }

  return (
    <div
      className={cn(ContentStyles({ visible }), className)}
      role={role}
      aria-labelledby={ariaLabelledby}
      onKeyDown={handleKeyDown}
    >
      {Children.map(children, (child) =>
        cloneElement(child, {
          onValueChange,
        })
      )}
    </div>
  )
}

export { Content }
