'use client'

import { Children, cloneElement, isValidElement, ReactElement } from 'react'
import { cn } from 'utils-tailwindcss'

import { ItemProps } from './components/Item/types'
import { NavigationStyles } from './Navigation.styles'
import { NavigationProps } from './types'
import { useNavigation } from './useNavigation'

const Navigation = ({
  value = '',
  onValueChange,
  children,
  className,
  ...rest
}: NavigationProps) => {
  const {
    state: { activeItem },
    actions: { onItemClick },
  } = useNavigation(value, onValueChange)

  return (
    <div className={cn(NavigationStyles.root(), className)} {...rest}>
      {Children.map(children as ReactElement<ItemProps>, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            activeItem: activeItem,
            onItemClick: onItemClick,
          })
        }
      })}
    </div>
  )
}

Navigation.displayName = 'Navigation'

export { Navigation }
