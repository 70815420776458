import { useMemo } from 'react'
import { Node } from '@/components/Node'
import { VIEW_MODE_CARD_SMALL_BASIC } from '@/content/constants'
import { Card, GradientImage, Heading, Text } from 'ui'

import { DrupalParagraphExperience } from '../../types'
import { FindPlace } from './components/FindPlace'

const Default = ({ paragraph }: { paragraph: DrupalParagraphExperience }) => {
  const contents = useMemo(() => {
    const contensArray = paragraph.contents
      .filter((content) => content.id !== 'missing' && content.status)
      .slice(0, 6)

    return contensArray
  }, [paragraph])

  return (
    <GradientImage
      variant="paragraph"
      image={'/custom_experience.jpeg'}
      className="min-h-[20rem]"
    >
      <div
        id="customExperience"
        className="container flex flex-col items-center w-full gap-8 py-16 mx-auto lg:items-start"
      >
        <div className="flex flex-col gap-3">
          <Heading as="h2" className="text-neutral-white">
            {paragraph.title}
          </Heading>
          <Text className="text-lg text-neutral-white">
            {paragraph.subtitle}
          </Text>
        </div>
        <Card className="flex flex-col items-center w-full gap-6 p-8 rounded-md shadow-none lg:gap-8 lg:items-end lg:flex-row">
          <FindPlace taxonomies={paragraph.taxonomies} />
        </Card>
        {!!contents.length && (
          <ul className="grid w-full grid-flow-col-dense grid-cols-2 grid-rows-3 gap-4 place-items-center lg:grid-rows-1 lg:grid-cols-6 md:grid-cols-3 md:grid-flow-row-dense md:grid-rows-2">
            {contents.map((node) => (
              <li className="flex h-40" key={node.id}>
                <Node
                  node={{
                    ...node,
                    viewMode: VIEW_MODE_CARD_SMALL_BASIC,
                  }}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </GradientImage>
  )
}

export { Default }
