import React from 'react'
import { Carousel } from '@/components/Carousel'
import { CKEditor } from '@/components/CKEditor'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Media } from '@/components/Media'
import { Node } from '@/components/Node/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { Title } from '@/components/Title'
import { VIEW_MODE_BASIC_CAROUSEL_3COL } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'

import { CategoriesTag, DateTag, LinkTag, MunicipalityTag } from './components'
import { BREAKPOINTS } from './constants'
import { DrupalNodeEventFullProps } from './types'

// https://admin-turismomadrid.demohiberus.com/jsonapi/node/event/cfc0263f-ff26-4bad-bb79-ea83f7598a85
const Full = ({ node }: DrupalNodeEventFullProps) => {
  const conditionalTranslationKeys = {
    bestSurroundings: 'node.event.full.theBestInTheSurroundings',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <div className="container my-16">
        <div className="mb-10">
          <Title title={FormattedMessage({ id: 'global.information' })} />
        </div>
        <ul className="grid grid-cols-2 gap-10 md:grid-cols-4">
          {!!node.occurrences.length && (
            <li>
              <DateTag node={node} />
            </li>
          )}
          {node.term_municipality && (
            <li>
              <MunicipalityTag term={node.term_municipality} />
            </li>
          )}
          {node.term_categories && (
            <li>
              <CategoriesTag term={node.term_categories} />
            </li>
          )}
          {node.link && (
            <li>
              <LinkTag node={node} />
            </li>
          )}
        </ul>
      </div>
      {/* @ts-ignore - body, image */}
      <div className="py-12 mb-12 bg-neutral-gray-100">
        <div className="container mt-12 md:mt-0">
          <div className="grid gap-4 md:grid-cols-3">
            <div className="col-span-2">
              <CKEditor text={node.body} />
            </div>
            <div className="hidden md:block">
              <div className="overflow-hidden rounded">
                {/* @ts-ignore */}
                <Media media={node.computed_image} breakpoints={BREAKPOINTS} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!node.surrounding_areas?.length && (
        <div className="pb-12">
          <div className="custom-container">
            <Carousel title={translations.bestSurroundings}>
              {node.surrounding_areas.map((content: BundleDrupalNode) => (
                <Node
                  key={content.id}
                  node={{ ...content, viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL }}
                />
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </>
  )
}
export { Full }
