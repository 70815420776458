import { DrupalNodeRoute } from '@/components/Node/components/Route/types'
import { CLOSEST_ROUTES_FILTER } from '@/components/RoutesList/constants'
import { DrupalJsonApiParams } from 'drupal-jsonapi-params'

export const getSortParams = (params: DrupalJsonApiParams, sort: string) => {
  const sorting = sort?.split('-')
  if (sorting) {
    switch (sorting[0]) {
      case CLOSEST_ROUTES_FILTER:
        // No se puede procesar aqui, tiene que ser en la respuesta
        break
      default:
        params.addSort(sorting[0], sorting[1])
        break
    }
  }
}

export const sortingResults = (
  a: DrupalNodeRoute,
  b: DrupalNodeRoute,
  userPosition: number[]
): number => {
  const locationA = a.text_initial_point?.split(',')
  const locationB = b.text_initial_point?.split(',')
  const distanceA = distanceBetween(
    userPosition[0],
    userPosition[1],
    Number(locationA?.[0]),
    Number(locationA?.[1])
  )
  const distanceB = distanceBetween(
    userPosition[0],
    userPosition[1],
    Number(locationB?.[0]),
    Number(locationB?.[1])
  )

  return distanceA - distanceB
}

const distanceBetween = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371 // Radio de la Tierra en kilómetros
  const dLat = (lat2 - lat1) * (Math.PI / 180)
  const dLon = (lon2 - lon1) * (Math.PI / 180)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c // Distancia en kilómetros
  return distance
}
