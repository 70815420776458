import Link from 'next/link'
import { Card } from 'ui'
import { cn } from 'utils-tailwindcss'

import { Content } from './components'
import { CardActivityProps } from './types'

const CardActivity = ({
  title,
  image,
  url,
  term_municipality,
  date,
  size,
  type,
  className,
  popup = false,
  description,
}: CardActivityProps) => (
  <Card className="grid w-full border-neutral-gray-400 p-4 md:p-0 h-full">
    {!url || type === 'node--establishment' || popup ? (
      <div className={cn('border', className)}>
        <Content
          title={title}
          image={image}
          term_municipality={term_municipality}
          date={date}
          size={size}
          popup={popup}
          description={description}
        />
      </div>
    ) : (
      <Link
        href={url}
        className={cn(
          'no-underline border hover:underline focus:border-2 focus:border-secondary-black-900 rounded-md focus:p-[2px]',
          className
        )}
      >
        <Content
          title={title}
          image={image}
          term_municipality={term_municipality}
          date={date}
          size={size}
        />
      </Link>
    )}
  </Card>
)

export { CardActivity }
