import { createElement } from 'react'

import { AgendaPage } from './components'
import { NEXT_PAGES } from './constants'
import { DrupalNodeNextPageFullProps, NodeNextPageType } from './types'

const Full = ({ node, ...rest }: DrupalNodeNextPageFullProps) => {
  if (node?.type !== 'unknown' && node && node?.status) {
    const bundle = NEXT_PAGES[node.mapping as NodeNextPageType] ?? AgendaPage

    return createElement(bundle, {
      node: node,
      ...rest,
    })
  }
}

export { Full }
