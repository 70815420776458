import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { BundleDrupalNode } from '@/components/Node/types'
import { useRouter } from 'next-translate-routes'
import { HookOutput } from 'utils'
import { DrupalMenuLinkContents } from 'utils-drupal'

import { ZIPCODES_MAP } from './constants'

type UseWhereToGoResult = HookOutput<
  {
    placeSelected?: DrupalMenuLinkContents
    selected?: string[]
    isLoading: boolean
    itemSelected?: BundleDrupalNode
    itemsSelected?: BundleDrupalNode[]
  },
  {
    setPlaceSelected: (_id: string) => void
    onPathClick: (_id: string) => void
    setIsLoading: Dispatch<SetStateAction<boolean>>
    setItemSelected: Dispatch<SetStateAction<BundleDrupalNode | undefined>>
  }
>

export const useWhereToGo = (
  initialPlaces: DrupalMenuLinkContents[],
  isMobile?: boolean
): UseWhereToGoResult => {
  const [placeSelected, setInternalPlaceSelected] = useState<
    DrupalMenuLinkContents | undefined
  >({ items: initialPlaces } as DrupalMenuLinkContents)
  const [selected, setSelected] = useState<string[] | undefined>([])
  const { locale } = useRouter()
  const [isLoading, setIsLoading] = useState(false)

  const [itemSelected, setItemSelected] = useState<
    BundleDrupalNode | undefined
  >(undefined)

  const [itemsSelected, setItemsSelected] = useState<
    BundleDrupalNode[] | undefined
  >(undefined)

  const getAllZipCodes = (id: string): string[] => {
    const totalZipCodes: string[] = []
    ZIPCODES_MAP[id]?.forEach((zipCode: number | string) => {
      if (typeof zipCode === 'string') {
        const range = zipCode.split('-').map((string) => Number(string.trim()))

        for (let index = range[0]; index <= range[1]; index++) {
          totalZipCodes.push(index.toString())
        }
      } else {
        totalZipCodes.push(zipCode.toString())
      }
    })
    return totalZipCodes
  }

  const getMenuItems = useCallback(
    async (id: string) => {
      const item = await fetch('/api/get-item', {
        method: 'POST',
        body: JSON.stringify({ id: id }),
      }).then((res) => res.json())
      item && setItemSelected(item.content)

      if (isMobile) {
        const items = await fetch('/api/get-menu-items', {
          method: 'POST',
          body: JSON.stringify({ id: id, locale: locale }),
        }).then((res) => res.json())
        items.length && setItemsSelected(items.map((item) => item.content))
      }
    },
    [isMobile]
  )

  const setPlaceSelected = useCallback(
    (id: string) => {
      setIsLoading(true)
      const place = initialPlaces.find((menuItem) => menuItem.id === id)
      setInternalPlaceSelected(place)
      if (place) {
        setSelected(getAllZipCodes(place.id ?? ''))

        getMenuItems(place.id)
      }
    },
    [initialPlaces, getMenuItems]
  )

  const onPathClick = (id: string) => {
    for (const key of Object.keys(ZIPCODES_MAP)) {
      if (getAllZipCodes(key).includes(id)) {
        setPlaceSelected(key)
        break
      }
    }
  }

  useEffect(() => {
    if (itemSelected) {
      setIsLoading(false)
      const target = document.querySelector('#location-card')
      target?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [itemSelected])

  return {
    state: { placeSelected, selected, isLoading, itemSelected, itemsSelected },
    actions: { setPlaceSelected, onPathClick, setIsLoading, setItemSelected },
  }
}
