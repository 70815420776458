import { ContactForm } from '@/components/ContactForm'
import { Grid } from '@/components/Grid'
import { Node } from '@/components/Node/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { VIEW_MODE_CARD_BASIC } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'

import { DrupalNodeNextContactPageProps } from './types'

const ContactPage = ({ node }: DrupalNodeNextContactPageProps) => {
  const conditionalTranslationKeys = {
    ofInterest: 'node.ofInterest',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <div className="container">
        <ContactForm />
      </div>
      {!!node.ofInterest?.length && (
        <div className="py-16 bg-neutral-gray-100">
          <Grid title={translations.ofInterest} redLine={true} cols={4}>
            {node.ofInterest.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_BASIC }}
                key={content.id}
              />
            ))}
          </Grid>
        </div>
      )}
    </>
  )
}

export { ContactPage }
