const GermanFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    href="http://www.w3.org/1999/xlink"
    width="28px"
    height="19px"
    viewBox="0 0 31 19"
    version="1.1"
    role="presentation"
    aria-hidden="true"
  >
    <g id="surface1">
      <rect
        x="0"
        y="0"
        width="31"
        height="19"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        stroke="none"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(86.666667%,0%,0%)"
        fillOpacity="1"
        d="M 0 6.332031 L 31 6.332031 L 31 19 L 0 19 Z M 0 6.332031 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(100%,80.784314%,0%)"
        fillOpacity="1"
        d="M 0 12.667969 L 31 12.667969 L 31 19 L 0 19 Z M 0 12.667969 "
      />
    </g>
  </svg>
)

export { GermanFlag }
