import { forwardRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { SkeletonStyles } from './Skeleton.styles'
import { SkeletonProps } from './types'

const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn(SkeletonStyles(), className)} {...props} />
  )
)

Skeleton.displayName = 'Skeleton'

export { Skeleton }
