export const EVENT_FIELDS = [
  'type',
  'path',
  'dates',
  'title',
  'status',
  'body',
  'link',
  'moderation_state',
  'metatags',
  'term_municipality',
  'term_categories',
  'occurrences',
  'current_occurrence',
  'computed_image',
  'computed_image.image',
]

export const EVENT_CARD_FIELDS = [
  'status',
  'term_categories',
  'term_municipality',
  'term_programs',
  'term_region',
  'term_type',
  'title',
  'path',
  'occurrences',
  'current_occurrence',
  'type',
  'className',
  'computed_image',
]
export const EVENT_CARD_BASE_FIELDS = [
  'term_municipality',
  'status',
  'title',
  'path',
  'occurrences',
  'dates',
  'type',
  'body',
  'computed_image',
  'list_highlighted',
  'moderation_state',
  'promote',
  'term_categories',
  'current_occurrence',
]

export const EVENT_INCLUDES = [
  'term_municipality',
  'term_categories',
  'computed_image',
]

export const EVENT_CARD_BASE_INCLUDES = [
  'term_municipality',
  'term_categories',
  'term_type',
  'term_region',
  'term_programs',
  'computed_image',
]

export const EVENT_CARD_INCLUDES = [
  'term_categories',
  'term_municipality',
  'term_programs',
  'term_region',
  'term_type',
  'computed_image',
  'computed_image.image',
]
