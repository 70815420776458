import { DATE_FILTER } from '@/components/List/constants'
import { Facets } from '@/components/List/types'
import { CheckboxField, SliderField } from '@/components/RoutesList/components'
import {
  DISTANCE_ROUTES_FILTER,
  DURATION_ROUTES_FILTER,
} from '@/components/RoutesList/constants'

import { MobileDatePicker } from '../../../DatePicker/components'

export const Content = ({ filter }: { filter: Facets }) => {
  switch (filter.id) {
    case DURATION_ROUTES_FILTER:
    case DISTANCE_ROUTES_FILTER:
      return <SliderField id={filter.id} />
    case DATE_FILTER:
      return <MobileDatePicker />
    default:
      return (
        <CheckboxField key={filter.id} id={filter.id} terms={filter.terms} />
      )
  }
}
