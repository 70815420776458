import { CardActivity } from '@/components/CardActivity'

import { DrupalNodeActivityProps } from './types'

const Activity = ({ node, size }: DrupalNodeActivityProps) => (
  <CardActivity
    title={node.title}
    image={node.computed_image}
    url={node.path?.alias}
    term_municipality={node.term_municipality}
    date={null}
    size={size}
    type={node.type}
  />
)

export { Activity }
