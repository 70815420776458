import { ImageStyleBreakPoints } from '@/components/ImageStyle'
import { SCALE_WEBP_600x400, SCALE_WEBP_1280x720 } from '@/content/constants'
import { Grid, Thumbs } from 'swiper'

export const MAIN_SETTINGS = (rows: number, thumbsSwiper: any) => ({
  slidesPerView: 1,
  showPagination: false,
  showNavigation: false,
  thumbs: { swiper: thumbsSwiper },
  modules: [Grid, Thumbs],
  grid: {
    rows: rows,
  },
  breakpoints: {
    768: {
      grid: {
        rows: undefined,
      },
    },
  },
})

export const BREAKPOINTS: ImageStyleBreakPoints = {
  default: {
    id: SCALE_WEBP_1280x720,
  },
}

export const BREAKPOINTS_SMALL: ImageStyleBreakPoints = {
  default: {
    id: SCALE_WEBP_600x400,
  },
}
