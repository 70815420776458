import { MOCK_FILE_DERIVATIVE } from '@/components/CardRoute/mock'
import { CKEditor } from '@/components/CKEditor'
import { ContentPopup } from '@/components/ContentPopup'
import { FormattedMessage } from '@/components/FormattedMessage'
import { MapPinIcon } from 'lucide-react'
import { DrupalMedia } from 'next-drupal'
import {
  CardContent,
  CardImage,
  Heading,
  ImageViewer,
  ImageViewerContent,
  ImageViewerTrigger,
  Text,
} from 'ui'
import { cn } from 'utils-tailwindcss'

import { FormattedDate } from '../../FormattedDate'
import { Media } from '../../Media'
import {
  OPTIONS_DAY,
  OPTIONS_MONTH,
} from '../../Node/components/Event/constans'
import { TaxonomyTerm } from '../../TaxonomyTerm'
import { BREAKPOINT } from '../constants'
import { CardActivityContentProps } from '../types'

const Content = ({
  title,
  image,
  term_municipality,
  date,
  size,
  popup,
  description,
}: CardActivityContentProps) => {
  if (!popup) {
    return (
      <>
        {image && (
          <CardImage>
            <Media
              media={image || MOCK_FILE_DERIVATIVE}
              //@ts-ignore
              breakpoints={BREAKPOINT}
            />
          </CardImage>
        )}

        <div className="w-full overflow-visible no-underline">
          <CardContent
            className={cn(
              `min-h-[147px] flex flex-col lg:flex-row gap-6  w-full justify-normal h-full lg:h-[177px]  ${
                !!size && 'flex-row'
              }`
            )}
          >
            {date && (
              <>
                <div
                  className={cn(
                    `flex-col items-center hidden lg:flex ${!!size && 'flex '}`
                  )}
                >
                  <Text className="text-base font-bold uppercase text-primary-red-900">
                    <FormattedDate date={date} options={OPTIONS_MONTH} />
                  </Text>
                  <Heading>
                    <FormattedDate date={date} options={OPTIONS_DAY} />
                  </Heading>
                </div>
                <div
                  className={cn(
                    `flex flex-row items-center lg:hidden ${!!size && 'hidden'}`
                  )}
                >
                  <Text className="text-base font-semibold text-primary-red-900">
                    <FormattedDate
                      date={date}
                      options={{
                        weekday: 'short',
                        day: 'numeric',
                        month: 'long',
                      }}
                    />
                  </Text>
                </div>
              </>
            )}
            <div className="flex flex-col gap-3 max-w-[237px] justify-between h-full ">
              <span className="font-semibold line-clamp-2">{title}</span>
              <div className="flex gap-2 ">
                {term_municipality && (
                  <>
                    <MapPinIcon
                      className="min-w-[20px]"
                      aria-labelledby="location"
                    />
                    <Text className="ml-2 break-words truncate text-ellipsis">
                      <TaxonomyTerm term={term_municipality} />
                    </Text>
                  </>
                )}
              </div>
            </div>
          </CardContent>
        </div>
      </>
    )
  }

  return (
    <CardImage>
      <ImageViewer>
        <ImageViewerTrigger
          className="w-full text-left hover:underline focus:border-2 focus:border-secondary-black-900 rounded-md focus:p-[2px]"
          aria-label={FormattedMessage({
            id: 'paragraph.gallery.enlarge',
          })}
        >
          <Media
            media={(image || MOCK_FILE_DERIVATIVE) as DrupalMedia}
            //@ts-ignore
            breakpoints={BREAKPOINT}
          />
          <div className="w-full overflow-visible no-underline">
            <CardContent
              className={cn(
                `min-h-[147px] flex flex-col lg:flex-row gap-6  w-full justify-normal h-full lg:h-[177px]  ${
                  !!size && 'flex-row'
                }`
              )}
            >
              {date && (
                <>
                  <div
                    className={cn(
                      `flex-col items-center hidden lg:flex ${
                        !!size && 'flex '
                      }`
                    )}
                  >
                    <Text className="text-base font-bold uppercase text-primary-red-900">
                      <FormattedDate date={date} options={OPTIONS_MONTH} />
                    </Text>
                    <Heading>
                      <FormattedDate date={date} options={OPTIONS_DAY} />
                    </Heading>
                  </div>
                  <div
                    className={cn(
                      `flex flex-row items-center lg:hidden ${
                        !!size && 'hidden'
                      }`
                    )}
                  >
                    <Text className="text-base font-semibold text-primary-red-900">
                      <FormattedDate
                        date={date}
                        options={{
                          weekday: 'short',
                          day: 'numeric',
                          month: 'long',
                        }}
                      />
                    </Text>
                  </div>
                </>
              )}
              <div className="flex flex-col gap-3 max-w-[237px] justify-between h-full ">
                <span className="font-semibold line-clamp-3">{title}</span>
                <div className="flex gap-2 ">
                  {term_municipality && (
                    <>
                      <MapPinIcon className="min-w-[20px]" />
                      <Text className="ml-2 break-words truncate text-ellipsis">
                        <TaxonomyTerm term={term_municipality} />
                      </Text>
                    </>
                  )}
                </div>
              </div>
            </CardContent>
          </div>
        </ImageViewerTrigger>
        <ImageViewerContent>
          <ContentPopup
            title={
              <Heading as="h3" className="font-bold">
                {title}
              </Heading>
            }
            body={!!description && <CKEditor text={description} />}
            image={
              <Media
                media={(image || MOCK_FILE_DERIVATIVE) as DrupalMedia}
                //@ts-ignore
                breakpoints={BREAKPOINT}
              />
            }
          />
        </ImageViewerContent>
      </ImageViewer>
    </CardImage>
  )
}

export { Content }
