import { useMemo } from 'react'
import { Carousel as GlobalCarousel } from '@/components/Carousel'
import { Link } from '@/components/Link'
import { Paragraph } from '@/components/Paragraph/Paragraph'
import { VIEW_MODE_CARD_BASIC } from '@/content/constants'
import { internalLink } from '@/lib/utils/internalLink'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ArrowRight, ExternalLink } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { DrupalParagraphContent } from '../../types'
import { DrupalParagraphCardsCarouselProps } from './types'

const Carousel = ({
  paragraph,
  cols = 3,
}: DrupalParagraphCardsCarouselProps) => {
  const viewMode =
    paragraph?.paragraph_view_mode &&
    paragraph?.content_view_mode &&
    paragraph?.paragraph_view_mode !== 'carousel_4col'
      ? `${paragraph?.content_view_mode}_${paragraph?.paragraph_view_mode}`
      : paragraph.content_view_mode ?? VIEW_MODE_CARD_BASIC

  const INTERNAL_LINK = paragraph.link && internalLink(paragraph.link.uri)

  const PUBLISHED_CONTENT = useMemo(
    () =>
      paragraph.paragraph_contents
        ? paragraph.paragraph_contents?.filter(
            (p) =>
              (p.content?.id !== 'missing' && p.content?.status) || !p.content
          )
        : [],
    [paragraph.paragraph_contents]
  )

  const conditionalTranslationKeys = {
    externalLink: 'global.externalLink',
    internalLink: 'global.internalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div
      className={cn(
        `${!!paragraph.village_filter && 'bg-neutral-gray-100 py-4'}`
      )}
    >
      <div className="custom-container">
        <GlobalCarousel
          title={paragraph.title}
          body={paragraph.body}
          subtitle={paragraph.subtitle}
          cols={cols}
          redLine={!!paragraph.underlined}
        >
          {
            PUBLISHED_CONTENT.map(
              (paragraph_content: DrupalParagraphContent, index) => (
                <div className="h-full" key={index}>
                  <Paragraph
                    paragraph={{
                      ...paragraph_content,
                      content_viewmode: viewMode,
                      cols: cols,
                      popup: paragraph.bool_popup,
                    }}
                  />
                </div>
              )
            ) as JSX.Element[]
          }
        </GlobalCarousel>
      </div>
      {paragraph.link && (
        <div className="container mb-12 text-right md:mb-18">
          <Link
            target={INTERNAL_LINK ? '_self' : '_blank'}
            href={paragraph.link.uri}
            intent="link"
            className="font-semibold underline"
            aria-label={paragraph.link.title}
            aria-describedby={
              INTERNAL_LINK
                ? translations.internalLink
                : translations.externalLink
            }
          >
            {paragraph.link.title}
            {INTERNAL_LINK ? (
              <ArrowRight
                aria-roledescription="presentation"
                aria-hidden="true"
              />
            ) : (
              <ExternalLink
                aria-roledescription="presentation"
                aria-hidden="true"
              />
            )}
          </Link>
          {!INTERNAL_LINK ? (
            <p className="sr-only">{translations.newWindow}</p>
          ) : null}
        </div>
      )}
    </div>
  )
}
export { Carousel }
