import { useMemo } from 'react'
import { LocationCard } from '@/components/LocationCard'
import { useMapListProvider } from '@/components/RoutesList/components/List/MapListProvider'

export const Default = () => {
  const {
    state: { currentRoute },
    setCurrentRoute,
  } = useMapListProvider()

  /// TODO: PREGUNTAR PARA PODER SABER QUE VA AQUI
  const currentTags: string[] = useMemo(() => {
    const tags: string[] = []
    currentRoute?.term_category && tags.push(currentRoute.term_category.name)
    currentRoute?.term_route_level &&
      tags.push(currentRoute.term_route_level.name)
    currentRoute?.term_route_type &&
      tags.push(currentRoute.term_route_type.name)
    return tags
  }, [currentRoute])

  return (
    currentRoute && (
      <LocationCard
        className="absolute bottom-2 left-2"
        onCloseClick={() => {
          setCurrentRoute(null)
        }}
        title={currentRoute.title}
        description={currentRoute.body}
        location={currentRoute.text_address}
        image={currentRoute.computed_image}
        tags={currentTags}
        link={currentRoute?.path?.alias}
        durationHours={currentRoute.duration_hours.toString()}
        durationMinutes={currentRoute.duration_minutes.toString()}
        distance={currentRoute.decimal_kmeters}
        urlGMaps={currentRoute.text_initial_point}
        gmapsUrl={currentRoute.gmaps_url}
      />
    )
  )
}
