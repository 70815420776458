import { useMemo } from 'react'
import { HookOutput } from 'utils'

import { INITIAL_STATE } from '../../constants'
import { useListProvider } from '../../ListProvider'

type usePaginatorResult = HookOutput<
  { isLoading: boolean | undefined; totalCount: number; actualShowed: number },
  {
    onClickMoreResults: () => void
  }
>

export const usePaginator = (): usePaginatorResult => {
  const {
    state: { isLoading, total_items, page, per_page, results },
    updateState,
  } = useListProvider()

  const totalCount = useMemo(() => total_items ?? 0, [total_items])
  const actualShowed = useMemo(
    () =>
      (results?.length ?? 0) < totalCount
        ? (page! ?? INITIAL_STATE.page) * (per_page! ?? INITIAL_STATE.per_page)
        : totalCount,
    [page, per_page, totalCount, results?.length]
  )

  const onClickMoreResults = () => {
    updateState({ page: (page ?? 0) + 1 })
  }

  return {
    state: { isLoading, totalCount, actualShowed },
    actions: { onClickMoreResults },
  }
}
