import { LocationCard } from '@/components/LocationCard'

import { useVillagesMapProvider } from '../../../../VillagesMapProvider'

export const List = () => {
  const {
    state: { villages },
  } = useVillagesMapProvider()

  return (
    <div className="container flex flex-col w-full gap-4 items-center">
      {villages.map((village) => (
        <LocationCard
          key={village.id}
          className="w-full border-neutral-gray-400"
          title={village.title}
          description={village.body}
          location={village.text_address}
          image={village.computed_image}
          link={village.path.alias}
          geofield_address={village.geofield_address}
        />
      ))}
    </div>
  )
}
