import { ElementRef, forwardRef } from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDown, PlusCircleIcon } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { TriggerStyles } from './Trigger.styles'
import { AccordionTriggerProps } from './types'

const Trigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionTriggerProps
>(({ className, children, icon, ...props }, ref) => (
  <AccordionPrimitive.Header className={TriggerStyles.header()}>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(TriggerStyles.root({ icon }), className)}
      {...props}
    >
      {children}
      {icon === 'plus' ? (
        <PlusCircleIcon
          className={TriggerStyles.icon()}
          aria-hidden="true"
          role="presentation"
        />
      ) : (
        <ChevronDown
          size={16}
          className={TriggerStyles.icon()}
          aria-hidden="true"
          role="presentation"
        />
      )}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))

Trigger.displayName = AccordionPrimitive.Trigger.displayName

export { Trigger }
