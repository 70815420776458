import { ElementRef, forwardRef } from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { cn } from 'utils-tailwindcss'

import { ContentStyles } from './Content.styles'
import { ContentProps } from './types'

const Content = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ContentProps
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(ContentStyles.content(), className)}
    {...props}
  >
    <div className={ContentStyles.children()}>{children}</div>
  </AccordionPrimitive.Content>
))

Content.displayName = AccordionPrimitive.Content.displayName

export { Content }
