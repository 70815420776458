import { createElement } from 'react'
import { VIEW_MODE_DEFAULT } from '@/content/constants'

import { NODES } from './constants'
import { BundleDrupalNode, NodeType } from './types'

const Node = ({ node, ...rest }: { node: BundleDrupalNode }) => {
  if (node?.type !== 'unknown' && node && node?.status) {
    const bundle = NODES[node.type as NodeType]
    const viewMode = node?.viewMode ?? VIEW_MODE_DEFAULT
    const className = node?.className ?? ''
    const popup = !!node.popup

    return createElement(bundle, {
      node: node,
      viewMode,
      className,
      popup,
      ...rest,
    })
  }
}

export { Node }
