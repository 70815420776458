import { cva } from 'class-variance-authority'

export const CalendarStyles = {
  header: {
    arrows: cva('relative flex items-center justify-start md:justify-center'),
    next: cva('absolute -right-4 top-1'),
    previous: cva('absolute -left-6 top-1'),
    currentMonthAndYearText: cva('capitalize font-bold text-center text-lg'),
  },
  cell: cva('h-8 w-8 md:w-12 md:h-8 relative'),
  table: {
    root: cva('w-full h-full'),
    head: {
      row: cva('flex justify-between md:gap-3'),
      daysText: cva('text-neutral-gray-500 capitalize w-6 md:w-12 px-0 py-3'),
    },
    body: {
      daysNumbers: cva('flex justify-between md:gap-3 text-sm'),
    },
  },
  day: {
    general: cva([
      'block rounded-full w-full h-full z-18',
      'hover:bg-primary-red-50 hover:text-primary-red-900 hover:font-semibold',
      'aria-selected:after:bg-neutral-white',
      'after:w-1 after:h-1 after:bottom-1 after:left-1/2 after:absolute after:-translate-x-1/2 after:rounded-full',
    ]),
    selected: cva([
      'bg-primary-red-900 aria-selected:font-bold aria-selected:text-neutral-gray-100 hover:bg-primary-red-900',
    ]),
    outside: cva('text-neutral-gray-300'),
    today: cva('text-neutral-gray-300 font-extrabold'),
    rangeMiddle: cva([
      'aria-selected:bg-primary-red-50 aria-selected:font-bold',
      'aria-selected:after:bg-primary-red-50 aria-selected:text-primary-red-900',
    ]),
    disabled: cva('text-neutral-gray-300'),
    day_range_end: cva('hover:bg-primary-red-900'),
    day_range_start: cva('hover:bg-primary-red-900'),
  },
  tBody: cva('flex flex-col md:gap-3 h-full'),
  headRow: cva('[&_th:last-child]:pr-0 w-full'),
  card: cva('p-6 flex flex-col w-full sm:w-full h-auto bg-neutral-white', {
    variants: {
      border: { true: ['shadow-sm border rounded-md'] },
      rounded: { true: ['rounded-2xl'] },
    },
  }),
  months: cva('flex flex-col md:flex-row w-full h-full '),
}
