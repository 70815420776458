import { ParsedUrlQuery } from 'querystring'
import { NODE_EVENT_BASE, NODE_ROUTE_BASE } from '@/components/Node/constants'
import { LIST_TYPE_ITINERARY } from '@/components/Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'
import { getSortParams } from '@/components/RoutesList/components/List/components/Desktop/components/SortFilter/helper'
import {
  CATEGORY_FILTER,
  CULTURE_ROUTES_FILTER,
  DISTANCE_ROUTES_FILTER,
  DURATION_ROUTES_FILTER,
  GASTRONOMY_ROUTES_FILTER,
  ITINERARIES_CULTURAL_FILTER,
  ITINERARIES_FILTER,
  ITINERARIES_FREETIME_FILTER,
  ITINERARIES_GASTRONOMY_FILTER,
  ITINERARIES_NATURE_FILTER,
  NATURAL_ROUTES_FILTER,
  ROUTES_TYPE,
  SORT_FILTER,
} from '@/components/RoutesList/constants'
import { getSortFilter } from '@/components/RoutesList/helpers/getSortFilter'
import { getParams } from '@/lib/get-params'
import { CalendarDateRange } from 'ui'
import { formattedDate } from 'utils'

import { getDateParams } from '../components/DatePicker/helper'
import {
  CICLA_MADRID_INDEX,
  DATE_FILTER,
  EVENTS,
  EXHIBITIONS,
  GRAN_TOUR_INDEX,
} from '../constants'
import { Filter, State } from '../types'

export const getQueryParams = (filters: Filter[], query?: string) => {
  let queryParams = ''
  if (filters && filters.length > 0) {
    queryParams = filters.reduce(
      (acc: string, filter: Filter) => {
        if (filter.type === DATE_FILTER) {
          let date = `${acc}from[${filter.type}]=${formattedDate(
            filter.date?.from ?? new Date(),
            'en',
            { dateStyle: 'short' }
          )}&`
          if (filter.date?.to !== undefined) {
            date += `to[${filter.type}]=${formattedDate(
              filter.date?.to ?? new Date(),
              'en',
              { dateStyle: 'short' }
            )}&`
          }
          return date
        } else if (
          filter.type === DURATION_ROUTES_FILTER ||
          filter.type === DISTANCE_ROUTES_FILTER
        ) {
          return `${acc}range[${filter.type}]=${filter.values.label!.replace(
            /\s+/g,
            ''
          )}&`
        } else if (filter.type === SORT_FILTER) {
          const label = filter.values.value.split('-')
          return `${acc}${label[0]}[${filter.type}]=${label[1]}&`
        }
        return `${acc}${filter.values.label}[${filter.type}]=${filter.values.value}&`
      },
      `?${query ? `query[query]=${query}&` : ''}`
    )
    queryParams = queryParams.substring(0, queryParams.length - 1)
  } else if (query) {
    queryParams = `?query[query]=${query}`
  }
  return encodeURI(queryParams)
}

export const getFiltersFromQueryParams = (
  query: ParsedUrlQuery,
  locale: string
): Filter[] => {
  const filters: Filter[] = Object.keys(query)
    .map((key) => {
      const endIdIndex = key.indexOf('[')
      const label = key.substring(0, endIdIndex)
      const type = key.substring(endIdIndex + 1, key.length - 1)
      const value = query[key]

      return {
        type: type,
        values: { value: value as string, label: label },
      }
    })
    .filter(
      (item) => item.type !== 'slu' && item.type !== 'i' && item.type !== 'pag'
    )

  const dateFilters = filters.filter((filter) => filter.type === DATE_FILTER)
  if (dateFilters.length > 0) {
    const dateRange: CalendarDateRange = {
      from: new Date(dateFilters.at(0)?.values.value as string),
      to: dateFilters.at(1)
        ? new Date(dateFilters.at(1)?.values.value as string)
        : undefined,
    } as CalendarDateRange

    const dateFilter: Filter = {
      type: DATE_FILTER,
      date: dateRange,
      values: {
        value: formattedDateToStringLabel(locale, dateRange),
        label: formattedDateToStringLabel(locale, dateRange),
      },
    }
    const filtersWithDate = filters.filter(
      (filter) => filter.type !== DATE_FILTER
    )
    filtersWithDate.push(dateFilter)
    return filtersWithDate
  }

  filters.forEach((filter: Filter) => {
    if (
      (filter.type === DISTANCE_ROUTES_FILTER ||
        filter.type === DURATION_ROUTES_FILTER) &&
      filter.values.label === 'range'
    ) {
      const rangeArray = filter.values.value.split('-')
      filter.range = [
        Number(rangeArray[0].split(':')[0]),
        Number(rangeArray[1].split(':')[0]),
      ]
      filter.values.label = filter.values.value
    } else if (filter.type === SORT_FILTER) {
      const sortFilter = getSortFilter(
        `${filter.values.label}-${filter.values.value}`
      )

      filter.values.value = sortFilter?.values.value || ''
      filter.values.label = sortFilter?.values.label
    }
  })
  return filters
}

export const formattedDateToStringLabel = (
  locale: string,
  date?: CalendarDateRange
): string => {
  let dateToString = ''
  if (date?.from !== undefined) {
    dateToString += formattedDate(date.from, locale ?? 'es')
  }

  if (date?.to !== undefined) {
    dateToString += ` - ${formattedDate(date.to, locale ?? 'es')}`
  }

  return dateToString
}

export const getParamsForQuery = (
  type: string,
  state: State,
  langcode: string = 'es'
) => {
  const apiParam = getParams(
    type === CICLA_MADRID_INDEX || type === GRAN_TOUR_INDEX
      ? NODE_ROUTE_BASE
      : type
  )

  apiParam.addFilter('status', '1')
  apiParam.addFilter('moderation_state', 'published', '=')
  apiParam.addFilter('langcode', langcode)

  if (state.query) {
    apiParam.addFilter('title', state.query)
  }

  if (state.per_page) {
    apiParam.addPageLimit(state.per_page)
  }
  if (state.page && state.per_page) {
    apiParam.addPageOffset(
      state.page === 1 ? 0 : (state.page - 1) * state.per_page
    )
  }
  //@todo: falta enpoint value harcodeado desde porduccion para que funcione ahi
  if (state.tabs === EVENTS && type === NODE_EVENT_BASE) {
    apiParam.addFilter('categories', '9', 'NOT IN')
  }
  //@todo: falta enpoint value harcodeado desde porduccion para que funcione ahi
  if (state.tabs === EXHIBITIONS && type === NODE_EVENT_BASE) {
    apiParam.addFilter('events_categories', '9')
  }
  //@todo: falta enpoint value harcodeado desde porduccion para que funcione ahi
  if (type === CICLA_MADRID_INDEX) {
    apiParam.addFilter(ROUTES_TYPE, ['70', '71'], 'IN')
  }
  //@todo: falta enpoint, value harcodeado desde porduccion para que funcione ahi
  if (type === GRAN_TOUR_INDEX) {
    apiParam.addFilter(ROUTES_TYPE, '70')
  }
  if (type === LIST_TYPE_ITINERARY) {
    apiParam.addSort('title', 'ASC')
  }

  const types = getAllDistinctFilterTypes(state)

  const categoriesValues: string[] = []
  const servicesValues: string[] = []
  if (state.activeFilters && state.activeFilters.length) {
    types?.map((filterType) => {
      switch (filterType) {
        case DATE_FILTER:
          const value = getFilters(state, DATE_FILTER)
          getDateParams(apiParam, value?.[0].date!)

          break
        case DISTANCE_ROUTES_FILTER:
          const distance = getFilters(state, filterType)?.[0]
            .range?.toString()
            .split(',')

          apiParam
            .addFilter(DISTANCE_ROUTES_FILTER, distance?.[0] ?? '0', '>=')
            .addFilter(DISTANCE_ROUTES_FILTER, distance?.[1] ?? '100', '<=')
          break

        case DURATION_ROUTES_FILTER:
          const duration = getFilters(state, filterType)?.[0].range

          if (duration) {
            apiParam.addFilter(filterType, (duration[0] * 60).toString(), '>=')
            apiParam.addFilter(filterType, (duration[1] * 60).toString(), '<=')
          }
          break
        case SORT_FILTER:
          const sort = getFilters(state, SORT_FILTER)?.[0]
          getSortParams(apiParam, sort?.values.value!)

          break
        case NATURAL_ROUTES_FILTER:
        case CULTURE_ROUTES_FILTER:
        case GASTRONOMY_ROUTES_FILTER:
          categoriesValues.push(
            ...(getValuesFromFilter(state, filterType!) ?? [])
          )
          break
        case ITINERARIES_GASTRONOMY_FILTER:
        case ITINERARIES_CULTURAL_FILTER:
        case ITINERARIES_FREETIME_FILTER:
        case ITINERARIES_NATURE_FILTER:
          servicesValues.push(
            ...(getValuesFromFilter(state, filterType!) ?? [])
          )
          break
        default:
          const filtersValues = getValuesFromFilter(state, filterType!)
          apiParam.addFilter(filterType!, filtersValues!, 'IN')
          break
      }
    })
  }

  if (!!categoriesValues.length) {
    apiParam.addFilter(CATEGORY_FILTER, categoriesValues!, 'IN')
  }
  if (!!servicesValues.length) {
    apiParam.addFilter(ITINERARIES_FILTER, servicesValues!, 'IN')
  }

  if (type === NODE_EVENT_BASE) {
    apiParam.addSort('list_highlighted', 'ASC')
    apiParam.addSort('current_occurrence', 'ASC')
  }

  return apiParam.getQueryObject()
}

export const getAllDistinctFilterTypes = (state: State) => {
  const types = state.activeFilters?.map((filter: Filter) => filter.type)
  return types?.filter((value, index, array) => array.indexOf(value) === index)
}

export const getValuesFromFilter = (state: State, type: string) => {
  return state.activeFilters
    ?.filter((filter: Filter) => filter.type === type)
    .map((filter: Filter) => filter.values.value)
}

export const getFilters = (state: State, type: string) => {
  return state.activeFilters?.filter((filter: Filter) => filter.type === type)
}
