import { CardVerticalBasic } from '@/components/CardVerticalBasic'

const VerticalBasic = ({ paragraph_content }: { paragraph_content }) => (
  <CardVerticalBasic
    title={paragraph_content.title}
    image={paragraph_content.image}
    url={paragraph_content.link?.uri}
    popup={!!paragraph_content.popup}
    description={paragraph_content.body}
  />
)

export { VerticalBasic }
