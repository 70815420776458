import { cva } from 'class-variance-authority'

export const ButtonTagStyles = cva(
  [
    'inline-flex items-center py-2 px-4 rounded-md bg-neutral-white text-base text-text-dark border border-neutral-400',
    'hover:ring-2 hover:ring-neutral-400 hover:cursor-pointer',
  ],
  {
    variants: {
      isActive: {
        true: 'bg-primary-red-900 text-neutral-white font-bold',
      },
    },
  }
)
