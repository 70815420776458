import { CardSmallBasic } from '@/components/CardSmallBasic'

import { DrupalNodeLandingBasictProps } from './types'

const SmallBasic = ({ node, className }: DrupalNodeLandingBasictProps) => (
  <CardSmallBasic
    title={node.title}
    image={node.computed_image}
    url={node.path?.alias}
    className={className}
  />
)

export { SmallBasic }
