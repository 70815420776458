import { DrupalTaxonomyTerm } from 'next-drupal'
import { generateUuid } from 'utils'

export const MOCK_TERM = (type: string): DrupalTaxonomyTerm => ({
  // JsonApiResource
  id: generateUuid(),
  type: type,
  langcode: '',
  status: true,
  // JsonApiResourceWithPath
  path: { alias: '', pid: 0, langcode: '' },
  // DrupalTaxonomyTerm
  //@ts-ignore
  drupal_internal__tid: '',
  changed: '0000-00-00T00:00:00+00:00',
  default_langcode: true,
  name: 'Lorem ipsum',
  description: 'Lorem ipsum dolor sit amet',
  weight: 0,
})
