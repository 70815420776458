'use client'

import { useEffect, useRef, useState } from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'
import { useWidth } from 'utils'

import { SelectStyles } from './Select.styles'
import { SelectProvider } from './SelectProvider'
import { SelectProps } from './types'

const Select = ({ children, ...rest }: SelectProps) => {
  const [contentWidth, setContentWidth] = useState(0)
  const width = useWidth()

  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current?.offsetWidth) {
      setContentWidth(contentRef.current.offsetWidth)
    }
  }, [contentRef, width])

  return (
    <SelectProvider width={contentWidth}>
      <div className={SelectStyles.root()} ref={contentRef}>
        <SelectPrimitive.Root {...rest}>{children}</SelectPrimitive.Root>
      </div>
    </SelectProvider>
  )
}

Select.displayName = 'Select'

export { Select }
