import { DrupalNode } from 'next-drupal'
import { generateUuid } from 'utils'

export const MOCK_NODE = (type: string): DrupalNode => ({
  // JsonApiResource
  id: generateUuid(),
  type: type,
  langcode: '',
  status: true,
  // JsonApiResourceWithPath
  path: { alias: '', pid: 0, langcode: '' },
  // DrupalNode
  drupal_internal__nid: 0,
  drupal_internal__vid: 0,
  changed: '0000-00-00T00:00:00+00:00',
  created: '0000-00-00T00:00:00+00:00',
  title: 'Lorem ipsum dolor',
  default_langcode: true,
  sticky: false,
})
