import { cva } from 'class-variance-authority'

export const CloseStyles = {
  close: cva([
    'rounded-full h-[25px] w-[25px] inline-flex items-center justify-center absolute top-[5px] right-[5px] outline-none cursor-default',
    'hover:bg-slate-100 hover:cursor-pointer',
    'first-letter:focus:shadow-[0_0_0_2px]',
    'active:text-primary-red-900',
  ]),
}
