import { useEffect, useState } from 'react'
import { FormattedMessage } from '@/components/FormattedMessage'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { Node } from '@/components/Node'
import { contentTouristPointType } from '@/components/Node/components/Route/types'
import { VIEW_MODE_BASIC_TEASER } from '@/content/constants'
import { ChevronLeft, ChevronRight, Share2Icon } from 'lucide-react'
import { Button, Card, CardTitle, Separator } from 'ui'
import { cn } from 'utils-tailwindcss'

export const Navigation = ({
  touristicPoints,
  currentTuristicPoints,
  onItemClick,
  onNavigationChange,
}: {
  touristicPoints: contentTouristPointType[]
  currentTuristicPoints?: contentTouristPointType | null
  onItemClick: (_touristicPoint: contentTouristPointType) => void
  onNavigationChange: (_isNavigationOpen: boolean) => void
}) => {
  const [showNavigation, setShowNavigation] = useState(false)

  useEffect(() => {
    onNavigationChange(showNavigation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNavigation])

  return (
    <div className="absolute z-10">
      <div className="relative flex">
        <button
          type="button"
          onClick={() => setShowNavigation(!showNavigation)}
          className="absolute top-0 w-6 rounded-l-none z-18 h-18 rounded-r-2xl -right-6 bg-neutral-gray-100"
          aria-label={FormattedMessage({
            id: 'paragraph.map.pointsOfInterestDropDown',
          })}
        >
          {showNavigation ? <ChevronLeft /> : <ChevronRight />}
        </button>
        <Card
          className={cn(
            'duration-500 transition-all ease-in-out h-fit rounded-none',
            {
              'border-neutral-gray-200 border-[0.5px] w-[26rem] overflow-x-auto':
                showNavigation,
              'w-0': !showNavigation,
            }
          )}
        >
          <CardTitle
            className={cn(
              'sticky top-0 flex flex-col gap-1 px-4 py-2 mb-0 text-base font-normal no-underline bg-neutral-gray-100'
            )}
          >
            <FormattedRichMessage
              id="node.route.full.routePointOfInterest"
              values={{ number: touristicPoints.length }}
            />
            <Button
              intent="link"
              className="no-underline w-fit"
              aria-label={FormattedMessage({
                id: 'paragraph.map.share',
              })}
            >
              <Share2Icon />
              <FormattedMessage id="paragraph.map.share" />
            </Button>
          </CardTitle>
          <Separator color="gray" size="empty" />
          <div className="max-h-[39rem] flex flex-col overflow-y-auto ">
            {touristicPoints.map((touristPoint: contentTouristPointType) => (
              <button
                key={touristPoint.id}
                type="button"
                onClick={() => onItemClick(touristPoint)}
                className={cn({
                  'border-2 border-primary-red-900':
                    currentTuristicPoints?.id === touristPoint?.id,
                })}
              >
                <Node
                  node={{
                    ...touristPoint,
                    viewMode: VIEW_MODE_BASIC_TEASER,
                  }}
                />
              </button>
            ))}
          </div>
        </Card>
      </div>
    </div>
  )
}
