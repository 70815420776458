import { useCallback, useMemo } from 'react'
import { Facets } from '@/components/List/types'
import { BundleDrupalNode } from '@/components/Node/types'
import {
  CLOSEST_ROUTES_FILTER,
  SORT_FILTER,
  SORT_FILTER_FACET,
} from '@/components/RoutesList/constants'
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form'
import { HookOutput } from 'utils'

import { useMapListProvider } from '../../../../MapListProvider'

type useSortFilterResult = HookOutput<
  {
    showSort: boolean
    sortForm: UseFormReturn<FieldValues, any, undefined>
    results?: BundleDrupalNode[]
    sortFilter: Facets
    activeFilterCount?: number | null
  },
  {
    onSubmit: (_data: Record<string, string | string[] | number[]>) => void
    deleteSortFilter: () => void
    setShowSort: (_showSort: boolean) => void
    toogleFilters: (_showFilter: boolean, _showSort: boolean) => void
  }
>

export const useSortFilters = (): useSortFilterResult => {
  const {
    state: { showSort, form: filterForm },
    listState: { activeFilters },
    onSubmitFilter,
    setShowSort,
    removeFilter,
    toogleFilters,
  } = useMapListProvider()
  const sortForm = useForm()

  const activeFilterCount = useMemo(
    () =>
      activeFilters?.filter((filter) => filter.type === SORT_FILTER).length ||
      null,
    [activeFilters]
  )

  const sortFilter = useMemo(() => {
    const defaultFilter = activeFilters?.filter(
      (fil) => fil.type === SORT_FILTER
    )?.[0]

    return {
      ...SORT_FILTER_FACET,
      defaultValue: !!defaultFilter
        ? defaultFilter.values.value
        : `${CLOSEST_ROUTES_FILTER}-CLOSE`,
    }
  }, [activeFilters])

  const deleteSortFilter = useCallback(() => {
    sortForm.setValue(SORT_FILTER, null)
    removeFilter(SORT_FILTER, true)
    setShowSort(false)
  }, [removeFilter, setShowSort, sortForm])

  const onSubmit = useCallback(
    (data: Record<string, string>) => {
      onSubmitFilter({ ...filterForm.getValues(), ...data })
      setShowSort(false)
    },
    [filterForm, onSubmitFilter, setShowSort]
  )

  return {
    state: { showSort, sortForm, activeFilterCount, sortFilter },
    actions: { deleteSortFilter, onSubmit, setShowSort, toogleFilters },
  }
}
