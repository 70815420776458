import HolyLoader from 'holy-loader'
import {
  Layout,
  LayoutContent,
  LayoutFooter,
  LayoutHeader,
} from 'ui/src/components/atoms/Layout'

import { FloatingItinerary } from '../FloatingItinerary'
import { JumpHeader } from '../JumpHeader'
import { Footer, Header } from './components'
import { Intro } from './components/Intro/Intro'
import { PublicLayoutProps } from './types'
import { usePublicLayout } from './usePublicLayout'

const PublicLayout = ({
  children,
  megamenu,
  isHome = false,
  title,
  footerMenu,
  footerMenuExtras,
  image,
  isCreateYourItinerary = false,
  isContactPage,
  breadcrumbs,
  entity,
  ...rest
}: PublicLayoutProps) => {
  const {
    state: { languages },
  } = usePublicLayout(entity)

  return (
    <Layout {...rest}>
      <JumpHeader />
      <LayoutHeader className="relative flex justify-center w-full z-21 md:z-30 bg-gradient-to-b from-secondary-black-900">
        <Header megamenu={megamenu} isHome={isHome} languages={languages} />
      </LayoutHeader>
      <LayoutContent>
        <HolyLoader
          color="#949494"
          height="0.2rem"
          speed={100}
          easing="linear"
        />
        {!isHome && (
          <>
            <Intro
              image={image}
              title={title}
              initialBreadcrumbs={breadcrumbs}
            />
            <span id="jump-header"></span>
          </>
        )}
        {!isCreateYourItinerary && <FloatingItinerary />}
        {children}
      </LayoutContent>
      <LayoutFooter className="bg-primary-red-900">
        <Footer
          links={footerMenu}
          linksLegal={footerMenuExtras}
          isContactPage={isContactPage}
          languages={languages}
        />
      </LayoutFooter>
    </Layout>
  )
}

export { PublicLayout }
