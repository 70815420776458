import { Paragraph } from '@/components/Paragraph'

import { DrupalNodeLandingFullProps } from './types'

const Full = ({ node, ...rest }: DrupalNodeLandingFullProps) => (
  <>
    {node.paragraphs.map((paragraph) => (
      <Paragraph
        key={paragraph.id}
        paragraph={paragraph}
        title={node.title}
        {...rest}
      />
    ))}
  </>
)

export { Full }
