import { forwardRef, HTMLAttributes } from 'react'
import { cn } from 'utils-tailwindcss'

import { TitleStyles } from './Title.styles'

const Title = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn(TitleStyles(), className)} {...props}>
      {children}
    </div>
  )
)

Title.displayName = 'Title'

export { Title }
