import { ComponentPropsWithoutRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { HeaderStyles } from './Header.styles'

const Header = ({ children, className }: ComponentPropsWithoutRef<'div'>) => (
  <div className={cn(HeaderStyles(), className)}>{children}</div>
)

export { Header }
