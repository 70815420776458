import { useState } from 'react'
import Link from 'next/link'
import { Link as LinkButton } from '@/components/Link'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Map, X } from 'lucide-react'
import { useRouter } from 'next-translate-routes'

import { SVGMap } from './components/SVGMap'

const FloatingItinerary = () => {
  const [toggleItinerary, setToggleItinerary] = useState(false)

  const conditionalTranslationKeys = {
    createYourItinerary: 'global.createYourItinerary',
    closeCreateItinerary: 'itinerary.closeCreateItinerary',
    close: 'global.close',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  const { locale } = useRouter()

  return (
    <>
      {!toggleItinerary && (
        <div className="fixed z-20 right-4 bottom-6 md:hidden">
          <LinkButton
            locale={locale}
            href={'/crea-tu-itinerario'}
            className="text-sm border bg-primary-red-900 text-neutral-white border-neutral-white"
          >
            <Map aria-roledescription="presentation" aria-hidden="true" />
            {translations.createYourItinerary}
          </LinkButton>
          <button
            onClick={() => setToggleItinerary(!toggleItinerary)}
            className="absolute z-30 flex items-center justify-center border rounded-full right-[-10px] top-[-10px] bg-neutral-white text-primary-red-900 border-primary-red-900 max-w-min"
          >
            <X
              className="p-1"
              width={26}
              height={26}
              aria-roledescription="presentation"
              aria-hidden="true"
            />
            <span className="sr-only">{translations.close}</span>
          </button>
        </div>
      )}
      {!toggleItinerary && (
        <div className="fixed bottom-0 z-20 hidden right-32 text-neutral-white md:flex">
          <button
            onClick={() => setToggleItinerary(!toggleItinerary)}
            className="absolute z-10 flex items-center justify-center border rounded-full right-6 bg-neutral-white text-primary-red-900 border-primary-red-900 max-w-min"
            aria-label={translations.createYourItinerary}
          >
            <X
              className="p-1"
              width={26}
              height={26}
              aria-roledescription="presentation"
              aria-hidden="true"
            />
            <span className="sr-only">{translations.close}</span>
          </button>
          {/**
           * @TODO
           * href?
           */}
          <Link
            locale={locale}
            href={'/crea-tu-itinerario'}
            className="relative"
          >
            <SVGMap />
            <span className="absolute w-1/2 text-sm font-bold text-center top-[40%] left-1/4">
              {translations.createYourItinerary}
            </span>
          </Link>
        </div>
      )}
    </>
  )
}

export { FloatingItinerary }
