import clsx from 'clsx'

import { SvgPaths } from './constants'
import { InteractiveMapProps } from './types'
import { useInteractiveMap } from './useInteractiveMap'

const InteractiveMap = ({
  value = [],
  onPathClick,
  ...rest
}: InteractiveMapProps) => {
  const {
    state: { clickedPath, hoveredPath },
    actions: { setHoveredPath, onClick },
  } = useInteractiveMap(value, onPathClick)

  return (
    <svg viewBox="0 0 375 400" role="presentation" aria-hidden="true" {...rest}>
      {SvgPaths.map((svg, index) => (
        <path
          key={svg.id}
          d={svg.d}
          onMouseEnter={() => setHoveredPath(index)}
          onMouseLeave={() => setHoveredPath(null)}
          //@ts-ignore
          onClick={() => onClick(svg.id.split(' ')[0])}
          className={clsx(
            'stroke-white cursor-pointer',
            (hoveredPath === index ||
              clickedPath.includes(svg.id.split(' ')[0])) &&
              svg.id
              ? 'fill-primary-red-900'
              : 'fill-transparent'
          )}
        />
      ))}
    </svg>
  )
}

export { InteractiveMap }
