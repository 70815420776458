export const MOCK_FILE_DERIVATIVE = {
  type: 'media--image',
  id: 'b2bd9ad6-9cc6-49d7-90b5-b3027382a4443',
  drupal_internal__fid: '',
  langcode: 'es',
  filename: 'lorem.png',
  uri: {
    value: 'public://0000-00/lorem.png',
    url: '/sites/default/files/0000-00/lorem.png',
  },
  filemime: 'image/png',
  filesize: 0,
  status: true,
  created: '2023-06-20T08:49:34+00:00',
  changed: '2023-06-20T08:49:34+00:00',
  image: {
    links: {
      thumbnail: {
        href: '/madrid_mobile.webp',
        title: '',
        type: 'image/png',
        meta: {
          width: 20,
          height: 20,
        },
      },
      scale_webp_640x320: {
        href: '/madrid_mobile.webp',
        title: 'Image Style: [Scale][Webp] 640x320',
        type: 'image/png',
        meta: {
          width: 640,
          height: 320,
        },
      },
      scale_webp_320x240: {
        href: '/madrid_mobile.webp',
        title: '[Scale][Webp] 320x240',
        type: 'image/png',
        meta: {
          width: 390,
          height: 260,
        },
      },
      scale_webp_600x400: {
        href: '/madrid_mobile.webp',
        title: '[Scale][Webp] 600x400',
        type: 'image/png',
        meta: {
          width: 600,
          height: 400,
        },
      },
      scale_webp_110x85: {
        href: '/madrid_mobile.webp',
        title: '[Scale][Webp] 110x85',
        type: 'image/png',
        meta: {
          width: 110,
          height: 85,
        },
      },
      scale_webp_150x100: {
        href: '/150/100',
        title: '[Scale][Webp] 150x100',
        type: 'image/png',
        meta: {
          width: 150,
          height: 100,
        },
      },
      scale_webp_390x520: {
        href: '/madrid_mobile.webp',
        title: '[Scale][Webp] 390x520',
        type: 'image/png',
        meta: {
          width: 390,
          height: 520,
        },
      },
      scale_webp_390x260: {
        href: '/madrid_mobile.webp',

        title: '[Scale][Webp] 390x260',
        type: 'image/png',
        meta: {
          width: 390,
          height: 260,
        },
      },
      scale_webp_286x191: {
        href: '/madrid_mobile.webp',

        title: '[Scale][Webp] 286x191',
        type: 'image/png',
        meta: {
          width: 286,
          height: 191,
        },
      },
      scale_webp_600x150: {
        href: '/madrid_mobile.webp',
        title: '[Scale][Webp] 600x150',
        type: 'image/png',
        meta: {
          width: 600,
          height: 150,
        },
      },
      scale_webp_1280x720: {
        href: '/madrid_mobile.webp',
        title: '[Scale][Webp] 1280x720',
        type: 'image/png',
        meta: {
          width: 1280,
          height: 720,
        },
      },
      scale_webp_640x400: {
        href: '/madrid_mobile.webp',
        title: '[Scale][Webp] 640x400',
        type: 'image/png',
        meta: {
          width: 640,
          height: 400,
        },
      },
      scale_webp_1920x400: {
        href: '/madrid_mobile.webp',
        title: '[Scale][Webp] 1920x400',
        type: 'image/png',
        meta: {
          width: 1920,
          height: 400,
        },
      },
      scale_webp_596x806: {
        href: '/madrid_mobile.webp',
        title: 'Image Style: [Scale][Webp] 596x806',
        type: 'image/png',
        meta: {
          width: 596,
          height: 806,
        },
      },
      scale_webp_768x1024: {
        href: '/madrid_mobile.webp',
        title: 'Image Style: [Scale][Webp] 768x1024',
        type: 'image/png',
        meta: {
          width: 596,
          height: 806,
        },
      },
      scale_webp_474x316: {
        href: '/madrid_mobile.webp',
        title: 'Image Style: [Scale][Webp] 474x316',
        type: 'image/png',
        meta: {
          width: 474,
          height: 316,
        },
      },
      scale_webp_474x520: {
        href: '/madrid_mobile.webp',
        title: 'Image Style: [Scale][Webp] 474x520',
        type: 'image/png',
        meta: {
          width: 474,
          height: 520,
        },
      },
    },
  },
}
