import { createElement } from 'react'
import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { DrupalTaxonomyTerm } from 'next-drupal'

import { RouteCategory } from './components/RouteCategory'
import { TAXONOMY_TERMS } from './constants'
import { TaxonomyTermType } from './types'

const TaxonomyTerm = ({
  term,
  ...rest
}: {
  term: DrupalTaxonomyTerm | DrupalTaxonomyTerm[]
}) => {
  return Array.isArray(term) ? (
    term.map((t, index) => {
      return <TaxonomyTermElement key={index} term={t} {...rest} />
    })
  ) : (
    <TaxonomyTermElement term={term} {...rest} />
  )
}

export { TaxonomyTerm }

const TaxonomyTermElement = ({
  term,
  ...rest
}: {
  term: DrupalTaxonomyTerm
}) => {
  if (term?.type === 'unknown' && term) {
    return <></>
  }

  const bundle = TAXONOMY_TERMS[term?.type as TaxonomyTermType] ?? RouteCategory
  const viewMode = term?.viewMode ?? VIEW_MODE_DEFAULT

  return (
    <span id={term?.id} className={`${term?.type} view-mode-${viewMode} block`}>
      {createElement(bundle, {
        term: term,
        viewMode: viewMode,
        ...rest,
      })}
    </span>
  )
}
