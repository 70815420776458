'use client'

import { ElementRef, forwardRef } from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { CheckboxStyles } from './Checkbox.styles'
import { CheckboxProps } from './types'

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(CheckboxStyles.root(), className)}
    aria-label="checkbox option"
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn(CheckboxStyles.indicator())}>
      <Check strokeWidth={4} size={14} radius={4} />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))

Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
