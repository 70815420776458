export const ZIPCODES_MAP = {
  'menu_link_content:b633445f-bbaf-48b1-bd85-e4304ba148b6': [28001],
  'menu_link_content:73fb99e9-8623-40a3-aaab-32b41533e6a6': ['28800 - 28807'],
  'menu_link_content:63d67395-15d6-47c8-a61e-b8c6fa1292cd': [
    28300, 28310, 28312,
  ],
  'menu_link_content:78947ae9-a43e-454c-9782-db5c445d8823': [28200],
  'menu_link_content:d26970c3-a677-43bc-a8af-f884b286c078': [
    28730, 28370, 28380, 28600, 28514, 28740, 28410, 28680, 28180, 28590, 28189,
  ],
  'menu_link_content:9234f6aa-da19-4c4b-978b-2ffdd52df795': [
    28749, 28194, 28737, 28730, 28720, 28721, 28743, 28193, 28192, 28722, 28743,
    28739, 28737, 28755, 28191, 28755, 28751, 28191, 28737, 28742, 28752, 28755,
    28190, 28729, 28739, 28189, 28749, 28737, 28191, 28190, 28754, 28195, 28196,
    28740, 28749, 28194, 28755, 28756, 28180, 28729, 28729, 28739, 28620, 28640,
    28650, 28694, 28214, 28213, 28212, 28695, 28696, 28294, 28649, 28680, 28296,
    28297, 28295, 28630, 28610, 28293, 28580, 28818, 28390, 28596, 28510, 28560,
    28370, 28350, 28380, 28811, 28595, 28597, 28817, 28530, 28514, 28515, 28570,
    28540, 28812, 28818, 28550, 28359, 28594, 28391, 28511, 28812, 28360, 28598,
    28512, 28513, 28514, 28590, 28749, 28490, 28470, 28413, 28742, 28410, 28792,
    28491, 28739, 28749, 28740, 28791, 28310, 28312, 28330, 28116, 28794, 28240,
    28411, 28460, 28440, 28450, 28430,
  ],
  'menu_link_content:dc82cedd-a3a6-46c8-bcc6-6f4e50bc681e': [
    28864,
    '28800 - 28807',
    28500,
    28939,
    28660,
    28690,
    28400,
    28770,
    '28901 - 28909',
    28231,
    28232,
    28290,
    '28930 - 28939',
    28600,
    28320,
    ' 28521 - 28524',
    28830,
    '28701 - 28703',
    '28706 - 28709',
    28160,
    28990,
    28850,
    28851,
    28250,
    28760,
    28210,
    28891,
    '28670 - 28679',
    '28921 - 28925',
    28100,
    28108,
    28109,
    28976,
    28977,
    28270,
    28821,
    28822,
    28823,
    28978,
    28607,
    '28940 - 28947',
    28140,
    28260,
    28971,
    28970,
    '28910 - 28919',
    28220,
    28221,
    28222,
    28880,
    28840,
    28950,
    '28980 - 28984',
    28223,
    28224,
    28693,
    28979,
    28991,
    28813,
    28816,
    '28340 - 28343',
    28691,
    28280,
    28229,
    28001,
    28860,
    28023,
    28750,
    28723,
    28723,
    28170,
    28815,
    28130,
    28150,
    28110,
    28057,
    28814,
    28863,
    28810,
    28890,
    28710,
  ],
}
