import { Node } from '@/components/Node'
import {
  VIEW_MODE_CARD_BASIC,
  VIEW_MODE_CARD_BIG_BASIC,
} from '@/content/constants'

const Full = ({ paragraph_content }) => (
  <Node
    node={{
      ...paragraph_content.content,
      viewMode:
        paragraph_content.cols === 2
          ? VIEW_MODE_CARD_BIG_BASIC
          : paragraph_content.content_viewmode ?? VIEW_MODE_CARD_BASIC,
      popup: paragraph_content.popup,
    }}
  />
)

export { Full }
