import { cn } from 'utils-tailwindcss'

import { BreadcrumbsStyles } from './Breadcrumbs.styles'
import { BreadcrumbsProps } from './types'

const Breadcrumbs = ({ children, className, ...rest }: BreadcrumbsProps) => (
  <nav aria-label="breadcrumbs" {...rest}>
    <ol className={cn(BreadcrumbsStyles(), className)}>{children}</ol>
  </nav>
)

Breadcrumbs.displayName = 'Breadcrumbs'

export { Breadcrumbs }
