import { CardBasic } from '@/components/CardBasic'

import { DrupalNodeTuristicPointBasictProps } from './types'

const Basic = ({
  node,
  className,
  popup,
}: DrupalNodeTuristicPointBasictProps) => (
  <CardBasic
    title={node.title}
    image={node.computed_image}
    url={node.path?.alias}
    description={node.body}
    className={className}
    popup={popup}
  />
)

export { Basic }
