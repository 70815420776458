import {
  VIEW_MODE_ITINERARY,
  VIEW_MODE_ROUTES_DEFAULT,
  VIEW_MODE_ROUTES_WITHOUT_RESULTS,
  VIEW_MODE_SEARCHER,
  VIEW_MODE_WITHOUT_RESULTS,
} from './constants'
import { HeaderProps } from './types'
import {
  HeaderDefault,
  HeaderItinerary,
  HeaderRoutesDefault,
  HeaderRoutesWithoutResults,
  HeaderSearcher,
  HeaderWithoutResults,
} from './viewmode'

const Header = ({ viewMode = 'default', type, ...rest }: HeaderProps) => (
  <div className={`view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        case VIEW_MODE_WITHOUT_RESULTS:
          return <HeaderWithoutResults {...rest} />
        case VIEW_MODE_ROUTES_WITHOUT_RESULTS:
          return <HeaderRoutesWithoutResults {...rest} />
        case VIEW_MODE_SEARCHER:
          return <HeaderSearcher {...rest} />
        case VIEW_MODE_ROUTES_DEFAULT:
          return <HeaderRoutesDefault type={type} {...rest} />
        case VIEW_MODE_ITINERARY:
          return <HeaderItinerary {...rest} />
        default:
          return <HeaderDefault {...rest} />
      }
    })()}
  </div>
)
export { Header }
