import { Paragraph } from '@/components/Paragraph/Paragraph'
import { Title } from '@/components/Title'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'ui'
import { DrupalFieldTextFormat } from 'utils-drupal'

import { DrupalParagraphTabs } from '../../types'

const Default = ({ paragraph }: { paragraph: DrupalParagraphTabs }) => (
  <div className="my-18">
    <div className="container">
      <Title
        title={paragraph.title}
        subtitle={
          typeof paragraph.subtitle !== 'string'
            ? paragraph.subtitle
            : ({ value: paragraph.subtitle } as DrupalFieldTextFormat)
        }
      />
    </div>
    <Tabs defaultValue={paragraph.tabs[0].title}>
      <TabsList>
        <div className="flex gap-6 container flex-wrap">
          {paragraph.tabs.map((tab) => (
            <TabsTrigger key={tab.id} value={tab.title}>
              {tab.title}
            </TabsTrigger>
          ))}
        </div>
        <div className="pl-6 md:pl-24">
          {paragraph.tabs.map((tab) => (
            <TabsContent key={tab.id} value={tab.title}>
              <div className="-mt-8">
                <Paragraph paragraph={tab} />
              </div>
            </TabsContent>
          ))}
        </div>
      </TabsList>
    </Tabs>
  </div>
)

export { Default }
