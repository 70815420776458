import { NoResults } from '@/components/List'
import { Node } from '@/components/Node'
import { DrupalNodeRoute } from '@/components/Node/components/Route/types'
import { useMapListProvider } from '@/components/RoutesList/components/List/MapListProvider'
import { VIEW_MODE_BASIC_TEASER } from '@/content/constants'
import { cn } from 'utils-tailwindcss'

export const Items = ({ isMobile }: { isMobile?: boolean }) => {
  const {
    state: { currentRoute, pageMobile, perPageMobile },
    listState: { results, isLoading },
    setCurrentRoute,
  } = useMapListProvider()

  return (
    <div className="h-fit md:h-[752px] overflow-auto gap-1 flex flex-col bg-neutral-gray-200">
      {results
        ?.slice(0, isMobile ? pageMobile * perPageMobile : undefined)
        .map((route: DrupalNodeRoute) => (
          <div
            key={route.id}
            className={cn(
              'flex justify-between gap-4 p-4 hover:cursor-pointer no-underline bg-neutral-white',
              currentRoute?.id === route.id && 'bg-neutral-gray-300'
            )}
            onClick={() => setCurrentRoute(route)}
            accessKey="button"
            tabIndex={0}
          >
            <Node node={{ ...route, viewMode: VIEW_MODE_BASIC_TEASER }} />
          </div>
        ))}
      {!isLoading && !!!results?.length && <NoResults />}
    </div>
  )
}
