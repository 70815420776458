import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import { ACTION_SET_STATE } from '@/components/List/constants'
import { DrupalNodeItinerary } from '@/components/Node/components/Itinerary/types'
import { useRouter } from 'next-translate-routes'
import { useForm } from 'react-hook-form'

import {
  SET_ID,
  SET_SELECTED,
  TOOGLE_SHOW_ITINERARIES,
  TOOGLE_SHOW_ITINERARIES_DIALOG,
} from '../../constants'
import { getItineraries } from './services/api'
import { ContextItineraryProps, ItineraryState } from './types'

export const reducer = (
  state: ItineraryState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action
  switch (type) {
    case SET_SELECTED:
      return { ...state, ...payload }
    case SET_ID:
      return { ...state, id: payload }
    case TOOGLE_SHOW_ITINERARIES:
      return { ...state, showItineraries: payload }
    case TOOGLE_SHOW_ITINERARIES_DIALOG:
      return { ...state, showItinerariesDialog: payload }
    case ACTION_SET_STATE:
      return { ...state, ...payload }
    default:
      return state
  }
}

const ItineraryContext = createContext<ContextItineraryProps | undefined>(
  undefined
)
ItineraryContext.displayName = 'ItineraryContext'

const ItineraryProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, {
    form: useForm(),
    selected: [],
    showItineraries: false,
    showItinerariesDialog: false,
  })
  const route = useRouter()
  const initialRender = useRef(true)

  const fetchItineraries = useCallback(async () => {
    const itineraries = await getItineraries(state.id, route.locale ?? 'es')
    dispatch({ type: SET_SELECTED, payload: { selected: itineraries } })
  }, [state, route.locale])

  const isSelected = useCallback(
    (id: string): boolean =>
      !!state.selected.filter((itinerary) => itinerary.id === id)[0],
    [state.selected]
  )

  const toogleShowItineraries = useCallback(
    (open?: boolean) => {
      dispatch({
        type: TOOGLE_SHOW_ITINERARIES,
        payload: open ?? !state.showItineraries,
      })
    },
    [state.showItineraries]
  )

  const toogleShowItinerariesDialog = useCallback(() => {
    dispatch({
      type: TOOGLE_SHOW_ITINERARIES_DIALOG,
      payload: !state.showItinerariesDialog,
    })
  }, [state.showItinerariesDialog])

  const addItinerary = useCallback(
    (itinerary: DrupalNodeItinerary) => {
      if (!isSelected(itinerary.id)) {
        const selected = state.selected.slice()
        selected.push(itinerary)
        dispatch({
          type: SET_SELECTED,
          payload: { selected: selected, showItinerariesDialog: true },
        })
      }
    },
    [isSelected, state.selected]
  )
  const removeItinerary = useCallback(
    (id: string) => {
      if (isSelected(id)) {
        const selected = state.selected.filter(
          (itinerary: DrupalNodeItinerary) => itinerary.id !== id
        )

        dispatch({
          type: SET_SELECTED,
          payload: { selected: selected, showItinerariesDialog: false },
        })
      }
    },
    [isSelected, state.selected]
  )

  useEffect(() => {
    const itineraryId = localStorage.getItem('itineraryId')
    !!itineraryId && dispatch({ type: SET_ID, payload: itineraryId })
    localStorage.removeItem('itineraryId')
  }, [route])

  useEffect(() => {
    if (!initialRender.current && !!state.id) {
      fetchItineraries()
    } else {
      initialRender.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id])

  const value = useMemo(() => {
    return {
      state,
      addItinerary,
      removeItinerary,
      isSelected,
      toogleShowItineraries,
      toogleShowItinerariesDialog,
    }
  }, [
    state,
    addItinerary,
    removeItinerary,
    isSelected,
    toogleShowItineraries,
    toogleShowItinerariesDialog,
  ])

  return (
    <ItineraryContext.Provider value={value}>
      {children}
    </ItineraryContext.Provider>
  )
}

const useItineraryProvider = () => {
  const ctxValue = useContext(ItineraryContext)
  if (!ctxValue)
    throw new Error(
      `Please wrap in an ${ItineraryContext.displayName} component`
    )
  const {
    state,
    addItinerary,
    removeItinerary,
    isSelected,
    toogleShowItineraries,
    toogleShowItinerariesDialog,
  } = ctxValue
  return {
    state,
    addItinerary,
    removeItinerary,
    isSelected,
    toogleShowItineraries,
    toogleShowItinerariesDialog,
  }
}

export { ItineraryProvider, useItineraryProvider }
