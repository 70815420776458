import { useEffect, useState } from 'react'
import { HookOutput } from 'utils'

type UseNavigationResult = HookOutput<
  { activeItem: string },
  {
    onItemClick: (id: string) => void
  }
>

export const useNavigation = (
  value: string,
  onValueChange: any
): UseNavigationResult => {
  const [activeItem, setActiveItem] = useState<string>('')

  const onItemClick = (id: string) => {
    setActiveItem(id)
    if (onValueChange) {
      onValueChange(id)
    }
  }

  useEffect(() => {
    setActiveItem(value)
  }, [value])

  return {
    state: { activeItem },
    actions: { onItemClick },
  }
}
