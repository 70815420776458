'use client'

import { useEffect, useMemo, useRef, useState } from 'react'
import { cn } from 'utils-tailwindcss'

import { Picture } from './components/Picture'
import { ImageStyleProps } from './types'

// https://usehooks-ts.com/react-hook/use-image-on-load

const ImageStyle = ({
  breakpoints,
  file,
  loading = 'lazy',
  ...rest
}: ImageStyleProps) => {
  // https://stackoverflow.com/questions/59787642/nextjs-images-loaded-from-cache-dont-trigger-the-onload-event
  const imageRef = useRef<HTMLImageElement>(null)
  const [isLoaded, setLoaded] = useState(false)

  const derivatives =
    file.resourceIdObjMeta?.imageDerivatives?.links ?? file.links

  const HREF = derivatives && derivatives[breakpoints.default.id]?.href
  const HREF_WITHOUT_SPACES = HREF?.replace(/[\s\uFEFF\xA0]+/g, '%20')
  const size: number[] =
    breakpoints.default.id
      ?.split('_')
      ?.at(-1)
      ?.split('x')
      ?.map((number) => Number(number)) ?? []

  const ASPECT_RATIO = derivatives && size?.length && (size[1] / size[0]) * 100

  const sources = useMemo(() => {
    if (!derivatives) {
      return null
    }
    const sources: any = []

    for (const [breakpoint, value] of Object.entries(breakpoints)) {
      if (value.id in derivatives) {
        const imageStyle = derivatives[value.id]

        if (breakpoint !== 'default') {
          sources.push({
            srcSet: imageStyle?.href,
            media: value.media,
            type: imageStyle.type,
          })
        }
      }
    }

    return sources
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakpoints, file])

  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      setLoaded(true)
    }
  }, [isLoaded])

  if (!derivatives) {
    return null
  }

  return (
    <div
      className={cn(
        'bg-cover bg-no-repeat before:absolute before:inset-0 before:opacity-0 before:bg-neutral-gray-200 before:content-[""] before:animate-pulse relative h-0 rounded-t-lg md:rounded-b-none rounded-b-lg',
        !isLoaded ? 'blur-[0px]' : 'before:content-none'
      )}
      style={{
        paddingTop: `${ASPECT_RATIO}%`,
        backgroundImage:
          !isLoaded && derivatives['thumbnail']
            ? `url(${derivatives['thumbnail']?.href})`
            : '',
      }}
    >
      <div className="absolute top-0 left-0 w-full h-full">
        <Picture sources={sources}>
          {/* eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text */}
          <img
            ref={imageRef}
            src={HREF_WITHOUT_SPACES}
            srcSet={HREF_WITHOUT_SPACES}
            alt={file.resourceIdObjMeta?.alt}
            loading={loading}
            onLoad={() => {
              setLoaded(true)
            }}
            style={{
              width: '100%',
              height: '100%',
            }}
            className={`${
              !isLoaded ? 'opacity-0' : 'opacity-1'
            } transition-opacity`}
            {...rest}
          />
        </Picture>
      </div>
    </div>
  )
}

export { ImageStyle }
