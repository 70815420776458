import { ComponentPropsWithRef, ElementRef, forwardRef } from 'react'
import { cn } from 'utils-tailwindcss'
import { Drawer } from 'vaul'

import { ContentStyles } from './Content.styles'

const Content = forwardRef<
  ElementRef<typeof Drawer.Content>,
  ComponentPropsWithRef<typeof Drawer.Content>
>(({ className, ...rest }, ref) => (
  <Drawer.Content
    className={cn(ContentStyles.root(), className)}
    {...rest}
    ref={ref}
  />
))

Content.displayName = 'Content'
export { Content }
