import Link from 'next/link'
import { MOCK_FILE_DERIVATIVE } from '@/components/CardRoute/mock'
import { ContentPopup } from '@/components/ContentPopup'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Phone } from 'lucide-react'
import { DrupalMedia } from 'next-drupal'
import {
  CardImage,
  CardSubtitle,
  CardTitle,
  Heading,
  ImageViewer,
  ImageViewerContent,
  ImageViewerTrigger,
} from 'ui'

import { CKEditor } from '../../CKEditor'
import { Media } from '../../Media'
import { BREAKPOINTS, BREAKPOINTS_SMALL } from '../constants'
import { CardTeaserContentProps } from '../types'

const Content = ({
  title,
  image,
  description,
  size,
  popup,
  phone,
}: CardTeaserContentProps) => {
  if (!popup) {
    return (
      <>
        {image && (
          <CardImage>
            <Media
              media={image || MOCK_FILE_DERIVATIVE}
              //@ts-ignore
              breakpoints={BREAKPOINTS[size] || BREAKPOINTS_SMALL}
            />
          </CardImage>
        )}

        <div className="h-full px-6 pb-6 no-underline border-b rounded-b border-x border-neutral-gray-400">
          <CardTitle>
            <span className="pb-2 font-bold">{title}</span>
          </CardTitle>
          {description && (
            <CardSubtitle className="pb-2">
              <CKEditor text={description} cleanText className="line-clamp-3" />
            </CardSubtitle>
          )}
          {phone && (
            <Link
              href={`tel:${phone}`}
              className="no-underline hover:underline flex gap-2 items-center"
            >
              <Phone height="1rem" width="1rem" />
              {phone}
            </Link>
          )}
        </div>
      </>
    )
  }

  return (
    <CardImage className="h-full">
      <ImageViewer>
        <ImageViewerTrigger
          className="h-full w-full text-left hover:underline focus:border-2 focus:border-secondary-black-900 rounded-md focus:p-[2px] border-b rounded-b border-x border-neutral-gray-400"
          aria-label={FormattedMessage({
            id: 'paragraph.gallery.enlarge',
          })}
        >
          <Media
            media={(image || MOCK_FILE_DERIVATIVE) as DrupalMedia}
            //@ts-ignore
            breakpoints={BREAKPOINTS[size] || BREAKPOINTS_SMALL}
          />
          <div className="h-full px-6 pb-6 no-underline">
            <CardTitle>
              <span className="pb-2 font-bold">{title}</span>
            </CardTitle>
            {description && (
              <CardSubtitle>
                <CKEditor
                  text={description}
                  cleanText
                  className="line-clamp-3"
                />
              </CardSubtitle>
            )}
            {phone && (
              <Link
                href={`tel:${phone}`}
                className="no-underline hover:underline flex gap-2 items-center"
              >
                <Phone height="1rem" width="1rem" />
                {phone}
              </Link>
            )}
          </div>
        </ImageViewerTrigger>
        <ImageViewerContent>
          <ContentPopup
            title={
              <Heading as="h3" className="font-bold">
                {title}
              </Heading>
            }
            body={
              <div className="flex flex-col gap-2">
                {!!description && <CKEditor text={description} />}
                {!!phone && (
                  <Link
                    href={`tel:${phone}`}
                    className="no-underline hover:underline flex gap-2 items-center"
                  >
                    <Phone height="1rem" width="1rem" />
                    {phone}
                  </Link>
                )}
              </div>
            }
            image={
              <Media
                media={(image || MOCK_FILE_DERIVATIVE) as DrupalMedia}
                //@ts-ignore
                breakpoints={BREAKPOINTS[size] || BREAKPOINTS_SMALL}
              />
            }
          />
        </ImageViewerContent>
      </ImageViewer>
    </CardImage>
  )
}

export { Content }
