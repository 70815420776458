import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'
import { HookOutput } from 'utils'

type UseMegamenuResult = HookOutput<
  { value: string },
  {
    setValue: Dispatch<SetStateAction<string>>
  }
>

export const useMegamenu = (): UseMegamenuResult => {
  const [value, setValue] = useState('')

  const path = usePathname()

  useEffect(() => {
    setValue('')
    return () => {}
  }, [path])

  return {
    state: { value },
    actions: { setValue },
  }
}
