import { Text } from 'ui'

import { FormattedMessage } from '../FormattedMessage'
import { Media } from '../Media'
import { DrupalMediaDocument } from '../Media/components/Document/types'

const AttachedDocument = ({
  documents,
}: {
  documents: DrupalMediaDocument
}) => (
  <div>
    <Text className="text-base font-bold text-neutral-gray-500 block mb-2">
      <FormattedMessage id="paragraph.customText.attachedFiles" />:
    </Text>
    <div className="flex flex-wrap gap-3">
      {documents.map((document) => (
        <Media key={document.id} media={{ ...document }} />
      ))}
    </div>
  </div>
)

export { AttachedDocument }
