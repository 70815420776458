import { Media } from '@/components/Media'
import { Paragraph } from '@/components/Paragraph/Paragraph'
import { Title } from '@/components/Title'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Heading,
} from 'ui'

import { DrupalParagraphAccordion } from '../../type'
import { BREAKPOINTS } from './constants'

const Default = ({
  paragraph,
  collapsable = false,
}: DrupalParagraphAccordion) => (
  <div className="my-18">
    <div className="container">
      <Title title={paragraph.title} />
      <Accordion
        type="multiple"
        value={collapsable ? undefined : [paragraph.id]}
      >
        {paragraph.paragraphs.map((paragraphItem) => (
          <AccordionItem
            key={paragraphItem.id}
            value={collapsable ? paragraphItem.id : paragraph.id}
            className="bg-neutral-gray-100 rounded-2xl px-6 mb-6"
          >
            <AccordionTrigger icon={collapsable ? undefined : 'none'}>
              <div className="flex gap-4 text-primary-red-900 flex-wrap">
                {paragraphItem.images.map((image) => (
                  <div key={image.id} className="flex items-center gap-6">
                    <div className="w-10">
                      <Media
                        media={image}
                        //@ts-ignore
                        breakpoints={BREAKPOINTS}
                      />
                    </div>
                    <Heading as="h3">
                      {image.image.resourceIdObjMeta.title}
                    </Heading>
                  </div>
                ))}
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <Paragraph paragraph={paragraphItem} />
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  </div>
)

export { Default }
