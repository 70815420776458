import { CardTeaser } from '@/components/CardTeaser'

import { DrupalNodeRouteTeaserProps } from './types'

const Teaser = ({ node, size }: DrupalNodeRouteTeaserProps) => (
  <CardTeaser
    title={node.title}
    image={
      node.computed_image ??
      node.images?.find((img) => img.image?.links !== undefined)
    }
    url={node.path?.alias}
    description={node.body}
    size={size}
  />
)

export { Teaser }
