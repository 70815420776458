import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { Label } from 'ui'

import { DrupalTaxonomyTermRouteActivityProps } from './types'

const RouteActivity = ({
  viewMode = VIEW_MODE_DEFAULT,
  term,
}: DrupalTaxonomyTermRouteActivityProps) => {
  switch (viewMode) {
    default:
      return <Label>{term.name}</Label>
  }
}

export { RouteActivity }
