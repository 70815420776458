import { Grid } from '@/components/Grid'
import { GENERAL_INDEX } from '@/components/List/constants'
import { Node } from '@/components/Node/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { SearcherList } from '@/components/SearcherList'
import { VIEW_MODE_CARD_BASIC } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'

import { DrupalNodeNextSearcherPageProps } from './types'

const SearcherPage = ({ node }: DrupalNodeNextSearcherPageProps) => {
  const conditionalTranslationKeys = {
    ofInterest: 'node.ofInterest',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <div className="container">
        <SearcherList
          type={GENERAL_INDEX}
          initialState={{
            ...node.response,
            page: 1,
            per_page: 12,
            showResults: true,
          }}
        />
      </div>
      {!!node.ofInterest?.length && (
        <div className="py-16 bg-neutral-gray-100">
          <Grid title={translations.ofInterest} redLine={true} cols={4}>
            {node.ofInterest.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_BASIC }}
                key={content.id}
              />
            ))}
          </Grid>
        </div>
      )}
    </>
  )
}

export { SearcherPage }
