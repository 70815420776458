import { forwardRef, HTMLAttributes } from 'react'
import { cn } from 'utils-tailwindcss'

import { Text } from '../../../../atoms'
import { useFormField } from '../../useFormField'
import { MessageStyles } from './Message.styles'

const Message = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField()
  const body = error ? String(error?.message) : children

  if (!body) {
    return null
  }

  return (
    <Text
      ref={ref}
      id={formMessageId}
      className={cn(MessageStyles(), className)}
      {...props}
    >
      {body}
    </Text>
  )
})

Message.displayName = 'Message'

export { Message }
