import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { Tag } from 'ui'

import { FilterTagsProps } from './types'

export const FilterTags = ({ tags }: FilterTagsProps) => {
  if (typeof tags === 'string') {
    return <Tag className="whitespace-nowrap">{tags}</Tag>
  }
  if (tags?.length) {
    return (
      <div className="flex flex-row space-x-3">
        <Tag className="truncate max-w-[10rem] flow-root">{tags[0]}</Tag>
        {tags.length > 1 && (
          <Tag className="whitespace-nowrap">
            <FormattedRichMessage
              id={
                tags.length > 2
                  ? 'searcher.labelFilters'
                  : 'searcher.labelFilter'
              }
              values={{ number: tags.length - 1 }}
            />
          </Tag>
        )}
      </div>
    )
  }
}
