import Link from 'next/link'
import { Card as CardUI } from 'ui'

import { Content } from './components'
import { CardRouteProps } from './types'

const CardRoute = ({
  title,
  url,
  image,
  duration,
  decimal_kmeters,
  term_category,
  location,
  type,
  popup,
  description,
}: CardRouteProps) => (
  <CardUI className="flex flex-col h-full">
    {!url || type === 'node--establishment' || popup ? (
      <Content
        title={title}
        image={image}
        duration={duration}
        decimal_kmeters={decimal_kmeters}
        term_category={term_category}
        location={location}
        popup={popup}
        description={description}
      />
    ) : (
      <Link
        href={url}
        className="flex flex-col h-full no-underline hover:underline focus:border-2 focus:border-secondary-black-900 rounded-md focus:p-[2px]"
      >
        <Content
          title={title}
          image={image}
          duration={duration}
          decimal_kmeters={decimal_kmeters}
          term_category={term_category}
          location={location}
        />
      </Link>
    )}
  </CardUI>
)

export { CardRoute }
