import { CardActivity } from '@/components/CardActivity'

import { DrupalNodeActivityProps } from './types'

const Activity = ({ node, size, className }: DrupalNodeActivityProps) => {
  const date = new Date(Date.parse(node.current_occurrence))

  return (
    <CardActivity
      title={node.title}
      image={node.computed_image}
      url={node.path?.alias}
      term_municipality={node.term_municipality}
      date={date}
      size={size}
      className={className}
    />
  )
}

export { Activity }
