export const ITINERARY_FIELDS = [
  'path',
  'title',
  'status',
  'body',
  'geofield_address',
  'link',
  'term_municipality',
  'term_services',
  'text_address',
  'term_services',
  'term_category',
  'term_type',
  'moderation_state',
  'computed_image',
  'gmaps_url',
]

export const ITINERARY_CARD_FIELDS = [
  'status',
  'type',
  'title',
  'path',
  'body',
  'geofield_address',
  'term_municipality',
  'term_category',
  'term_type',
  'term_services',
  'text_address',
  'link',
  'computed_image',
  'gmaps_url',
]

// TODO: CUANDo Daniel me confirme qué taxonomía son los tags que se pintan, se podrá prescindir de includes

export const ITINERARY_INCLUDES = [
  'term_municipality',
  'term_category',
  'term_services',
  'term_type',
  'computed_image',
]
