import { CardVerticalBasic } from '@/components/CardVerticalBasic'

import { DrupalNodePlaceVerticalBasictProps } from './types'

const VerticalBasic = ({ node }: DrupalNodePlaceVerticalBasictProps) => (
  <CardVerticalBasic
    title={node.title}
    image={
      node.computed_image ??
      node.images?.find((img) => img.image?.links !== undefined)
    }
    url={node.path?.alias}
  />
)

export { VerticalBasic }
