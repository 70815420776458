import { FormattedMessage } from '@/components/FormattedMessage'
import { Media } from '@/components/Media'
import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { CardImage, Text } from 'ui'

import { CARD_BIG } from '../../constants'
import { DrupalParagraphGalleryDefaultProps } from '../../Default/types'
import { BREAKPOINTS } from '../constants'

const Layout = ({ paragraph, type }: DrupalParagraphGalleryDefaultProps) => {
  switch (type) {
    case CARD_BIG:
      return (
        <CardImage>
          <Media
            media={{
              ...(paragraph.computed_image || paragraph.image),
              viewMode: VIEW_MODE_DEFAULT,
            }}
            // @ts-ignore
            breakpoints={BREAKPOINTS}
          />

          <div className="px-4 py-3 text-base text-left rounded-b bg-neutral-gray-100">
            <Text>
              <FormattedMessage id="paragraph.gallery.photo" />:{' '}
              {paragraph.title}
            </Text>
          </div>
        </CardImage>
      )

    default:
      return (
        <CardImage>
          <Media
            media={{
              ...(paragraph.computed_image || paragraph.image),
              viewMode: VIEW_MODE_DEFAULT,
            }}
            // @ts-ignore
            breakpoints={BREAKPOINTS}
          />
          <div className="px-4 py-3 text-base text-left rounded-b bg-neutral-gray-100">
            <Text>
              <FormattedMessage id="paragraph.gallery.photo" />:{' '}
              {paragraph.title}
            </Text>
          </div>
        </CardImage>
      )
  }
}

export { Layout }
