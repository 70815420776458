import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import { useRouter } from 'next-translate-routes'

import { NODE_ROUTE_BASE } from '../Node/constants'
import { BundleDrupalNode } from '../Node/types'
import { LIST_TYPE_ITINERARY } from '../Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'
import {
  ACTION_SET_ACTIVE_FILTERS,
  ACTION_SET_IS_LOADING,
  ACTION_SET_PAGE,
  ACTION_SET_RESULTS,
  ACTION_SET_STATE,
  ACTION_SET_TOTAL_ITEMS,
  CICLA_MADRID_INDEX,
  GRAN_TOUR_INDEX,
} from './constants'
import { fetchIndexDrupal } from './services/api'
import { ContextProps, ContextProviderProps, Filter, State } from './types'
import { getFiltersFromQueryParams, getQueryParams } from './utils/helpers'

export const reducer = (
  state: State,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_SET_ACTIVE_FILTERS:
      return { ...state, ...payload }
    case ACTION_SET_PAGE:
      return { ...state, page: payload }
    case ACTION_SET_RESULTS:
      return { ...state, ...payload, isLoading: false }
    case ACTION_SET_TOTAL_ITEMS:
      return { ...state, total_items: payload }
    case ACTION_SET_IS_LOADING:
      return { ...state, isLoading: payload }
    case ACTION_SET_STATE:
      return { ...state, ...payload }
    default:
      return state
  }
}

const ListContext = createContext<ContextProps | undefined>(undefined)
ListContext.displayName = 'List'

const ListProvider = ({
  children,
  initialState,
  type,
}: ContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const router = useRouter()
  const initialRender = useRef(true)

  const addToResults = useCallback(
    (newResults?: BundleDrupalNode[]) => {
      const totalResults: any[] = state.results?.slice()
      newResults?.map((result) => totalResults.push(result))
      return totalResults
    },
    [state.results]
  )

  const updateState = useCallback(
    async (propState?: State) => {
      dispatch({
        type: ACTION_SET_ACTIVE_FILTERS,
        payload: {
          ...state,
          ...propState,
          isLoading: propState?.isLoading ?? true,
        },
      })

      // Llamada a la API
      const response = await fetchIndexDrupal(
        { ...state, ...propState },
        type,
        false,
        router.locale
      )

      // Si es la primera pagina, actualiza los resultados completamente
      if ({ ...state, ...propState }.page === 1) {
        dispatch({
          type: ACTION_SET_RESULTS,
          payload: response,
        })
      } else {
        // Si no es la primera pagina, agrega los nuevos resultados a los existentes
        const allResults = addToResults(response.results)
        dispatch({
          type: ACTION_SET_RESULTS,
          payload: {
            ...state,
            ...{ ...propState, ...{ ...response, results: allResults } },
          },
        })
      }
    },
    [state, addToResults, type, router.locale]
  )

  const addFilters = useCallback(
    (filter: Filter | Filter[], type?: string | string[]) => {
      if (!Array.isArray(filter)) {
        let query = ''
        const updatedActiveFilter: Filter[] = state.activeFilters?.slice() ?? []
        if (filter.type === 'query') {
          query = filter.values.value
          updateState({
            query: filter.values.value,
            page: 1,
            results: [],
          })
        } else {
          query = state.query
          const activeFilterIndex = state.activeFilters?.findIndex(
            (activeFilter: Filter) => activeFilter.type === filter.type
          )

          if (activeFilterIndex !== undefined && activeFilterIndex !== -1) {
            updatedActiveFilter[activeFilterIndex] = filter
          } else {
            updatedActiveFilter.push(filter)
          }

          updateState({
            activeFilters: updatedActiveFilter,
            page: 1,
            results: [],
          })
        }
        updateUrl(updatedActiveFilter, query)
      } else {
        const updatedActiveFilter: any = []

        if (!Array.isArray(type)) {
          const activeFilters =
            state.activeFilters?.filter(
              (activeFilter) => activeFilter.type !== type
            ) ?? []

          updatedActiveFilter.push(...activeFilters)
        }

        updatedActiveFilter.push(...filter)

        updateState({
          activeFilters: updatedActiveFilter,
          page: 1,
          results: [],
        })
        updateUrl(updatedActiveFilter, state.query)
      }
    },
    [updateState, state.activeFilters, state.query]
  )

  const removeFilterFromList = useCallback(
    (filters: Filter[]) => {
      updateState({ activeFilters: filters, page: 1, results: [] })
      updateUrl(filters, state.query)
    },
    [updateState, state.query]
  )

  const removeFilter = useCallback(
    (id: string | string[], isType = false) => {
      const condition = isType ? 'type' : 'values.value'
      const updatedActiveFilter: Filter[] = []
      if (!Array.isArray(id)) {
        let query = state.query
        const activeFilterIndex = state.activeFilters?.findIndex(
          (activeFilter: Filter) => {
            return activeFilter[condition] === id
          }
        )
        if (id === 'query') {
          query = null
          updatedActiveFilter.push(...(state.activeFilters ?? []))
        } else {
          if (activeFilterIndex !== undefined && activeFilterIndex !== -1) {
            updatedActiveFilter.push(
              ...state.activeFilters.filter(
                (filter) => filter[condition] !== id
              )
            )
          }
        }

        updateState({
          activeFilters: updatedActiveFilter,
          page: 1,
          results: [],
          query: query,
        })
        updateUrl(updatedActiveFilter, query)
      } else {
        const activeFilters =
          state.activeFilters?.filter(
            (activeFilter) =>
              id.filter(
                (filterType) => activeFilter[condition] !== filterType
              )[0] !== undefined
          ) ?? []

        updatedActiveFilter.push(...activeFilters)
        updateState({
          activeFilters: updatedActiveFilter,
          page: 1,
          results: [],
        })
        updateUrl(updatedActiveFilter, state.query)
      }
    },
    [updateState, state.activeFilters, state.query]
  )

  const updateUrl = (filters: Filter[], query?: string) => {
    const newUrl = getQueryParams(filters, query)
    if (!!newUrl || newUrl.length > 0) {
      window.history.pushState({ path: newUrl }, '', newUrl)
    } else {
      window.history.pushState({}, document.title, window.location.pathname)
    }
  }

  const goToActivitiesPageWithFilters = useCallback(() => {
    state.urlResults &&
      router.push(
        `/${state.urlResults}${getQueryParams(
          state.activeFilters,
          state.query
        )}`
      )
  }, [router, state.query, state.activeFilters, state.urlResults])

  const toggleTabs = useCallback(
    (tab) => {
      updateState({
        ...state,
        tabs: tab,
        results: [],
        isLoading: true,
        page: 1,
      })
    },
    [updateState, state]
  )

  useEffect(() => {
    if (router.isReady) {
      const filters = getFiltersFromQueryParams(
        router.query,
        router.locale ?? 'es'
      )

      if (filters.length > 0 && initialRender.current) {
        const query: Filter | undefined = filters
          .filter((filter: Filter) => filter.type === 'query')
          ?.at(0)
        updateState({
          activeFilters: [
            ...filters.filter((filter: Filter) => filter.type !== 'query'),
          ],
          page: 1,
          query: query?.values.value,
        })
      } else if (initialState.activeFilters?.length && initialRender.current) {
        updateState(initialState)
        updateUrl(initialState.activeFilters)
      } else {
        if (
          initialRender.current &&
          (type === LIST_TYPE_ITINERARY ||
            type === NODE_ROUTE_BASE ||
            type === CICLA_MADRID_INDEX ||
            type === GRAN_TOUR_INDEX)
        ) {
          updateState({
            activeFilters: [],
            page: 1,
            isLoading: false,
          })
        } else {
          initialRender.current = false
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  const value = useMemo(() => {
    return {
      state,
      type,
      updateState,
      addFilters,
      removeFilter,
      removeFilterFromList,
      goToActivitiesPageWithFilters,
      toggleTabs,
    }
  }, [
    state,
    type,
    updateState,
    addFilters,
    removeFilter,
    removeFilterFromList,
    goToActivitiesPageWithFilters,
    toggleTabs,
  ])

  return <ListContext.Provider value={value}>{children}</ListContext.Provider>
}

const useListProvider = () => {
  const ctxValue = useContext(ListContext)
  if (!ctxValue)
    throw new Error(`Please wrap in an ${ListContext.displayName} component`)
  const {
    state,
    type,
    updateState,
    addFilters,
    removeFilter,
    removeFilterFromList,
    goToActivitiesPageWithFilters,
    toggleTabs,
  } = ctxValue
  return {
    state,
    type,
    updateState,
    addFilters,
    removeFilter,
    removeFilterFromList,
    goToActivitiesPageWithFilters,
    toggleTabs,
  }
}

export { ListProvider, useListProvider }
