import { useState } from 'react'
import { cn } from 'utils-tailwindcss'

import { useMapProvider } from '../../MapProvider'
import { LocationCard } from '../LocationCard'
import { Map } from '../Map'
import { Navigation } from './components'

export const Desktop = () => {
  const {
    actions: { setCurrentTouristicPoint },
    state: { currentTouristicPoint, touristicPoints },
  } = useMapProvider()

  const [isNavigationOpen, setIsNavigationOpen] = useState<boolean>()

  return (
    <div className="flex flex-col md:flex-row">
      <Navigation
        currentTuristicPoints={currentTouristicPoint}
        onItemClick={setCurrentTouristicPoint}
        touristicPoints={touristicPoints}
        onNavigationChange={setIsNavigationOpen}
      />
      <div className="relative w-full h-full">
        <Map isNavigationOpen={isNavigationOpen} />
        {currentTouristicPoint && (
          <div
            className={cn(
              'absolute bottom-2 transition-all ease-in-out delay-150 z-10',
              {
                'left-[35%]': isNavigationOpen,
                'left-2': !isNavigationOpen,
              }
            )}
          >
            <LocationCard
              touristicPoint={currentTouristicPoint}
              onCloseClick={() => {
                setCurrentTouristicPoint(null)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
