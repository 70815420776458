import { cva } from 'class-variance-authority'

export const ItemStyles = {
  root: cva(
    [
      'text-lg text-secondary-black-900 flex items-center justify-between py-4 cursor-pointer font-normal',
      'hover:text-primary-red-900',
      'first-of-type:pt-0 md:border-b md:border-neutral-gray-300',
    ],
    {
      variants: {
        active: {
          true: 'text-primary-red-900 font-bold',
        },
      },
    }
  ),
}
