import { Language } from '@/components/PublicLayout/usePublicLayout'

import { MegamenuType } from '../../../Megamenu/types'
import { HeaderBase } from '../HeaderBase'

export const HomeHeader = ({
  megamenu,
  languages,
}: {
  megamenu: MegamenuType
  languages: Language[]
}) => (
  <div className="absolute top-0 left-0 w-full ">
    <div className="bg-gradient-to-b from-secondary-black-900">
      <HeaderBase megamenu={megamenu} languages={languages} />
    </div>
  </div>
)
