import { useState } from 'react'
import { FormattedMessage } from '@/components/FormattedMessage'
import { MobileModal } from '@/components/MobileModal'
import { RadioGroupField } from '@/components/RoutesList/components/RadioGroupField'
import { ArrowUpDown } from 'lucide-react'
import { Form } from 'ui'

import { useSortFilters } from '../../../Desktop/components/SortFilter/useSortFilter'

export const SortFilter = () => {
  const {
    state: { showSort: internalShow, sortForm, activeFilterCount, sortFilter },
    actions: { deleteSortFilter, onSubmit },
  } = useSortFilters()

  const [showSort, setShowSort] = useState(internalShow)

  return (
    <MobileModal
      title={FormattedMessage({ id: 'global.sort' })}
      triggerIcon={ArrowUpDown}
      activeFilterCount={activeFilterCount}
      showModal={showSort}
      onCancel={deleteSortFilter}
      onCancelText={FormattedMessage({ id: 'global.delete' })}
      onOpenChange={(value) => setShowSort(value ?? false)}
      onSubmit={sortForm.handleSubmit(onSubmit)}
    >
      <Form {...sortForm}>
        <form className="p-6">
          <RadioGroupField {...sortFilter} />
        </form>
      </Form>
    </MobileModal>
  )
}
