import { DrupalJsonApiParams } from 'drupal-jsonapi-params'
import { DateTimeFormatOptions } from 'next-intl'
import { CalendarDateRange } from 'ui'

const OPTIONS = {
  weekday: 'short' as 'short',
  month: 'short' as 'short',
  day: 'numeric' as 'numeric',
}

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24

export const formattedDate = (
  date: Date,
  locale,
  options?: DateTimeFormatOptions
): string => {
  const dateString = date?.toLocaleDateString(locale, options ?? OPTIONS)
  return dateString.charAt(0).toUpperCase() + dateString.slice(1)
}

export const getDateParams = (
  params: DrupalJsonApiParams,
  date?: CalendarDateRange
): DrupalJsonApiParams => {
  const startDate = new Date(date?.from ?? '')
  const endDate = new Date(date?.to ?? '')

  const recur =
    Math.round(
      ((!isNaN(endDate.getDate()) ? endDate : startDate).getTime() -
        startDate.getTime()) /
        DAY_IN_MILLISECONDS
    ) + 1

  const dateRecur: string[] = []
  for (let index = 0; index < recur; index++) {
    const date = new Date(startDate.getTime() + DAY_IN_MILLISECONDS * index)
    dateRecur.push(
      `${date.toLocaleDateString('es', {
        year: 'numeric',
      })}-${date.toLocaleDateString('es', {
        month: '2-digit',
      })}-${date.toLocaleDateString('es', {
        day: '2-digit',
      })}T00:00:00Z`
    )
  }

  startDate.getDate() == endDate.getDate()
    ? params.addFilter('occurrences', dateRecur, 'IN')
    : dateRecur.length === 1
    ? params.addFilter('occurrences', dateRecur[0], '>=')
    : params.addFilter('occurrences', dateRecur, 'IN')
  return params
}
