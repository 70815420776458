import { CardTeaser } from '@/components/CardTeaser'

const Teaser = ({
  paragraph_content,
  size,
}: {
  paragraph_content
  size: 'small' | 'long' | 'horizontal' | 'carousel_3col'
}) => (
  <CardTeaser
    title={paragraph_content.title}
    image={paragraph_content.image}
    url={paragraph_content.link?.uri}
    description={paragraph_content.body}
    size={size}
    popup={!!paragraph_content.popup}
  />
)

export { Teaser }
