import { Dispatch, SetStateAction, useState } from 'react'
import { HookOutput } from 'utils'

import { contentTouristPointType } from '../../../../types'
import { Route } from './types'

type RoutesMapResult = HookOutput<
  {
    routes: Route[]
    touristicPoints: contentTouristPointType[]
    currentTouristicPoint: contentTouristPointType | null
  },
  {
    setCurrentTouristicPoint: Dispatch<
      SetStateAction<contentTouristPointType | null>
    >
  }
>

type MapProps = {
  routes: Route[]
  touristicPoints: contentTouristPointType[]
}

export const useMap = ({
  routes,
  touristicPoints,
}: MapProps): RoutesMapResult => {
  const [currentTouristicPoint, setCurrentTouristicPoint] =
    useState<contentTouristPointType | null>(touristicPoints[0])

  return {
    state: {
      routes,
      touristicPoints,
      currentTouristicPoint,
    },
    actions: {
      setCurrentTouristicPoint,
    },
  }
}
