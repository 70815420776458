import { useEffect, useMemo, useState } from 'react'
import { HookOutput } from 'utils'

import { useMapListProvider } from '../../../../MapListProvider'

type usePaginatorResult = HookOutput<
  { isLoading: boolean | undefined; totalCount: number; actualShowed: number },
  {
    onClickMoreResults: () => void
  }
>

export const usePaginator = (): usePaginatorResult => {
  const {
    state: { pageMobile, perPageMobile },
    listState: { total_items },
    setPageMobile,
  } = useMapListProvider()
  const [isLoading, setIsLoading] = useState(false)

  const totalCount = useMemo(() => total_items ?? 0, [total_items])
  const actualShowed = useMemo(
    () =>
      (pageMobile ?? 1) * (perPageMobile ?? 12) < totalCount
        ? (pageMobile ?? 1) * (perPageMobile ?? 12)
        : totalCount,
    [pageMobile, perPageMobile, totalCount]
  )

  const onClickMoreResults = () => {
    setIsLoading(true)
    setPageMobile(pageMobile + 1)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [pageMobile])

  return {
    state: { isLoading, totalCount, actualShowed },
    actions: { onClickMoreResults },
  }
}
