'use client'

import { ElementRef, forwardRef } from 'react'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { cn } from 'utils-tailwindcss'

import { ProgressStyles } from './Progress.styles'
import { ProgressProps } from './types'

const Progress = forwardRef<
  ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(({ className, value, max = 100, ...props }, ref) => {
  const valueTranslate = ((value ?? 0) * 100) / max

  return (
    <ProgressPrimitive.Root
      aria-label="Progress bar"
      ref={ref}
      className={cn(ProgressStyles.root(), className)}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={ProgressStyles.indicator()}
        style={{ transform: `translateX(-${100 - (valueTranslate || 0)}%)` }}
      />
    </ProgressPrimitive.Root>
  )
})

Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
