import * as TabsPrimitive from '@radix-ui/react-tabs'

import { TabsProps } from './types'

const Tabs = ({ children, defaultValue, ...rest }: TabsProps) => (
  <TabsPrimitive.Root defaultValue={defaultValue} {...rest} aria-busy="false">
    {children}
  </TabsPrimitive.Root>
)

Tabs.displayName = 'Tabs'

export { Tabs }
