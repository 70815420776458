import { CKEditor } from '@/components/CKEditor'

import { DrupalParagraphAccordionItem } from '../../type'

const Default = ({
  paragraph,
}: {
  paragraph: DrupalParagraphAccordionItem
}) => <CKEditor text={paragraph.body} />

export { Default }
