import { formattedDateToStringLabel } from '@/components/List/utils/helpers'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Text } from 'ui'

import { FilterTags } from '../../../FilterTags'
import { HeaderProps } from './types'

const Header = ({ showResults, locale, date }: HeaderProps) => {
  const conditionalTranslationKeys = {
    date: 'global.date',
    selectDate: 'filters.selectDate',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return showResults ? (
    translations.date
  ) : !!date ? (
    <FilterTags tags={formattedDateToStringLabel(locale ?? 'es', date)} />
  ) : (
    <Text className="py-1">{translations.selectDate}</Text>
  )
}

export { Header }
