'use client'

import { useMemo } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import {
  BreadcrumbsItem as BreadcrumbsItemUi,
  Breadcrumbs as BreadcrumbsUi,
} from 'ui'

import { FormattedMessage } from '../FormattedMessage'
import { BreadcrumbsProps } from './types'
import { convertBreadcrumb } from './utils/helpers'

const Breadcrumbs = ({
  initialBreadcrumbs = [],
  ...rest
}: BreadcrumbsProps) => {
  const router = useRouter()

  const breadcrumbs = initialBreadcrumbs

  const conditionalTranslationKeys = {
    homePage: 'global.homePage',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  const breadcrumbsArray = useMemo(() => {
    if (breadcrumbs.length <= 0 && router.isReady) {
      const homeBreadcrumb = {
        title: translations.homePage,
        uri: '/',
      }

      const pathSegments = router.asPath.split('?')[0].split('/')
      pathSegments.shift()
      pathSegments.pop()

      let pathSoFar = ''
      return [
        homeBreadcrumb,
        ...pathSegments.map((segment) => {
          pathSoFar += `/${segment}`
          return {
            title: segment,
            uri: pathSoFar,
          }
        }),
      ]
    }
    return breadcrumbs
  }, [translations.homePage, breadcrumbs, router.isReady, router.asPath])

  return router.pathname !== '/' && router.isReady ? (
    <>
      <div>
        {breadcrumbsArray.map((breadcrumb, index) => (
          <BreadcrumbsItemUi key={index}>
            <Link
              href={breadcrumb.uri}
              data-testid="breadcrumbItems"
              className="hidden"
            >
              {convertBreadcrumb(breadcrumb.title)}
            </Link>
          </BreadcrumbsItemUi>
        ))}
      </div>
      <div className="hidden lg:inline-block">
        <BreadcrumbsUi {...rest}>
          {breadcrumbsArray.map((breadcrumb, index) => (
            <BreadcrumbsItemUi key={index}>
              <Link
                href={breadcrumb.uri}
                data-testid="breadcrumbItems"
                className="text-sm "
              >
                {convertBreadcrumb(breadcrumb.title)}
              </Link>
            </BreadcrumbsItemUi>
          ))}
        </BreadcrumbsUi>
      </div>
      <div className="lg:hidden">
        <BreadcrumbsUi {...rest}>
          <BreadcrumbsItemUi>
            <Link
              href="/home"
              data-testid="breadcrumbItems"
              className="text-sm"
            >
              <FormattedMessage id="global.homePage" />
            </Link>
          </BreadcrumbsItemUi>

          {breadcrumbsArray.length > 1 && (
            <>
              <span className="px-4 md:text-neutral-white">...</span>
              <BreadcrumbsItemUi className="flex">
                <Link
                  href={breadcrumbsArray[breadcrumbsArray.length - 1].uri}
                  data-testid="breadcrumbItems"
                  className="text-sm "
                >
                  {convertBreadcrumb(
                    breadcrumbsArray[breadcrumbsArray.length - 1].title
                  )}
                </Link>
              </BreadcrumbsItemUi>
            </>
          )}
        </BreadcrumbsUi>
      </div>
    </>
  ) : null
}

export { Breadcrumbs }
