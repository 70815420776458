import { Banner } from './components/Banner'
import { Document } from './components/Document'
import { File } from './components/File'
import { Icon } from './components/Icon'
import { Image } from './components/Image'
import { Logo } from './components/Logo'
import { RemoteVideo } from './components/RemoteVideo'
import { Video } from './components/Video'

export const MEDIA = 'media'
export const MEDIA_VIDEO = 'media--video'
export const MEDIA_IMAGE = 'media--image'
export const MEDIA_BANNER = 'media--banner'
export const MEDIA_LOGO = 'media--logo'
export const MEDIA_FILE = 'media--file'
export const MEDIA_ICON = 'media--icon'
export const MEDIA_REMOTE_VIDEO = 'media--remote_video'
export const MEDIA_DOCUMENT = 'media--document'
export const FILE_IMAGE = 'file--image'
export const FILE_DOCUMENT = 'file--document'

export const MEDIAS = {
  [MEDIA_VIDEO]: Video,
  [MEDIA_IMAGE]: Image,
  [MEDIA_BANNER]: Banner,
  [MEDIA_LOGO]: Logo,
  [MEDIA_FILE]: File,
  [MEDIA_ICON]: Icon,
  [MEDIA_REMOTE_VIDEO]: RemoteVideo,
  [MEDIA_DOCUMENT]: Document,
}
