import { ImageStyle, MOCK_BREAKPOINTS } from '@/components/ImageStyle'

import { DrupalMediaImageDefaultProps } from './types'

const Default = ({ media, breakpoints }: DrupalMediaImageDefaultProps) => {
  if (!!!media.image) {
    console.error('Theres is no file for this', media.type, media.id)
    return <></>
  }
  return (
    <ImageStyle
      breakpoints={breakpoints ?? MOCK_BREAKPOINTS}
      file={media.image}
      className="rounded"
    />
  )
}

export { Default }
