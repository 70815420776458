import { CardSmallBasic } from '@/components/CardSmallBasic'

import { DrupalNodeNextPageBasictProps } from './types'

const SmallBasic = ({ node, className }: DrupalNodeNextPageBasictProps) => (
  <CardSmallBasic
    title={node.title}
    image={node.computed_image}
    url={node.path?.alias}
    className={className}
  />
)

export { SmallBasic }
