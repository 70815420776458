'use client'

import { ReactNode, useEffect, useState } from 'react'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import {
  Button,
  CloseButton,
  MobileDrawer,
  MobileDrawerClose,
  MobileDrawerContent,
  MobileDrawerOverlay,
  MobileDrawerPortal,
  MobileDrawerTrigger,
} from 'ui'

export const MobileVideo = ({ children }: { children: ReactNode }) => {
  const [showModal, setShowModal] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const conditionalTranslationKeys = {
    seeVideo: 'paragraph.hero.seeVideo',
    closeVideo: 'paragraph.hero.closeVideo',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    isMounted && (
      <MobileDrawer shouldScaleBackground={false} open={showModal}>
        <MobileDrawerTrigger asChild onClick={() => setShowModal(true)}>
          <Button className="w-full" aria-label={translations.seeVideo}>
            {translations.seeVideo}
          </Button>
        </MobileDrawerTrigger>
        <MobileDrawerOverlay className="fixed inset-0 z-20 bg-secondary-black-900" />
        <MobileDrawerPortal>
          <MobileDrawerContent className="top-0 z-30">
            <div className="relative z-40 flex items-center justify-end px-6 py-6 text-neutral-white CACACA">
              <MobileDrawerClose asChild onClick={() => setShowModal(false)}>
                <CloseButton
                  className="[&>*]:w-8 [&>*]:h-8"
                  aria-label={translations.closeVideo}
                />
              </MobileDrawerClose>
            </div>
            <div className="z-30 bg-primary-red-900 max-h-40px max-w-40px">
              {children}
            </div>
          </MobileDrawerContent>
        </MobileDrawerPortal>
      </MobileDrawer>
    )
  )
}
