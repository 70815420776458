import { forwardRef } from 'react'
import { Indicator, Item as ItemPrimitive } from '@radix-ui/react-radio-group'
import { cx } from 'class-variance-authority'

import { ItemStyles } from './Item.Styles'
import { ItemProps } from './types'

const Item = forwardRef<HTMLButtonElement, ItemProps>(
  ({ children, className, value, disabled, ...rest }, ref) => (
    <div className={cx(ItemStyles.wrapper({ disabled }), className)}>
      <ItemPrimitive
        className={ItemStyles.item()}
        disabled={disabled}
        ref={ref}
        value={value}
        {...rest}
      >
        <Indicator className={ItemStyles.indicator()} />
      </ItemPrimitive>
      {children}
    </div>
  )
)

Item.displayName = ItemPrimitive.displayName

export { Item }
