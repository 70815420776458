import { cva } from 'class-variance-authority'

import { focusable } from '../../../utils/helpers'

export const ButtonStyles = cva(
  [
    'px-6 py-3 relative inline-flex items-center justify-center rounded-lg select-none h-auto font-bold gap-4',
    focusable(),
  ],
  {
    variants: {
      fullWidth: {
        true: 'w-full',
      },
      intent: {
        primary: [
          'transition-all scale-100 enabled:hover:scale-110',
          'text-neutral-white bg-secondary-black-900',
          'focus:text-neutral-white focus:bg-primary-red-900',
          'disabled:bg-neutral-gray-300',
          'enabled:hover:bg-primary-red-50 enabled:hover:text-primary-red-900',
        ],
        secondary: [
          'transition-all scale-100 enabled:hover:scale-110',
          'outline outline-2 text-secondary-black-900 border-secondary-black-900 bg-neutral-white',
          'enabled:hover:outline-none',
          'focus:text-neutral-white focus:bg-primary-red-900 focus:shadow-outline-black focus:border-primary-red-900',
          'enabled:hover:bg-primary-red-50 enabled:hover:text-primary-red-900',
          'disabled:text-neutral-gray-300',
        ],
        tertiary: [
          'transition-all scale-100 enabled:hover:scale-110',
          'text-primary-base bg-transparent border-transparent',
          'focus:text-primary-red-900 focus:bg-primary-red-900 focus:shadow-outline-black focus:text-neutral-gray-100',
          'enabled:hover:bg-primary-red-50 enabled:hover:text-primary-red-900',
          'disabled:text-neutral-gray-300',
        ],
        link: [
          'p-0 underline-offset-2',
          'enabled:hover:no-underline',
          'text-neutral-white text-secondary-black-900 underline border-neutral-gray-300 border-transparent',
          'focus:text-primary-red-900 focus:bg-none focus:shadow-outline-black',
          'disabled:text-neutral-gray-300',
        ],
        filter: [
          'outline outline-2 outline-neutral-gray-200 bg-neutral-white text-text-medium ',
          'focus:text-neutral-white focus:bg-primary-red-900',
          'hover:text-primary-red-900 hover:bg-primary-red-50 hover:outline-0 ',
          'disabled:text-neutral-gray-300',
        ],
      },
      isDisabled: {
        true: 'cursor-not-allowed',
      },
      size: {
        sm: 'text-sm',
        base: 'text-base',
      },
      isLoading: {
        true: 'pointer-events-none',
      },
    },
    defaultVariants: {
      intent: 'primary',
      size: 'base',
    },
  }
)
