export const ROUTE_FIELDS = [
  //attributes
  'path',
  'title',
  'status',
  'decimal_kmeters',
  'duration',
  'duration_hours',
  'duration_minutes',
  'body',
  'text_address',
  'text_initial_point',
  'gpx',
  // includes
  'term_category',
  'term_categories',
  'term_route_type',
  'term_route_level',
  'images',
  'content_tourist_points',
  'nearby_routes',
  'metatags',
  'moderation_state',
  'computed_image',
  'file_kmz',
]
export const ROUTE_FIELDS_BASE = [
  'status',
  'type',
  'path',
  'title',
  'images',
  'body',
  'term_category',
  'term_route_type',
  'term_route_level',
  'term_categories',
  'computed_image',
  'text_address',
  'duration',
  'duration_hours',
  'duration_minutes',
]

export const ROUTE_CARD_FIELDS = [
  'type',
  'status',
  'path',
  'title',
  'term_category',
  'term_categories',
  'term_route_type',
  'term_route_level',
  'text_initial_point',
  'gpx',
  'decimal_kmeters',
  'duration',
  'duration_hours',
  'duration_minutes',
  'body',
  'text_address',
  'images',
  'computed_image',
]

export const ROUTE_INCLUDES = [
  'term_category',
  'term_categories',
  'term_route_type',
  'term_route_level',
  'images',
  'content_tourist_points',
  'content_tourist_points.image',
  'nearby_routes',
  'nearby_routes.image',
  'nearby_routes.images',
  'nearby_routes.term_category',
  'computed_image',
  'file_kmz',
]

export const ROUTE_CARD_INCLUDES_BASE = [
  'term_category',
  'term_categories',
  'term_route_type',
  'term_route_level',
  'images',
  'computed_image',
]
