import { ComponentPropsWithRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { InfoTagStyles } from './InfoTag.styles'

const InfoTag = ({
  className,
  children,
  ...props
}: ComponentPropsWithRef<'div'>) => (
  <div className={cn(InfoTagStyles(), className)} {...props}>
    {children}
  </div>
)

export { InfoTag }
