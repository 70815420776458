import { cva } from 'class-variance-authority'

export const ProgressStyles = {
  root: cva(
    'relative h-3 w-full overflow-hidden rounded-full bg-white border border-neutral-gray-400'
  ),
  indicator: cva(
    'flex-1 w-full h-full transition-all bg-secondary-black-900 rounded-full'
  ),
}
