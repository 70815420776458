import { fetchIndexDrupal } from '@/components/List/services/api'
import { State } from '@/components/List/types'
import { NODE_EVENT_BASE } from '@/components/Node/constants'

export const dateFetch = async (state: State): Promise<number> => {
  try {
    const response = await fetchIndexDrupal(state, NODE_EVENT_BASE, true)

    return response.total_items ?? 0
  } catch (error) {
    console.error('Error en dataFetch: ', error)
    return 0
  }
}
