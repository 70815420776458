import { Media } from '@/components/Media'
import { Title } from '@/components/Title'
import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { DrupalFieldTextFormat } from 'utils-drupal'

import { DrupalParagraphRemoteVideo } from '../../types'

const Default = ({ paragraph }: { paragraph: DrupalParagraphRemoteVideo }) => {
  return (
    <div className="container my-18">
      <Title
        title={paragraph.title}
        subtitle={
          typeof paragraph.subtitle !== 'string'
            ? paragraph.subtitle
            : ({
                value: paragraph.subtitle,
              } as unknown as DrupalFieldTextFormat)
        }
        description={paragraph.body}
      />
      <div className="aspect-video mx-auto max-w-[50vh] ">
        <Media media={{ ...paragraph.video, viewMode: VIEW_MODE_DEFAULT }} />
      </div>
    </div>
  )
}

export { Default }
