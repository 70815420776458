import {
  VIEW_MODE_ACTIVITY_CAROUSEL_3COL,
  VIEW_MODE_BASIC_CAROUSEL_3COL,
  VIEW_MODE_BASIC_TEASER,
  VIEW_MODE_BASIC_VERTICAL_CAROUSEL_3COL,
  VIEW_MODE_CARD_ACTIVITY,
  VIEW_MODE_CARD_BASIC,
  VIEW_MODE_CARD_BASIC_VERTICAL,
  VIEW_MODE_CARD_BIG_BASIC,
  VIEW_MODE_CARD_ROUTE,
  VIEW_MODE_CARD_TEASER,
  VIEW_MODE_CARD_TEASER_HORIZONTAL,
  VIEW_MODE_CARD_TEASER_LONG,
  VIEW_MODE_DEFAULT,
  VIEW_MODE_ROUTE_CAROUSEL_3COL,
  VIEW_MODE_TEASER_CAROUSEL_3COL,
} from '@/content/constants'

import { DrupalNodeRouteProps } from './types'
import {
  Activity,
  Basic,
  BasicTeaser,
  Teaser,
  VerticalBasic,
  Route as ViewmodeRoute,
} from './viewmodes'
import { Full } from './viewmodes/Full'

const Route = ({
  viewMode = VIEW_MODE_DEFAULT,
  ...rest
}: DrupalNodeRouteProps) => {
  switch (viewMode) {
    case VIEW_MODE_CARD_BASIC:
      return <Basic {...rest} />
    case VIEW_MODE_CARD_BIG_BASIC:
      return <Basic {...rest} />
    case VIEW_MODE_CARD_TEASER:
      return <Teaser {...rest} size={'small'} />
    case VIEW_MODE_CARD_TEASER_LONG:
      return <Teaser {...rest} size={'long'} />
    case VIEW_MODE_CARD_TEASER_HORIZONTAL:
      return <Teaser {...rest} size={'horizontal'} />
    case VIEW_MODE_CARD_ROUTE:
      return <ViewmodeRoute {...rest} />
    case VIEW_MODE_CARD_ACTIVITY:
      return <Activity {...rest} />
    case VIEW_MODE_CARD_BASIC_VERTICAL:
      return <VerticalBasic {...rest} />
    case VIEW_MODE_TEASER_CAROUSEL_3COL:
      return <Teaser {...rest} size="carousel_3col" />
    case VIEW_MODE_BASIC_CAROUSEL_3COL:
      return <Basic {...rest} />
    case VIEW_MODE_ACTIVITY_CAROUSEL_3COL:
      return <Activity {...rest} size="carousel_3col" />
    case VIEW_MODE_BASIC_VERTICAL_CAROUSEL_3COL:
      return <VerticalBasic {...rest} />
    case VIEW_MODE_ROUTE_CAROUSEL_3COL:
      return <ViewmodeRoute {...rest} />
    case VIEW_MODE_BASIC_TEASER:
      return <BasicTeaser {...rest} />
    default:
      return <Full {...rest} />
  }
}

export { Route }
