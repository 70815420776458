import { useState } from 'react'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Ban } from 'lucide-react'
import { Button, Form, FormField, Input, Text, useForm } from 'ui'
import { focusable } from 'ui/src/utils/helpers'
import { cn } from 'utils-tailwindcss'

import { FormattedMessage } from '../FormattedMessage'

const ContactForm = () => {
  const form = useForm({ reValidateMode: 'onChange' })
  const [sendForm, setSendForm] = useState<boolean | null>(null)

  const handleInputChange = () => {
    setSendForm(null)
  }

  const handleOnSubmit = async (data) => {
    if (!!!data.color) {
      try {
        const response = await fetch('/api/submit-feedback', {
          method: 'POST',
          body: JSON.stringify(data),
        })

        if (!response.ok) {
          setSendForm(false)
          throw new Error('Error al enviar el formulario')
        }
        setSendForm(true)
        form.reset()
      } catch (error) {
        setSendForm(false)
        console.error('Error en la solicitud ->', error)
      }
    }
  }
  const conditionalTranslationKeys = {
    requiredError: 'contactUs.requiredError',
    minError: 'contactUs.minError',
    itineraryError: 'itinerary.error',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleOnSubmit)}
        className="container py-24"
      >
        <Text className="inline-block mb-6">
          Los campos marcados con asterisco (
          <span className="font-bold">*</span>) son obligatorios.
        </Text>
        <FormField
          name="name"
          render={(value) => (
            <div className="flex flex-col">
              <Text className="text-lg font-bold ">
                <label htmlFor="contact-input-name">
                  * <FormattedMessage id="contactUs.nameField" />
                </label>
              </Text>
              <Input
                className="my-4"
                {...form.register('name', {
                  required: {
                    value: true,
                    message: translations.requiredError,
                  },
                  minLength: {
                    value: 3,
                    message: translations.minError,
                  },
                  onChange: handleInputChange,
                })}
                name="name"
                aria-describedby="contact-error-names"
                id="contact-input-name"
                autoComplete="given-name"
              />
              {value.fieldState.error && (
                <Text
                  className="flex items-center justify-start gap-1 text-primary-red-900"
                  id="contact-error-names"
                >
                  <Ban height="1rem" />
                  {value.fieldState.error.message ?? translations.requiredError}
                </Text>
              )}
            </div>
          )}
        />
        <FormField
          name="lastName"
          render={(value) => (
            <div className="flex flex-col">
              <Text className="text-lg font-bold ">
                <label htmlFor="contact-input-surname">
                  * <FormattedMessage id="contactUs.lastNameField" />
                </label>
              </Text>
              <Input
                className="my-4"
                {...form.register('lastName', {
                  required: {
                    value: true,
                    message: translations.requiredError,
                  },
                  minLength: {
                    value: 3,
                    message: translations.minError,
                  },
                  onChange: handleInputChange,
                })}
                name="lastName"
                aria-describedby="contact-error-surname"
                id="contact-input-surname"
                autoComplete="family-name"
              />
              {value.fieldState.error && (
                <Text
                  className="flex items-center justify-start gap-1 text-primary-red-900"
                  id="contact-error-surname"
                >
                  <Ban height="1rem" />
                  {value.fieldState.error.message ?? translations.requiredError}
                </Text>
              )}
            </div>
          )}
        />
        <FormField
          name="email"
          render={(value) => (
            <div className="flex flex-col">
              <Text className="text-lg font-bold ">
                <label htmlFor="contact-input-email">
                  * <FormattedMessage id="node.share.emailShare" />
                </label>
              </Text>
              <Input
                className="my-4 placeholder-text-dark"
                {...form.register('email', {
                  required: {
                    value: true,
                    message: translations.requiredError,
                  },
                  pattern: {
                    value: new RegExp('^[^@]+@[^@]+\\.[^@]+$'),
                    message: translations.itineraryError,
                  },
                  onChange: handleInputChange,
                })}
                placeholder={FormattedMessage({
                  id: 'node.share.emailExample',
                })}
                name="email"
                aria-describedby="contact-error-email"
                id="contact-input-email"
                autoComplete="email"
              />
              {value.fieldState.error && (
                <Text
                  className="flex items-center justify-start gap-1 text-primary-red-900"
                  id="contact-error-email"
                >
                  <Ban height="1rem" />
                  {value.fieldState.error.message ?? translations.requiredError}
                </Text>
              )}
            </div>
          )}
        />
        <FormField
          name="observations"
          render={(value) => (
            <div className="flex flex-col">
              <Text className="text-lg font-bold ">
                <label htmlFor="contact-input-observations">
                  <FormattedMessage id="contactUs.observationsField" />
                </label>
              </Text>
              <textarea
                className={cn(
                  'flex h-40 px-6 py-4 my-4 border rounded-lg border-neutral-gray-400 outline-none',
                  focusable()
                )}
                {...form.register('observations', {
                  minLength: {
                    value: 3,
                    message: translations.minError,
                  },
                  onChange: handleInputChange,
                })}
                name="observations"
                aria-describedby="error-observations"
                id="contact-input-observations"
              />
              {value.fieldState.error && (
                <Text
                  className="flex items-center justify-start gap-1 text-primary-red-900"
                  id="contact-error-observations"
                >
                  <Ban height="1rem" />
                  {value.fieldState.error.message ?? translations.requiredError}
                </Text>
              )}
            </div>
          )}
        />
        <FormField
          name="color"
          render={() => (
            <Input
              className="invisible py-0"
              {...form.register('color', {
                minLength: {
                  value: 4,
                  message: translations.itineraryError,
                },
              })}
              placeholder={FormattedMessage({
                id: 'node.share.emailExample',
              })}
              name="color"
            />
          )}
        />
        <div className="flex justify-end w-full">
          <Button type="submit" className="w-fit min-w-[6ch]">
            <FormattedMessage id="global.send" />
          </Button>
        </div>
        {sendForm === true && (
          <Text className="mt-4 text-green-500">
            Formulario enviado exitosamente.
          </Text>
        )}
        {sendForm === false && (
          <Text className="mt-4 text-red-500">
            Hubo un error al enviar el formulario. Inténtalo de nuevo.
          </Text>
        )}
      </form>
    </Form>
  )
}

export { ContactForm }
