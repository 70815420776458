import Link from 'next/link'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { Grid } from '@/components/Grid'
import { LocationCard } from '@/components/LocationCard'
import { VIEW_MODE_ITINERARY_BASIC } from '@/components/LocationCard/constants'
import { DrupalNodeItinerary } from '@/components/Node/components/Itinerary/types'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ArrowRight } from 'lucide-react'
import { useRouter } from 'next-translate-routes'

import { useItineraryProvider } from '../../../../ItineraryProvider'

const MyItineraries = () => {
  const {
    state: { selected },
    addItinerary,
    removeItinerary,
    isSelected,
    toogleShowItineraries,
  } = useItineraryProvider()

  const { locale } = useRouter()

  const body = FormattedRichMessage({
    id: 'itinerary.pointsOfInterestAdded',
    values: { total: selected.length },
  }) as string

  const conditionalTranslationKeys = {
    yourSelection: 'itinerary.yourSelection',
    accessToMyItinerary: 'itinerary.accessToMyItinerary',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    !!selected.length && (
      <div className="container mt-18 bg-neutral-gray-200">
        <Grid
          cols={4}
          title={translations.yourSelection}
          subtitle={body}
          className="container mt-12 mb-4 md:mb-4 md:mt-18"
        >
          {selected.slice(0, 4).map((content: DrupalNodeItinerary) => (
            <LocationCard
              key={content.id}
              title={content.title}
              image={content.computed_image}
              viewMode={VIEW_MODE_ITINERARY_BASIC}
              onAddedClick={() => addItinerary(content as DrupalNodeItinerary)}
              onRemoveClick={() => removeItinerary(content.id)}
              isAdded={isSelected(content.id)}
            />
          ))}
        </Grid>
        <div className="flex justify-end w-full pt-4 pb-10">
          <Link
            href="/crea-tu-itinerario#myItinerary"
            passHref
            locale={locale}
            onClick={() => toogleShowItineraries(true)}
            className="flex items-center justify-center h-auto gap-4 px-6 py-3 font-bold rounded-lg select-none"
          >
            {translations.accessToMyItinerary}
            <ArrowRight />
          </Link>
        </div>
      </div>
    )
  )
}

export { MyItineraries }
