import { CSSProperties } from 'react'

const TwitterIcon = ({
  className,
  style,
}: {
  className?: string
  style?: CSSProperties
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M16.3875 4H18.9949L13.2999 10.5259L20 19.4062H14.7544L10.6458 14.0194L5.94419 19.4062H3.33575L9.42706 12.4256L3 4H8.37944L12.0929 8.92256L16.3875 4ZM15.4738 17.8422H16.9188L7.59319 5.48219H6.04406L15.4738 17.8422Z"
      fill="black"
    />
  </svg>
)

export { TwitterIcon }
