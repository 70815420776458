import { FormattedMessage } from '@/components/FormattedMessage'
import { Link } from '@/components/Link'
import { Title } from '@/components/Title'
import { internalLink } from '@/lib/utils/internalLink'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ArrowRight, ExternalLink } from 'lucide-react'
import { DrupalFieldTextFormat } from 'utils-drupal'

import { EXTERNAL_CONTENT, INTERNAL_CONTENT } from '../constants'
import { Layout } from './components/Layout'
import { DrupalParagraphGalleryDefaultProps } from './types'

// TODO: REVISAR EL FUNCIONAMIENTO DEL MOSTRAR MAS O SI QUEREMOS MOSTRARLO
const Default = ({ paragraph }: DrupalParagraphGalleryDefaultProps) => {
  const INTERNAL_LINK = paragraph.link && internalLink(paragraph.link.uri)

  const conditionalTranslationKeys = {
    showMore: 'paragraph.gallery.showMore',
    externalLink: 'global.externalLink',
    internalLink: 'global.internalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div className="my-18 bg-neutral-white">
      <div className="container">
        <Title
          title={paragraph.title}
          subtitle={
            typeof paragraph.subtitle !== 'string'
              ? paragraph.subtitle
              : ({
                  value: paragraph.subtitle,
                } as unknown as DrupalFieldTextFormat)
          }
          description={paragraph.body}
        />
        <div className="flex flex-col gap-6 md:gap-16">
          {paragraph.paragraph_contents?.map((paragraphType) => {
            switch (paragraphType.type) {
              case INTERNAL_CONTENT:
                return (
                  paragraphType.content?.id !== 'missing' &&
                  paragraphType.content?.status && (
                    <Layout
                      paragraph={paragraphType.content}
                      key={paragraphType.id}
                      url={paragraphType.content.path?.alias}
                      showMore={
                        <FormattedMessage id="paragraph.gallery.showMore" />
                      }
                    />
                  )
                )

              case EXTERNAL_CONTENT:
                return (
                  <Layout
                    paragraph={paragraphType}
                    key={paragraphType.id}
                    url={paragraphType.link?.uri}
                    target="_blank"
                    showMore={
                      paragraphType.link?.title || translations.showMore
                    }
                  />
                )
            }
          })}
        </div>
      </div>
      {paragraph.link && (
        <div className="container mt-12 text-right ">
          <Link
            target={INTERNAL_LINK ? '_self' : '_blank'}
            href={paragraph.link.uri}
            intent="link"
            className="font-semibold underline"
            aria-label={paragraph.link.title}
            aria-describedby={
              INTERNAL_LINK
                ? translations.internalLink
                : translations.externalLink
            }
          >
            {paragraph.link.title}
            {INTERNAL_LINK ? (
              <ArrowRight
                aria-roledescription="presentation"
                aria-hidden="true"
              />
            ) : (
              <ExternalLink
                aria-roledescription="presentation"
                aria-hidden="true"
              />
            )}
          </Link>
          {!INTERNAL_LINK ? (
            <p className="sr-only">{translations.newWindow}</p>
          ) : null}
        </div>
      )}
    </div>
  )
}
export { Default }
