import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DrupalNodePlace } from '@/components/Node/components/Place/types'
import { HookOutput } from 'utils'

type UseVillagesMapResult = HookOutput<
  {
    villages: DrupalNodePlace[]
    currentVillage: DrupalNodePlace | null
  },
  {
    setCurrentVillage: Dispatch<SetStateAction<DrupalNodePlace | null>>
  }
>

export const useVillagesMap = ({
  villages,
}: {
  villages: DrupalNodePlace[]
}): UseVillagesMapResult => {
  const [currentVillage, setCurrentVillage] = useState<DrupalNodePlace | null>(
    villages[1]
  )

  useEffect(() => {
    villages.length && setCurrentVillage(villages[0])
  }, [villages, villages.length])

  return {
    state: {
      villages,
      currentVillage,
    },
    actions: {
      setCurrentVillage,
    },
  }
}
