import { useState } from 'react'

//TODO: un nodo? un objeto? un string?
export const useCopyPath = (route: string | '') => {
  const [showMessage, setShowMessage] = useState(false)

  const copyPath = () => {
    const jsonString = JSON.stringify(route).replace(/"/g, '')
    navigator.clipboard.writeText(jsonString)
    setShowMessage(true)
    setTimeout(() => {
      setShowMessage(false)
    }, 1500)
  }

  return { state: { showMessage }, actions: { copyPath } }
}
