import Link from 'next/link'
import { CKEditor } from '@/components/CKEditor'
import { ContentPopup } from '@/components/ContentPopup'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Media } from '@/components/Media'
import { VIEW_MODE_DEFAULT } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ExternalLink, FileText, LinkIcon, PlusCircle } from 'lucide-react'
import {
  Card,
  CardContent,
  CardImage,
  CardSubtitle,
  CardTitle,
  Heading,
  ImageViewer,
  ImageViewerContent,
  ImageViewerTrigger,
} from 'ui'

import { BREAKPOINTS } from '../constants'
import { DrupalParagraphGalleryDefaultProps } from '../types'

const Layout = ({
  paragraph,
  url,
  showMore,
  target,
}: DrupalParagraphGalleryDefaultProps) => {
  const conditionalTranslationKeys = {
    showMore: 'paragraph.customText.showMore',
    externalLink: 'global.externalLink',
    internalLink: 'global.internalLink',
    newWindow: 'global.newWindow',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )
  return (
    <Card className="flex flex-col shadow-none bg-neutral-gray-100 md:flex-row">
      <CardImage>
        <Media
          media={{
            ...(paragraph.computed_image || paragraph.image),
            viewMode: VIEW_MODE_DEFAULT,
          }}
          // @ts-ignore
          breakpoints={BREAKPOINTS}
        />
      </CardImage>
      <CardContent className="flex flex-col gap-3">
        <CardTitle className="no-underline">
          <Heading as="h3">{paragraph.title}</Heading>
        </CardTitle>

        <CardSubtitle className="flex flex-col w-full gap-3">
          <div className="line-clamp-4 text-text-medium">
            <CKEditor text={paragraph.body} />
          </div>
          <ImageViewer>
            <ImageViewerTrigger
              className="flex justify-end w-full hover:underline"
              aria-label={FormattedMessage({
                id: 'paragraph.gallery.enlarge',
              })}
            >
              <PlusCircle />
            </ImageViewerTrigger>
            <ImageViewerContent>
              <ContentPopup
                image={
                  <Media
                    media={{
                      ...(paragraph.computed_image || paragraph.image),
                      viewMode: VIEW_MODE_DEFAULT,
                    }}
                    // @ts-ignore
                    breakpoints={BREAKPOINTS}
                  />
                }
                body={<CKEditor text={paragraph.body} />}
                title={
                  <Heading as="h3" className="font-semibold">
                    {paragraph.title}
                  </Heading>
                }
              />
            </ImageViewerContent>
          </ImageViewer>
          <div className="flex flex-col gap-2">
            <div className="flex gap-1">
              {url && (
                <>
                  {target === '_blank' ? (
                    <ExternalLink
                      className="min-w-6"
                      aria-roledescription="presentation"
                      aria-hidden="true"
                    />
                  ) : (
                    <LinkIcon
                      className="min-w-6"
                      aria-roledescription="presentation"
                      aria-hidden="true"
                    />
                  )}
                  <Link
                    href={url}
                    className="font-semibold"
                    target={target}
                    aria-label={translations.showMore}
                    aria-describedby={
                      target === '_blank'
                        ? translations.externalLink
                        : translations.internalLink
                    }
                  >
                    {showMore}
                  </Link>
                  {target === '_blank' ? (
                    <p className="sr-only">{translations.newWindow}</p>
                  ) : null}
                </>
              )}
            </div>
            <div className="flex gap-1">
              {!!paragraph.document?.file && (
                <>
                  <FileText
                    className="min-w-6"
                    role="presentation"
                    aria-hidden="true"
                  />
                  <Link
                    href={paragraph?.document?.file?.uri?.url}
                    className="font-semibold"
                    target={target}
                  >
                    {paragraph.document.name}
                  </Link>
                </>
              )}
            </div>
          </div>
        </CardSubtitle>
      </CardContent>
    </Card>
  )
}

export { Layout }
