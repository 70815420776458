import { useState } from 'react'
import { FormattedMessage } from '@/components/FormattedMessage'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { LIST_TYPE_ITINERARY } from '@/components/Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { ListIcon, MapIcon } from 'lucide-react'
import { Button } from 'ui'
import { Skeleton } from 'ui/src/components/atoms/Skeleton'
import { cn } from 'utils-tailwindcss'

import { RouteMap } from '../../..'
import { useItineraryProvider } from '../../ItineraryProvider'
import { useMapListProvider } from '../../MapListProvider'
import { Items } from '../Desktop/components/Navigation/components'
import { Itineraries } from '../Itineraries'
import { Current, Filters, SortFilter } from './components'
import { Paginator } from './components/Paginator'

const Mobile = () => {
  const {
    listState: { results, isLoading },
    type,
  } = useMapListProvider()
  const {
    state: { selected, showItineraries },
    toogleShowItineraries,
  } = useItineraryProvider()

  const [showMap, setShowMap] = useState(false)

  const conditionalTranslationKeys = {
    myItinerary: 'itinerary.myItinerary',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div
      className={cn(
        'relative md:hidden',
        showMap && 'h-screen md:hidden grid grid-flow-row'
      )}
    >
      <div className="sticky top-0 z-50 flex justify-between items-center px-6 py-3 mt-6 bg-neutral-gray-200">
        {showItineraries ? (
          <FormattedRichMessage
            id="itinerary.totalPointsAdded"
            values={{ total: selected.length }}
          />
        ) : (
          <>
            <FormattedRichMessage
              id="searcher.routes.totalRoutes"
              values={{ total: results?.length || 0 }}
            />
            <div className="flex gap-4">
              <Filters />
              {type === LIST_TYPE_ITINERARY ? (
                <Button
                  intent={'tertiary'}
                  isDisabled={selected?.length == 0}
                  className="justify-start gap-2 pl-0 pr-0"
                  onClick={() => toogleShowItineraries()}
                >
                  <MapIcon /> {translations.myItinerary}
                </Button>
              ) : (
                <SortFilter />
              )}
            </div>
          </>
        )}
      </div>
      {!showMap ? (
        showItineraries ? (
          <Itineraries />
        ) : (
          <>
            <Items isMobile={true} />
            {isLoading &&
              Array(4)
                .fill(0)
                .map((_, index) => (
                  <div
                    className="flex items-center justify-between w-full gap-4 px-3 pb-4"
                    key={index}
                  >
                    <div className="flex flex-col w-3/5 gap-2">
                      <Skeleton className="w-full h-16" />
                      <Skeleton className="w-full h-3" />
                    </div>
                    <Skeleton className="w-32 h-28 rounded-2xl" />
                  </div>
                ))}
            <Paginator />
          </>
        )
      ) : (
        <>
          <RouteMap type={type} />
          <Current type={type} />
        </>
      )}

      <Button
        className="fixed z-50 flex gap-2 right-6 bottom-24"
        intent="primary"
        onClick={() => setShowMap(!showMap)}
      >
        {showMap ? <ListIcon /> : <MapIcon />}
        <FormattedMessage
          id={showMap ? 'villageMap.seeList' : 'villageMap.seeMap'}
        />
      </Button>
    </div>
  )
}

export { Mobile }
