import { cva } from 'class-variance-authority'

export const GradientImageStyles = {
  wrapper: cva('bg-cover w-full h-full'),
  gradient: cva('w-full h-full bg-no-repeat', {
    variants: {
      variant: {
        paragraph: 'bg-gradient-to-r from-text-dark from-59.32% to-78.57%',
        dark: 'bg-gradient-to-t from-text-dark from-65%',
        header: 'bg-gradient-to-r from-neutral-gray-200 from-24% to-100%',
        noBackground: '',
      },
    },
    defaultVariants: {
      variant: 'paragraph',
    },
  }),
}

// linear-gradient(90deg, rgba(0, 0, 0, 0.80) 59.32%, rgba(0, 0, 0, 0.00) 78.57%), url(<path-to-image>), lightgray 50% / cover no-repeat;
