import { Carousel } from '@/components/Carousel'
import { Node } from '@/components/Node'
import { VIEW_MODE_ACTIVITY_CAROUSEL_3COL } from '@/content/constants'

import { DrupalParagraphEvents } from '../../types'

const Default = ({ paragraph }: { paragraph: DrupalParagraphEvents }) => (
  <div className="my-18">
    <div className="custom-container">
      <Carousel
        title={paragraph.title}
        subtitle={paragraph.subtitle}
        body={paragraph.body}
        link={paragraph.link}
      >
        {paragraph.village_events?.map((content) => (
          <Node
            key={content.id}
            node={{
              ...content,
              viewMode: VIEW_MODE_ACTIVITY_CAROUSEL_3COL,
            }}
          />
        ))}
      </Carousel>
    </div>
  </div>
)

export { Default }
