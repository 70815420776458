import { VIEW_MODE_DEFAULT } from '@/content/constants'

const File = ({ viewMode = VIEW_MODE_DEFAULT, ...rest }: any) => {
  switch (viewMode) {
    default:
      return <div {...rest}></div>
  }
}

export { File }
