import { SCALE_WEBP_320x240, SCALE_WEBP_600x400 } from '@/content/constants'

import { ImageStyleBreakPoints } from '../ImageStyle'
import { LIST_TYPE_ITINERARY } from '../Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'

export const VIEW_MODE_MOBILE = 'mobile'
export const VIEW_MODE_MEGAMENU = 'megamenu'
export const VIEW_MODE_DYNAMIC = 'dynamic'
export const VIEW_MODE_ITINERARY = LIST_TYPE_ITINERARY
export const VIEW_MODE_ITINERARY_BASIC = `${LIST_TYPE_ITINERARY}_basic`
export const VIEW_MODE_ITINERARY_MOBILE = `${LIST_TYPE_ITINERARY}_mobile`

export const BREAKPOINTS: ImageStyleBreakPoints = {
  default: {
    id: SCALE_WEBP_320x240,
  },
}

export const MOBILE_BREAKPOINTS: ImageStyleBreakPoints = {
  default: {
    id: SCALE_WEBP_600x400,
  },
}
