export const TODOTOGO_FIELDS = [
  'name',
  'status',
  'description',
  'content',
  'content.computed_image',
  'parent',
  'path',
]

export const TODOTOGO_INCLUDES = [
  'content',
  'content.computed_image',
  'parent',
  'parent.parent',
  'parent.parent.parent',
]
