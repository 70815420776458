import { LIST_TYPE_ITINERARY } from '@/components/Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'

import { RouteMap } from '../../..'
import { useMapListProvider } from '../../MapListProvider'
import {
  Current,
  Filters,
  MyItineraries,
  RouteMapNavigation,
} from './components'

export const Desktop = () => {
  const { type } = useMapListProvider()
  return (
    <>
      <div
        id="myItinerary"
        className="relative z-0 flex-col hidden w-full pt-6 md:flex md:flex-row"
      >
        {/* NAVEGACION */}
        <RouteMapNavigation />

        {/* FILTERS POPUP */}
        <Filters />

        {/* MAP */}
        <div className="relative z-0 w-full h-full">
          <RouteMap type={type} />
          <Current type={type} />
        </div>
      </div>
      {type === LIST_TYPE_ITINERARY && <MyItineraries />}
    </>
  )
}
