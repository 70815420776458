import { forwardRef, useState } from 'react'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { cn } from 'utils-tailwindcss'

import { TextProps } from './types'

const Text = forwardRef<HTMLHeadingElement, TextProps>(
  (
    { className, children, showMore, showMoreText, showLessText, ...rest },
    ref
  ) => {
    const [show, setShow] = useState(false)
    return (
      <p className="contents">
        <span
          ref={ref}
          className={cn(!show && 'text-ellipsis line-clamp-10', className)}
          {...rest}
        >
          {children}
        </span>
        {showMore && (
          <button
            onClick={() => setShow((show) => !show)}
            className="flex items-center gap-2 mt-6"
          >
            {show ? (
              <>
                <ChevronUp />
                {showLessText}
              </>
            ) : (
              <>
                <ChevronDown />
                {showMoreText}
              </>
            )}
          </button>
        )}
      </p>
    )
  }
)

Text.displayName = 'Text'

export { Text }
