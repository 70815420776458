import Link from 'next/link'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { LocationCard } from '@/components/LocationCard'
import { VIEW_MODE_MEGAMENU } from '@/components/LocationCard/constants'
import { Node } from '@/components/Node'
import { DrupalNodeLanding } from '@/components/Node/components/Landing/types'
import { VIEW_MODE_BASIC_CAROUSEL_3COL } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import {
  Heading,
  InteractiveMap,
  Navigation,
  NavigationFooterItem,
  NavigationItem,
  Separator,
} from 'ui'
import { Skeleton } from 'ui/src/components/atoms/Skeleton'

import { WhereToGoProps } from './types'
import { useWhereToGo } from './useWhereToGo'

const WhereToGo = ({ categories, isMobile }: WhereToGoProps) => {
  const {
    state: { placeSelected, selected, itemSelected, isLoading, itemsSelected },
    actions: { setPlaceSelected, onPathClick },
  } = useWhereToGo(categories?.items ?? [], isMobile)

  const conditionalTranslationKeys = {
    highlights: 'global.highlights',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <div className="flex flex-col h-full md:grid-cols-2 md:grid lg:grid-cols-3 md:gap-12">
      <Navigation
        value={placeSelected?.id}
        onValueChange={(id: string) => setPlaceSelected(id)}
        role="tablist"
        aria-orientation="vertical"
      >
        {categories.items?.map((category) => (
          <NavigationItem
            key={category.id}
            id={category.id}
            className="text-base font-semibold text-left"
            aria-selected={itemSelected?.title === category.title}
            aria-controls={category.id}
          >
            {category.title}
          </NavigationItem>
        ))}
        <NavigationFooterItem>
          <Link href={categories?.url ?? ''}>
            <FormattedRichMessage
              id="megamenu.accessTo"
              values={{ name: categories.title }}
            />
          </Link>
        </NavigationFooterItem>
      </Navigation>
      <Separator className="mt-8 mb-6 bg-neutral-gray-300 md:hidden" />
      <div
        className="hidden w-full h-full md:flex"
        role="tabpanel"
        id={placeSelected?.id}
      >
        <InteractiveMap value={selected} onPathClick={onPathClick} />
      </div>
      <div className="h-full" id={'location-card'}>
        {isLoading ? (
          <div className="flex flex-col md:ml-24 md:mt-10">
            <Skeleton className="w-[17.175rem] h-6" />
            <Skeleton className="w-[17.175rem] h-24 mt-4" />
            <Skeleton className="w-[10rem] h-6 mt-8" />
            <Skeleton className="w-[17.175rem] h-6 mt-4" />
            <Skeleton className="w-[17.175rem] h-6 mt-4" />
          </div>
        ) : (
          itemSelected && (
            <LocationCard
              title={itemSelected.title}
              description={itemSelected.body}
              image={itemSelected.computed_image}
              link={itemSelected.path.alias}
              viewMode={VIEW_MODE_MEGAMENU}
              onCloseClick={() => setPlaceSelected('')}
            />
          )
        )}
      </div>

      {!!itemsSelected?.length && (
        <div className="h-full md:hidden">
          <Heading as="h3" className="mb-8 font-semibold">
            {translations.highlights}
          </Heading>
          <div className="grid grid-cols-2 gap-6 md:grid-cols-3 md:gap-8">
            {itemsSelected?.map((child) => (
              <Node
                key={child.id}
                node={
                  {
                    ...child,
                    viewMode: VIEW_MODE_BASIC_CAROUSEL_3COL,
                  } as unknown as DrupalNodeLanding
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export { WhereToGo }
