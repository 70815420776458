import { useEffect } from 'react'
import { FormattedMessage } from '@/components/FormattedMessage'
import { useListProvider } from '@/components/List/ListProvider'
import { MapIcon } from 'lucide-react'
import { Link } from 'next-translate-routes'

import { SearchForm } from '../../../SearchForm'

const RoutesWithoutResults = () => {
  const {
    state: { query, urlResults },
    goToActivitiesPageWithFilters,
  } = useListProvider()

  useEffect(() => {
    query && goToActivitiesPageWithFilters()
  }, [query, goToActivitiesPageWithFilters])

  return (
    <div className="flex flex-col items-start w-full gap-10 md:items-center md:flex-row">
      <SearchForm
        placeholder={FormattedMessage({
          id: 'searcher.routes.searcherPlaceholder',
        })}
      />
      <Link
        href={urlResults ?? ''}
        className="flex flex-row gap-3 font-bold no-underline"
      >
        <MapIcon />
        <FormattedMessage id="searcher.routes.mapExplore" />
      </Link>
    </div>
  )
}
export { RoutesWithoutResults }
