import { cva } from 'class-variance-authority'

export const CheckboxStyles = {
  root: cva([
    'ring-1 rounded-4 ring-neutral-gray-400 rounded',
    'disabled:ring-neutral-gray-300',
    'data-[state=checked]:ring-2 data-[state=checked]:ring-primary-red-900',
    'data-[state=unchecked]:enabled:hover:bg-neutral-gray-100 data-[state=unchecked]:enabled:hover:ring-2 data-[state=unchecked]:enabled:hover:ring-neutral-gray-400',
    'data-[state=unchecked]:enabled:hover:bg-neutral-gray-100',
    'h-[22px] w-[22px]',
  ]),
  indicator: cva([
    'flex items-center justify-center',
    'data-[state=checked]:text-primary-red-900',
  ]),
}
