import { cn } from 'utils-tailwindcss'

import { LayoutStyles } from './Layout.styles'
import { LayoutProps } from './types'

const Layout = ({ className, hasSidebar = false, children }: LayoutProps) => (
  <section
    className={cn(LayoutStyles({ hasSidebar }), className)}
    suppressHydrationWarning
  >
    {children}
  </section>
)

export { Layout }
