import { forwardRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { SpinnerStyles } from './Spinner.styles'
import { SpinnerProps } from './types'

const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  ({ label = 'Loading...', size, className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(SpinnerStyles({ size }), className)}
      {...props}
    >
      {label && <span className="hidden">{label}</span>}
    </div>
  )
)

Spinner.displayName = 'Spinner'

export { Spinner }
