import { FormattedMessage } from '@/components/FormattedMessage'
import { FormattedRichMessage } from '@/components/FormattedRichMessage'
import { CheckBoxList } from '@/components/List/components/CheckBoxList'
import { CheckBoxFilter } from '@/components/List/components/CheckBoxList/types'
import { SearchForm } from '@/components/List/components/SearchForm'
import { useListProvider } from '@/components/List/ListProvider'
import { Text } from 'ui'

export const Desktop = () => {
  const {
    state: { query, facets, results },
  } = useListProvider()

  return (
    <div className="flex flex-col gap-8 px-6 sm:px-0">
      <div className="flex flex-col w-full lg:flex-row">
        <div className="flex flex-col justify-start w-full">
          <SearchForm />
          <Text className="mt-4 mb-4 lg:mt-12 lg:mb-0text-xl">
            <FormattedMessage id="searcher.textForFilters" />
          </Text>
        </div>
        <div className="relative flex flex-row self-end justify-end gap-10 w-fit h-fit">
          {facets?.map((filter: CheckBoxFilter, key: number) => (
            <CheckBoxList {...filter} key={key} />
          ))}
        </div>
      </div>
      {!!query && !!results?.length && (
        <Text className="hidden text-lg font-semibold md:flex">
          <FormattedRichMessage
            id="searcher.activeQueryText"
            values={{ query }}
          />
        </Text>
      )}
    </div>
  )
}
