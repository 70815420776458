import { Language } from '../../usePublicLayout'
import { HeaderMobile } from './components'
import { DesktopHeader } from './components/DesktopHeader'
import { MegamenuType } from './components/Megamenu/types'

export const Header = ({
  ...props
}: {
  isHome: boolean
  megamenu: MegamenuType
  languages: Language[]
}) => (
  <>
    <div className="hidden md:block">
      <DesktopHeader {...props} />
    </div>
    <div className="md:hidden">
      <HeaderMobile {...props} />
    </div>
  </>
)
