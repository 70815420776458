import { CardRoute } from '@/components/CardRoute'

import { CardRouteProps } from './types'

const Route = ({ node }: CardRouteProps) => (
  <CardRoute
    title={node.title}
    image={node.computed_image}
    url={node.path?.alias}
  />
)

export { Route }
