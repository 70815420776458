import { DrupalTaxonomyTermCustomExperience } from '@/components/TaxonomyTerm/components/CustomExperience/types'

export const findExperienceValues = (
  options: DrupalTaxonomyTermCustomExperience[],
  optionSelected: string
): DrupalTaxonomyTermCustomExperience[] | [] => {
  const item = options.find(
    (item: DrupalTaxonomyTermCustomExperience) => item.id === optionSelected
  )
  return item?.children || []
}

export const getTaxonomiesSorted = (
  taxonomies: DrupalTaxonomyTermCustomExperience[]
) => {
  taxonomies
    ?.filter((filter) => filter!)
    .forEach((filter) => {
      filter.parent?.at(0)?.id === 'virtual'
        ? (filter.deep = 0)
        : (filter.deep = findDeep(filter, taxonomies, 1))
    })

  const parents = taxonomies.filter((tax) => tax.deep! === 0)

  const children = taxonomies.filter((tax) => tax.deep! === 1)

  parents?.forEach((father) => {
    const taxonomyChildren = new Array<DrupalTaxonomyTermCustomExperience>()

    children?.forEach((child: DrupalTaxonomyTermCustomExperience) => {
      !!child.parent?.filter((parent) => parent?.id === father.id).length &&
        taxonomyChildren.push(child)

      father.children = taxonomyChildren
    })
  })

  return parents
}

const findDeep = (
  child: DrupalTaxonomyTermCustomExperience,
  taxonomyList: DrupalTaxonomyTermCustomExperience[],
  lastDeep: number
) => {
  const father = taxonomyList.find(
    (item: DrupalTaxonomyTermCustomExperience) =>
      item.id === child.parent?.[0].id
  )

  return father === undefined ||
    father.name === undefined ||
    father.parent?.[0].id === 'virtual'
    ? lastDeep
    : findDeep(father!, taxonomyList, ++lastDeep)
}
