import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
} from 'react'
import { HookOutput } from 'utils'

import { contentTouristPointType } from '../../../../types'
import { Route } from './types'
import { useMap } from './useMap'

type ContextProps = HookOutput<
  {
    routes: Route[]
    touristicPoints: contentTouristPointType[]
    currentTouristicPoint: contentTouristPointType | null
  },
  {
    setCurrentTouristicPoint: Dispatch<
      SetStateAction<contentTouristPointType | null>
    >
  }
>

const MapContext = createContext<ContextProps | undefined>(undefined)
MapContext.displayName = 'MapContext'

type ContextProviderProps = {
  children: ReactNode
  routes: Route[]
  touristicPoints: contentTouristPointType[]
}

const MapProvider = ({
  children,
  routes,
  touristicPoints,
}: ContextProviderProps) => {
  const { state, actions } = useMap({ routes, touristicPoints })

  const value = useMemo(() => {
    return { state, actions }
  }, [state, actions])

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>
}

const useMapProvider = () => {
  const ctxValue = useContext(MapContext)
  if (!ctxValue) throw new Error(`Please wrap in an ${MapContext.displayName}`)
  return ctxValue
}

export { MapProvider, useMapProvider }
