import { ComponentPropsWithoutRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { ItemStyles } from './Item.styles'

const Item = ({
  children,
  className,
  ...rest
}: ComponentPropsWithoutRef<'li'>) => (
  <li className={cn(ItemStyles(), className)} {...rest}>
    {children}
  </li>
)

Item.displayName = 'BreadcrumbsItem'

export { Item }
