import { DrupalNodeRoute } from '@/components/Node/components/Route/types'
import { NODE_EVENT_BASE, NODE_ROUTE_BASE } from '@/components/Node/constants'
import { BundleDrupalNode } from '@/components/Node/types'
import { LIST_TYPE_ITINERARY } from '@/components/Paragraph/components/CreateItineraryMap/viewmodes/Default/constants'
import { sortingResults } from '@/components/RoutesList/components/List/components/Desktop/components/SortFilter/helper'
import {
  CATEGORY_FILTER,
  CLOSEST_ROUTES_FILTER,
  ITINERARIES_FILTER,
  ITINERARIES_FILTER_SERVICES,
  ROUTES_CATEGORIES,
  SORT_FILTER,
} from '@/components/RoutesList/constants'
import {
  TERM_ITINERARY_CATEGORY,
  TERM_ROUTE_CATEGORY,
} from '@/components/TaxonomyTerm/constants'
import { getParams } from '@/lib/get-params'
import { getDrupalFunction } from '@/lib/utils/getDrupalFunction'

import {
  CICLA_MADRID_INDEX,
  DATE_FILTER,
  GENERAL_INDEX,
  GENERAL_TYPE,
  GRAN_TOUR_INDEX,
  translations,
} from '../constants'
import { Facets, Filter, ResultData, State } from '../types'
import { getFilters, getParamsForQuery } from '../utils/helpers'

const getItems = async (
  state: State,
  type: string,
  offset: number,
  isFilterCall: boolean,
  locale?: string
): Promise<{
  response: any | undefined
  results: BundleDrupalNode[] | undefined
}> => {
  try {
    const queryParams =
      type === NODE_EVENT_BASE || type === GENERAL_INDEX || isFilterCall
        ? getParamsForQuery(type, state, locale)
        : getParamsForQuery(
            type,
            {
              ...state,
              per_page: 50,
              page: offset ?? 1,
            },
            locale
          )

    const url = await getDrupalFunction(
      'getDrupalUrl',
      { locale: locale ?? 'es', type, queryParams },
      {
        url: '/api/build-url',
        body: JSON.stringify({ locale, type, queryParams }),
      }
    )

    const response = await getDrupalFunction('getIndexDrupal', url, {
      url: '/api/fetch-index',
      body: JSON.stringify({ url }),
    })

    const results: BundleDrupalNode[] = await getDrupalFunction(
      'getDrupalDeserialize',
      { response },
      { url: '/api/deserialize', body: JSON.stringify({ response }) }
    )

    if (
      type !== NODE_EVENT_BASE &&
      type !== GENERAL_INDEX &&
      !isFilterCall &&
      results.length === 50
    ) {
      const { results: resultsToAdd } = await getItems(
        state,
        type,
        ++offset,
        isFilterCall
      )
      resultsToAdd && results?.push(...resultsToAdd)
    }

    return { response, results }
  } catch (error) {
    console.error('🚀 ~ error getItems:', error)
    return { response: {}, results: [] }
  }
}

export const fetchIndexDrupal = async (
  state: State,
  type: string = NODE_ROUTE_BASE,
  isFilterCall: boolean = false,
  locale: string = 'es'
): Promise<ResultData> => {
  try {
    const { response, results } = await getItems(state, type, 1, isFilterCall)

    if (
      type === NODE_ROUTE_BASE ||
      type === CICLA_MADRID_INDEX ||
      type === GRAN_TOUR_INDEX
    ) {
      const sort = getFilters(state, SORT_FILTER)?.[0]
      if (sort) {
        const sortingType = sort.values.value.split('-')[0]

        sortingType === CLOSEST_ROUTES_FILTER &&
          navigator.geolocation &&
          navigator.geolocation.getCurrentPosition(function (position) {
            let userLat = position.coords.latitude
            let userLon = position.coords.longitude
            results?.sort((a: DrupalNodeRoute, b: DrupalNodeRoute) =>
              sortingResults(a, b, [userLat, userLon])
            )
          })
      }

      if (response) {
        response.meta.facets = await getRouteFacets(
          response.meta.facets,
          locale
        )
      }
    } else if (type === LIST_TYPE_ITINERARY && response) {
      response.meta.facets = await getItinerariesFacets(
        response.meta.facets,
        locale
      )
    } else if (type === GENERAL_INDEX) {
      response.meta.facets = translateFacetsLabels(response.meta.facets, locale)
    }
    type === NODE_EVENT_BASE &&
      //@ts-ignore
      response.meta.facets.unshift({
        id: DATE_FILTER,
        label: 'Fecha',
        terms: [],
        path: '',
      })

    return {
      //@ts-ignore
      results: results,
      //@ts-ignore
      total_items: response.meta.count,
      //@ts-ignore
      facets: response.meta?.facets,
    }
  } catch (error) {
    console.error('error in  fetchIndexDrupal', error)
    return {
      results: [],
      facets: [],
      total_items: 0,
    }
  }
}

const getRouteFacets = async (
  facets: Facets[],
  locale?: string
): Promise<Facets[]> => {
  try {
    const { tree } = await getDrupalFunction(
      'getDrupalTaxonomies',
      {
        taxonomyName: TERM_ROUTE_CATEGORY,
        params: getParams(TERM_ROUTE_CATEGORY).getQueryObject(),
        locale: locale,
        defaultLocale: 'es',
        withAuth: true,
      },
      {
        url: '/api/fetch-taxonomies',
        body: JSON.stringify({
          taxonomyName: TERM_ROUTE_CATEGORY,
          params: getParams(TERM_ROUTE_CATEGORY).getQueryObject(),
          locale: locale,
          defaultLocale: 'es',
          withAuth: true,
        }),
      }
    )

    const taxonomiesParent = tree?.filter((tax) =>
      ROUTES_CATEGORIES.includes(tax.id)
    )

    const itemsTermCategories =
      facets.find((facet) => facet.id === CATEGORY_FILTER)?.terms ?? []

    taxonomiesParent.forEach((tax) => {
      const terms: Filter[] = tax.children.map(
        (taxChild) =>
          ({
            ...{
              values: {
                label: taxChild.name,
                count: 0,
                value: taxChild.drupal_internal__tid,
              },
            },
            ...itemsTermCategories.find(
              (item) =>
                item.values.value === taxChild.drupal_internal__tid.toString()
            ),
          }) as Filter
      )

      const facet: Facets = {
        id: tax.id,
        label: tax.name,
        path: CATEGORY_FILTER,
        terms: terms.sort(
          (a, b) => (b.values.count ?? 0) - (a.values.count ?? 0)
        ),
      }
      facets.push(facet)
    })

    return facets.filter((facet) => facet.id !== CATEGORY_FILTER)
  } catch (error) {
    console.error('🚀 ~ error getRouteFacets:', error)
    return []
  }
}

const getItinerariesFacets = async (
  facets: Facets[],
  locale?: string
): Promise<Facets[]> => {
  try {
    const { tree } = await getDrupalFunction(
      'getDrupalTaxonomies',
      {
        taxonomyName: TERM_ITINERARY_CATEGORY,
        params: getParams(TERM_ITINERARY_CATEGORY).getQueryObject(),
        locale: locale,
        defaultLocale: 'es',
        withAuth: true,
      },
      {
        url: '/api/fetch-taxonomies',
        body: JSON.stringify({
          taxonomyName: TERM_ITINERARY_CATEGORY,
          params: getParams(TERM_ITINERARY_CATEGORY).getQueryObject(),
          locale: locale,
          defaultLocale: 'es',
          withAuth: true,
        }),
      }
    )

    const itemsTermServices =
      facets.find((facet) => facet.id === ITINERARIES_FILTER)?.terms ?? []

    tree.forEach((tax) => {
      const childname = tax.children?.map((child) => child.name) ?? []

      const terms: Filter[] = childname.map(
        (name) =>
          ({
            ...{
              values: {
                label: name,
                count: 0,
                value: name,
              },
            },
            ...itemsTermServices.find((item) => item.values.label === name),
          }) as Filter
      )

      const facet: Facets = {
        id: tax.id,
        label: tax.name,
        path: ITINERARIES_FILTER,
        terms: terms.sort(
          (a, b) => (b.values.count ?? 0) - (a.values.count ?? 0)
        ),
      }
      facets.push(facet)
    })

    return facets.filter(
      (facet) =>
        facet.id !== ITINERARIES_FILTER &&
        facet.id !== ITINERARIES_FILTER_SERVICES
    )
  } catch (error) {
    console.error('🚀 ~ error getItinerariesFacets:', error)
    return []
  }
}

const translateFacetsLabels = (facets: Facets[], locale: string = 'es') => {
  const type = facets.find((facets) => facets.id === GENERAL_TYPE)

  const translateFacets =
    facets.find((facet) => facet.id === GENERAL_TYPE)?.terms ?? []

  translateFacets.map(
    (translateFacet) =>
      (translateFacet.values.label =
        translations[locale][translateFacet.values.label])
  )

  return [
    {
      id: type?.id,
      label: type?.label,
      path: type?.path,
      terms: [...translateFacets],
    },
  ]
}
