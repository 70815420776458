import { Title } from '@/components/Title'

import { DrupalParagraphStage } from '../../types'

const Default = ({ paragraph }: { paragraph: DrupalParagraphStage }) => (
  <div className="my-18 bg-neutral-white">
    <div className="container">
      <Title title={paragraph.title} subtitle={paragraph.body} />
    </div>
  </div>
)

export { Default }
