import { Carousel } from '@/components/Carousel'
import { Grid } from '@/components/Grid'
import { NODE_ROUTE_BASE } from '@/components/Node/constants'
import { Node } from '@/components/Node/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { RoutesList } from '@/components/RoutesList'
import {
  VIEW_MODE_CARD_BASIC,
  VIEW_MODE_CARD_TEASER,
} from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'

import { DrupalNodeNextRoutesSearcherPageProps } from './types'

const RoutesSearcherPage = ({
  node,
}: DrupalNodeNextRoutesSearcherPageProps) => {
  const conditionalTranslationKeys = {
    bestRoutes: 'searcher.routes.bestRoutes',
    bestRoutesBody: 'searcher.routes.bestRoutesBody',
    ofInterest: 'node.ofInterest',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      <div className="container">
        <RoutesList
          initialState={{ ...node.response, per_page: 50 }}
          type={NODE_ROUTE_BASE}
          categories={node.categories}
        />
        {!!node.bestRoutes?.length && (
          <Carousel
            title={translations.bestRoutes}
            subtitle={translations.bestRoutesBody}
          >
            {node.bestRoutes.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_TEASER }}
                key={content.id}
              />
            ))}
          </Carousel>
        )}
      </div>

      {!!node.ofInterest?.length && (
        <div className="py-16 mt-18 bg-neutral-gray-100">
          <Grid title={translations.ofInterest} redLine={true} cols={4}>
            {node.ofInterest.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_BASIC }}
                key={content.id}
              />
            ))}
          </Grid>
        </div>
      )}
    </>
  )
}

export { RoutesSearcherPage }
