import { useState } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'

import { ImageViewerProps } from './types'

const ImageViewer = ({ children }: ImageViewerProps) => {
  const [open, setIsOpen] = useState<boolean>(false)

  return (
    <DialogPrimitive.Root open={open} onOpenChange={setIsOpen}>
      {children}
    </DialogPrimitive.Root>
  )
}

ImageViewer.displayName = 'ImageViewer'

export { ImageViewer }
