export const MEDIA_IMAGE_FIELDS = ['image', 'status', 'type']
export const MEDIA_REMOTE_VIDEO_FIELDS = [
  'video_url',
  'media_oembed_video',
  'status',
  'type',
]
export const FILE_IMAGE_FIELDS = ['status', 'type', 'uri']
export const BASIC_TAXONOMY_FIELDS = ['name', 'status', 'type']
export const TAXONOMY_FIELDS = [
  'name',
  'status',
  'type',
  'parent',
  'drupal_internal__tid',
]
export const CARD_BASIC_FIELDS = [
  'title',
  'path',
  'type',
  'body',
  'status',
  'computed_image',
]
export const CARD_BASIC_IMAGE_FIELDS = [
  'title',
  'status',
  'path',
  'type',
  'body',
  'term_accommodation_type',
  'term_category',
  'term_cuisine_type',
  'term_hotel_chain',
  'term_municipality',
  'term_programs',
  'term_services',
  'contents',
  'computed_image',
  'phone',
]

export const PARAGRAPH_GALLERY_FIELDS = [
  'type',
  'status',
  'title',
  'subtitle',
  'body',
  'paragraph_view_mode',
  'paragraph_contents',
  'link',
  'document',
]
export const PARAGRAPH_INTERNAL_CONTENT_FIELDS = ['type', 'status', 'content']

export const PARAGRAPH_MAP_FIELDS = [
  'status',
  'type',
  'title',
  'bool_multipoint',
  'contents',
  'paragraphs',
]
export const PARAGRAPH_EXTERNAL_CONTENT_FIELDS = [
  'type',
  'title',
  'status',
  'body',
  'link',
  'image',
  'document',
  'computed_image',
]
export const PARAGRAPH_ACCORDION_FIELDS = [
  'type',
  'status',
  'title',
  'paragraphs',
  'type',
]
export const PARAGRAPH_ACCORDION_ITEM_FIELDS = [
  'type',
  'status',
  'title',
  'status',
  'type',
  'body',
  'images',
]

export const PARAGRAPH_ITINERARY_FIELDS = [
  'type',
  'status',
  'title',
  'subtitle',
  'body',
  'link',
  'contents',
  'content',
  'bool',
  'background',
]

export const PARAGRAPH_TAGS_FIELDS = [
  'type',
  'title',
  'status',
  'subtitle',
  'body',
  'content_type',
  'terms',
  'contents_tags',
  'village_filter',
  'computed_villages',
]

export const PARAGRAPH_CUSTOM_TEXT_FIELDS = [
  'type',
  'status',
  'title',
  'body',
  'link',
  'documents',
]
export const PARAGRAPH_IMAGE_CUSTOM_TEXT_FIELDS = [
  'type',
  'title',
  'status',
  'body',
  'link',
  'image',
  'paragraph_view_mode',
  'documents',
]

export const PARAGRAPH_CARD_FIELDS = [
  'type',
  'title',
  'status',
  'subtitle',
  'body',
  'content_view_mode',
  'paragraph_view_mode',
  'paragraph_contents',
  'link',
  'village_filter',
  'underlined',
  'bool_popup',
]

export const PARAGRAPH_REMOTE_VIDEO_FIELDS = [
  'status',
  'type',
  'title',
  'subtitle',
  'video',
  'paragraph_view_mode',
]

export const PARAGRAPH_CUSTOM_EXPERIENCE_FIELDS = [
  'type',
  'status',
  'title',
  'subtitle',
  'contents',
]
export const PARAGRAPH_PLACEHOLDER_ITINERARY_MAP_FIELDS = [
  'type',
  'preloaded_itineraries',
]
export const TAXONOMY_INCLUDES = ['parent']

export const MEDIA_DOCUMENT_FIELDS = ['file', 'status', 'name', 'type']
export const FILE_DOCUMENT_FIELDS = ['type', 'status', 'uri', 'filename']

export const PARAGRAPH_RESOURCE_FIELDS = [
  'contents',
  'type',
  'title',
  'status',
  'body',
  'link',
]

export const PARAGRAPH_EVENT_FIELDS = [
  'type',
  'title',
  'status',
  'subtitle',
  'body',
  'link',
  'village_events',
]
