import Link from 'next/link'
import { ImageStyle } from '@/components/ImageStyle'
import { CardImage, CardTitle, Card as CardUI, Heading } from 'ui'

import { BREAKPOINTS } from './constants'
import { HighlightCardProps } from './types'

export const HighlightCard = ({ media, link, ...rest }: HighlightCardProps) => (
  <CardUI {...rest} className="bg-transparent">
    <CardImage>
      <ImageStyle breakpoints={BREAKPOINTS} file={media.image} />
    </CardImage>

    <Link {...link.options} href={link.uri}>
      <CardTitle className="py-3">
        <Heading as="h3" className="text-base font-semibold">
          {link.title}
        </Heading>
      </CardTitle>
    </Link>
  </CardUI>
)
