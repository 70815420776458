import { FormattedMessage } from '@/components/FormattedMessage'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Searcher } from 'ui'

import { useSearchForm } from './useSearchForm'

const SearchForm = ({ placeholder }: { placeholder?: string }) => {
  const {
    state: { querySearch, clearable },
    actions: { setQuerySearch, fetch, remove },
  } = useSearchForm()

  const conditionalTranslationKeys = {
    inputPlaceholder: 'megamenu.searcher.inputPlaceholder',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <Searcher
      text={FormattedMessage({ id: 'global.search' })}
      value={querySearch}
      placeholder={placeholder ?? translations.inputPlaceholder}
      clearable={clearable}
      onClear={remove}
      onKeyDown={(event) => {
        event.key === 'enter' && fetch(event.currentTarget.value)
      }}
      onValueChange={setQuerySearch}
      onSearchClick={fetch}
      className="max-w-lg"
    />
  )
}

export { SearchForm }
