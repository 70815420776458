import NextLink from 'next/link'
import { FormattedMessage } from '@/components/FormattedMessage'
import { Link as LinkIcon } from 'lucide-react'
import { DrupalNode } from 'next-drupal'
import { InfoTag, InfoTagBody, InfoTagHeader } from 'ui'

const Link = ({ node }: { node: DrupalNode }) => (
  <InfoTag>
    <InfoTagHeader>
      <LinkIcon />
      <FormattedMessage id="global.link" />
    </InfoTagHeader>
    <InfoTagBody>
      <NextLink href={node.link.uri}>{node.link.title}</NextLink>
    </InfoTagBody>
  </InfoTag>
)

export { Link }
