import { Dispatch, SetStateAction, useState } from 'react'
import { useRouter } from 'next/navigation'
//import { FormattedMessage } from '@/components/FormattedMessage'
import { DrupalNodeItinerary } from '@/components/Node/components/Itinerary/types'
import { DrupalNodeRoute } from '@/components/Node/components/Route/types'
import { NODE_ITINERARY } from '@/components/Node/constants'
import { HookOutput } from 'utils'

import { useItineraryProvider } from '../../ItineraryProvider'
import { useMapListProvider } from '../../MapListProvider'

type useItinerariesResult = HookOutput<
  {
    currentRoute?: DrupalNodeRoute | DrupalNodeItinerary | null
    selected: DrupalNodeItinerary[]
    isLoading: boolean
  },
  {
    setCurrentRoute: Dispatch<
      SetStateAction<DrupalNodeRoute | DrupalNodeItinerary | null>
    >
    toogleShowItineraries: () => void
    handleClick: (_e: any) => Promise<void>
  }
>

export const useItineraries = (): useItinerariesResult => {
  const {
    state: { currentRoute },
    setCurrentRoute,
  } = useMapListProvider()

  const {
    state: { selected, id },
    toogleShowItineraries,
  } = useItineraryProvider()
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()

  const handleClick = async () => {
    const ids = selected.map((itinerary: DrupalNodeItinerary) => ({
      type: NODE_ITINERARY,
      id: itinerary.id,
    }))

    try {
      setIsLoading(true)
      const response = await fetch('/api/itinerary-resource', {
        method: 'POST',
        body: JSON.stringify({
          ids,
          created: !!!id,
          id,
        }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      router.push(`/my-itinerary/${data}`)
    } catch (error) {
      setIsLoading(false)
      console.error('Error al hacer la petición:', error)
    }
  }

  return {
    state: { currentRoute, selected, isLoading },
    actions: {
      setCurrentRoute,
      toogleShowItineraries,
      handleClick,
    },
  }
}
