import { forwardRef } from 'react'
import { cn } from 'utils-tailwindcss'

import { HeadingStyles } from './Heading.styles'
import { HeadingProps } from './types'

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ as = 'h2', className, children, ...rest }, ref) => {
    const Tag = as
    return (
      <Tag ref={ref} className={cn(HeadingStyles(), className)} {...rest}>
        {children}
      </Tag>
    )
  }
)

Heading.displayName = 'Heading'

export { Heading }
