import { ReactNode } from 'react'

import 'ui'

import { FormattedMessage } from '@/components/FormattedMessage'
import { Separator, Tabs, TabsList, TabsTrigger } from 'ui'
import { useWidth } from 'utils'
import { cn } from 'utils-tailwindcss'

import { EVENTS, EXHIBITIONS } from '../../constants'
import { useListProvider } from '../../ListProvider'
import { ActiveFilterList } from '../ActiveFilterList'

const Content = ({
  children,
  isSearcher,
}: {
  children?: ReactNode
  isSearcher?: boolean
}) => {
  const {
    state: { activeFilters, showResults },
    removeFilterFromList,
    toggleTabs,
  } = useListProvider()
  const { isMobile } = useWidth()

  const events = FormattedMessage({ id: 'searcher.events' })
  const exhibitions = FormattedMessage({ id: 'searcher.exhibitions' })

  return (
    <>
      {!isMobile() && showResults && (
        <>
          <Separator
            color="gray"
            size="lg"
            className={cn(
              (!showResults ||
                activeFilters === undefined ||
                activeFilters?.length === 0) &&
                'mb-0'
            )}
          />
          <div className="container">
            <ActiveFilterList
              filters={activeFilters}
              onDeleteFilter={removeFilterFromList}
            />
          </div>
        </>
      )}
      {isSearcher && (
        <div className="container mt-8 mb-4">
          <Tabs defaultValue={EVENTS}>
            <TabsList>
              <TabsTrigger
                onClick={() => toggleTabs(EVENTS)}
                value={EVENTS}
                className="mr-4"
              >
                {events}
              </TabsTrigger>
              <TabsTrigger
                onClick={() => toggleTabs(EXHIBITIONS)}
                value={EXHIBITIONS}
              >
                {exhibitions}
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      )}

      {children}
    </>
  )
}

export { Content }
