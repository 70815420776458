import {
  CICLA_MADRID_INDEX,
  GRAN_TOUR_INDEX,
} from '@/components/List/constants'
import { NODE_ITINERARY, NODE_PLACE } from '@/components/Node/constants'
import { BikeIcon, MapPin } from 'lucide-react'

export const getIcon = (contentType: string) => {
  if (contentType === NODE_PLACE) {
    return MapPin
  }
  if (contentType === NODE_ITINERARY) {
    return MapPin
  }
  if (contentType === CICLA_MADRID_INDEX || contentType === GRAN_TOUR_INDEX) {
    return BikeIcon
  }
  return MapPin
}
