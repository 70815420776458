import { useMemo } from 'react'
import Link from 'next/link'
import { Carousel } from '@/components/Carousel'
import { Node } from '@/components/Node'
import { BundleDrupalNode } from '@/components/Node/types'
import { VIEW_MODE_CARD_ROUTE } from '@/content/constants'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'

import { DrupalParagraphGrantourProps } from '../../types'

const Default = ({
  paragraph,
}: {
  paragraph: DrupalParagraphGrantourProps
}) => {
  const ITEMS = paragraph.grantour_queue?.[0]?.items

  const urlCiclaGranTour = useMemo(
    () => paragraph.grantour_search?.[0]?.path.alias || '',
    [paragraph]
  )

  const conditionalTranslationKeys = {
    goToGranTour: 'ciclaMadrid.goToGranTour',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return (
    <>
      {!!ITEMS?.length && (
        <div className="custom-container">
          <Carousel
            title={paragraph.title}
            subtitle={paragraph.subtitle}
            body={paragraph.body}
            link={
              !!paragraph.grantour_search?.[0]?.path.alias && (
                <div className="container flex justify-end w-full mt-8 font-bold">
                  <Link href={urlCiclaGranTour} className="text-right">
                    {translations.goToGranTour}
                  </Link>
                </div>
              )
            }
          >
            {ITEMS.map((content: BundleDrupalNode) => (
              <Node
                node={{ ...content, viewMode: VIEW_MODE_CARD_ROUTE }}
                key={content.id}
              />
            ))}
          </Carousel>
        </div>
      )}
    </>
  )
}

export { Default }
