import { Skeleton } from 'ui/src/components/atoms/Skeleton'

export const SkeletonList = () =>
  Array(5)
    .fill(0)
    .map((_, index) => (
      <div
        className="flex items-center justify-between w-full gap-4 px-3 pt-4"
        key={index}
      >
        <div className="flex flex-col w-4/6 gap-4">
          <Skeleton className="w-full h-16" />
          <Skeleton className="w-full h-3" />
        </div>
        <Skeleton className="w-32 h-24 rounded-2xl" />
      </div>
    ))
