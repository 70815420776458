import { DATE_FILTER } from '@/components/List/constants'
import { Filter } from '@/components/List/types'
import { CloseButton, Tag } from 'ui'

const MobileActiveFilters = ({
  id,
  activeFilters,
  onDeleteFilter,
}: {
  id: string
  activeFilters?: Filter[]
  onDeleteFilter: (_filters: Filter[] | undefined) => void
}) => {
  const remove = (filter: Filter) => {
    const newFilters = activeFilters?.filter(
      (item: Filter) => item.values.value !== filter.values.value
    )
    onDeleteFilter(newFilters?.length ? newFilters : undefined)
  }

  return (
    <div className="flex flex-wrap w-full gap-2 pb-4">
      {activeFilters?.map((item) => {
        if (item.type === id && item.type !== DATE_FILTER) {
          return (
            <Tag className="flex gap-2 w-fit" key={item.values.value}>
              {item.values.label}
              <CloseButton onClick={() => remove(item)} />
            </Tag>
          )
        }
      })}
    </div>
  )
}
export { MobileActiveFilters }
