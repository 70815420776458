import { DrupalParagraphCiclaMadridSearchProps } from './types'
import { Default } from './viewmodes/Default'

const CiclaMadridSearch = ({
  paragraph,
  viewMode,
  ...rest
}: DrupalParagraphCiclaMadridSearchProps) => (
  <div className={`${paragraph.type} view-mode-${viewMode}`}>
    {(() => {
      switch (viewMode) {
        default:
          return <Default paragraph={paragraph} {...rest} />
      }
    })()}
  </div>
)

export { CiclaMadridSearch }
