import { TaxonomyTerm } from '@/components/TaxonomyTerm'
import { useCustomTranslations } from '@/lib/utils/useCustomTranslations'
import { Activity } from 'lucide-react'
import { InfoTag, InfoTagBody, InfoTagHeader } from 'ui'

const Difficulty = ({ term }) => {
  const conditionalTranslationKeys = {
    difficulty: 'global.difficulty',
  }

  const translations: { [key: string]: string } = useCustomTranslations(
    conditionalTranslationKeys
  )

  return term.id === 'missing' ? (
    <></>
  ) : (
    <InfoTag>
      <InfoTagHeader>
        <Activity />
        {translations.difficulty}
      </InfoTagHeader>
      <InfoTagBody>
        <TaxonomyTerm term={term} />
      </InfoTagBody>
    </InfoTag>
  )
}

export { Difficulty }
